import React, { useEffect, useState } from 'react';
import { TableManager } from '../Managers/TableManager';
import {
  DKTooltipWrapper,
  DKLabel,
  DKCheckMark,
  DKButton,
  INPUT_TYPE,
  DKIcons,
  DKListPicker
} from 'deskera-ui-library';
import {
  COUNTRY_CODES,
  DOCUMENT_TYPE,
  DOC_TYPE,
  PRODUCT_TYPE
} from '../Constants/Constant';
import { TABLES } from '../Constants/TableConstants';
import { DraftTypes } from '../Models/Drafts';
import DKDropdown from '../Components/Dashboard/DKDropdown';
import { CONTACT_TRANSACTION_TYPE } from '../Constants/Enum';
import Utility, { getCapitalized } from '../Utility/Utility';
import { ConfigUtility } from '../Utility/ConfigUtility';
import { useAppSelector } from '../Redux/Hooks';
import { activeTenantInfo } from '../Redux/Slices/AuthSlice';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../Constants/Permission';
import { isSalesOrderVisible } from './DocumentForm/NewDocumentHelper';
import { localizedText } from '../Services/Localization/Localization';
import {
  JC_STATUS,
  JOB_CARD_COLS,
  JOB_CARD_STATUS
} from '../Components/Mrp/Constants/MRPColumnConfigs';
import { isTabletView } from '../Utility/ViewportSizeUtils';
import { WorkOrderHelper } from '../Components/Mrp/WorkOrder/WorkOrderHelper';
import ApprovalOrReject from './FulfillmentPopup/ApprovalOrRejectPopup';

interface IContextMenuProps {
  tableName: string;
  displayTableName: string;
  className?: string;
  needBoldTheme?: boolean;
  onDelete?: () => void;
  onFulfill?: () => void;
  onConsolidateSO?: () => void;
  onConvertWO?: () => void;
  onReceived?: () => void;
  onBulkPoConvert?: () => void;
  onCancel?: () => void;
  onApply?: (data: any) => void;
  docType?: string;
  bulkPaymentRows?: any;
  bulkReceivedGoodsRows?: any;
  bulkFulfillmentRows?: any;
  bulkPrintRows?: any;
  consolidateRows?: any;
  barcodeRowData?: any[];
  generateBarcode?: () => void;
  onBulkPayment?: () => void;
  onDeactivateClick?: () => void;
  onBulkDeleteClick?: () => void;
  selection?: any;
  showSelectedRecordsCount?: boolean;
  showDeactivateBtn: {
    btnText: string;
    showBtn: boolean;
    className: string;
    showDeleteBtn: boolean;
    showDocumentTransactionBtn: boolean;
  };
  showSendEmail?: {
    btnText: string;
    className: string;
    hoverTitle: string;
    showEmailBtn: boolean;
  };
  onSendEmail?: () => void;
  bulkApprovalRows?: any;
  onBulkApproval?: (data:any,remarks:string) => void;
  bulkRejectRows?: any;
  onBulkReject?: (data?:any,remarks?:string) => void;
  bulkEWBRows?: any[];
  jobCardRows?: any[];
  onBulkJCStatusChange?: (data: any) => void;
  bulkPendingEInvoicesRows?: any[];
  bulkGeneratedEInvoicesRows?: any[];
  onBulkEWBGenerate?: () => void;
  onBulkPrintChecks?: () => void;
  onBulkPrint?: () => void;
  onBulkPendingEInvoice?: () => void;
  onBulkGeneratedEInvoice?: () => void;
  bulkPrintChecksRows?: any;
  documentTransaction?: (data: CONTACT_TRANSACTION_TYPE) => void;
  permissionModule?: any;
  displayBulkGenerateBankFile?: boolean;
  bulkGenerateBankFile?: () => void;
}
const ContextMenu = (props: IContextMenuProps) => {
  let columnsToShow: any[] = [];
  if (props.tableName !== TABLES.BOOKKEEPER_CLIENT) {
    columnsToShow = TableManager.getTableColumns(props.tableName)?.filter(
      (column) =>
        (column.type === INPUT_TYPE.SELECT ||
          column.type === INPUT_TYPE.MULTI_SELECT) &&
        column.editable
    );
  } else {
    if (props.tableName === TABLES.BOOKKEEPER_CLIENT) {
      columnsToShow = ConfigUtility.getStaticConfigColumns(
        TABLES.BOOKKEEPER_CLIENT
      );
    }
  }

  const isBulkConsolidateBtnVisible = () => {
    let isBtnVisible = false;

    if (
      props.docType === DOCUMENT_TYPE.SALES_ORDER &&
      props.consolidateRows &&
      props.consolidateRows.length > 1
    ) {
      isBtnVisible = true;

      for (const item of props.consolidateRows) {
        if (
          item.linkedWorkOrderDocuments &&
          Array.isArray(item.linkedWorkOrderDocuments)
        ) {
          const hasWorkOrder = item.linkedWorkOrderDocuments.some(
            (doc: any) => doc.documentType === DOC_TYPE.WORK_ORDER
          );

          isBtnVisible = !hasWorkOrder;
        }
      }
    }

    return isBtnVisible;
  };
  const isConvertWOBtnVisible = () => {
    let isBtnVisible = false;

    if (
      props.docType === DOCUMENT_TYPE.SALES_ORDER &&
      props.consolidateRows &&
      props.consolidateRows?.length > 0
    ) {
      let soHasBOM = false;
      let soConvertedFromWo = false;
      props?.consolidateRows?.forEach((soItem: any) => {
        let soHasBOMObject = soItem?.salesOrderItems?.some(
          (item: any) => item?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
        );
        soHasBOM = soHasBOMObject || soHasBOM;
        let woConvertedInSO =
          WorkOrderHelper.isSOConvertedFromWorkOrder(soItem);
        soConvertedFromWo = soConvertedFromWo || woConvertedInSO;
      });
      if (soHasBOM && !soConvertedFromWo) {
        props.consolidateRows.forEach((record: any) => {
          let tmpList = record.salesOrderItems.filter(
            (e: any) => e.productQuantity - e.qtyConvertedToWo !== 0
          );
          if (tmpList && tmpList.length > 0) {
            isBtnVisible = true;
          }
        });
      }
    }

    return isBtnVisible;
  };

  // Approval/Reject PopUp

  const [showApprovalOrRejectPopup, setShowApprovalOrRejectPopup] =
    useState<boolean>(false);

  const [approvalOrRejectData, setApprovalOrRejectData] = useState<any>({
    title: '',
    data: null
  });

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [count, setCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [opData, setOpData] = useState({});
  const [selectedJCBulkStatusData, setSelectedJCBulkStatusData] =
    useState<any>();
  const [displayReceivedGoods, setDisplayReceivedGoods] = useState(
    (props.docType === DOCUMENT_TYPE.BILL ||
      props.docType === DOCUMENT_TYPE.PURCHASE_ORDER) &&
      props.bulkReceivedGoodsRows &&
      props.bulkReceivedGoodsRows.length > 0
  );
  const [displayConvertBulkPo, setDisplayConvertBulkPo] = useState(
    props.docType === DOCUMENT_TYPE.REQUISITION
  );
  const [displayFulfillment, setDisplayFulfillment] = useState(
    (props.docType === DOCUMENT_TYPE.INVOICE ||
      (props.docType === DOCUMENT_TYPE.QUOTATION && !isSalesOrderVisible())) &&
      props.bulkFulfillmentRows &&
      props.bulkFulfillmentRows.length > 0
  );
  const [displayConsolidateSOBtn, setDisplayConsolidateSOBtn] = useState(
    isBulkConsolidateBtnVisible()
  );
  const [displayConvertWOBtn, setDisplayConvertWOBtn] = useState(
    isConvertWOBtnVisible()
  );

  const [displayBulkPayment, setDisplayBulkPayment] = useState(
    (props.docType === DOCUMENT_TYPE.INVOICE ||
      props.docType === DOCUMENT_TYPE.BILL) &&
      props.bulkPaymentRows &&
      props.bulkPaymentRows.length > 0
  );

  const [displayPendingEInvoice, setDisplayPendingEInvoice] = useState(
    props.docType === DOCUMENT_TYPE.INVOICE &&
      props.bulkPendingEInvoicesRows &&
      props.bulkPendingEInvoicesRows.length > 0
  );

  const [displayGeneratedEInvoice, setDisplayGeneratedEInvoice] = useState(
    props.docType === DOCUMENT_TYPE.INVOICE &&
      props.bulkGeneratedEInvoicesRows &&
      props.bulkGeneratedEInvoicesRows.length > 0
  );

  const [displayBulkEWB, setDisplayBulkEWB] = useState(
    props.docType === DOCUMENT_TYPE.INVOICE &&
      props.bulkEWBRows &&
      props.bulkEWBRows.length > 0
  );

  const [displayJCBulkStatus, setDisplayJCBulkStatus] = useState(
    props.tableName === TABLES.JOB_CARDS &&
      props.jobCardRows &&
      props.jobCardRows.length > 0
  );

  const [displayBulkApproval, setDisplayBulkApproval] = useState(
    props.docType === DraftTypes.DRAFT &&
      props.bulkApprovalRows &&
      props.bulkApprovalRows.length > 0
  );
  const [displayBulkReject, setDisplayBulkReject] = useState(
    props.docType === DraftTypes.DRAFT &&
      props.bulkRejectRows &&
      props.bulkRejectRows.length > 0
  );

  const [displayBulkPrint, setDisplayBulkPrint] = useState(
    props.bulkPrintRows &&
      props.bulkPrintRows.length > 1 &&
      props.bulkPrintRows.length < 11
  );

  const [displayGenerateBarcode, setDisplayGenerateBarcode] = useState(
    props.barcodeRowData && props.barcodeRowData.length > 1
  );

  const [displayBulkPrintChecks, setDisplayBulkPrintChecks] = useState(
    (props.docType === DOC_TYPE.BILL ||
      props.docType === DOC_TYPE.EXPENSE ||
      props.docType === DOC_TYPE.PREPAYMENT ||
      props?.bulkPrintChecksRows?.[0]?.rowType === DOC_TYPE.BILL ||
      props?.bulkPrintChecksRows?.[0]?.rowType === DOC_TYPE.EXPENSE ||
      props?.bulkPrintChecksRows?.[0]?.rowType === DOC_TYPE.PREPAYMENT) &&
      props?.bulkPrintChecksRows &&
      props?.bulkPrintChecksRows?.length > 0
  );

  const [showGenerateBankFileOptions, setShowGenerateBankFileOptions] = useState(false);

  const getDocumentTransactionOptions = () => {
    let options: any[] = [];

    if (checkUserPermission(PERMISSIONS_BY_MODULE.BILL.CREATE)) {
      options.push(CONTACT_TRANSACTION_TYPE.BILL);
    }

    if (checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.CREATE)) {
      options.push(CONTACT_TRANSACTION_TYPE.INVOICE);
    }

    if (checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.CREATE)) {
      Utility.isUSorg()
        ? options.push(CONTACT_TRANSACTION_TYPE.ESTIMATE)
        : options.push(CONTACT_TRANSACTION_TYPE.QUOTE);
    }

    if (checkUserPermission(PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE)) {
      options.push(CONTACT_TRANSACTION_TYPE.ORDER);
    }
    if (isSalesOrderVisible()) {
      options.push(CONTACT_TRANSACTION_TYPE.SALES_ORDER);
    }

    return options;
  };

  useEffect(() => {
    if (columnsToShow) setColumns(JSON.parse(JSON.stringify(columnsToShow)));
  }, [count]);

  useEffect(() => {
    setDisplayBulkEWB(
      props.docType === DOCUMENT_TYPE.INVOICE &&
        props.bulkEWBRows &&
        props.bulkEWBRows.length > 0
    );
    setDisplayBulkPayment(
      (props.docType === DOCUMENT_TYPE.INVOICE ||
        props.docType === DOCUMENT_TYPE.BILL) &&
        props.bulkPaymentRows &&
        props.bulkPaymentRows.length > 0
    );
    setDisplayConsolidateSOBtn(isBulkConsolidateBtnVisible());
    setDisplayConvertWOBtn(isConvertWOBtnVisible());
    setDisplayPendingEInvoice(
      props.docType === DOCUMENT_TYPE.INVOICE &&
        props.bulkPendingEInvoicesRows &&
        props.bulkPendingEInvoicesRows.length > 0
    );
    setDisplayGeneratedEInvoice(
      props.docType === DOCUMENT_TYPE.INVOICE &&
        props.bulkGeneratedEInvoicesRows &&
        props.bulkGeneratedEInvoicesRows.length > 0
    );
    setDisplayReceivedGoods(
      (props.docType === DOCUMENT_TYPE.BILL ||
        props.docType === DOCUMENT_TYPE.PURCHASE_ORDER) &&
        props.bulkReceivedGoodsRows &&
        props.bulkReceivedGoodsRows.length > 0
    );
    setDisplayConvertBulkPo(props.docType === DOCUMENT_TYPE.REQUISITION);
    setDisplayFulfillment(
      (props.docType === DOCUMENT_TYPE.INVOICE ||
        (props.docType === DOCUMENT_TYPE.QUOTATION &&
          !isSalesOrderVisible())) &&
        props.bulkFulfillmentRows &&
        props.bulkFulfillmentRows.length > 0
    );
    setDisplayBulkApproval(
      props.docType === DraftTypes.DRAFT &&
        props.bulkApprovalRows &&
        props.bulkApprovalRows.length > 0
    );
    setDisplayBulkReject(
      props.docType === DraftTypes.DRAFT &&
        props.bulkRejectRows &&
        props.bulkRejectRows.length > 0
    );

    setDisplayBulkPrint(
      props.bulkPrintRows &&
        props.bulkPrintRows.length > 1 &&
        props.bulkPrintRows.length < 11
    );
    setDisplayBulkPrintChecks(
      (props.docType === DOC_TYPE.BILL ||
        props.docType === DOC_TYPE.EXPENSE ||
        props.docType === DOC_TYPE.PREPAYMENT ||
        props?.bulkPrintChecksRows?.[0]?.rowType === DOC_TYPE.BILL ||
        props?.bulkPrintChecksRows?.[0]?.rowType === DOC_TYPE.EXPENSE ||
        props?.bulkPrintChecksRows?.[0]?.rowType === DOC_TYPE.PREPAYMENT) &&
        props?.bulkPrintChecksRows &&
        props?.bulkPrintChecksRows?.length > 0
    );
    setDisplayGenerateBarcode(
      props.barcodeRowData && props.barcodeRowData.length > 1
    );
  }, [props]);

  useEffect(() => {
    const anyJCCompletedOrCancelled = props.jobCardRows?.some(
      (jc: any) =>
        jc.status === JOB_CARD_STATUS.COMPLETED ||
        jc.status === JOB_CARD_STATUS.CANCELLED
    );
    setDisplayJCBulkStatus(
      props.tableName === TABLES.JOB_CARDS &&
        props.jobCardRows &&
        props.jobCardRows.length > 0 &&
        !anyJCCompletedOrCancelled
    );
  }, [props.jobCardRows]);

  const onSelect = (column: any, data: any) => {
    setOpData({ [column.id]: data });
    setCount((prevCount) => prevCount + 1);
  };

  const onApply = () => {
    if (props.onApply) props.onApply(opData);
  };

  const checkDeleteButtonAllowed = (tableName: string) => {
    let _notAllowedDeleteButton: any[] = [
      TABLES.CONTACT,
      TABLES.CURRENCY,
      TABLES.FY_CLOSING,
      TABLES.COA,
      TABLES.PICK_PACK_SHIP_PICKING,
      TABLES.EWAY_BILL,
      TABLES.PRINT_CHECKS,
      TABLES.BOOKKEEPER_CLIENT
    ];
    let allowed: boolean = true;
    if (
      _notAllowedDeleteButton.findIndex((table) => table === tableName) > -1
    ) {
      allowed = false;
    }

    if (!checkUserPermission(props?.permissionModule?.DELETE)) {
      allowed = false;
    }

    return allowed;
  };

  const _checkModulePrintPermission = () => {
    return checkUserPermission(props?.permissionModule?.PRINT) ? true : false;
  };

  const onGenerateBarcodeBtnClick = () => {
    if (props.generateBarcode) props.generateBarcode();
  };

  const getJCStatusDropDown = () => {
    let colsToShow: any[] = [];
    colsToShow = JOB_CARD_COLS.filter((col: any) => col.key === 'status').map(
      (col: any) => {
        return {
          ...col,
          options: col.options?.map((opt: any) => {
            return {
              ...opt,
              name: opt.id === 'COMPLETED' ? ' Completed ' : opt.name
            };
          })
        };
      }
    );
    if (colsToShow.length > 0) {
      return (
        <>
          {colsToShow.map((column: any) => {
            return (
              <SelectList
                {...column}
                className={`mr-r`}
                onSelect={(data: any) => {
                  let selectedJobCards =
                    props.jobCardRows && props.jobCardRows.length > 0
                      ? [...props.jobCardRows]
                      : [];
                  setSelectedJCBulkStatusData({
                    status: data?.id,
                    selectedJobCards
                  });
                }}
              />
            );
          })}
          {!Utility.isEmpty(selectedJCBulkStatusData) && (
            <DKButton
              title="Apply"
              className="border-green mr-r bg-green text-white"
              onClick={() => {
                if (props.onBulkJCStatusChange) {
                  props.onBulkJCStatusChange(selectedJCBulkStatusData);
                  setSelectedJCBulkStatusData(null);
                }
              }}
            />
          )}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="parent-width pt-2 pr-r pb-xs row">
      {props.displayTableName && (
        <DKLabel
          text={
            !Utility.isEmpty(props.displayTableName)
              ? props.displayTableName
              : ''
          }
          className={`fs-r text-align-left fw-m mr-l ${
            props.needBoldTheme ? 'fs-xxxl fw-b' : 'fs-l'
          }`}
        />
      )}
      <div className={`row`} style={{ width: 'auto' }}>
        {columns.map((column: any) => {
          switch (column.type) {
            case INPUT_TYPE.MULTI_SELECT:
              return (
                <MultiSelectList
                  {...column}
                  className={`mr-r`}
                  onSelect={(data: any) => onSelect(column, data)}
                />
              );
            case INPUT_TYPE.SELECT:
              return (
                <SelectList
                  {...column}
                  className={`mr-r`}
                  onSelect={(data: any) => onSelect(column, [data])}
                />
              );
          }
        })}

        {props.showSelectedRecordsCount && (
          <DKLabel
            text={`${props.selection?.length} ${
              props.selection?.length > 1 ? 'Records' : 'Record'
            } Selected`}
            className="fs-r text-align-left fs-l mr-l"
          />
        )}
        {displayFulfillment && (
          <DKButton
            title={localizedText('Fulfill')}
            className="mr-r bg-button text-white"
            onClick={() => (props.onFulfill ? props.onFulfill() : false)}
          />
        )}
        {displayConsolidateSOBtn && Utility.isMRPWithURLCheck() && (
          <DKButton
            title={'Consolidate to WO'}
            className="mr-r bg-button text-white"
            onClick={() =>
              props.onConsolidateSO ? props.onConsolidateSO() : false
            }
          />
        )}
        {displayConvertWOBtn && Utility.isMRPWithURLCheck() && (
          <DKButton
            title={'Convert to WO'}
            className="mr-r bg-button text-white"
            onClick={() => (props.onConvertWO ? props.onConvertWO() : false)}
          />
        )}

        {displayReceivedGoods && (
          <DKButton
            title="Receive"
            className="mr-r bg-button text-white"
            onClick={() => (props.onReceived ? props.onReceived() : false)}
          />
        )}
        {displayConvertBulkPo && (
          <DKButton
            title="Bulk PO Conversion"
            className="mr-r bg-button text-white"
            onClick={() =>
              props.onBulkPoConvert ? props.onBulkPoConvert() : false
            }
          />
        )}
        {displayBulkPayment && (
          <DKButton
            title="Bulk Payment"
            className="mr-r bg-button text-white"
            onClick={() =>
              props.onBulkPayment ? props.onBulkPayment() : false
            }
          />
        )}
        {displayBulkEWB && tenantInfo.country === COUNTRY_CODES.IN && (
          <DKButton
            title="Generate E-Way Bill"
            className="mr-r bg-button text-white"
            onClick={() =>
              props.onBulkEWBGenerate ? props.onBulkEWBGenerate() : false
            }
          />
        )}
        {displayJCBulkStatus && getJCStatusDropDown()}
        {displayPendingEInvoice && tenantInfo.country === COUNTRY_CODES.IN && (
          <DKButton
            title="Generate e-Invoice"
            className="mr-r bg-button text-white"
            onClick={() =>
              props.onBulkPendingEInvoice
                ? props.onBulkPendingEInvoice()
                : false
            }
          />
        )}
        {displayGeneratedEInvoice &&
          tenantInfo.country === COUNTRY_CODES.IN && (
            <DKButton
              title="Cancel eInvoice"
              className="mr-r bg-button text-white"
              onClick={() =>
                props.onBulkGeneratedEInvoice
                  ? props.onBulkGeneratedEInvoice()
                  : false
              }
            />
          )}

        {displayBulkApproval && (
          <DKButton
            title="Approve"
            className="mr-r bg-button text-white"
            onClick={() => {
              setShowApprovalOrRejectPopup(true);
              setApprovalOrRejectData({
                title: 'Approve',
                data: props.onBulkApproval,
                selection: props.selection
              });
            }}
          />
        )}

        {displayBulkReject && (
          <DKButton
            title="Reject"
            className="mr-r bg-button text-white"
            onClick={() => {
              setShowApprovalOrRejectPopup(true);
              setApprovalOrRejectData({
                title: 'Reject',
                data: props.onBulkReject,
                selection: props.selection
              });
            }}
          />
        )}
        {displayBulkPrintChecks && (
          <DKButton
            title={`Print ${getCapitalized(localizedText('cheque'))}s`}
            className="mr-r bg-button text-white"
            onClick={() =>
              props.onBulkPrintChecks ? props.onBulkPrintChecks() : false
            }
          />
        )}

        {displayBulkPrint &&
          !isTabletView() &&
          _checkModulePrintPermission() && (
            <DKButton
              title="Bulk Print"
              className="mr-r bg-button text-white"
              onClick={() => (props.onBulkPrint ? props.onBulkPrint() : false)}
            />
          )}

        {props.showDeactivateBtn.showBtn && (
          <DKButton
            title={props.showDeactivateBtn.btnText}
            className={props.showDeactivateBtn.className}
            onClick={() =>
              props.onDeactivateClick ? props.onDeactivateClick() : false
            }
          />
        )}

        {props.showDeactivateBtn.showBtn &&
          props.showDeactivateBtn.showDeleteBtn && (
            <DKButton
              title="Delete"
              className="mr-r bg-red text-white"
              onClick={() =>
                props.onBulkDeleteClick ? props.onBulkDeleteClick() : false
              }
            />
          )}

        {props?.showSendEmail?.showEmailBtn &&
          props.showSendEmail.showEmailBtn && (
            <DKTooltipWrapper
              tooltipClassName="width-auto"
              content={props.showSendEmail.hoverTitle}
            >
              <DKButton
                title={props.showSendEmail.btnText}
                className="mr-r bg-blue text-white"
                onClick={() =>
                  props.onSendEmail ? props.onSendEmail() : false
                }
              />
            </DKTooltipWrapper>
          )}
        {GranularPermissionsHelper.isAnyDocCreatePermissionAvailable() &&
          props.showDeactivateBtn.showDocumentTransactionBtn && (
            <div className="bg-white border-l border-radius-m p-h-s p-v-s shadow-s-2 mr-r">
              <DKDropdown
                title={'Create New'}
                data={getDocumentTransactionOptions()}
                onSelect={(index: number) =>
                  props.documentTransaction
                    ? props.documentTransaction(
                        getDocumentTransactionOptions()[index]
                      )
                    : null
                }
                titleClass="text-black"
              />
            </div>
        )}

        {props.displayBulkGenerateBankFile && (
          <div className="position-relative">
            <DKButton
                title={"Generate Bank File"}
                className="mr-r bg-button text-white"
                onClick={() => {
                  if (props.bulkGenerateBankFile) {
                    props.bulkGenerateBankFile()
                  }
                }}
              />
          </div>
        )}

        {checkDeleteButtonAllowed(props.tableName) && (
          <DKButton
            title="Delete"
            className="mr-r bg-red text-white"
            onClick={() => (props.onDelete ? props.onDelete() : false)}
          />
        )}

        {Object.keys(opData).length > 0 && (
          <DKButton
            title="Apply"
            className="border-green mr-r bg-green text-white"
            onClick={() => onApply()}
          />
        )}
        {displayGenerateBarcode && (
          <DKButton
            title="Generate Barcode"
            className="mr-r bg-blue text-white"
            onClick={() => {
              onGenerateBarcodeBtnClick();
            }}
          />
        )}
        <DKButton
          icon={DKIcons.ic_close}
          className="border-m mr-r bg-white"
          onClick={() => (props.onCancel ? props.onCancel() : false)}
        />
      </div>
      {showApprovalOrRejectPopup && (
        <ApprovalOrReject
          data={approvalOrRejectData}
          actionData={{
            isBulkAction: true,
            action: approvalOrRejectData.data
          }}
          setShowApprovalOrRejectPopup={setShowApprovalOrRejectPopup}
          title={`${approvalOrRejectData.title} ${props.displayTableName}`}
        ></ApprovalOrReject>
      )}
    </div>
  );
};
const SelectList = (props: any) => {
  const [showList, setShowList] = useState(false);
  const [options, setOptions] = useState(props.options);

  const onOptionSelect = (index: number) => {
    let newOptions = [...options].filter((item, i) => {
      item.selected = false;
      if (index === i) {
        item.selected = true;
        props.onSelect(item);
      }
      return item;
    });
    setOptions(newOptions);
    setShowList(false);
  };
  const getStatusCard = (value: any) => {
    return JC_STATUS[value as keyof typeof JC_STATUS] || {};
  };
  const getOptionList = () => {
    return (
      <div
        className="option-list-wrapper column bg-white p-s shadow-l"
        style={{
          position: 'absolute',
          zIndex: 2
        }}
      >
        {options.map((option: any, index: number) => {
          let jcOptionColor: any;
          jcOptionColor = getStatusCard(option.id);
          const jcOptionClasses = jcOptionColor
            ? `row justify-content-center p-h-s border-radius-m p-v-xs bg-chip-${jcOptionColor.color} text-${jcOptionColor.color} border-${jcOptionColor.color}`
            : '';
          return (
            <div
              key={index}
              className="p-xs cursor-hand row"
              onClick={() => onOptionSelect(index)}
            >
              <DKLabel text={option.name} className={jcOptionClasses} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={`multi-select-list parent-width fw-m ${props.className}`}
      style={props.style}
    >
      <DKButton
        className={`border-m bg-white shadow-s ${
          options.find((i: any) => i.selected) ? 'border-blue text-blue' : ''
        }`}
        icon={showList ? DKIcons.ic_arrow_up2 : DKIcons.ic_arrow_down2}
        isReverse={true}
        onClick={() => setShowList(!showList)}
        title={
          options.find((i: any) => i.selected)
            ? options.find((i: any) => i.selected).name
            : props.name
        }
      />
      {showList && getOptionList()}
    </div>
  );
};
const MultiSelectList = (props: any) => {
  const [showList, setShowList] = useState(false);
  const [options, setOptions] = useState(props.options);

  const onOptionSelect = (index: any) => {
    let newOptions = [...options];
    newOptions[index]['selected'] = !newOptions[index]['selected'];
    setOptions(newOptions);
    props.onSelect(newOptions.filter((option) => option.selected));
  };

  const getOptionList = () => {
    return (
      <div
        className="option-list-wrapper column bg-white p-s shadow-l"
        style={{
          position: 'absolute',
          zIndex: 2
        }}
      >
        {options.map((option: any, index: number) => {
          return (
            <div key={index} className="p-xs cursor-hand row">
              <DKCheckMark
                isSelected={option.selected}
                onClick={() => onOptionSelect(index)}
              />
              <DKLabel text={option.name} />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={`multi-select-list parent-width fw-m ${props.className}`}
      style={props.style}
    >
      <DKButton
        className={`border-m bg-white shadow-s ${
          options.find((i: any) => i.selected) ? 'border-blue text-blue' : ''
        }`}
        icon={showList ? DKIcons.ic_arrow_up2 : DKIcons.ic_arrow_down2}
        isReverse={true}
        onClick={() => setShowList(!showList)}
        title={
          options.find((i: any) => i.selected)
            ? options.find((i: any) => i.selected).name
            : props.name
        }
      />
      {showList && getOptionList()}
    </div>
  );
};
export default ContextMenu;
