import {
  DKButton,
  DKDataGrid,
  DKIcons,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { map } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import ic_bom_allocate_green from '../../../Assets/Icons/ic_bom_allocate_green.svg';
import ic_bom_allocate_red from '../../../Assets/Icons/ic_bom_allocate_red.svg';
import ic_expand from '../../../Assets/Icons/ic_expand.svg';
import ic_no_data from '../../../Assets/Icons/ic_no_data.png';
import ic_shrink from '../../../Assets/Icons/ic_shrink.svg';
import {
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  CUSTOM_FIELD_TYPE,
  DOCUMENT_MODE,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  MODULE_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION,
  RECORD_SAVED_EVENT_DOC_TYPE,
  STATUS_TYPE,
  STOCK_TRANSFER_EMPTY,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import { CustomField } from '../../../Models/CustomField';
import {
  StockTransfer,
  StockTransferInitialState,
  StockTransferItems
} from '../../../Models/StockTransfer';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import {
  fetchClassesByDimensionId,
  fetchCustomFields,
  selectCustomFields,
  selectProductCustomFields,
  selectShowMainDocsInFullScreen,
  selectUOMs,
  selectWarehouse,
  setShowMainDocsInFullScreen
} from '../../../Redux/Slices/CommonDataSlice';
import { fetchCategoryDimensions } from '../../../Redux/Slices/LocationSlice';
import {
  fetchStockTransferBrief,
  fetchStockTransfers,
  selectStockTransferColumnConfig,
  selectStockTransferColumnConfigTableId,
  selectStockTransferProduct
} from '../../../Redux/Slices/StockTransferSlice';
import { fetchWorkOuts } from '../../../Redux/Slices/WorkOutSlice';
import DateFormatService from '../../../Services/DateFormat';
import http from '../../../Services/Interceptor';
import ProductService from '../../../Services/Product';
import StockTransferService, {
  StockTransferAPIConfig
} from '../../../Services/StockTransfer';
import {
  COMMON_EVENTS,
  commonCustomEvent
} from '../../../Services/event/commonEvents';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import { customFieldsContainsErrors } from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import { DocumentConfigUtility } from '../../../Utility/DocumentConfigUtility';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../../Utility/Utility';
import { isTabletView } from '../../../Utility/ViewportSizeUtils';
import { getNewColumn } from '../../Accounting/JournalEntry/JEHelper';
import AddClass from '../../Settings/Classes/AddClass';
import StockTransferAdvancedBatchTrackingPopup from './StockTransferAdvancedBatchTrackingPopup';
import StockTransferAdvancedSerialTracking from './StockTransferAdvancedSerialTrackingPopup';
import StockTransferDetailPopup from './StockTransferDetailPopup';
import StockTransferProductWarehouseManagement from './StockTransferProductWarehouseManagement';
import { isEqual } from 'lodash';
import MRPProductsService from '../../../Services/MRP/MRPProducts';
import {
  getApprovalConfig,
  getIsApprovalFlowEnabled
} from '../StockAdjustment/StockAdjustmentHelper';
export default function AddStockTransferPopup(props: any) {
  const dispatch = useAppDispatch();
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [warehouseProduct, setWarehouseProduct] = useState<any>({});
  const [stockTransferNotes, setStockTransferNotes] = useState<any>('');
  const [showWarehouseManagement, setShowWarehouseManagement] = useState(false);
  const [showTransferDetailPopup, setShowTransferDetailPopup] = useState(false);
  const [rowBeingEdited, setRowBeingEdited] = useState<any>(null);
  const [showBatchTransferPopup, setShowBatchTransferPopup] = useState(false);
  const [showSerialTrackingPopup, setShowSerialTrackingPopup] = useState(false);

  const [stockTransferState, setStockTransferState] = useState<StockTransfer>(
    StockTransferInitialState
  );
  const [defaultAllocationWarehouse, setDefaultAllocationWarehouse] =
    useState<any>('');
  const tenantInfo = useAppSelector(activeTenantInfo);
  const productList = useAppSelector(selectStockTransferProduct);
  const uomData = useAppSelector(selectUOMs);
  // const productBriefData = useAppSelector(selectStockTransferProductBrief);
  const [productBriefData, setProductBriefData] = useState<any>();
  const [selectedWarehouseProductsData, setSelectedWarehouseProductsData] =
    useState([]);
  const [productData, setProductData] = useState<any[]>([]);
  const columnConfig = useAppSelector(selectStockTransferColumnConfig);
  const isEdit = useRef<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<any>();
  const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);
  const productCFfromStore = useAppSelector(selectProductCustomFields);

  const columnConfigTableId: any = useAppSelector(
    selectStockTransferColumnConfigTableId
  );
  // Tablet Attributes
  const [tempProduct, setTempProduct] = useState<any>(props.tempProduct);

  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );
  const isFullScreenMode = !!props.allowFullScreen && showMainDocsInFullscreen;
  const productIdListRef = useRef<string[] | null>(null);

  const callProductShortInfoApi = () => {
    let productIds: { productCode: string; quantity: number }[] = [];
    let stockTransferItems = props?.selectedRecord?.stockTransferItems ?? [];
    if (Utility.isEmptyObject(stockTransferItems)) {
      stockTransferItems = props?.detail?.stockTransferItems ?? [];
    }
    stockTransferItems?.forEach((item: any) => {
      if (item?.product?.bomProductsConfiguration?.length) {
        item?.product?.bomProductsConfiguration.forEach((configProd: any) =>
          productIds.push({
            productCode: configProd.productCode,
            quantity: configProd.quantity
          })
        );
      }
      if (item.productCode && item.productQuantity) {
        productIds.push({
          productCode: item.productCode,
          quantity: item.productQuantity
        });
      } else {
        if (item.productVariantCode && item.quantity) {
          productIds.push({
            productCode: item.productVariantCode,
            quantity: item.quantity
          });
        }
      }
    });
    if (Utility.isNotEmpty(productIds)) {
      MRPProductsService.getProductShortInfo(productIds).then((res: any) =>
        setProductBriefData(res)
      );
    }
  };

  useEffect(() => {
    setDefaultActiveWarehouse();

    if (Utility.isNotEmpty(props.selectedRecord)) {
      callProductShortInfoApi();
    } else if (Utility.isNotEmpty(props.detail)) {
      if (
        !Utility.isEmpty(props.detail) &&
        !Utility.isEmpty(props?.document) &&
        props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        callProductShortInfoApi();
      } else if (props.detail.itemName) {
        let tmpState = { ...StockTransferInitialState };
        tmpState.stockTransferItems = [];
        if (uomData && uomData.length > 0) {
          let uom = uomData.filter(
            (u: any) => u.id === props.detail.itemName.stockUom
          );
          if (uom && uom.length > 0) {
            let stockItem: StockTransferItems = {
              productName: props.detail.itemName.name,
              productVariantCode: props.detail.itemName.pid,
              documentSequenceCode:
                props.detail?.itemName?.documentSequenceCode,
              documentUom: props.detail.itemName.stockUom,
              documentUOMSchemaDefinition: props?.detail
                ?.documentUOMSchemaDefinition
                ? props?.detail?.documentUOMSchemaDefinition
                : uom[0],
              quantity: props?.detail?.itemName?.availableQuantity,
              stockTransferWarehouseInventoryData: null,
              availableQuantity:
                props?.detail?.itemName?.availableQuantity -
                props?.detail?.itemName?.reservedQuantity,
              requiredQuantity: props?.detail?.requiredQty,
              type: 'Tracked',
              product: props.detail.itemName,
              rowButtons: getButtonsForRow({})
            };

            tmpState.stockTransferItems.push(stockItem);
            setStockTransferState(tmpState);
          }
        }
      }
    } else {
      let tmpState = { ...StockTransferInitialState };
      tmpState.stockTransferItems = [
        {
          productName: '',
          productVariantCode: '',
          documentUom: 0,
          documentUOMSchemaDefinition: {},
          quantity: 0,
          availableQuantity: 0,
          stockTransferWarehouseInventoryData: '',
          type: null,
          product: null,
          rowButtons: []
        }
      ];
      setStockTransferState(tmpState);
    }
  }, []);

  const getTrackingIconDetail = (row: any) => {
    let icon = ic_no_data;
    let disabled = false;
    if (Utility.isNotEmpty(props?.selectedRecord)) {
      return { icon, disabled, readOnly: true };
    }
    if (Utility.isNotEmpty(row?.stockTransferWarehouseInventoryData)) {
      icon = ic_bom_allocate_green;
      disabled = true;
    } else {
      icon = ic_bom_allocate_red;
      disabled = false;
    }
    return { icon, disabled, readOnly: false };
  };

  useEffect(() => {
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];
      prodCFs.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setProductCustomFields(prodCFs);
    }
  }, [productCFfromStore]);

  useEffect(() => {
    if (productList && productList.length > 0) {
      setProductData(productList);
    }
  }, [productList]);

  useEffect(() => {
    if (
      Utility.isNotEmpty(productBriefData) &&
      Utility.isNotEmpty(props.selectedRecord)
    ) {
      updateEditData();
    }
  }, [productBriefData]);

  useEffect(() => {
    if (
      !Utility.isEmpty(props?.detail) &&
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      Utility.isNotEmpty(productBriefData)
    ) {
      updateProductData();
    }
    if (isTabletView() && tempProduct) {
      showLoader('Please wait...');
      const queryString: string = getProductApi(tempProduct.name);
      http
        .get(`${queryString}`)
        .then((data: any) => {
          let newEntryJSON = STOCK_TRANSFER_EMPTY;
          newEntryJSON.rowData.product = data.find(
            (item: any) => tempProduct.productId === item.pid
          );
          rowEdit(newEntryJSON);
          removeLoader();
        })
        .catch((err: any) => {
          removeLoader();
          console.error('Error loading audit log@: ', err);
          return Promise.reject(err);
        });
    }
  }, [productBriefData, selectedWarehouseProductsData]);

  useEffect(() => {
    const updatedProductIdList =
      stockTransferState?.stockTransferItems
        ?.map((item) => item?.product?.pid ?? item?.productVariantCode)
        .filter((productId) => Utility.isNotEmpty(productId)) || [];

    if (
      Utility.isEmpty(updatedProductIdList) ||
      isEqual(updatedProductIdList, productIdListRef.current)
    )
      return;

    ProductService.fetchWarehouseProductsByID(updatedProductIdList)
      .then((res) => {
        if (Utility.isEmpty(res?.warehouses)) return;

        productIdListRef.current = updatedProductIdList;
        const tempStockTransferState = { ...stockTransferState };
        tempStockTransferState.stockTransferItems =
          tempStockTransferState.stockTransferItems.map((item) => {
            const newItem = {
              ...item,
              availableQuantity: getProductAvailableQuantity(
                item.product?.pid ?? item.productVariantCode,
                item.product,
                res.warehouses
              )
            };

            return newItem;
          });
        setSelectedWarehouseProductsData(res.warehouses);
        setStockTransferState(tempStockTransferState);
      })
      .catch((err) => {
        console.error('Failed to fetch warehouse products info', err);
      });
  }, [stockTransferState]);

  useEffect(() => {
    setShowTransferDetailPopup(Utility.isNotEmpty(rowBeingEdited));
  }, [rowBeingEdited]);

  const getProductApi = (search: string) => {
    const config: StockTransferAPIConfig = {
      ...StockTransferService.apiConfig,
      SearchTerm: search
    };
    StockTransferService.apiConfig = config;
    return StockTransferService.getProductEndpoint();
  };

  const getProductData = (data: any) => {
    return data
      ? data?.filter(
          (ele: any) =>
            ele.type !== PRODUCT_TYPE.NON_TRACKED && !ele.hasVariants
        )
      : [];
  };

  const localColumnConfig = [
    {
      name: 'Product Name',
      index: 0,
      options: null,
      required: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'product',
      id: 'product',
      datasource: [],
      key: 'product',
      lineItem: false,
      type: INPUT_TYPE.DROPDOWN,
      formatter: (value: any) => {
        return value?.rowData?.productName;
      },
      width: 250,
      dropdownConfig: {
        title: 'Select Product',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 250 },
        className: 'shadow-m width-auto',
        searchApiConfig: {
          getUrl: getProductApi,
          dataParser: getProductData,
          debounceTime: 300
        },
        data: productData,
        renderer: DocumentConfigUtility.productRenderer,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      id: 'documentSequenceCode',
      key: 'documentSequenceCode',
      name: 'Product Code',
      type: INPUT_TYPE.TEXT,
      width: 160,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'Available Qty',
      index: 0,
      textAlign: 'right',
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'availableQuantity',
      id: 'availableQuantity',
      datasource: [],
      key: 'availableQuantity',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 225,
      formatter: (obj: any = 0) => {
        return `${Number(obj.value).toFixed(tenantInfo?.decimalScale)}`;
      }
    }
  ];

  const buttonAction = (rowData: any, index: any) => {
    if (Utility.isEmpty(rowData?.product)) {
      showAlert('Error', 'Please select product first.');
      return;
    }
    const trackingType = rowData?.product?.advancedTracking;
    setCurrentIndex(index);
    if (
      trackingType !== TRACKING_TYPE.BATCH &&
      trackingType !== TRACKING_TYPE.SERIAL
    ) {
      setWarehouseProduct({
        ...rowData.product,
        requiredQuantity: Utility.roundingOff(
          Number(rowData?.requiredQuantity),
          QTY_ROUNDOFF_PRECISION
        )
      });
      setShowWarehouseManagement(true);
    } else if (trackingType === TRACKING_TYPE.BATCH) {
      setShowBatchTransferPopup(true);
      setWarehouseProduct({
        ...rowData.product,
        requiredQuantity: Utility.roundingOff(
          Number(rowData?.requiredQuantity),
          QTY_ROUNDOFF_PRECISION
        )
      });
    } else if (trackingType === TRACKING_TYPE.SERIAL) {
      setShowSerialTrackingPopup(true);
      setWarehouseProduct({
        ...rowData.product,
        requiredQuantity: rowData?.requiredQuantity
      });
    }
  };

  const getButtonsForRow = (data: any) => {
    let buttons: any[] = [];
    const { icon, disabled, readOnly } = getTrackingIconDetail(data);
    buttons.push({
      icon,
      disabled,
      className: 'p-v-0 text-blue underline grid-action-link-h-padding',
      onClick: ({ rowIndex, rowData }: any) => {
        if (!readOnly) {
          buttonAction(rowData, rowIndex);
        } else {
          setRowBeingEdited(rowData);
        }
      }
    });
    const allowRowDelete = props?.document !== DOC_TYPE.JOB_WORK_OUT_ORDER;
    if (!isEdit?.current && allowRowDelete) {
      buttons.push({
        icon: DKIcons.ic_delete,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: ({ rowIndex, rowData }: any) => {
          let copyOfStateTransferState = { ...stockTransferState };
          copyOfStateTransferState.stockTransferItems.splice(rowIndex, 1);
          setStockTransferState(copyOfStateTransferState);
        }
      });
    }

    return buttons;
  };

  const warehousesData: any = useAppSelector(selectWarehouse);

  const updateEditData = () => {
    let copyOfStockTransferState = { ...stockTransferState };

    copyOfStockTransferState.transferDate =
      DateFormatService.getDateStrFromDate(
        new Date(props.selectedRecord.transferDate),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
    let updatedItems: any[] = [];
    isEdit.current = true;
    copyOfStockTransferState.transferCost = props.selectedRecord.transferCost;
    props.selectedRecord.stockTransferItems.forEach((item: any, index: any) => {
      let product = productBriefData?.filter(
        (e: any) => e.pid === item.productVariantCode
      );
      if (product && product.length > 0) {
        if (uomData && uomData.length > 0) {
          let uom = uomData.filter((u: any) => u.id === item.documentUom);
          let mock = {
            productName: product[0].name,
            productVariantCode: product[0].pid,
            documentSequenceCode: product[0].productSequenceCode
              ? product[0].productSequenceCode
              : product[0].documentSequenceCode
              ? product[0].documentSequenceCode
              : '',
            documentUOMSchemaDefinition:
              item?.documentUOMSchemaDefinition ?? uom[0],
            quantity: item.quantity,
            stockTransferWarehouseInventoryData:
              item.stockTransferWarehouseInventoryData,
            availableQuantity: getProductAvailableQuantity(
              product[0]?.pid,
              product[0]
            ),
            type: product[0]?.type,
            product: product[0],
            rowButtons: getButtonsForRow(item)
          };
          updatedItems.push(mock);
        }
      }
    });

    props.selectedRecord.stockTransferItems?.forEach(
      (item1: any, index1: any) => {
        item1?.customField?.forEach((item: any, index: any) => {
          let filteredCF: any = selectCustomFieldsData?.content?.find(
            (cf: any) => cf.code === item.code
          );
          if (!Utility.isEmpty(filteredCF)) {
            let cfValue;
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              cfValue = DateFormatService.getDateFromStr(
                item.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === item.value
              );
              if (tempCF) {
                cfValue = tempCF ? tempCF : '';
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
            ) {
              cfValue = item ? item : '';
            } else {
              cfValue = item.value ? item.value : '';
            }
            updatedItems[index1][item.id] = cfValue;
          }
        });
      }
    );
    copyOfStockTransferState.stockTransferItems = updatedItems;

    setStockTransferState(copyOfStockTransferState);
    setStockTransferNotes(props.selectedRecord.notes);
  };

  const updateProductData = () => {
    let tmpState: any = { ...StockTransferInitialState };
    tmpState.stockTransferItems = [];

    if (uomData && uomData.length > 0) {
      props?.detail?.stockTransferItems?.forEach((item: any) => {
        if (item?.product?.bomProductsConfiguration?.length) {
          item?.product?.bomProductsConfiguration?.forEach((bomItem: any) => {
            // ADHOC bom has Service product too hence filtering service products as per req.
            if (bomItem.productType !== PRODUCT_TYPE.NON_TRACKED) {
              let product = productBriefData?.filter(
                (e: any) => e.pid === bomItem.productCode
              );

              let uom = uomData.filter(
                (u: any) => u.id === item?.product?.stockUom
              );

              let stockItem: StockTransferItems = {
                requiredQuantity:
                  Number(item?.productQuantity) * Number(bomItem?.quantity),
                productName: product[0]?.name,
                productVariantCode: product[0]?.pid,
                quantity: item.quantity || 0,
                stockTransferWarehouseInventoryData: null,
                availableQuantity:
                  getProductAvailableQuantity(product[0]?.pid, product[0]) || 0,
                type: product[0]?.type,
                product: product[0],
                documentSequenceCode: product[0]?.documentSequenceCode,
                rowButtons: getButtonsForRow({}),
                documentUom: item?.documentUom ?? item?.product?.stockUom,
                documentUOMSchemaDefinition:
                  item?.documentUOMSchemaDefinition ?? uom[0],
                originalRequiredQuantity:
                  Number(item?.pendingQuantity) * Number(bomItem?.quantity)
              };

              if (
                props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
                props.detail?.dispatchedQtyItems
              ) {
                stockItem['dispatchedQty'] =
                  props.detail.dispatchedQtyItems.find(
                    (dispatchedItem: any) =>
                      dispatchedItem.productCode ===
                      stockItem.productVariantCode
                  )?.dispatchedQty || 0;
              } else if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
                stockItem['dispatchedQty'] = 0;
              }

              let checkExistProductInListIndex =
                tmpState.stockTransferItems.findIndex((stockItem: any) => {
                  return stockItem.productVariantCode === bomItem.productCode;
                });

              if (checkExistProductInListIndex <= -1) {
                tmpState.stockTransferItems.push(stockItem);
              } else if (checkExistProductInListIndex >= 0) {
                tmpState.stockTransferItems[
                  checkExistProductInListIndex
                ].requiredQuantity += stockItem.requiredQuantity;
                tmpState.stockTransferItems[
                  checkExistProductInListIndex
                ].originalRequiredQuantity +=
                  stockItem.originalRequiredQuantity;
              }
            }
          });
        }
      });
      setStockTransferState(tmpState);
    }
  };

  const validateData = (warehouses?: any) => {
    setApiCallInProgress(true);
    if (Utility.isEmpty(stockTransferState.transferDate)) {
      showAlert('Error', 'Please select transfer date');
      setApiCallInProgress(false);
      return false;
    }

    if (Utility.isEmpty(stockTransferState.stockTransferItems)) {
      showAlert('Error', 'Please select at least one product to transfer');
      setApiCallInProgress(false);
      return false;
    }

    let isStockTransferWarehouseInventoryData = false;
    stockTransferState.stockTransferItems.forEach((item: any) => {
      if (Utility.isEmpty(item.stockTransferWarehouseInventoryData)) {
        isStockTransferWarehouseInventoryData = true;
      }
    });

    if (isStockTransferWarehouseInventoryData) {
      showAlert('Error', 'Please assign transfer details for all products');
      setApiCallInProgress(false);
      return false;
    }
    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      stockTransferState.customField
    );
    if (customFieldHasErrors) {
      setApiCallInProgress(false);
      return false;
    }
    // Custom fields validation ends

    let invalidItems: string[] = [];
    let invalidItemsProductCodes: any = [];
    let warehouseQtyList = deepClone(warehouses);
    stockTransferState.stockTransferItems.forEach((record: any) => {
      // let isNegativeInventory =
      //   tenantInfo.allowNegativeInventory &&
      //   record.productType === PRODUCT_TYPE.TRACKED;
      // if (!isNegativeInventory) {
      if (
        !Utility.isEmpty(record.stockTransferWarehouseInventoryData) &&
        record.stockTransferWarehouseInventoryData.advancedTrackingType ===
          ADVANCE_TRACKING.NONE
      ) {
        let ele = record.stockTransferWarehouseInventoryData;
        warehouseQtyList.forEach((warehouse: any) => {
          if (
            warehouse.code === ele.srcWarehouseCode &&
            warehouse?.productAvailableQuantity?.hasOwnProperty(
              record.productVariantCode
            )
          ) {
            if (
              Number(ele.quantity) <=
              warehouse.productAvailableQuantity[record.productVariantCode]
            ) {
              warehouse.productAvailableQuantity[record.productVariantCode] =
                warehouse.productAvailableQuantity[record.productVariantCode] -
                Number(ele.quantity);
            } else {
              if (
                !invalidItemsProductCodes.includes(record.productVariantCode)
              ) {
                invalidItemsProductCodes.push(record.productVariantCode);
                invalidItems.push(record.productName);
              }
            }
          }
        });
      } else if (
        record.stockTransferWarehouseInventoryData.advancedTrackingData &&
        record.stockTransferWarehouseInventoryData.advancedTrackingData
          ?.length > 0 &&
        record.stockTransferWarehouseInventoryData.advancedTrackingType ===
          ADVANCE_TRACKING.SERIAL
      ) {
        let transferItem = record.stockTransferWarehouseInventoryData;
        record.stockTransferWarehouseInventoryData.advancedTrackingData.forEach(
          (ele: any) => {
            warehouseQtyList.forEach((warehouse: any) => {
              if (
                warehouse.code === transferItem.srcWarehouseCode &&
                warehouse.productAvailableQuantity?.hasOwnProperty(
                  record.productVariantCode
                )
              ) {
                if (
                  Number(ele.qtyToTransfer) <=
                  warehouse.productAvailableQuantity[record.productVariantCode]
                ) {
                  warehouse.productAvailableQuantity[
                    record.productVariantCode
                  ] =
                    warehouse.productAvailableQuantity[
                      record.productVariantCode
                    ] - Number(ele.qtyToTransfer);
                } else {
                  if (
                    !invalidItemsProductCodes.includes(
                      record.productVariantCode
                    )
                  ) {
                    invalidItemsProductCodes.push(record.productVariantCode);
                    invalidItems.push(record.productName);
                  }
                }
              }
            });
          }
        );
      } else if (
        record.stockTransferWarehouseInventoryData.advancedTrackingData &&
        record.stockTransferWarehouseInventoryData.advancedTrackingData
          ?.length > 0 &&
        record.stockTransferWarehouseInventoryData.advancedTrackingType ===
          ADVANCE_TRACKING.BATCH
      ) {
        let transferItem = record.stockTransferWarehouseInventoryData;
        // record.stockTransferWarehouseInventoryData.advancedTrackingData.forEach(
        //   (ele: any) => {
        warehouseQtyList.forEach((warehouse: any) => {
          warehouse.productAdvancedTrackingMeta?.[
            record.productVariantCode
          ]?.forEach((ele: any) => {
            if (warehouse.code === transferItem.srcWarehouseCode) {
              record.stockTransferWarehouseInventoryData.advancedTrackingData?.forEach(
                (batch: any) => {
                  if (ele.serialBatchNumber === batch.sourceSerialBatchNumber) {
                    if (
                      Number(batch.qtyToTransfer) <=
                      ele.batchSize - ele.batchSizeFulfilled
                    ) {
                      ele.batchSize =
                        ele.batchSize - Number(batch.qtyToTransfer);
                    } else {
                      if (
                        !invalidItemsProductCodes.includes(
                          record.productVariantCode
                        )
                      ) {
                        invalidItemsProductCodes.push(
                          record.productVariantCode
                        );
                        invalidItems.push(record.productName);
                      }
                    }
                  }
                }
              );
            }
          });
        });
      }
    });
    if (invalidItems && invalidItems.length > 0) {
      let invalidItemsStr = '';
      invalidItems?.forEach((invalidItem: string, index: number) => {
        if (invalidItems.length > 1 && index === invalidItems.length - 1) {
          invalidItemsStr += ` and ${invalidItem}`;
        } else if (invalidItems.length === 1) {
          invalidItemsStr += `${invalidItem}`;
        } else {
          invalidItemsStr += `${invalidItem}, `;
        }
      });
      showAlert(
        'Error',
        `${invalidItemsStr} ${
          invalidItems.length > 1 ? 'have' : 'has'
        } insufficient quantity.`
      );
      setApiCallInProgress(false);
      return false;
    }
    return true;
  };

  const getProductAvailableQuantity = (
    productcode: any,
    product: any,
    warehouseData = selectedWarehouseProductsData
  ) => {
    let reserveQty = 0;
    let availableQty = 0;
    warehouseData.length &&
      warehouseData?.forEach((ele: any) => {
        reserveQty += ele.productReservedQuantity[product?.pid]
          ? ele.productReservedQuantity[product?.pid]
          : 0;
        availableQty += ele.productAvailableQuantity[product?.pid]
          ? ele.productAvailableQuantity[product?.pid]
          : 0;
      });
    return product ? availableQty - reserveQty : 0;
  };

  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = getDataGridColumns();
    let colConfigsWithOnlyCF = oldColConfigs?.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(selectCustomFieldsData?.content)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = selectCustomFieldsData?.content.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              new Date(lineItem[cf.id]),
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'STOCKTRANSFER',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };

  const createStockTransfer = (warehouses?: any) => {
    if (validateData(warehouses)) {
      setApiCallInProgress(true);
      let item: any[] = [];
      stockTransferState.stockTransferItems.forEach((data: any) => {
        let d = {
          productName: data.productName,
          productVariantCode: data.productVariantCode,
          documentUom: data.documentUOMSchemaDefinition.id,
          documentUOMSchemaDefinition: data.documentUOMSchemaDefinition,
          quantity: data?.stockTransferWarehouseInventoryData?.quantity,
          stockTransferWarehouseInventoryData:
            data.stockTransferWarehouseInventoryData,
          customField: getLineItemCFs(data)
        };

        item.push(d);
      });
      let data: any = {
        ...stockTransferState,
        notes: stockTransferNotes,
        transferDate: stockTransferState.transferDate,
        stockTransferItems: item,
        customField: stockTransferState?.customField?.length
          ? stockTransferState?.customField
          : null,
        linkedDocuments:
          !Utility.isEmpty(props?.document) &&
          (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER ||
            props?.document === DOC_TYPE.WORK_ORDER)
            ? props?.detail?.linkedDocuments
            : []
      };
      data['approvalConfig'] = getApprovalConfig(DOC_TYPE.STOCK_TRANSFER);
      data['isApprovalFlowEnabled'] = getIsApprovalFlowEnabled(
        DOC_TYPE.STOCK_TRANSFER
      );
      if (
        props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
        props.detail.allStockTransferItems
      ) {
        data['fgQtyPayload'] = [...props.detail.allStockTransferItems].map(
          (fg: any) => {
            return {
              jwoiCode: fg.jobWorkOutOrderItemCode,
              lineNumber: fg.lineNumber,
              fgCode: fg.productCode,
              fgQty: fg.productQuantity
            };
          }
        );
      }
      StockTransferService.createStockTransfer(data)
        .then((res: any) => {
          dispatch(fetchStockTransfers());
          setApiCallInProgress(false);
          onSaveComplete(res);
          dispatch(fetchWorkOuts());
          commonCustomEvent.dispatch(COMMON_EVENTS.RECORD_SAVED, {
            id: null,
            type: RECORD_SAVED_EVENT_DOC_TYPE.DISPATCHED_GOODS,
            linkedDocId: res?.linkedDocuments?.[0]?.documentCode,
            linkedDocType: RECORD_SAVED_EVENT_DOC_TYPE.JOB_WORK_OUT,
            isEdit: true
          });
        })
        .catch((error: any) => {
          setApiCallInProgress(false);
          showAlert(
            'Error',
            error?.data?.message ?? 'Unable to save stock transfer'
          );
        });
    }
  };

  const validateRequiredQty = () => {
    let isValid = true;
    let isRequiredQtyErrorMsg = '';
    if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      let remainingRequiredQty = 0;
      stockTransferState.stockTransferItems.forEach((stockItem) => {
        if (props.detail?.dispatchedQtyItems) {
          const dispatchedItems = props.detail.dispatchedQtyItems.find(
            (dispatchedItem: any) =>
              dispatchedItem.productCode === stockItem.productVariantCode
          );
          stockItem['dispatchedQty'] = dispatchedItems?.dispatchedQty || 0;
          if (!stockItem['requiredQuantity']) {
            isValid = false;
            isRequiredQtyErrorMsg = `Required Qty should not be 0.`;
          }

          remainingRequiredQty =
            dispatchedItems['requiredQty'] - dispatchedItems['dispatchedQty'];
        } else {
          remainingRequiredQty = Number(stockItem['originalRequiredQuantity']); // originalRequiredQuantity i.e. BOM pending Qty * RM
        }
        if (
          !(
            (remainingRequiredQty > 0 &&
              remainingRequiredQty >= Number(stockItem['requiredQuantity'])) ||
            Utility.isJWOReceiveDispatchAdditionalQtyEnable()
          )
        ) {
          isValid = false;
          isRequiredQtyErrorMsg = `Required Qty is more than FG quantity.`;
        }
      });
    }
    if (!isValid) {
      showAlert('Please Note!', isRequiredQtyErrorMsg, [
        {
          title: 'Ok',
          className: 'bg-button text-white',
          onClick: () => {
            // props.onCancel();
          }
        }
      ]);
    }
    return isValid;
  };
  const saveStockTransfer = () => {
    if (!validateRequiredQty()) {
      return;
    }
    if (Utility.isEmpty(props.selectedRecord)) {
      let ids = map(
        stockTransferState.stockTransferItems,
        'productVariantCode'
      );
      setApiCallInProgress(true);
      ProductService.fetchWarehouseProductsByID(ids)
        .then((response: any) => {
          createStockTransfer(response?.warehouses);
        })
        .catch((err: any) => {
          setApiCallInProgress(false);
          createStockTransfer(setSelectedWarehouseProductsData);
          console.log(err, 'product fetch failed');
        });
    } else {
      setApiCallInProgress(true);
      let data = {
        transferCost: stockTransferState.transferCost,
        code: props.selectedRecord.code,
        customField: stockTransferState?.customField?.length
          ? stockTransferState?.customField
          : null,
        notes: stockTransferNotes
      };
      StockTransferService.updateStockTransfer(data)
        .then((res: any) => {
          dispatch(fetchStockTransfers());
          setApiCallInProgress(false);
          onSaveComplete(res);
        })
        .catch((error: any) => {
          setApiCallInProgress(false);
          showAlert('Error', 'Unable to update stock transfer');
        });
    }
  };

  const onSaveComplete = (res: any) => {
    if (tempProduct) {
      setTempProduct(undefined);
    }
    props.onSave(res);
  };

  const setDefaultActiveWarehouse = () => {
    let activeWarehouses =
      warehousesData &&
      warehousesData.content &&
      warehousesData.content.filter(
        (item: any) => item.active && !item.rejected
      );
    if (activeWarehouses && activeWarehouses.length > 0) {
      let selectedWarehouse = activeWarehouses.filter(
        (warehouse: any) => warehouse.primary === true
      );
      if (selectedWarehouse[0] !== undefined && selectedWarehouse[0] !== null) {
        setDefaultAllocationWarehouse(selectedWarehouse[0].code);
      }
    }
  };

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };

  const getShrinkExpandButton = () => {
    return (
      <DKButton
        icon={showMainDocsInFullscreen ? ic_shrink : ic_expand}
        onClick={() => {
          dispatch(setShowMainDocsInFullScreen(!showMainDocsInFullscreen));
        }}
        disabled={apiCallInProgress}
        className="border-m mr-r"
      />
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle gap-2">
          <DKLabel
            text={
              !props?.isDraft
                ? Utility.isEmpty(props.selectedRecord)
                  ? `Add ${
                      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
                        ? `Dispatch Goods`
                        : `Stock Transfer`
                    }`
                  : `Edit ${
                      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
                        ? `Dispatch Goods`
                        : `Stock Transfer`
                    }`
                : `View Stock Transfer`
            }
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          {props.allowFullScreen && getShrinkExpandButton()}
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          {!props?.isDraft && (
            <div
              className={`row border-radius-m ${
                apiCallInProgress
                  ? ' bg-gray1 text-gray  border-m pr-2'
                  : ' bg-button text-white'
              }`}
            >
              <DKButton
                title={apiCallInProgress ? 'Saving' : 'Save'}
                onClick={() => {
                  !apiCallInProgress && saveStockTransfer();
                }}
              />
              {apiCallInProgress && <DKSpinner iconClassName="ic-s" />}
            </div>
          )}
        </div>
      </div>
    );
  };

  const onTransferDateChange = (inpDate: any) => {
    if (
      validateAndUpdateDate(
        inpDate,
        DateFormatService.getDateFromStr(
          tenantInfo.bookBeginningStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      )
    ) {
      showAlert(
        'Invalid Date',
        `Stock transfer date cannot be before books beginning date.`
      );
      inpDate = new Date();
    }
    if (!Utility.checkClosingDate(inpDate, 'Stock Transfer Date')) {
      inpDate = new Date();
    }
    let tmpStockTransfer = { ...stockTransferState };
    tmpStockTransfer.transferDate = DateFormatService.getDateStrFromDate(
      inpDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );
    setStockTransferState(tmpStockTransfer);
  };

  const validateAndUpdateDate = (newDate: Date, minAcceptedDate: Date) => {
    if (newDate.getTime() < minAcceptedDate.getTime()) {
      return true;
    } else {
      return false;
    }
  };

  const addProductCustomFieldsToLineItem = (lineItem: any, product: any) => {
    let cfs: any[] = [];
    if (
      !Utility.isEmpty(productCustomFields) &&
      !Utility.isEmpty(product.customField)
    ) {
      // Set default values of CFs when new line is added
      product.customField.forEach((productCF: any) => {
        const filteredCF = productCustomFields?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DROPDOWN.toLowerCase()
            ) {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.value === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
    }
    return { ...lineItem, customField: cfs };
  };

  const rowEdit = (data: any) => {
    let tmpStockTransferState = { ...stockTransferState };
    let tmpStockTransferItemState = tmpStockTransferState.stockTransferItems;
    let selectedRows = tmpStockTransferItemState[data.rowIndex];
    if (data.columnKey === 'product') {
      StockTransferService.fetchProductDocSeqCode([
        data.rowData.product.documentSequenceCode
      ])
        .then((res: any) => {
          if (uomData && uomData.length > 0) {
            let uom = uomData.filter((u: any) => u.id === res[0].stockUom);
            if (uom && uom.length > 0) {
              let stockItem: StockTransferItems = {
                productName: data.rowData.product.name,
                productVariantCode: data.rowData.product.pid,
                documentSequenceCode: data.rowData.product.documentSequenceCode,
                documentUom: res.stockUom,
                documentUOMSchemaDefinition: uom[0],
                quantity: data.rowData.product.availableQuantity,
                stockTransferWarehouseInventoryData: null,
                availableQuantity: getProductAvailableQuantity(
                  data.rowData.product.documentSequenceCode,
                  data.rowData.product
                ),
                type: data.rowData.product.type,
                product: data.rowData.product,
                rowButtons: getButtonsForRow({})
              };
              stockItem = addProductCustomFieldsToLineItem(
                { ...stockItem },
                res[0]
              );
              if (Utility.isEmpty(selectedRows)) {
                tmpStockTransferState.stockTransferItems.push(stockItem);
              } else {
                tmpStockTransferState.stockTransferItems[data.rowIndex] =
                  stockItem;
              }
              setStockTransferState(tmpStockTransferState);
            }
          }
        })
        .catch((error: any) => {});
    }
  };

  const uomFormatter = (obj: any) => {
    const value = obj.rowData?.documentUOMSchemaDefinition;
    let isUomSchema =
      obj.rowData?.documentUOMSchemaDefinition?.sourceUOM &&
      obj.rowData?.documentUOMSchemaDefinition?.sinkUOM;
    if (obj?.rowData?.requiredQuantity && isUomSchema) {
      return `${
        DocumentConfigUtility.getBaseUomName(value)?.name
      } <br><span style="font-size: 10px; color:gray; margin-top: 3px;">${
        value?.sourceConversionFactor
      } ${DocumentConfigUtility.getBaseUomName(value)?.name} = ${
        value?.sinkConversionFactor
      } ${value.name}</span>`;
    } else {
      return value?.sourceUOM
        ? DocumentConfigUtility.getBaseUomName(value)?.name
        : value?.name;
    }
  };

  const getDataGridColumns = () => {
    let columns: any = [...localColumnConfig];
    if (!Utility.isEmpty(props?.detail)) {
      let availableQuantityColumnIndex = columns.findIndex(
        (column: any) => column.columnCode === 'availableQuantity'
      );

      if (availableQuantityColumnIndex > -1) {
        columns[availableQuantityColumnIndex].width = 170;
      }

      columns.splice(1, 0, {
        name: 'Required Qty',
        index: 0,
        options: null,
        required: false,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'requiredQuantity',
        id: 'requiredQuantity',
        datasource: [],
        key: 'requiredQuantity',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 170,
        formatter: (obj: any = 0) => {
          return `${Utility.roundingOff(obj.value, QTY_ROUNDOFF_PRECISION)}`;
        }
      });

      columns.splice(2, 0, {
        name: 'UOM',
        index: 0,
        options: null,
        required: false,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'uom',
        id: 'uom',
        datasource: [],
        key: 'uom',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 170,
        formatter: uomFormatter
      });
    } else {
      columns.splice(3, 0, {
        name: 'UOM',
        index: 0,
        options: null,
        required: false,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'uom',
        id: 'uom',
        datasource: [],
        key: 'uom',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 170,
        formatter: uomFormatter
      });
    }
    if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      columns.splice(2, 0, {
        name: 'Dispatched Qty',
        index: 0,
        options: null,
        required: false,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'dispatchedQty',
        id: 'dispatchedQty',
        datasource: [],
        key: 'dispatchedQty',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 170
      });
    }
    if (isEdit?.current && Utility.isEmpty(props?.detail)) {
      let availableQuantityColumnIndex = columns.findIndex(
        (column: any) => column.columnCode === 'availableQuantity'
      );

      if (availableQuantityColumnIndex > -1) {
        columns[availableQuantityColumnIndex].width = 170;
      }
      columns.splice(availableQuantityColumnIndex, 1, {
        name: 'Transferred Qty',
        index: 0,
        options: null,
        required: false,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'quantity',
        id: 'quantity',
        datasource: [],
        key: 'quantity',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 200
      });
    }

    let activeStockTransferCustomFields =
      selectCustomFieldsData?.content?.filter((item: any) => {
        return (
          item.modules?.includes('STOCKTRANSFER') &&
          item.status === STATUS_TYPE.ACTIVE
        );
      });
    activeStockTransferCustomFields = activeStockTransferCustomFields?.filter(
      (ele: any) => ele.label === LOCATION_CLASS_ENUM.CLASS
    );

    activeStockTransferCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);

      const newItemInExistingColConfig = columns.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        newItem['dropdownConfig']['button'] = {
          title: '+ Add New',
          className: 'bg-button text-white',
          onClick: () => setShowAddClassPopup(true)
        };

        columns.push({ ...newItem, hidden: hideClassColumn() });
      }
    });

    let activeProductCustomFields = selectCustomFieldsData?.content?.filter(
      (item: any) => {
        return (
          item.modules?.includes('PRODUCT') &&
          item.status === STATUS_TYPE.ACTIVE
        );
      }
    );

    activeProductCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      const newItemInExistingColConfig = columns.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        columns.push({ ...newItem });
      }
    });

    columns.push({
      key: 'actions',
      name: 'Actions',
      type: INPUT_TYPE.BUTTON,
      options: [],
      width: 180
    });
    columns = columns.filter((col: any) => !col.hidden);
    return columns;
  };

  const getLineItemGrid = () => {
    return (
      <>
        <DKDataGrid
          styles={{
            mainGridHolder: { maxHeight: 320, paddingBottom: 0 },
            shadowHolder: { maxHeight: 300 }
          }}
          needShadow={false}
          needBorder={true}
          needColumnIcons={false}
          needTrailingColumn={true}
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowRowEdit={!Utility.isEmpty(props.selectedRecord) ? false : true}
          columns={getDataGridColumns()}
          rows={updatedStockItems}
          onRowUpdate={rowEdit}
          currentPage={1}
          totalPageCount={1}
          title={''}
          allowColumnShift={false}
          allowBulkOperation={false}
        />
      </>
    );
  };

  const addNewItem = () => {
    let copyOfStockTransferState = { ...stockTransferState };
    let newRow: any = {};
    newRow['rowButtons'] = getButtonsForRow({});
    copyOfStockTransferState.stockTransferItems.push(newRow);

    setStockTransferState(copyOfStockTransferState);
  };

  const getAddClassForm = () => (
    <AddClass
      data={null}
      onSuccess={() => {
        dispatch(fetchCategoryDimensions());
        dispatch(fetchClassesByDimensionId());
        dispatch(fetchCustomFields({ status: 'ACTIVE', limit: '1000' }));
      }}
      onCancel={() => {
        setShowAddClassPopup(false);
      }}
    />
  );

  const customFieldUpdated = (cfList: CustomField[]) => {
    let newState = { ...stockTransferState };
    newState.customField = cfList;
    setStockTransferState({ ...newState });
  };

  const normalProductTrackingSaved = (data: any) => {
    let copyOfStockTransferState = { ...stockTransferState };
    copyOfStockTransferState.stockTransferItems.forEach(
      (item: any, index: any) => {
        if (
          index === currentIndex &&
          item?.product?.pid ===
            data['stockTransferWarehouseInventoryData']?.productCode
        ) {
          item['stockTransferWarehouseInventoryData'] =
            data['stockTransferWarehouseInventoryData'];
          item['rowButtons'] = getButtonsForRow(item);
        }
      }
    );
    setStockTransferState(copyOfStockTransferState);
    setShowWarehouseManagement(false);
  };

  const onBatchSerialTrackSave = (trackingData: any, type: TRACKING_TYPE) => {
    let tmpStockTransferState = { ...stockTransferState };
    tmpStockTransferState.stockTransferItems.forEach(
      (item: any, index: any) => {
        if (
          index === currentIndex &&
          item.product.documentSequenceCode ===
            trackingData['stockTransferWarehouseInventoryData'].productCode
        ) {
          item['stockTransferWarehouseInventoryData'] =
            trackingData['stockTransferWarehouseInventoryData'];
          item['rowButtons'] = getButtonsForRow(item);
        }
      }
    );
    setStockTransferState(tmpStockTransferState);
    if (type === TRACKING_TYPE.BATCH) {
      setShowBatchTransferPopup(false);
    } else {
      if (type === TRACKING_TYPE.SERIAL) {
        setShowSerialTrackingPopup(false);
      }
    }
  };

  const updatedStockItems = useMemo(() => {
    const copyOfItems = [...(stockTransferState?.stockTransferItems ?? [])];
    copyOfItems.forEach((item: any) => {
      item['rowButtons'] = getButtonsForRow(item);
    });
    return copyOfItems;
  }, [stockTransferState]);

  const { stockTransferWarehouseInventoryData = {} } = rowBeingEdited ?? {};
  const trackingType =
    rowBeingEdited?.stockTransferWarehouseInventoryData?.advancedTrackingType;
  const isBatch = trackingType === ADVANCE_TRACKING.BATCH;
  const isNormal = trackingType === ADVANCE_TRACKING.NONE;

  let detailsToPass =
    stockTransferWarehouseInventoryData?.advancedTrackingData?.map(
      (data: any) => {
        return {
          ...data,
          destWarehouseName:
            stockTransferWarehouseInventoryData?.destWarehouseName,
          srcWarehouseName:
            stockTransferWarehouseInventoryData?.srcWarehouseName
        };
      }
    );
  if (isNormal) detailsToPass = stockTransferWarehouseInventoryData;

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: isFullScreenMode ? '100%' : 750,
            width: isFullScreenMode ? '100%' : '90%',
            maxHeight: isFullScreenMode ? '100%' : '95%',
            height: isFullScreenMode ? '100%' : '95%',
            padding: 0,
            paddingBottom: 30,
            borderRadius: isFullScreenMode ? 0 : 4
          }}
        >
          {getHeader()}
          <div
            className="column parent-width parent-height p-r"
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              scrollbarWidth: 'none'
            }}
          >
            <div
              id="popup-container"
              className="column parent-width parent-height"
              style={{
                pointerEvents: 'auto'
              }}
            >
              <div
                className="row row-responsive parent-width"
                style={{ gap: 12, width: isFullScreenMode ? 475 : '100%' }}
              >
                <div className="w-1/2">
                  <DKInput
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    type={INPUT_TYPE.TEXT}
                    title={'Transfer Document'}
                    value={
                      !Utility.isEmpty(props.selectedRecord)
                        ? props.selectedRecord.code
                        : 'T-0000'
                    }
                    onChange={(value: number) => {}}
                    canValidate={false}
                    validator={(value: string) => {}}
                    errorMessage={''}
                    readOnly={true}
                    required={false}
                  />
                </div>
                <div className="w-1/2">
                  <DKInput
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    type={INPUT_TYPE.DATE}
                    title={'Date'}
                    className=""
                    value={
                      stockTransferState && stockTransferState.transferDate
                        ? DateFormatService.getDateFromStr(
                            stockTransferState?.transferDate,
                            BOOKS_DATE_FORMAT['YYYY-MM-DD']
                          )
                        : stockTransferState?.transferDate
                    }
                    onChange={(value: number) => {
                      onTransferDateChange(value);
                    }}
                    canValidate={false}
                    validator={(value: string) => {}}
                    errorMessage={''}
                    readOnly={
                      !Utility.isEmpty(props.selectedRecord) ? true : false
                    }
                    required={true}
                    dateFormat={convertBooksDateFormatToUILibraryFormat(
                      tenantInfo.dateFormat
                    )}
                  />
                </div>
                <div className="w-1/2 mobile-mt-m">
                  <DKInput
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    type={INPUT_TYPE.NUMBER}
                    title={'Transfer Cost'}
                    value={stockTransferState.transferCost}
                    onChange={(value: number) => {
                      setStockTransferState({
                        ...stockTransferState,
                        transferCost: value
                      });
                    }}
                    canValidate={false}
                    validator={(value: string) => {}}
                    errorMessage={''}
                    readOnly={props?.isDraft ? true : false}
                    required={false}
                  />
                </div>
              </div>
              <div className="row">
                <div className="column parent-width mt-m">
                  <CustomFieldsHolder
                    moduleName={MODULES_NAME.STOCK_TRANSFER}
                    customFieldsList={
                      props.selectedRecord?.customField
                        ? props.selectedRecord.customField
                        : []
                    }
                    columnConfig={columnConfig}
                    columnConfigTableId={columnConfigTableId}
                    documentMode={
                      props?.isDraft
                        ? DOCUMENT_MODE.VIEW
                        : isEdit.current
                        ? DOCUMENT_MODE.EDIT
                        : DOCUMENT_MODE.NEW
                    }
                    onUpdate={(list) => {
                      customFieldUpdated(list);
                    }}
                  />
                </div>
              </div>
              <div className="m-v-r parent-width">
                <div className="m-v-r parent-width">{getLineItemGrid()}</div>
                {Utility.isEmpty(props.selectedRecord) &&
                  props?.document !== DOC_TYPE.JOB_WORK_OUT_ORDER && (
                    <div className="row">
                      <DKButton
                        title={`+ Add Item`}
                        onClick={() => addNewItem()}
                        className={`${'text-blue'} fw-m p-0`}
                        style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
                      />
                    </div>
                  )}
              </div>
              <div className="row">
                <div className="mobile-full-grid-width">
                  <textarea
                    className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400 overflow-auto"
                    style={{
                      width: '100%',
                      height: 130,
                      backgroundColor: 'rgb(250, 250, 250)',
                      border: '1px dashed rgb(200, 200, 200)'
                    }}
                    placeholder={'Notes'}
                    readOnly={props?.isDraft ? true : false}
                    value={stockTransferNotes}
                    onChange={(e: any) => {
                      setStockTransferNotes(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div>
              {showWarehouseManagement && (
                <StockTransferProductWarehouseManagement
                  selectedWarehouseProductsData={selectedWarehouseProductsData}
                  document={props?.document}
                  details={warehouseProduct}
                  module={MODULE_TYPE.SELL}
                  hasMultilpleWarehouseData={[]}
                  buildQuantity={0}
                  stockTransferData={stockTransferState['stockTransferItems']}
                  stockTransferDocumentSeqCodes={
                    props.detail?.stockTransferDocumentSeqCodes || []
                  }
                  currentIndex={currentIndex}
                  availableQuantity={warehouseProduct.quantity}
                  targetWarehouse={props.targetWarehouse}
                  onSave={normalProductTrackingSaved}
                  onClose={() => {
                    setShowWarehouseManagement(false);
                  }}
                ></StockTransferProductWarehouseManagement>
              )}
            </div>
            <div>
              {showBatchTransferPopup && (
                <StockTransferAdvancedBatchTrackingPopup
                  selectedWarehouseProductsData={selectedWarehouseProductsData}
                  document={props?.document}
                  itemDetails={{
                    ...warehouseProduct
                  }}
                  module={MODULE_TYPE.SELL}
                  stockTransferData={stockTransferState['stockTransferItems']}
                  stockTransferDocumentSeqCodes={
                    props.detail?.stockTransferDocumentSeqCodes || []
                  }
                  currentIndex={currentIndex}
                  defaultProductWarehouse={defaultAllocationWarehouse}
                  buildQuantity={0}
                  targetWarehouse={props.targetWarehouse}
                  onSave={(data: any) =>
                    onBatchSerialTrackSave(data, TRACKING_TYPE.BATCH)
                  }
                  onClose={() => {
                    setShowBatchTransferPopup(false);
                  }}
                ></StockTransferAdvancedBatchTrackingPopup>
              )}
            </div>
            <div>
              {showSerialTrackingPopup && (
                <StockTransferAdvancedSerialTracking
                  selectedWarehouseProductsData={selectedWarehouseProductsData}
                  document={props?.document}
                  itemDetails={{
                    ...warehouseProduct
                  }}
                  module={MODULE_TYPE.SELL}
                  defaultProductWarehouse={defaultAllocationWarehouse}
                  buildQuantity={0}
                  targetWarehouse={props.targetWarehouse}
                  stockTransferData={stockTransferState['stockTransferItems']}
                  stockTransferDocumentSeqCodes={
                    props.detail?.stockTransferDocumentSeqCodes || []
                  }
                  currentIndex={currentIndex}
                  onSerialSave={(data: any) =>
                    onBatchSerialTrackSave(data, TRACKING_TYPE.SERIAL)
                  }
                  onClose={() => {
                    setShowSerialTrackingPopup(false);
                  }}
                ></StockTransferAdvancedSerialTracking>
              )}
            </div>

            <div>
              {showTransferDetailPopup && (
                <StockTransferDetailPopup
                  detail={!isNormal ? detailsToPass : null}
                  normalDetail={isNormal ? detailsToPass : null}
                  isBatch={isBatch}
                  isNormal={isNormal}
                  onClose={() => {
                    setRowBeingEdited(null);
                  }}
                ></StockTransferDetailPopup>
              )}
            </div>
            {showAddClassPopup && getAddClassForm()}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
