import {
  BUY_TYPE,
  DOCUMENT_MODE,
  DOCUMENT_STATUS,
  DOC_TYPE,
  RECEIVED_GOODS_STATUS
} from '../../Constants/Constant';
import { FAPurchaseOrder } from '../../Models/FAOrder';
import { PurchaseOrder } from '../../Models/Order';
import {
  defaultAdditionalChargesObject,
  getTenantTaxSystem
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../Utility/Utility';

export const orderEmailTemplateConfig: any = [
  {
    hidden: false,
    text: 'Contact Name',
    action: 'contactName'
  },
  {
    hidden: false,
    text: 'User Name',
    action: 'userName'
  },
  {
    hidden: false,
    text: 'Order Date',
    action: 'orderDate'
  },
  {
    hidden: false,
    text: 'Order Amount',
    action: 'amount'
  },
  {
    hidden: false,
    text: 'Currency Code',
    action: 'currencyCode'
  },
  {
    hidden: false,
    text: 'Currency Symbol',
    action: 'currencySymbol'
  },
  {
    hidden: false,
    text: 'Company Name',
    action: 'companyName'
  },
  {
    hidden: false,
    text: 'Order Number',
    action: 'orderNo'
  }
];

export const setItemsFromPurchaseOrderItems = (doc: any, docMode:any) => {
  const document = {
    ...doc,
    items: doc.purchaseOrderItems?.map((item: any) => {
      return {
        ...item,
        documentSequenceCode: item?.product?.documentSequenceCode,
        taxSystem: getTenantTaxSystem(),
        unitPriceGstInclusive: doc.unitPriceGstInclusive
      };
    }),
    contact: !Utility.isEmpty(doc.contactDto) ? doc.contactDto : doc.contact,
    documentType: DOC_TYPE.ORDER,
    documentDate: doc.documentDate,
    validTillDate: doc.orderDueDate,
    fulfillmentDate: doc.receiveByDate,
    additionalCharges: !Utility.isEmpty(doc.additionalCharges)
      ? doc.additionalCharges
      : { ...defaultAdditionalChargesObject }
  };
  if((docMode === DOCUMENT_MODE.VIEW || docMode === DOCUMENT_MODE.EDIT) && Utility.isNotEmpty(doc?.contact?.name)){
    document.contact = {...document?.contact,name: doc?.contact?.name ?? document?.contact?.name ?? '' }
  }     
  return document;
};

export const getUpdatedPurchaseOrderObject = (props: PurchaseOrder) => {
  let purchaseOrderAssets = (props as any).purchaseOrderAssets?.map(
    (item: any) => {
      let updatedItem = { ...item };
      updatedItem.fixedAssetGroup = {
        id: item.assetGroupId,
        name: item.assetGroupName
      };
      //revisit
      if (updatedItem.taxDetails.length > 0) {
        updatedItem.tax = {
          id: item.taxDetails[0].taxId,
          name: item.taxDetails[0].taxName,
          taxCode: item.taxDetails[0].taxCode,
          percent: item.taxDetails[0].taxRate,
          code: item.taxDetails[0].taxSeqCode,
          type: item.taxDetails[0].taxType ? item.taxDetails[0].taxType : 'BOTH'
        };
      }

      updatedItem.productQuantity = item.quantity;
      return {
        ...updatedItem,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.purchaseOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    }
  );

  let updatedObj = {
    ...props,
    documentType:
      props.orderType === BUY_TYPE.ASSET ? DOC_TYPE.FA_ORDER : DOC_TYPE.ORDER,
    currencyCode: Utility.getValue(props.currency, props.currencyCode),
    documentCode: Utility.getValue(props.documentCode, props.poCode),
    vendorCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    contactCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    buyType: Utility.getValue(props.buyType, props.orderType),
    orderType: Utility.getValue(props.buyType, props.orderType),
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    placeOfSupply: props.placeOfSupply,
    reorder: props.reorder || false,
    dropShip: props.dropShip ? props.dropShip : false,
    backOrder: props.backOrder ? props.backOrder : false,
    linkedPurchaseInvoices: props.linkedPurchaseInvoices,
    dueDate: Utility.getValue(props.dueDate, props.validTillDate),
    orderDueDate: Utility.getValue(props.dueDate, props.validTillDate),
    fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),
    validTillDate: Utility.getValue(props.dueDate, props.validTillDate),
    receiveGoodsStatus:
      props.receiveGoodsStatus ||
      props.receiptStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,
    currency: Utility.getValue(props.currency, props.currencyCode),
    items:
      props.orderType === BUY_TYPE.ASSET
        ? (props as any).purchaseOrderAssets?.map((item: any) => {
            return {
              ...item,
              advancedTracking: item?.product?.advancedTracking,
              unitPriceGstInclusive: props.unitPriceGstInclusive,
              exchangeRate: props.exchangeRate,
              documentItemCode: Utility.getValue(
                item.documentItemCode,
                item.purchaseOrderItemCode,
                item.purchaseInvoiceItemCode
              ),
              fixedAssetGroup: {
                id: item.assetGroupId,
                name: item.assetGroupName
              },
              productQuantity: item.quantity,
              tax:
                item.taxDetails.length > 0
                  ? {
                      id: item.taxDetails[0].taxId,
                      name: item.taxDetails[0].taxName,
                      taxCode: item.taxDetails[0].taxCode,
                      percent: item.taxDetails[0].taxRate,
                      code: item.taxDetails[0].taxSeqCode,
                      type: item.taxDetails[0].taxType
                        ? item.taxDetails[0].taxType
                        : 'BOTH'
                    }
                  : null
            };
          })
        : props.purchaseOrderItems?.map((item) => {
            return {
              ...item,
              documentSequenceCode: item?.product?.documentSequenceCode,
              advancedTracking: item?.product?.advancedTracking,
              unitPriceGstInclusive: props.unitPriceGstInclusive,
              exchangeRate: props.exchangeRate,
              documentItemCode: Utility.getValue(
                item.documentItemCode,
                item.purchaseOrderItemCode,
                item.purchaseInvoiceItemCode
              )
            };
          }),
    hasPartialBill:
      props.linkedPurchaseInvoices && props.linkedPurchaseInvoices.length > 0,
    purchaseOrderAssets: purchaseOrderAssets,
    purchaseOrderItems: props.purchaseOrderItems?.map((item) => {
      let totalReceivedQty =
        (item.receivedQuantityInBills || 0) +
        (item.receivedQuantityInOrder || 0);
      return {
        ...item,
        receivedQuantity: totalReceivedQty,
        balanceReceivedQuantity: item.productQuantity - totalReceivedQty,
        billedQuantity: item.productQuantity - (item.pendingQuantity || 0),
        balanceBilledQuantity: item.pendingQuantity || 0
      };
    })
  };

  return updatedObj;
};

export const getUpdatedFAPurchaseOrderObject = (props: FAPurchaseOrder) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.FA_ORDER,
    currencyCode: Utility.getValue(props.currency, props.currencyCode),
    documentCode: Utility.getValue(props.documentCode, props.poCode),
    vendorCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    contactCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    buyType: BUY_TYPE.ASSET,
    orderType: BUY_TYPE.ASSET,
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    placeOfSupply: props.placeOfSupply,
    reorder: props.reorder || false,
    dropShip: props.dropShip ? props.dropShip : false,
    backOrder: props.backOrder ? props.backOrder : false,
    linkedPurchaseInvoices: props.linkedPurchaseInvoices,
    dueDate: Utility.getValue(props.dueDate, props.validTillDate),
    orderDueDate: Utility.getValue(props.dueDate, props.validTillDate),
    fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),
    validTillDate: Utility.getValue(props.dueDate, props.validTillDate),
    receiveGoodsStatus:
      props.receiveGoodsStatus ||
      props.receiptStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,
    currency: Utility.getValue(props.currency, props.currencyCode),

    items: props.purchaseOrderAssets?.map((item: any) => {
      item.fixedAssetGroup = {
        id: item.assetGroupId,
        name: item.assetGroupName
      };
      //revisit
      if (item.taxDetails.length > 0) {
        item.tax = {
          id: item.taxDetails[0].taxId,
          name: item.taxDetails[0].taxName,
          taxCode: item.taxDetails[0].taxCode,
          percent: item.taxDetails[0].taxRate,
          code: item.taxDetails[0].taxSeqCode,
          type: item.taxDetails[0].taxType ? item.taxDetails[0].taxType : 'BOTH'
        };
      }

      item.productQuantity = item.quantity;
      return {
        ...item,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.purchaseOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    }),
    hasPartialBill:
      props.linkedPurchaseInvoices && props.linkedPurchaseInvoices.length > 0
  };

  return updatedObj;
};
