import {
  INPUT_TYPE,
  showAlert,
  DKTooltipWrapper,
  DKIcon,
  DKLabel,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import {
  APPLY_TO_TYPE,
  APPROVAL_STATUS,
  APPROVAL_STATUS_LIST,
  BOOKS_DATE_FORMAT,
  CESS_RULE_AMOUNT,
  CESS_RULE_QUANTITY,
  COMPLAINCE_CURRENCY,
  COUNTRY_CODES,
  CURRENCIES,
  CUSTOMER_TYPE,
  DEEMED_EXPORT,
  DOCUMENT_MODE,
  DOC_TYPE,
  gstTreatmentWithoutGSTIN,
  GST_TYPE,
  ITC_IS_REVERSED_ID,
  LOCATION_CLASS_ENUM,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  NUMBER_30,
  OVERSEAS,
  PRODUCT_OFFERING_TYPE,
  REGISTERED_BUSINESS_COMPOSITION,
  REGISTERED_BUSINESS_REGULAR,
  SPECIAL_ECONOMIC_ZONE,
  TAX_PARSING_ERROR_SUB_MSG,
  TAX_SYSTEM,
  TRACKING_TYPE,
  UNREGISTERED_BUSINESS,
  STATUS_TYPE,
  EMAIL_STATUS,
  ITC_OPTIONS,
  VENDOR_IMPORT,
  COMPONENT_LIST_SUPPORTED_DOCS,
  COMPONENTLIST_GROUP_IDS,
  PRODUCE_PRODUCT_TYPE
} from '../../Constants/Constant';
import {
  DocumentItem,
  DocumentItemDefaultObj
} from '../../Models/DocumentItem';
import { BooksAddress, BooksAddressFormatted } from '../../Models/Interfaces';
import { Store } from '../../Redux/Store';
import { CURRENCY_PRECISION } from '../../Constants/Constant';
import { PaymentTerm } from '../../Models/PaymentTerm';
import { AdditionalChargeForDoc, Document } from '../../Models/Document';
import Utility, { deepClone, getCapitalized } from '../../Utility/Utility';
import DateFormatService from '../../Services/DateFormat';
import { addDays } from 'date-fns';
import { ADVANCE_TRACKING, CREDIT_LIMIT_TYPE } from '../../Constants/Enum';
import InvoiceService from '../../Services/Invoice';
import PrintPreview from '../Printing/PrintPreview';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import Email from '../Email/Email';
import DocumentAlert from './DocumentAlert';
import { getColumnConfigType } from '../../Components/Accounting/JournalEntry/JEHelper';
import CoaService from '../../Services/COA';
import { CUSTOM_FIELD_TYPE, COMMON_CONSTANT } from '../../Constants/Constant';
import ic_email from '../../Assets/ic_email_2.png';
import { DraftTypes } from '../../Models/Drafts';
import ProductService from '../../Services/Product';
import { ItemTaxCalculator } from './ItemTaxCalculator';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { checkUserPermission } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';

const store = Store;

export const DEFAULT_PURCHASE_TAX_CODE_SG = 'TX-0000011';
export const DEFAULT_PURCHASE_TAX_CODE_ID = 'TX-0000001';
export const DEFAULT_SALES_TAX_CODE = 'TX-0000001';
export const DEFAULT_PURCHASE_TAX_CODE = 'TX-0000002';
export const DEFAULT_PURCHASE_TAX_CODE_UK = 'TX-0000001';
export const DEFAULT_PURCHASE_TAX_CODE_NL = 'TX-0000007';
export const DEFAULT_PURCHASE_TAX_CODE_BE = 'TX-0000010';
export const DEFAULT_SALES_TAX_CODE_UK = 'TX-0000018';
export const DEFAULT_PURCHASE_TAX_CODE_DE = 'TX-0000005';
export const COUNTRIES_ALLOWED_FOR_SALES_ORDER = [
  COUNTRY_CODES.IN,
  COUNTRY_CODES.US,
  COUNTRY_CODES.SG,
  COUNTRY_CODES.IL,
  COUNTRY_CODES.MY,
  COUNTRY_CODES.UK,
  COUNTRY_CODES.MY
];
export const COUNTRIES_ALLOWED_FOR_REV_REC = [
  COUNTRY_CODES.IN,
  COUNTRY_CODES.US
];
export const CASCADING_DISCOUNT_PREFIX = 'cascading_discount_';

export const LINEITEMS_ADV_TRACK_STATUS = {
  NOT_AAPLICABLE: 'Not applicable',
  BATCH_TRACKED: 'Batch Tracked',
  SERIAL_TRACKED: 'Serial Tracked',
  BOTH: 'Both'
};

export const inactiveContactMessage =
  'The contact used in this document, is set as inactive. Please select an active contact to proceed.';
export const cascadingDiscountsInvalidMessage =
  'One or more line items have total discount amount greater than the item value. Please update the discount values to proceed.';
export const calculateUSTaxAPIErrorMessages = [
  'postal code is not valid',
  "Please provide customer's address/zip code to calculate the tax properly"
];
export const DEFAULT_PRICE_BOOK_ID = 0;
export const DEFAULT_PRICE_BOOK = {
  id: DEFAULT_PRICE_BOOK_ID,
  name: 'Default'
};

export const isSalesOrderVisible = () => {
  // const country = Store.getState().authInfo?.currentTenantInfo?.data?.country;
  const isEnableSaleOrder = Store.getState().authInfo?.currentTenantInfo?.data
    ?.additionalSettings?.ENABLE_SO
    ? Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.ENABLE_SO
    : false;
  // commenting usage of COUNTRIES_ALLOWED_FOR_SALES_ORDER to support generic compliance verification
  // will remove this when tested
  // return (
  //   COUNTRIES_ALLOWED_FOR_SALES_ORDER.includes(country) && isEnableSaleOrder
  // );
  return isEnableSaleOrder;
};

export const isFixedAssetVisible = () => {
  const isEnableFixedAsset = Store.getState().authInfo?.currentTenantInfo?.data
    ?.additionalSettings?.ENABLE_FA
    ? Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.ENABLE_FA
    : false;
  return isEnableFixedAsset;
};

export const isRevRecVisible = () => {
  const isEnableRevRec = Store.getState().authInfo?.currentTenantInfo?.data
    ?.additionalSettings?.REV_REC
    ? Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.REV_REC
    : false;
  return isEnableRevRec;
};

export const isFAVisible = () => {
  const country = Store.getState().authInfo?.currentTenantInfo?.data?.country;
  const isEnableFA = Store.getState().authInfo?.currentTenantInfo?.data
    ?.additionalSettings?.ENABLE_FA
    ? Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        ?.ENABLE_FA
    : false;
  return COUNTRIES_ALLOWED_FOR_SALES_ORDER.includes(country) && isEnableFA;
};

export const checkContact = (
  document: Document,
  contact: any,
  updateAddresses: boolean,
  isContactChangedManually?: boolean
) => {
  document = onContactChange(
    document,
    contact,
    updateAddresses,
    isContactChangedManually
  );
  return document;
};

export const onContactChange = (
  document: Document,
  contact: any,
  updateAddresses: boolean,
  isContactChangedManually?: boolean
) => {
  const tenantDetails = store.getState().authInfo.currentTenantInfo.data;
  // this.getContactCurrencyRates(contact);
  let documentUpdates: any = {};
  let billingAddresses = [];
  let shippingAddresses = [];

  if (Utility.isSalesDocument(document)) {
    billingAddresses =
      (document?.id || document?.isConverting) &&
      !isContactChangedManually &&
      document?.billTo
        ? [{ ...document?.billTo, preferred: true }]
        : contact.billingAddress;
    shippingAddresses =
      (document?.id || document?.isConverting) &&
      !isContactChangedManually &&
      document?.shipTo
        ? [{ ...document?.shipTo, preferred: true }]
        : contact.shippingAddress;
  } else if (
    document.documentType === DOC_TYPE.BILL ||
    document.documentType === DOC_TYPE.FA_BILL ||
    document.documentType === DOC_TYPE.FA_ORDER ||
    document.documentType === DOC_TYPE.ORDER ||
    document.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
  ) {
    billingAddresses = tenantDetails.billingAddresses;
    shippingAddresses = tenantDetails.shippingAddresses;

    if (
      document.documentType === DOC_TYPE.ORDER ||
      document.documentType === DOC_TYPE.FA_ORDER ||
      document.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      if (document.dropShip) {
        const shippingAddress = document?.shipTo ? [document?.shipTo] : [];
        shippingAddresses = shippingAddress || contact?.shippingAddress;
      } else {
        shippingAddresses = tenantDetails?.shippingAddresses;
      }
    }
  }
  const shippingAddress =
    shippingAddresses && shippingAddresses.length > 0
      ? shippingAddresses.filter((address: any) => address.preferred)
      : null;
  const billingAddress =
    billingAddresses && billingAddresses.length > 0
      ? billingAddresses.filter((address: any) => address.preferred)
      : null;

  let isExistingBillToPresentInBillingAddresses = false;
  let isExistingShipToPresentInShippingAddresses = false;
  if (
    (!billingAddress || !billingAddress?.length) &&
    billingAddresses &&
    billingAddresses.length
  ) {
    isExistingBillToPresentInBillingAddresses = billingAddresses.find(
      (address: any) =>
        getFormattedAddress(document.billTo) === getFormattedAddress(address)
    );
  }
  if (
    (!shippingAddress || !shippingAddress?.length) &&
    shippingAddresses &&
    shippingAddresses.length
  ) {
    isExistingShipToPresentInShippingAddresses = shippingAddresses.find(
      (address: any) =>
        getFormattedAddress(document.shipTo) === getFormattedAddress(address)
    );
  }

  const validTillDate = getValidTillDate(document, contact);
  if (validTillDate) {
    documentUpdates.validTillDate = DateFormatService.getDateStrFromDate(
      validTillDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
  }

  const shipAddress = shippingAddress?.length ? shippingAddress[0] : null;
  const billAddress = billingAddress?.length ? billingAddress[0] : null;

  if (updateAddresses && !isExistingBillToPresentInBillingAddresses) {
    documentUpdates.billTo = billAddress;
  }
  if (updateAddresses && !isExistingShipToPresentInShippingAddresses) {
    documentUpdates.shipTo = shipAddress;
  }

  if (
    updateAddresses &&
    (document.documentType === DOC_TYPE.BILL ||
      document.documentType === DOC_TYPE.ORDER ||
      document.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
      document.documentType === DOC_TYPE.FA_ORDER ||
      document.documentType === DOC_TYPE.FA_BILL)
  ) {
    documentUpdates.shipFrom =
      (document?.id || document?.isConverting) &&
      !isContactChangedManually &&
      document?.shipFrom
        ? { ...document?.shipFrom, preferred: true }
        : Utility.getPreferredAddress(contact, 'shippingAddress');
  }

  if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
    documentUpdates = {
      ...documentUpdates,
      gstin: contact.gstin,
      // placeOfSupply: documentUpdates.shipTo?.state,
      customerType: contact.customerType,
      vendorType: contact.vendorType,
      gstTreatment: contact.gstTreatment
    };
  }
  // update document object
  document = { ...document, ...documentUpdates };
  document = updateTaxOnContactChange(
    document,
    contact,
    isContactChangedManually
  );
  return document;
};

const updateTaxOnContactChange = (
  document: Document,
  contact: any,
  isContactChangedManually?: boolean
) => {
  if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
    const gstType = updateGstType(document);

    let isZeroTaxApplied = false;
    const purchaseTax = Store.getState().commonData.data.tax.purchase;
    const zeroTax = purchaseTax.find((tax: any) => tax.percent === 0);
    if (
      (document.documentType === DOC_TYPE.BILL ||
        document.documentType === DOC_TYPE.FA_BILL ||
        document.documentType === DOC_TYPE.FA_ORDER ||
        document.documentType === DOC_TYPE.ORDER) &&
      isContactChangedManually
    ) {
      if (
        document.contact?.gstTreatment === OVERSEAS &&
        document.contact?.vendorType === VENDOR_IMPORT
      ) {
        isZeroTaxApplied = true;
      }
    }

    document.gstType = gstType;
    if (document.items && document.items.length > 0) {
      document.items = document.items.map((item) => {
        let updatedItem = {
          ...item,
          gstType: gstType,
          isRcmApplied: rcmAppliedIndiaWithCheckRCMApply(
            document.documentType,
            contact.gstTreatment,
            item.product,
            document.applyRcmCheck
          )
        };

        if (isZeroTaxApplied && !updatedItem.isRcmApplied && zeroTax) {
          updatedItem.igstRate = 0;
          updatedItem.igstAmount = 0;
          updatedItem.taxCode = zeroTax.taxCode;
          updatedItem.taxName = zeroTax.name;
          updatedItem.tax = { ...zeroTax };

          ItemTaxCalculator.item = updatedItem;
          ItemTaxCalculator.setInitialValues();
          const taxAmount = ItemTaxCalculator.calculateTaxAmount();
          ItemTaxCalculator.item.taxAmount = taxAmount;
          ItemTaxCalculator.updateCalculatedValues();
        }

        return updatedItem;
      });
      // calculate tax after this
      // this.calculateTaxLocalAndUpdate();
    }
  }
  return document;
};
export const rcmAppliedIndia = (
  docType: DOC_TYPE,
  gstTreatment: string,
  item: any
) => {
  if (
    getTenantTaxSystem() !== TAX_SYSTEM.INDIA_GST ||
    (docType !== DOC_TYPE.BILL &&
      docType !== DOC_TYPE.EXPENSE_BILL &&
      docType !== DOC_TYPE.Direct_S_Expense)
  ) {
    return false;
  }

  let isRCMApplied = false;
  if (gstTreatment === UNREGISTERED_BUSINESS) {
    isRCMApplied = true;
  }
  if (gstTreatment === OVERSEAS) {
    if (
      (item &&
        item.itcAdjustment === ITC_IS_REVERSED_ID &&
        item.offeringType === PRODUCT_OFFERING_TYPE.SERVICES) ||
      (item &&
        (docType === DOC_TYPE.BILL ||
          docType === DOC_TYPE.EXPENSE_BILL ||
          docType === DOC_TYPE.Direct_S_Expense) &&
        item.itcAdjustment === ITC_IS_REVERSED_ID)
    ) {
      isRCMApplied = true;
    }
  }
  if (
    gstTreatment === REGISTERED_BUSINESS_REGULAR ||
    gstTreatment === REGISTERED_BUSINESS_COMPOSITION ||
    gstTreatment === CUSTOMER_TYPE ||
    gstTreatment === SPECIAL_ECONOMIC_ZONE ||
    gstTreatment === DEEMED_EXPORT
  ) {
    if (item && item.itcAdjustment === ITC_IS_REVERSED_ID) {
      isRCMApplied = true;
    }
  }
  return isRCMApplied;
};

export const rcmAppliedIndiaWithCheckRCMApply = (
  docType: DOC_TYPE,
  gstTreatment: string,
  item: any,
  useRCMDocumentCheck?: boolean
) => {
  if (useRCMDocumentCheck === false) {
    return false;
  }
  if (
    getTenantTaxSystem() !== TAX_SYSTEM.INDIA_GST ||
    (docType !== DOC_TYPE.BILL &&
      docType !== DOC_TYPE.EXPENSE_BILL &&
      docType !== DOC_TYPE.Direct_S_Expense)
  ) {
    return false;
  }

  let isRCMApplied = false;
  if (gstTreatment === UNREGISTERED_BUSINESS) {
    isRCMApplied = true;
  }
  if (
    gstTreatment === REGISTERED_BUSINESS_REGULAR ||
    gstTreatment === REGISTERED_BUSINESS_COMPOSITION ||
    gstTreatment === CUSTOMER_TYPE ||
    gstTreatment === SPECIAL_ECONOMIC_ZONE ||
    gstTreatment === OVERSEAS ||
    gstTreatment === DEEMED_EXPORT
  ) {
    if (useRCMDocumentCheck === true) {
      isRCMApplied = true;
    }
  }
  return isRCMApplied;
};

export const renderItcIneligibleSection = (itcIneligibleType: string) => {
  return (
    <>
      {itcIneligibleType !== '' &&
        undefined !== itcIneligibleType &&
        null !== itcIneligibleType && (
          <DKLabel
            className="text-gray fw-r fs-s mr-s"
            text={`${
              itcIneligibleType === 'ITC_INELIGIBLE_SECTION_17'
                ? ITC_OPTIONS.ITC_INELIGIBLE_SECTION_17
                : ITC_OPTIONS.ITC_INELIGIBLE_OTHERS
            }`}
          />
        )}
    </>
  );
};

export const updateGstType = (document: Document) => {
  let gstType: GST_TYPE | undefined = undefined;
  if (Utility.isSalesDocument(document)) {
    let tenantState = document.shipFrom?.state;
    let state: any = '';
    if (Utility.isEmpty(document.shipTo?.placeOfSupply)) {
      state = document.shipTo?.state;
    } else {
      state = document.shipTo?.placeOfSupply;
    }
    gstType = Utility.checkSezForSales(
      state,
      document.contact.customerType,
      document.contact.gstTreatment,
      tenantState
    );
  } else if (
    document.documentType === DOC_TYPE.BILL ||
    document.documentType === DOC_TYPE.FA_BILL ||
    document.documentType === DOC_TYPE.FA_ORDER ||
    document.documentType === DOC_TYPE.ORDER ||
    document.documentType === DOC_TYPE.PURCHASE_INWARD_QUOTATION ||
    document.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
    document.documentType === DOC_TYPE.REQUISITION
  ) {
    let placeOfSupply: any = !Utility.isEmpty(document.shipFrom?.placeOfSupply)
      ? document.shipFrom?.placeOfSupply
      : document.shipFrom?.state;
    let DestinationState: any = document.shipTo?.state;
    if (Utility.isEmpty(document.shipTo?.destinationOfSupply)) {
      DestinationState = document.shipTo?.state;
    } else {
      DestinationState = document.shipTo?.destinationOfSupply;
    }
    gstType = Utility.getVendorGstType(
      placeOfSupply,
      document.contact?.vendorType,
      document.contact?.gstTreatment,
      document?.applyRcmCheck,
      DestinationState
    );
  }
  return gstType;
};

export const getValidTillDate = (document: Document, contact: any) => {
  let validTillDate = new Date();
  let validDays = NUMBER_30;
  const paymentTerms = store.getState().contacts.formData.paymentTerm;
  if (contact && contact.paymentTermCode) {
    const termCode =
      +contact.paymentTermCode ??
      store.getState().authInfo.currentTenantInfo.data?.defaultPaymentTerm;
    const paymentTerm: PaymentTerm = paymentTerms.find(
      (pt: PaymentTerm) => pt.id === termCode
    );
    if (paymentTerm) {
      validDays = paymentTerm.termDays;
    }
  }
  const docDate = DateFormatService.getDateFromStr(
    document.documentDate,
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  );
  const tempDate = docDate;
  validTillDate = new Date(tempDate.setDate(tempDate.getDate() + validDays));
  return validTillDate;
};

export const getValidTillDateFromDocDate = (
  documentDate: Date,
  contact: any
) => {
  let validDays = NUMBER_30;
  const paymentTerms = store.getState().contacts.formData.paymentTerm;
  if (contact && contact.paymentTermCode) {
    const termCode =
      +contact.paymentTermCode ??
      store.getState().authInfo.currentTenantInfo.data?.defaultPaymentTerm;
    const paymentTerm: PaymentTerm = paymentTerms.find(
      (pt: PaymentTerm) => pt.id === termCode
    );
    if (paymentTerm) {
      validDays = paymentTerm.termDays;
    }
  }
  const validTillDate = addDays(documentDate, validDays);
  return validTillDate;
};

export const getTenantTaxSystem = (
  localComplianceEnabled?: boolean
): TAX_SYSTEM => {
  const tenantCountry =
    store.getState().authInfo.currentTenantInfo.data.country;
  const complianceEnabled =
    typeof localComplianceEnabled === 'boolean'
      ? localComplianceEnabled
      : Utility.isComplianceEnabled();

  const skipComplianceCheck =
    tenantCountry === TAX_SYSTEM.US ||
    tenantCountry === TAX_SYSTEM.UAE ||
    tenantCountry === TAX_SYSTEM.SG ||
    tenantCountry === TAX_SYSTEM.AUSTRALIA ||
    tenantCountry === TAX_SYSTEM.PHILIPPINES ||
    tenantCountry === TAX_SYSTEM.CANADA ||
    tenantCountry === TAX_SYSTEM.INDONESIA ||
    tenantCountry === TAX_SYSTEM.NL ||
    tenantCountry === TAX_SYSTEM.NZ ||
    tenantCountry === TAX_SYSTEM.UK ||
    tenantCountry === TAX_SYSTEM.IL ||
    tenantCountry === TAX_SYSTEM.BE ||
    tenantCountry === TAX_SYSTEM.DE ||
    tenantCountry === TAX_SYSTEM.SA;

  if (!skipComplianceCheck && !complianceEnabled) {
    return TAX_SYSTEM.DEFAULT;
  }

  switch (tenantCountry) {
    case TAX_SYSTEM.INDIA_GST:
      return TAX_SYSTEM.INDIA_GST;
    case TAX_SYSTEM.US:
      return TAX_SYSTEM.US;
    case TAX_SYSTEM.SG:
      return TAX_SYSTEM.SG;
    case TAX_SYSTEM.INDONESIA:
      return TAX_SYSTEM.INDONESIA;
    case TAX_SYSTEM.PHILIPPINES:
      return TAX_SYSTEM.PHILIPPINES;
    case TAX_SYSTEM.MALAYSIA:
      return TAX_SYSTEM.MALAYSIA;
    case TAX_SYSTEM.UAE:
      return TAX_SYSTEM.UAE;
    case TAX_SYSTEM.AUSTRALIA:
      return TAX_SYSTEM.AUSTRALIA;
    case TAX_SYSTEM.CANADA:
      return TAX_SYSTEM.CANADA;
    case TAX_SYSTEM.NZ:
      return TAX_SYSTEM.NZ;
    case TAX_SYSTEM.UK:
      return TAX_SYSTEM.UK;
    case TAX_SYSTEM.IL:
      return TAX_SYSTEM.IL;
    case TAX_SYSTEM.NL:
      return TAX_SYSTEM.NL;
    case TAX_SYSTEM.BE:
      return TAX_SYSTEM.BE;
    case TAX_SYSTEM.DE:
      return TAX_SYSTEM.DE;
    case TAX_SYSTEM.SA:
      return TAX_SYSTEM.SA;
    default:
      return TAX_SYSTEM.DEFAULT;
  }
};

// Parses only DD-MM-YYYY currently
// export const getDateFromStr = (str: string) => {
//   const strParts = str.split('-');
//   const date = new Date(+strParts[2], +strParts[1] - 1, +strParts[0]);
//   return date;
// };

export const getDateFromString = (date: string, format = '') => {
  let dateComponent = date.split('-');
  let dateObj = new Date(
    parseInt(dateComponent[2]),
    parseInt(dateComponent[1]),
    parseInt(dateComponent[0])
  );
  return dateObj;
};

export const getDateAsString = (date: Date, format: string) => {
  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ];

  var d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  switch (format.toLowerCase()) {
    case 'ddmmyyyy':
      return day + month + year;
    case 'dd/mm/yyyy':
      return day + '/' + month + '/' + year;
    case 'dd-mm-yyyy':
      return day + '-' + month + '-' + year;
    case 'mmddyyyy':
      return month + day + year;
    case 'mm/dd/yyyy':
      return month + '/' + day + '/' + year;
    case 'mm-dd-yyyy':
      return month + '-' + day + '-' + year;
    case 'mmm yyyy':
      return `${months[parseInt(month) - 1]} ${year}`;
    // case 'dd mmmm yyyy':
    //   return day + ' ' + LongMonth[month - 1] + ' ' + year;
    // case 'dd mmm yyyy':
    //   return day + ' ' + ShortMonth[month - 1] + ' ' + year;
    // case 'mmm dd, yyyy':
    //   return ShortMonth[month - 1] + ' ' + day + ', ' + year;
    // case 'dd-dd-yyyy':
    //   return day + '-' + month + '-' + year;
    // case 'd mmm yyyy':
    //   return day + ' ' + ShortMonth[month - 1] + ' ' + year;
    case 'yyyy-mm-dd':
      return year + '-' + month + '-' + day;
    default:
      return date;
    // alert(
    //   "Invalid date format.\nFormats supported: \n - ddMMyyyy\n- dd/MM/yyyy\n- dd-MM-yyyy\n- MMddyyyy\n- MM/dd/yyyy\n- MM-dd-yyyy\n- dd MMMM yyyy\n- dd MMM yyyy\n- MMM dd, yyyy"
    // );
  }

  return [day, month, year].join('/');
};

export const createLineItem = (
  product: any,
  gstType: any,
  lineNumber: number,
  docType: DOC_TYPE
) => {
  let unitPrice = 0;
  if (
    docType === DOC_TYPE.INVOICE ||
    docType === DOC_TYPE.QUOTE ||
    docType === DOC_TYPE.SALES_ORDER
  ) {
    unitPrice = product.salesPrice;
  } else if (docType === DOC_TYPE.BILL || docType === DOC_TYPE.ORDER) {
    unitPrice = product.purchasePrice;
  }

  const getDefaultUom = (uomID: any) => {
    let filtered = store
      .getState()
      .commonData.data.uoms.filter((uom: any) => uom.id === uomID);
    if (!Utility.isEmpty(filtered)) {
      return filtered[0];
    } else {
      return null;
    }
  };

  const lineItem: DocumentItem = {
    ...DocumentItemDefaultObj,
    product: product,
    productName: product.name,
    productDescription: product.description,
    productQuantity: product.productQuantity || 1,
    productCode: product.productId,
    documentSequenceCode: product.documentSequenceCode,
    lineNumber: lineNumber,
    unitPrice: unitPrice,
    hsnOrSacCode: product.hsnOrSacCode,
    type: product.type,
    totalAmount: unitPrice,
    taxPreference: product.taxPreference,
    isTaxable: true,
    taxExemptionReason: product.taxExemptionReason,
    tax: product.tax,
    taxSystem: getTenantTaxSystem(),
    gstType: gstType,
    exciseApplicable: product ? product.exciseApplicable : false,
    exciseType: product ? product.exciseType : null,
    exciseRate: product ? product.exciseRate : null,
    advancedTracking: product ? product.advancedTracking : null,
    documentUom: product?.stockUom,
    uom: product?.stockUom ? getDefaultUom(product?.stockUom) : null,
    availableQuantity: product?.inventory?.availableQuantity,
    basePrice: product?.basePrice,
    reservedQuantity: product?.inventory?.reservedQuantity || 0
  };

  return lineItem;
};

const getDefaultPurchaseTax = () => {
  const taxSystem = getTenantTaxSystem();
  switch (taxSystem) {
    case TAX_SYSTEM.INDONESIA:
      return DEFAULT_PURCHASE_TAX_CODE_ID;
    case TAX_SYSTEM.NZ:
      return DEFAULT_PURCHASE_TAX_CODE;
    case TAX_SYSTEM.UK:
      return DEFAULT_PURCHASE_TAX_CODE_UK;
    case TAX_SYSTEM.NL:
      return DEFAULT_PURCHASE_TAX_CODE_NL;
    case TAX_SYSTEM.BE:
      return DEFAULT_PURCHASE_TAX_CODE_BE;
    default:
      return DEFAULT_PURCHASE_TAX_CODE_SG;
  }
};

export const getFormattedAddressObj = (
  address: BooksAddress
): BooksAddressFormatted => {
  const contactName =
    address && !Utility.isEmpty(address.contactName) ? address.contactName : '';
  const line1 =
    address && !Utility.isEmpty(address.address1) ? address.address1 : '';
  const line2 =
    (address && !Utility.isEmpty(address.address2)) ||
    (address && !address.address2)
      ? address.address2
      : '';
  const city = address && !Utility.isEmpty(address.city) ? address.city : '';
  const state = address && !Utility.isEmpty(address.state) ? address.state : '';
  const country =
    address && !Utility.isEmpty(address.country) ? address.country : '';
  const postalCode =
    address && !Utility.isEmpty(address.postalCode) ? address.postalCode : '';
  let cityAndState = '';
  let countryAndPostalCode = '';

  if (city !== '' && state !== '') {
    cityAndState = `${city}, ${state}`;
  }
  if (city !== '' && state === '') {
    cityAndState = `${city}`;
  }
  if (city === '' && state !== '') {
    cityAndState = `${state}`;
  }

  if (country !== '' && postalCode !== '') {
    countryAndPostalCode = `${country}, ${postalCode}`;
  }
  if (country !== '' && postalCode === '') {
    countryAndPostalCode = `${country}`;
  }
  if (country === '' && postalCode !== '') {
    countryAndPostalCode = `${postalCode}`;
  }

  return { contactName, line1, line2, cityAndState, countryAndPostalCode };
};

export const getFormattedAddress = (address: any, isVendorType?: boolean) => {
  const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
    getFormattedAddressObj(address);

  let formattedAddress = '';
  if (!Utility.isEmpty(contactName) && !isVendorType) {
    formattedAddress += contactName + ', <br/>';
  }
  if (!Utility.isEmpty(line1) && !isVendorType) {
    formattedAddress += line1 + ', ';
  }
  if (!Utility.isEmpty(line2) && !isVendorType) {
    formattedAddress += line2 + ', ';
  }

  if (isVendorType && !Utility.isEmpty(address.state)) {
    formattedAddress += address.state + ', ';
  } else if (!Utility.isEmpty(cityAndState)) {
    formattedAddress += cityAndState + ', ';
  }

  if (!Utility.isEmpty(countryAndPostalCode)) {
    formattedAddress += countryAndPostalCode;
  }

  return formattedAddress;
};

export const roundOff = (val: any): number => {
  const decimalScale =
    store.getState().authInfo.currentTenantInfo.data.decimalScale;
  return roundingOff(val, decimalScale) ? roundingOff(val, decimalScale) : 0;
};
export const roundingOff = (val: any, precisionVal = CURRENCY_PRECISION) => {
  val = Number(val);
  val = val + 1 / Math.pow(10, precisionVal + 10);
  var newnumber =
    Math.round(val * Math.pow(10, precisionVal)) / Math.pow(10, precisionVal);
  return newnumber;
};
export const roundOffStr = (val: any) => {
  const decimalScale =
    store.getState().authInfo.currentTenantInfo.data.decimalScale;
  const num = roundingOff(val, decimalScale);
  return num.toFixed(decimalScale);
};
export const roundingOffStr = (val: any, precisionVal = CURRENCY_PRECISION) => {
  const num = roundingOff(val, precisionVal);
  return num.toFixed(precisionVal);
};

export const evaluateTaxGroupInclusiveTax = (
  tax: any,
  taxableAmount: number,
  totalTax: number
) => {
  return calculateTaxGroup(tax, taxableAmount, true, totalTax);
};
export const evaluateTaxGroup = (tax: any, taxableAmount: number) => {
  return calculateTaxGroup(tax, taxableAmount, false, 0);
};
export const calculateTaxGroup = (
  tax: any,
  taxableAmount: number,
  isInclusiveTax: boolean = false,
  totalTax: number = 0
) => {
  if (!tax || typeof tax !== 'object') {
    return [];
  }

  let taxDetails = [];
  if (tax.isTaxGroup) {
    //Breakdown tax group
    taxDetails = getTaxGroupDetails(
      tax,
      taxableAmount,
      isInclusiveTax,
      totalTax
    );
  } else {
    //Normal tax
    let taxDetail = getTaxDetail(tax, taxableAmount);
    taxDetails.push(taxDetail);
  }
  return taxDetails;
};

export const getTaxGroupDetails = (
  taxGroup: any,
  taxableAmount: number,
  isInclusiveTax: boolean = false,
  totalTax: number = 0
) => {
  let taxDetails = [];
  let totalPreTax = 0;
  let afterTax;

  taxGroup.taxGroupDetails.forEach((tax: any) => {
    if (tax.applyTo === APPLY_TO_TYPE.PRE) {
      let taxDetail = getTaxDetail(tax, taxableAmount);
      taxDetails.push(taxDetail);
      totalPreTax += taxDetail.taxAmount;
    } else if (
      tax.applyTo === APPLY_TO_TYPE.AFTER ||
      tax.applyTo === APPLY_TO_TYPE.POST
    ) {
      afterTax = tax;
    }
  });

  if (afterTax) {
    let taxDetail = getTaxDetail(afterTax, taxableAmount + totalPreTax);

    if (isInclusiveTax) {
      totalTax = totalTax || (taxableAmount * taxGroup.percent) / 100;
      taxDetail.taxAmount = roundOff(totalTax - totalPreTax);
    }

    taxDetails.push(taxDetail);
  }
  return taxDetails;
};

export const getTaxDetail = (tax: any, taxableAmount: number) => {
  const percent = tax.percentage || tax.percent || 0;
  return {
    taxAmount: roundOff((taxableAmount * percent) / 100),
    taxId: tax.id,
    taxName: tax.name,
    taxRate: percent,
    taxCode: tax.taxCode || tax.code,
    taxSeqCode: tax.code,
    taxType: tax.type,
    taxApplyTo: tax.applyTo,
    additionalTaxIn: tax.additionalTaxIn,
    taxableAmount
  };
};

export const convertToCurrenctExchangeRate = (
  exchangeRate: number,
  previousExchangeRate: number,
  value: any
) => {
  return Utility.roundOff(
    (exchangeRate / (previousExchangeRate || 1)) * Number(value),
    CURRENCY_PRECISION
  );
};

export const checkForSameBaseCurrency = (
  taxSystem: string,
  currency: string
) => {
  return COMPLAINCE_CURRENCY[taxSystem] === currency;
};

export const isGSTExchangeRateRequired = (
  taxSystem: string,
  currency: string
) => {
  return (
    COMPLAINCE_CURRENCY[taxSystem] &&
    !checkForSameBaseCurrency(taxSystem, currency)
  );
};

export const isGSTExchangeRateRequiredOnEdit = (
  taxSystem: string,
  currency: string
) => {
  return (
    (taxSystem === TAX_SYSTEM.SG && currency !== CURRENCIES.SG) ||
    (taxSystem === TAX_SYSTEM.BE && currency !== CURRENCIES.BE)
  );
};

export const getMainModuleName = (docType: DOC_TYPE) => {
  if (
    docType === DOC_TYPE.INVOICE ||
    docType === DOC_TYPE.QUOTE ||
    docType === DOC_TYPE.SALES_ORDER
  ) {
    return MODULE_NAME_FOR_STORAGE_UTILITY.SELL;
  } else if (
    docType === DOC_TYPE.BILL ||
    docType === DOC_TYPE.ORDER ||
    docType === DOC_TYPE.EXPENSE_BILL ||
    docType === DOC_TYPE.JOB_WORK_OUT_ORDER
  ) {
    return MODULE_NAME_FOR_STORAGE_UTILITY.BUY;
  }
};

const getDocCodeKeyForDoc = (docType: DOC_TYPE) => {
  switch (docType) {
    case DOC_TYPE.INVOICE:
      return 'salesInvoiceCode';
    case DOC_TYPE.QUOTE:
      return 'quotationCode';
    case DOC_TYPE.SALES_ORDER:
      return 'salesOrderCode';
    case DOC_TYPE.BILL:
      return 'purchaseInvoiceCode';
    case DOC_TYPE.ORDER:
      return 'poCode';
  }
};
// Get whether a document form is minimized and it's updated data
export const getMinimizedDocInfo = (
  documentCode: string,
  isDraft: boolean,
  docType: DOC_TYPE
): { isMinimized: boolean; activeFormData: any } => {
  const activeDrafts: any[] = Store.getState().drafts.data;
  let openedAndMinimizedDraft: any;
  if (isDraft) {
    openedAndMinimizedDraft = activeDrafts?.find(
      (draft: any) =>
        draft.id === documentCode && !draft.isMaximized && !draft.isCenterAlign
    );
  } else {
    const key: any = getDocCodeKeyForDoc(docType);
    openedAndMinimizedDraft = activeDrafts?.find(
      (draft: any) =>
        documentCode === draft.populateFormData?.[key] &&
        !draft.isMaximized &&
        !draft.isCenterAlign
    );
  }
  return {
    isMinimized: !Utility.isEmpty(openedAndMinimizedDraft),
    activeFormData: openedAndMinimizedDraft?.populateFormData
  };
};

// Update address in document depending on doc type and Location CF
export const updateAddressAsPerLocationCF = (payload: any) => {
  const listContainsLocation = payload.customField?.find(
    (cf: any) => cf.label === LOCATION_CLASS_ENUM.LOCATION
  );
  const locationData = Store.getState().location?.locationData;

  if (listContainsLocation) {
    const locationObjForCF = locationData?.find(
      (loc: any) => loc.label === listContainsLocation.value
    );
    if (locationObjForCF) {
      if (Utility.isSalesDocument(payload)) {
        payload = {
          ...payload,
          shipFrom: locationObjForCF.locationDetails?.address
        };
      } else if (
        payload.documentType === DOC_TYPE.BILL ||
        payload.documentType === DOC_TYPE.FA_BILL ||
        payload.documentType === DOC_TYPE.FA_ORDER ||
        payload.documentType === DOC_TYPE.ORDER ||
        payload.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        payload = {
          ...payload,
          shipTo: locationObjForCF.locationDetails?.address,
          billTo: locationObjForCF.locationDetails?.address
        };
      }
    }
  }

  return payload;
};

export const checkGSTINPresentForSelectedContact = (docToValidate: any) => {
  const taxSystem = getTenantTaxSystem();
  if (
    docToValidate.documentType === DOC_TYPE.INVOICE &&
    taxSystem === TAX_SYSTEM.INDIA_GST &&
    docToValidate.contact &&
    !gstTreatmentWithoutGSTIN.includes(
      docToValidate.contact.gstTreatment
        ? docToValidate.contact.gstTreatment
        : docToValidate.gstTreatment
    ) &&
    (docToValidate.contact.gstTreatment
      ? !docToValidate.contact.gstin || docToValidate.contact.gstin === ''
      : !docToValidate.gstin || docToValidate.gstin === '')
  ) {
    return false;
  } else if (
    docToValidate.documentType === DOC_TYPE.BILL &&
    taxSystem === TAX_SYSTEM.INDIA_GST &&
    docToValidate.contact &&
    !gstTreatmentWithoutGSTIN.includes(docToValidate.contact.gstTreatment) &&
    (!docToValidate.contact.gstin || docToValidate.contact.gstin === '')
  ) {
    return false;
  } else if (
    (docToValidate.documentType === DOC_TYPE.QUOTE ||
      docToValidate.documentType === DOC_TYPE.SALES_ORDER ||
      docToValidate.documentType === DOC_TYPE.ORDER) &&
    taxSystem === TAX_SYSTEM.INDIA_GST &&
    docToValidate.contact &&
    !gstTreatmentWithoutGSTIN.includes(docToValidate.contact.gstTreatment) &&
    (!docToValidate.gstin || docToValidate.gstin === '')
  ) {
    return false;
  } else {
    return true;
  }
};

const isMandatoryCFValid = (value: string) => {
  const trimmedValue = value.trim();
  return (
    typeof trimmedValue !== 'undefined' &&
    trimmedValue !== null &&
    trimmedValue !== ''
  );
};

export const customFieldsContainsErrors = (customFields: any[] | null) => {
  let customFieldHasErrors = false;
  if (customFields && customFields.length) {
    for (let i = 0; i < customFields?.length; i++) {
      const mandatory = customFields[i]?.mandatory;
      if (mandatory) {
        const isFieldValid = isMandatoryCFValid(customFields[i]?.value);
        if (mandatory && !isFieldValid) {
          customFieldHasErrors = true;
          break;
        }
      }
      if (
        customFields[i]?.fieldType === CUSTOM_FIELD_TYPE.TEXT &&
        customFields[i]?.value?.length >
          (customFields[i]?.maxLength ||
            COMMON_CONSTANT.DEFAULT_CUSTOM_FIELD_MAX_LENGTH)
      ) {
        customFieldHasErrors = true;
        break;
      }
    }
  }
  return customFieldHasErrors;
};

export const checkCreditLimit = async (doc: Document) => {
  const globalCreditLimitSettings =
    Store.getState().authInfo.currentTenantInfo.data;
  const contact = { ...doc.contact };
  const isContactCreditLimitApplicable = contact.isCreditLimitApplicable;

  let creditLimitSettings: any;

  if (isContactCreditLimitApplicable) {
    creditLimitSettings = {
      includeCurrentDoc:
        doc.documentType === DOC_TYPE.INVOICE
          ? contact.isIncludeCurrentInvoice
          : doc.documentType === DOC_TYPE.QUOTE
          ? contact.isIncludeCurrentQuotation
          : contact.isIncludeCurrentSalesOrder,
      creditLimitType:
        doc.documentType === DOC_TYPE.INVOICE
          ? contact.invoiceCreditLimitType
          : doc.documentType === DOC_TYPE.QUOTE
          ? contact.quotationCreditLimitType
          : contact.salesOrderCreditLimitType,
      creditLimit: contact.creditLimit,
      creditLimitInBaseCurrency: contact.creditLimitInBaseCurrency
    };
  } else {
    creditLimitSettings = {
      includeCurrentDoc:
        doc.documentType === DOC_TYPE.INVOICE
          ? globalCreditLimitSettings.isIncludeCurrentInvoice
          : doc.documentType === DOC_TYPE.QUOTE
          ? globalCreditLimitSettings.isIncludeCurrentQuotation
          : globalCreditLimitSettings.isIncludeCurrentSalesOrder,
      creditLimitType:
        doc.documentType === DOC_TYPE.INVOICE
          ? globalCreditLimitSettings.invoiceCreditLimitType
          : doc.documentType === DOC_TYPE.QUOTE
          ? globalCreditLimitSettings.quotationCreditLimitType
          : globalCreditLimitSettings.salesOrderCreditLimitType,
      creditLimit: contact.creditLimit,
      creditLimitInBaseCurrency: contact.creditLimitInBaseCurrency
    };
  }

  try {
    const invoiceAmountDueResponse =
      await InvoiceService.getContactInvoiceAmountDue(contact.code);
    let oldDueAmoutForDoc = 0;
    if (
      typeof doc.oldDueAmount !== 'undefined' &&
      typeof doc.exchangeRate !== 'undefined' &&
      !doc.duplicate
    ) {
      oldDueAmoutForDoc = doc.oldDueAmount / doc.exchangeRate;
    }

    let contactInvoiceDueAmount: number;
    if (
      !Utility.isEmpty(invoiceAmountDueResponse) &&
      typeof doc.totalAmount !== 'undefined' &&
      doc.totalAmount !== null &&
      typeof doc.exchangeRate !== 'undefined' &&
      doc.exchangeRate !== null
    ) {
      const paidAmount =
        doc.knockoffInfo?.reduce(
          (total: number, paymentInfo: any) =>
            total + Number(paymentInfo?.amount || 0),
          0
        ) || 0;
      const currentDocDueAmountInBaseCurrency = Utility.roundOff(
        (doc.totalAmount - paidAmount) / doc.exchangeRate
      );

      contactInvoiceDueAmount = invoiceAmountDueResponse[0].dueAmount;

      // Deduct any old due amount within current document from total due amount.
      // This handles editing existing document
      contactInvoiceDueAmount -= oldDueAmoutForDoc;

      if (creditLimitSettings.includeCurrentDoc) {
        contactInvoiceDueAmount += currentDocDueAmountInBaseCurrency;
      }

      creditLimitSettings = {
        ...creditLimitSettings,
        totalDueAmount: contactInvoiceDueAmount
      };

      let objToReturn: any = {};

      // Handle contacts with no credit limit set
      if (
        !creditLimitSettings.creditLimit &&
        !creditLimitSettings.creditLimitInBaseCurrency
      ) {
        return {
          ...objToReturn,
          showAlertPopup: false,
          settings: creditLimitSettings
        };
      }

      switch (creditLimitSettings.creditLimitType) {
        case CREDIT_LIMIT_TYPE.BLOCK:
          if (
            contactInvoiceDueAmount >=
            creditLimitSettings.creditLimitInBaseCurrency
          ) {
            return {
              ...objToReturn,
              showAlertPopup: true,
              settings: creditLimitSettings
            };
          } else {
            return {
              ...objToReturn,
              showAlertPopup: false,
              settings: creditLimitSettings
            };
          }

        case CREDIT_LIMIT_TYPE.WARN:
          if (
            contactInvoiceDueAmount >
            creditLimitSettings.creditLimitInBaseCurrency
          ) {
            return {
              ...objToReturn,
              showAlertPopup: true,
              settings: creditLimitSettings
            };
          } else {
            return {
              ...objToReturn,
              showAlertPopup: false,
              settings: creditLimitSettings
            };
          }
        default:
          return {
            ...objToReturn,
            showAlertPopup: false,
            settings: creditLimitSettings
          };
      }
    }
  } catch (err) {
    console.error('Error while fetching invoice due amount for contact: ', err);
  }
};

export const getAccountsAndCFForBudgetValidation = (
  lineItems: any[],
  customFields: any[],
  accountCodeKeyName: string,
  taxKeyName: string,
  exchangeRate = 1,
  hasProduct = false
) => {
  let allAccountsData = [];
  for (let i = 0; i < lineItems?.length; i++) {
    const item = lineItems[i];
    const accountCode = hasProduct
      ? item?.product?.purchaseAccountCode
      : item?.[accountCodeKeyName];
    const totalAmount = item.totalAmount
      ? Utility.roundOffToTenantDecimalScale(item.totalAmount / exchangeRate)
      : 0;
    const taxAmount = item.taxAmount
      ? Utility.roundOffToTenantDecimalScale(item.taxAmount / exchangeRate)
      : 0;
    const amount = totalAmount - taxAmount;
    allAccountsData.push({
      accountCode,
      amount
    });
    if (item[taxKeyName] && taxAmount > 0) {
      const taxAccountCode = item[taxKeyName].accountCode;
      allAccountsData.push({
        accountCode: taxAccountCode,
        amount: taxAmount
      });
    }
  }

  let customField: any = {};
  const allDropDownCustomFields = customFields?.filter(
    (field: any) => field.fieldType === 'DROPDOWN' && field.value
  );
  for (let i = 0; i < allDropDownCustomFields?.length; i++) {
    customField = {
      ...customField,
      [allDropDownCustomFields[i].code]: allDropDownCustomFields[i].value
    };
  }

  return { accountsInfo: allAccountsData, customField };
};

export const validateBudgetForAccounts = async (
  docDate: string,
  accountsInfo: any[],
  customField: any,
  jeCode: string
) => {
  if (accountsInfo?.length) {
    const payload = {
      documentDate: docDate,
      data: accountsInfo,
      customField,
      jeCode: jeCode
    };
    const budgetValidationResp = await CoaService.checkBudgetLimit(payload);
    return budgetValidationResp;
  }
};

export const defaultAdditionalChargesObject: AdditionalChargeForDoc = {
  globalDiscount: {
    amount: 0,
    isPercent: false,
    isSubTotalOnly: true,
    percent: 0
  },
  additionalChargeAmount: null,
  additionalChargeTaxAmount: null,
  additionalChargesDetails: []
};

export const getNewCFItem = (item: any, filteredCF: any, editable = true) => {
  const newItem = {
    id: item.id,
    key: item.id,
    name: item.label,
    type: getColumnConfigType(filteredCF?.fieldType),
    width: 100,
    systemField: true,
    editable: editable,
    hidden: false,
    uiVisible: true,
    isCustomField: true, //IMP,
    code: item?.code, //IMP
    allowAddOption: false,
    formatter: (obj: any) => {
      return Utility.isObject(obj?.value) ? obj?.value?.value : obj?.value;
    },
    dropdownConfig:
      filteredCF?.fieldType !== 'DROPDOWN' && filteredCF?.fieldType !== 'USER'
        ? null
        : {
            title: '',
            allowSearch: false,
            searchableKey: 'name',
            style: { minWidth: 100 },
            className: 'shadow-m width-auto',
            data: !Utility.isEmpty(filteredCF?.attributes)
              ? filteredCF?.attributes?.filter(
                  (attr: any) =>
                    attr.status?.toUpperCase() === STATUS_TYPE.ACTIVE
                )
              : [],
            renderer: (index: any, obj: any) => {
              return obj?.value;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {}
          }
  };

  return newItem;
};

export const getNewColumnForCF = (item: any, editable = true) => {
  const newItem = {
    id: item.id,
    key: item.id,
    name: item.system ? getCapitalized(item.label?.toLowerCase()) : item.label,
    type: getColumnConfigType(item?.fieldType),
    width: 100,
    systemField: true,
    editable: editable,
    hidden: false,
    uiVisible: true,
    isCustomField: true, //IMP,
    code: item?.code, //IMP
    allowAddOption: false,
    formatter: (obj: any) => {
      return Utility.isObject(obj?.value) ? obj?.value?.value : obj?.value;
    },
    dropdownConfig:
      item?.fieldType !== 'DROPDOWN' && item?.fieldType !== 'USER'
        ? null
        : {
            title: '',
            allowSearch: false,
            searchableKey: 'name',
            style: { minWidth: 100 },
            className: 'shadow-m width-auto',
            data: !Utility.isEmpty(item?.attributes)
              ? item?.attributes?.filter(
                  (attr: any) =>
                    attr.status?.toUpperCase() === STATUS_TYPE.ACTIVE
                )
              : [],
            renderer: (index: any, obj: any) => {
              return obj?.value;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {}
          }
  };

  return newItem;
};

export const getNewColumnForDiscount = (discountDetail: any) => {
  const newItem = {
    id: discountDetail.id,
    key: `cascading_discount_${discountDetail.id}`,
    name: getCapitalized(discountDetail.name?.toLowerCase()),
    type: INPUT_TYPE.TEXT,
    width: 120,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    isCustomField: false, //IMP
    isDiscount: true,
    allowAddOption: false,
    textAlign: 'right',
    isPercent: discountDetail.isPercent,
    accountCode: discountDetail.accountCode,
    discountName: discountDetail.name
  };

  return newItem;
};

export const getNewColumnForTotalDiscount = () => {
  const newItem = {
    id: 'totalDiscountAmount',
    key: 'totalDiscountAmount',
    name: 'Total Discount',
    type: INPUT_TYPE.TEXT,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowAddOption: false,
    textAlign: 'right'
  };

  return newItem;
};

export const getCascadingDiscountsFromStore = (doc: any) => {
  let discountsFromStore: any[] = [];
  let cascadingDiscountsInStore =
    Store.getState()?.additionalCharges?.data?.all;
  cascadingDiscountsInStore = !Utility.isEmpty(cascadingDiscountsInStore)
    ? cascadingDiscountsInStore
    : [];
  cascadingDiscountsInStore = cascadingDiscountsInStore?.filter(
    (disc: any) => disc.isDiscount && disc.isItemDiscount
  );
  if (!Utility.isEmpty(cascadingDiscountsInStore)) {
    if (Utility.isSalesDocument(doc)) {
      discountsFromStore = cascadingDiscountsInStore.filter(
        (discount: any) =>
          discount.applyTo === 'SELL' || discount.applyTo === 'BOTH'
      );
    } else {
      discountsFromStore = cascadingDiscountsInStore.filter(
        (discount: any) =>
          discount.applyTo === 'BUY' || discount.applyTo === 'BOTH'
      );
    }
  }
  return discountsFromStore;
};

export const rebuildCascadingDiscountsForSaving = (payload: any) => {
  let lineItems = payload?.items ? [...payload?.items] : [];
  lineItems = lineItems?.map((lineItem: any) => {
    const discountDetailsKeys = Object.keys(lineItem)?.filter(
      (key: string) =>
        key?.toString()?.startsWith(CASCADING_DISCOUNT_PREFIX) &&
        key?.toString()?.endsWith('_details')
    );
    let discountDetails: any[] = [];
    discountDetailsKeys.forEach((discKey: string) => {
      if (!Utility.isEmpty(lineItem[discKey])) {
        discountDetails.push(lineItem[discKey]);
      }
    });
    discountDetails?.sort(
      (discount1: any, discount2: any) =>
        discount1.discountIndex - discount2.discountIndex
    );

    let existingGlobalDiscountsInLineItem = !Utility.isEmpty(
      lineItem?.additionalCharges?.globalDiscounts
    )
      ? [...lineItem?.additionalCharges?.globalDiscounts]
      : [];

    let cascadingDiscountsInStore = getCascadingDiscountsFromStore(payload);

    discountDetails.forEach((disc: any) => {
      const discIndexInExitingGlobalDisounts =
        existingGlobalDiscountsInLineItem.findIndex(
          (gDiscount: any) => gDiscount.id === disc.id
        );
      if (discIndexInExitingGlobalDisounts !== -1) {
        let existingDiscount = {
          ...existingGlobalDiscountsInLineItem[discIndexInExitingGlobalDisounts]
        };
        if (disc.isPercent) {
          existingDiscount = {
            ...existingDiscount,
            isPercent: true,
            percentageValue: disc.discount,
            percent: disc.discount,
            chargeValue: 0,
            amount: 0
          };
        } else {
          existingDiscount = {
            ...existingDiscount,
            isPercent: false,
            percentageValue: 0,
            percent: 0,
            chargeValue: disc.discount,
            amount: disc.discount
          };
        }
        existingGlobalDiscountsInLineItem[discIndexInExitingGlobalDisounts] =
          existingDiscount;
      } else {
        let discountInStore = cascadingDiscountsInStore.find(
          (storeDisc: any) => storeDisc.id === disc.id
        );
        if (!Utility.isEmpty(discountInStore)) {
          let accountCode = !Utility.isSalesDocument(payload)
            ? discountInStore.incomeAccountCode
            : discountInStore.expenseAccountCode;
          if (
            accountCode !== disc.accountCode &&
            !Utility.isEmpty(disc.accountCode)
          ) {
            accountCode = disc.accountCode;
          }

          if (disc.isPercent) {
            discountInStore = {
              ...discountInStore,
              isPercent: true,
              percentageValue: disc.discount,
              percent: disc.discount,
              chargeValue: 0,
              amount: 0,
              accountCode: accountCode
            };
          } else {
            discountInStore = {
              ...discountInStore,
              isPercent: false,
              percentageValue: 0,
              percent: 0,
              chargeValue: disc.discount,
              amount: disc.discount,
              accountCode: accountCode
            };
          }
          existingGlobalDiscountsInLineItem.push(discountInStore);
        } else {
          let tempDiscount: any;
          if (disc.isPercent) {
            tempDiscount = {
              id: disc.id,
              name: disc.name,
              isItemDiscount: true,
              isPercent: true,
              percentageValue: disc.discount,
              percent: disc.discount,
              chargeValue: 0,
              amount: 0,
              accountCode: disc.accountCode
            };
          } else {
            tempDiscount = {
              id: disc.id,
              name: disc.name,
              isItemDiscount: true,
              isPercent: false,
              percentageValue: 0,
              percent: 0,
              chargeValue: disc.discount,
              amount: disc.discount,
              accountCode: disc.accountCode
            };
          }
          existingGlobalDiscountsInLineItem.push(tempDiscount);
        }
      }
    });

    lineItem = {
      ...lineItem,
      additionalCharges: {
        ...lineItem.additionalCharges,
        globalDiscounts: existingGlobalDiscountsInLineItem
      }
    };

    if (Utility.isEmpty(existingGlobalDiscountsInLineItem)) {
      delete lineItem.itemDiscountMethod;
    }

    return lineItem;
  });
  return lineItems;
};

export const convertExpectedDeliveryDateInString = (payload: any) => {
  let lineItems = payload?.items ? [...payload?.items] : [];
  lineItems = lineItems?.map((item: any) => {
    let dateToConvert = new Date(item.expectedDeliveryDt);
    let expectedDeliveryDate = DateFormatService.getDateStrFromDate(
      dateToConvert,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    item.expectedDeliveryDt = Utility.isEmpty(expectedDeliveryDate)
      ? null
      : expectedDeliveryDate;
    return item;
  });
  return lineItems;
};

export const checkIfTotalDiscountInvalid = (payload: any, itemsKey: string) => {
  const items = payload[itemsKey]?.length ? [...payload[itemsKey]] : [];
  let isTotalDiscountInvalid = false;
  for (let item of items) {
    if (
      Utility.roundOffToTenantDecimalScale(item.subTotal) <
      item.totalDiscountAmount
    ) {
      isTotalDiscountInvalid = true;
      break;
    }
  }
  return isTotalDiscountInvalid;
};

export const showAlertOnDocAPIError = (err: any) => {
  const debugMessage = err?.data?.debugMessage;
  const errorMessage = err?.data?.errorMessage;
  if (debugMessage?.includes(TAX_PARSING_ERROR_SUB_MSG)) {
    showAlert('Error!', 'Tax selection is missing for one or more items.');
    return;
  }
  if (errorMessage && !errorMessage?.includes('exception')) {
    showAlert('Error!', errorMessage);
  } else {
    showAlert(
      'Error creating document!',
      'Error processing request. Please try again!'
    );
  }
};

export const getUomQuantity = (
  baseQuantity: any,
  documentUOMSchemaDefinition: any
) => {
  if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
    return Utility.roundOff(
      (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
        documentUOMSchemaDefinition.sourceConversionFactor
    );
  }
  return baseQuantity;
};

export const calculateCessAmount = (item: any, totalAfterDiscount?: number) => {
  let cessAmount = 0;
  const taxableAmount =
    typeof totalAfterDiscount === 'number'
      ? totalAfterDiscount
      : item.totalWithDiscount;
  if (item.cessRule && taxableAmount) {
    let cessExpression = '';
    if (item.productQuantity) {
      cessExpression = item.cessRule.replace(
        CESS_RULE_QUANTITY,
        `${item.productQuantity}`
      );
    }

    if (taxableAmount) {
      cessExpression = cessExpression
        ? cessExpression.replace(CESS_RULE_AMOUNT, `${taxableAmount}`)
        : item.cessRule.replace(CESS_RULE_AMOUNT, `${taxableAmount}`);
    }
    if (cessExpression) {
      cessAmount = eval(cessExpression);
    }
  }
  return roundOff(cessAmount);
};

export const rebuildAdvancedTrackingMetaDtosAndUOMInfo = (
  reservedQuantitiesData: any[],
  documentUOMSchemaDefinition: any,
  isConverting?: boolean
) => {
  let newReservedQuantitiesData: any[] = [];
  let rrbEnabled =
    Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings?.ROW_RACK_BIN?.filter(
      (item: any) => item?.enable
    );
  reservedQuantitiesData.forEach((data: any) => {
    let existingIndex;
    if (!Utility.isEmpty(rrbEnabled)) {
      existingIndex = newReservedQuantitiesData.findIndex(
        (item: any) =>
          item.warehouseCode === data.warehouseCode &&
          item.productCode === data.productCode &&
          item?.advancedTrackingMetaDtos?.[0]?.serialBatchNumber ==
            data?.advancedTrackingMetaDtos?.[0]?.serialBatchNumber &&
          item?.rowCode == data?.rowCode &&
          item?.rackCode == data?.rackCode &&
          item?.binCode == data?.binCode &&
          item?.reservedDate === data?.reservedDate
      );
    } else {
      existingIndex = newReservedQuantitiesData.findIndex(
        (item: any) =>
          item.warehouseCode === data.warehouseCode &&
          item.productCode === data.productCode &&
          item?.advancedTrackingMetaDtos?.[0]?.serialBatchNumber ==
            data?.advancedTrackingMetaDtos?.[0]?.serialBatchNumber &&
          item?.reservedDate === data?.reservedDate
      );
    }
    if (existingIndex !== -1) {
      const existingObj = newReservedQuantitiesData[existingIndex];
      const availableQuantity =
        existingObj.advancedTrackingMetaDtos
          .map((data: any) =>
            !Utility.isEmpty(documentUOMSchemaDefinition) &&
            !documentUOMSchemaDefinition?.isBaseUom &&
            data.advancedTrackingType !== TRACKING_TYPE.SERIAL
              ? Utility.getUomQtyIntoBaseQtyDecimal(
                  data.batchSize,
                  documentUOMSchemaDefinition
                )
              : data.batchSize
          )
          .reduce((prev: any, curr: any) => prev + curr, 0) +
          !Utility.isEmpty(documentUOMSchemaDefinition) &&
        !documentUOMSchemaDefinition?.isBaseUom &&
        data.advancedTrackingType !== TRACKING_TYPE.SERIAL
          ? Utility.getUomQtyIntoBaseQtyDecimal(
              data.advancedTrackingMetaDtos?.[0]?.batchSize,
              documentUOMSchemaDefinition
            )
          : data.advancedTrackingMetaDtos?.[0]?.batchSize;

      let currentAdvancedTrackingData: any = [];
      if (data.advancedTrackingType !== TRACKING_TYPE.SERIAL) {
        currentAdvancedTrackingData = data.advancedTrackingMetaDtos.map(
          (ele: any) => {
            return {
              ...ele,
              batchSize:
                !Utility.isEmpty(documentUOMSchemaDefinition) &&
                !documentUOMSchemaDefinition?.isBaseUom
                  ? Utility.getUomQtyIntoBaseQtyDecimal(
                      ele.batchSize,
                      documentUOMSchemaDefinition
                    )
                  : ele.batchSize,
              reservedQuantity:
                !Utility.isEmpty(documentUOMSchemaDefinition) &&
                !documentUOMSchemaDefinition?.isBaseUom
                  ? Utility.getUomQtyIntoBaseQtyDecimal(
                      ele.reservedQuantity,
                      documentUOMSchemaDefinition
                    )
                  : ele.reservedQuantity
            };
          }
        );
      } else {
        currentAdvancedTrackingData = data.advancedTrackingMetaDtos;
      }
      let updatedReservedQuantitiesData = {
        ...existingObj,
        availableQuantity: availableQuantity,
        reservedQuantity:
          existingObj.reservedQuantity +
            !Utility.isEmpty(documentUOMSchemaDefinition) &&
          !documentUOMSchemaDefinition?.isBaseUom &&
          data.advancedTrackingType !== TRACKING_TYPE.SERIAL
            ? Utility.getUomQtyIntoBaseQtyDecimal(
                data.reservedQuantity,
                documentUOMSchemaDefinition
              )
            : data.reservedQuantity,
        advancedTrackingMetaDtos: [
          ...existingObj.advancedTrackingMetaDtos,
          ...currentAdvancedTrackingData
        ]
      };
      delete updatedReservedQuantitiesData.advancedTracking;
      delete updatedReservedQuantitiesData.totalAmount;
      newReservedQuantitiesData[existingIndex] = updatedReservedQuantitiesData;
    } else {
      if (data.advancedTrackingType === TRACKING_TYPE.NONE) {
        data = {
          ...data,
          availableQuantity:
            !Utility.isEmpty(documentUOMSchemaDefinition) &&
            !documentUOMSchemaDefinition?.isBaseUom
              ? Utility.getUomQtyIntoBaseQtyDecimal(
                  isConverting ? data.availableQuantity : data.totalQuantity,
                  documentUOMSchemaDefinition
                )
              : isConverting
              ? data.availableQuantity
              : data.totalQuantity,
          reservedQuantity:
            !Utility.isEmpty(documentUOMSchemaDefinition) &&
            !documentUOMSchemaDefinition?.isBaseUom
              ? Utility.getUomQtyIntoBaseQtyDecimal(
                  data.reservedQuantity,
                  documentUOMSchemaDefinition
                )
              : data.reservedQuantity
        };
      } else if (data.advancedTrackingType === TRACKING_TYPE.BATCH) {
        data = {
          ...data,
          availableQuantity:
            !Utility.isEmpty(documentUOMSchemaDefinition) &&
            !documentUOMSchemaDefinition?.isBaseUom
              ? Utility.getUomQtyIntoBaseQtyDecimal(
                  data.advancedTrackingMetaDtos?.[0]?.batchSize -
                    (data.advancedTrackingMetaDtos?.[0]?.reservedQuantity -
                      data.advancedTrackingMetaDtos?.[0]
                        ?.reservedQuantityFulfilled +
                      data.advancedTrackingMetaDtos?.[0]?.batchSizeFulfilled),
                  documentUOMSchemaDefinition
                )
              : data.advancedTrackingMetaDtos?.[0]?.batchSize -
                (data.advancedTrackingMetaDtos?.[0]?.reservedQuantity -
                  data.advancedTrackingMetaDtos?.[0]
                    ?.reservedQuantityFulfilled +
                  data.advancedTrackingMetaDtos?.[0]?.batchSizeFulfilled),
          reservedQuantity:
            !Utility.isEmpty(documentUOMSchemaDefinition) &&
            !documentUOMSchemaDefinition?.isBaseUom
              ? Utility.getUomQtyIntoBaseQtyDecimal(
                  data.reservedQuantity,
                  documentUOMSchemaDefinition
                )
              : data.reservedQuantity,
          advancedTrackingMetaDtos: data.advancedTrackingMetaDtos.map(
            (ele: any) => {
              return {
                ...ele,
                batchSize:
                  !Utility.isEmpty(documentUOMSchemaDefinition) &&
                  !documentUOMSchemaDefinition?.isBaseUom
                    ? Utility.getUomQtyIntoBaseQtyDecimal(
                        ele.batchSize,
                        documentUOMSchemaDefinition
                      )
                    : ele.batchSize,
                reservedQuantity:
                  !Utility.isEmpty(documentUOMSchemaDefinition) &&
                  !documentUOMSchemaDefinition?.isBaseUom
                    ? Utility.getUomQtyIntoBaseQtyDecimal(
                        ele.reservedQuantity,
                        documentUOMSchemaDefinition
                      )
                    : ele.reservedQuantity,
                reservedQuantityFulfilled: isConverting
                  ? ele.reservedQuantityFulfilled
                  : 0
              };
              // ⇧ ⇧ Setting reservedQuantityFulfilled to zero to properly update reserve stock
            }
          )
        };
      } else {
        data = {
          ...data,
          availableQuantity:
            data.advancedTrackingMetaDtos?.[0]?.batchSize -
            (data.advancedTrackingMetaDtos?.[0]?.reservedQuantity -
              data.advancedTrackingMetaDtos?.[0]?.reservedQuantityFulfilled +
              data.advancedTrackingMetaDtos?.[0]?.batchSizeFulfilled)
        };
      }
      delete data.advancedTracking;
      delete data.totalQuantity;
      newReservedQuantitiesData.push(data);
    }
  });
  return newReservedQuantitiesData;
};

export const handleReservedQuantityDataObject = (
  payload: any,
  itemsKey: string
) => {
  let documentObj = {
    ...payload,
    [itemsKey]: payload[itemsKey].map((item: any) => {
      if (!Utility.isEmpty(item.reservedQuantitiesData)) {
        return {
          ...item,
          reservedQuantitiesData: rebuildAdvancedTrackingMetaDtosAndUOMInfo(
            item.reservedQuantitiesData,
            item.documentUOMSchemaDefinition,
            payload.isConverting
          )
        };
      }
      return item;
    })
  };
  return documentObj;
};

const attachDocumentCodeToDocument = (document: any) => {
  switch (document.documentType) {
    case DOC_TYPE.INVOICE:
      return {
        ...document,
        documentCode: document.salesInvoiceCode
      };
    case DOC_TYPE.EXPENSE_BILL:
    case DOC_TYPE.BILL:
      return {
        ...document,
        documentCode: document.purchaseInvoiceCode
      };
    case DOC_TYPE.QUOTE:
      return {
        ...document,
        documentCode: document.quotationCode
      };
    case DOC_TYPE.SALES_ORDER:
      return {
        ...document,
        documentCode: document.salesOrderCode
      };
    case DOC_TYPE.ORDER:
      return {
        ...document,
        documentCode: document.poCode
      };
    case DOC_TYPE.REQUISITION:
    case DOC_TYPE.PURCHASE_REQUISITION:
    case DOC_TYPE.SERVICE_REQUISITION:
      return {
        ...document,
        documentCode: document.purchaseRequestCode
      };
    case DOC_TYPE.EWAY_BILL:
      return {
        ...document,
        documentCode: document.ewayBillNo
      };
    case DOC_TYPE.JOB_WORK_OUT_ORDER:
      return {
        ...document,
        documentCode: document.jwoCode
      };
    case DOC_TYPE.WORK_ORDER:
      return {
        ...document,
        documentCode: document.workOrderCode
      };
    case DOC_TYPE.REQUISITION:
      return {
        ...document,
        documentCode: document.purchaseRequestCode
      };
    case DOC_TYPE.STOCK_TRANSFER:
      return {
        ...document,
        documentCode: document.code
      };
    case DOC_TYPE.STOCK_ADJUSTMENT:
      return {
        ...document,
        documentCode: document.code
      };
    case DOC_TYPE.QC_DOCUMENT:
      return {
        ...document,
        documentCode: document.documentSeqCode
      };
    case DOC_TYPE.SALES_RETURN:
      return {
        ...document,
        documentCode: document.salesReturnCode
      };
    case DOC_TYPE.PRODUCT:
      return {
        ...document,
        documentCode: document.productId
      };
    case DOC_TYPE.STOCK_REQUEST:
      return {
        ...document,
        documentCode: document.stockRequestCode
      };
    case DOC_TYPE.STOCK_ISSUE:
      return {
        ...document,
        documentCode: document.stockIssueCode
      };
    case DOC_TYPE.GATE_ENTRY:
      return {
        ...document,
        documentCode: document.gateEntryCode
      };
    case DOC_TYPE.QC_INSPECTION:
    case DOC_TYPE.INSPECTION_REPORT:
      return {
        ...document,
        documentCode: document.workOrderCode
      };
    default:
      return document;
  }
};

export const populateScondaryOverlayContainer = (content: JSX.Element) => {
  const overlayContainerElement: HTMLElement | null = document.getElementById(
    'secondary-overlay-container'
  );
  return overlayContainerElement
    ? ReactDOM.render(content, overlayContainerElement)
    : null;
};

export const createNewOverlayContainer = (content: JSX.Element) => {
  let overlayContainerElement = document.getElementById('doc-alert-container');
  if (!overlayContainerElement) {
    const parentElement: HTMLElement | null =
      document.getElementsByTagName('body')[0];
    overlayContainerElement = document.createElement('div');
    overlayContainerElement.setAttribute('id', 'doc-alert-container');
    parentElement.appendChild(overlayContainerElement);
  }
  return overlayContainerElement
    ? ReactDOM.render(content, overlayContainerElement)
    : null;
};

const cleanupAlertContainer = () => {
  let overlayContainerElement = document.getElementById('doc-alert-container');
  overlayContainerElement?.remove();
};

// Get print preview of a document
export const getPrintPreview = (
  docType: any,
  document: any,
  callbackFn?: any
) => {
  document = attachDocumentCodeToDocument(document);
  return populateScondaryOverlayContainer(
    <Provider store={Store}>
      <PrintPreview
        documentType={docType}
        document={document}
        closePreview={callbackFn}
      />
    </Provider>
  );
};

export const getBulkPrintPreview = (
  docType: any,
  documentCodes: any[],
  callbackFn?: any
) => {
  return populateScondaryOverlayContainer(
    <Provider store={Store}>
      <PrintPreview
        documentType={docType}
        bulkPrintDocumentCodes={documentCodes}
        isBulkPrint={true}
        closePreview={callbackFn}
      />
    </Provider>
  );
};

// Get Email popup for a document
export const getEmailPopup = (
  docType: any,
  document: any,
  templateConfig?: any,
  callbackFn?: any
) => {
  document = attachDocumentCodeToDocument(document);
  return populateScondaryOverlayContainer(
    <Provider store={Store}>
      <Email
        onEmailDialogClose={callbackFn}
        documentType={docType}
        data={document}
        emailTemplateConfig={templateConfig}
      />
    </Provider>
  );
};

export const getDocumentAlert = (
  title: string,
  message: string,
  booksDocument: any,
  draftData: any,
  documentMode: DOCUMENT_MODE,
  pageRoute: string
) => {
  const document = attachDocumentCodeToDocument(booksDocument);
  return createNewOverlayContainer(
    <Provider store={Store}>
      <DocumentAlert
        showAlert={true}
        title={title}
        message={message}
        booksDocument={document}
        draftData={draftData}
        documentMode={documentMode}
        pageRoute={pageRoute}
        onClose={() => {
          cleanupAlertContainer();
        }}
      />
    </Provider>
  );
};

export const checkMultiApprovalRequired = (data: any, status?: any) => {
  let multiApproveRequired: any = false;
  let condition: any = 'ANY';
  const draft = data?.populateFormData;
  let loginUserEmail = Store.getState().authInfo.userInfo.data[0].email;
  let currentApproverCondition: any = [];

  let multiApprovalHistory: any = {
    currentLevel: 1,
    currentLevelName: '',
    approvalHistory: [
      {
        level: 1,
        approvedBy: loginUserEmail,
        approvedDate: DateFormatService.getDateStrFromDate(
          new Date(),
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        status: status,
        Remarks: ''
      }
    ],
    approvalRequiredFor: draft?.multiApprovalDetails?.approvalRequiredFor || ''
  };
  if (status === APPROVAL_STATUS.APPROVED) {
    multiApprovalHistory['approverUsers'] = { 1: [loginUserEmail] };
    multiApprovalHistory['rejectedUsers'] = { 1: [] };
  } else {
    multiApprovalHistory['rejectedUsers'] = { 1: [loginUserEmail] };
    multiApprovalHistory['approverUsers'] = { 1: [] };
  }

  currentApproverCondition = Utility.getUserSpecificAutomation(draft, true);

  let levels = 1;
  if (
    currentApproverCondition &&
    currentApproverCondition.fields &&
    currentApproverCondition.fields.length > 0 &&
    currentApproverCondition?.fields[0]?.multiApprovalDetails
  ) {
    levels = currentApproverCondition.fields[0].multiApprovalDetails.length;
  }
  if (
    currentApproverCondition &&
    currentApproverCondition.fields &&
    currentApproverCondition.fields.length > 0 &&
    currentApproverCondition?.fields[0]?.multiApprovalDetails
  ) {
    let currentLevel = draft?.multiApprovalDetails?.currentLevel || 1;
    let currentLevelNode =
      currentApproverCondition?.fields[0]?.multiApprovalDetails?.filter(
        (ele: any) => ele.level === currentLevel
      );
    condition = currentLevelNode[0].condition;
  }

  if (
    !draft.hasOwnProperty('multiApprovalDetails') ||
    Utility.isEmpty(draft.multiApprovalDetails?.approvalHistory)
  ) {
    let currentLevel = draft?.multiApprovalDetails?.currentLevel || 1;
    multiApproveRequired = true;

    multiApproveRequired =
      condition === 'ANY'
        ? levels === currentLevel
          ? false
          : true
        : levels === currentLevel
        ? false
        : true;
    multiApprovalHistory = multiApprovalHistory;
    if (
      currentApproverCondition &&
      currentApproverCondition.fields &&
      currentApproverCondition.fields.length > 0 &&
      !Utility.isEmpty(currentApproverCondition?.fields[0]?.approver)
    ) {
      multiApproveRequired = false;
    }

    let approverArray: any = [];
    if (
      currentApproverCondition &&
      currentApproverCondition.fields &&
      currentApproverCondition.fields.length > 0
    ) {
      approverArray =
        currentApproverCondition?.fields[0]?.multiApprovalDetails?.filter(
          (ele: any) => ele.level === currentLevel
        )[0]?.approver;
    }

    if (condition === 'ALL' && approverArray?.length > 1) {
      multiApproveRequired = true;
    }
    let isCurrentLevelIncrease = approverArray.every((ele: any) =>
      draft.multiApprovalDetails?.['approverUsers']?.[currentLevel]?.includes(
        ele
      )
    );

    if (
      currentApproverCondition &&
      currentApproverCondition.fields &&
      currentApproverCondition.fields.length > 0
    ) {
      approverArray =
        currentApproverCondition?.fields[0]?.multiApprovalDetails?.filter(
          (ele: any) => ele.level === currentLevel
        )[0]?.approver;
    }

    isCurrentLevelIncrease =
      condition === 'ALL'
        ? approverArray.every((ele: any) =>
            multiApprovalHistory['approverUsers'][currentLevel]?.includes(ele)
          )
        : approverArray.some((ele: any) =>
            multiApprovalHistory['approverUsers'][currentLevel]?.includes(ele)
          );

    currentLevel = isCurrentLevelIncrease
      ? levels === currentLevel
        ? currentLevel
        : currentLevel + 1
      : currentLevel;
    multiApprovalHistory['currentLevel'] = currentLevel;
  } else if (
    draft.hasOwnProperty('multiApprovalDetails') &&
    draft.multiApprovalDetails.approvalHistory
  ) {
    let currentLevel = draft.multiApprovalDetails.currentLevel;

    multiApproveRequired =
      condition === 'ANY' ? (levels === currentLevel ? false : true) : true;
    // let approverArray = currentApproverCondition?.fields[0].approver.split(',');
    let approverArray: any = [];
    if (
      currentApproverCondition &&
      currentApproverCondition.fields &&
      currentApproverCondition.fields.length > 0
    ) {
      approverArray =
        currentApproverCondition?.fields[0]?.multiApprovalDetails?.filter(
          (ele: any) => ele.level === currentLevel
        )[0]?.approver;
    }
    let isCurrentLevelIncrease = approverArray.every((ele: any) =>
      draft.multiApprovalDetails?.['approverUsers']?.[currentLevel]?.includes(
        ele
      )
    );

    if (
      currentApproverCondition &&
      currentApproverCondition.fields &&
      currentApproverCondition.fields.length > 0
    ) {
      approverArray =
        currentApproverCondition?.fields[0]?.multiApprovalDetails?.filter(
          (ele: any) => ele.level === currentLevel
        )[0]?.approver;
    }

    multiApprovalHistory = {
      currentLevel: currentLevel,
      approvalHistory: [
        ...draft.multiApprovalDetails.approvalHistory,
        {
          level: currentLevel,
          approvedBy: loginUserEmail,
          approvedDate: DateFormatService.getDateStrFromDate(
            new Date(),
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          status: status,
          Remarks: ''
        }
      ],
      approverUsers: draft.multiApprovalDetails.approverUsers || {},
      rejectedUsers: draft.multiApprovalDetails.rejectedUsers || {},
      approvalRequiredFor: draft.multiApprovalDetails.approvalRequiredFor || ''
    };

    if (Array.isArray(multiApprovalHistory.approverUsers)) {
      const approverUsers = multiApprovalHistory.approverUsers;
      multiApprovalHistory.approverUsers = {};
      approverUsers.forEach((users: string[], index: number) => {
        multiApprovalHistory.approverUsers[index] = users;
      });
    }

    if (Array.isArray(multiApprovalHistory.rejectedUsers)) {
      const rejectedUsers = multiApprovalHistory.rejectedUsers;
      multiApprovalHistory.rejectedUsers = {};
      rejectedUsers.forEach((users: string[], index: number) => {
        multiApprovalHistory.rejectedUsers[index] = users;
      });
    }

    let users: any = [];
    multiApprovalHistory.approvalHistory.forEach((ele: any) => {
      if (ele.level === multiApprovalHistory.currentLevel) {
        if (!users.includes(ele.approvedBy) && ele.status === status) {
          users.push(ele.approvedBy);
        }
      }
    });
    if (status === APPROVAL_STATUS.APPROVED) {
      multiApprovalHistory['approverUsers'][currentLevel] = users;
      const index =
        multiApprovalHistory['rejectedUsers']?.[currentLevel]?.indexOf(
          loginUserEmail
        );
      if (index > -1) {
        multiApprovalHistory['rejectedUsers']?.[currentLevel]?.splice(index, 1);
      }
    } else {
      multiApprovalHistory['rejectedUsers'][currentLevel] = users;
    }

    isCurrentLevelIncrease =
      condition === 'ALL'
        ? approverArray.every((ele: any) =>
            multiApprovalHistory['approverUsers'][currentLevel]?.includes(ele)
          )
        : approverArray.some((ele: any) =>
            multiApprovalHistory['approverUsers'][currentLevel]?.includes(ele)
          );

    let nextLevel = isCurrentLevelIncrease
      ? levels === currentLevel
        ? currentLevel
        : currentLevel + 1
      : currentLevel;
    multiApprovalHistory['currentLevel'] = nextLevel;

    approverArray =
      currentApproverCondition?.fields?.[0]?.multiApprovalDetails?.filter(
        (ele: any) => ele.level === currentLevel
      )[0]?.approver;

    if (levels < nextLevel && isCurrentLevelIncrease) {
      multiApproveRequired = false;
    }
    if (levels == currentLevel && isCurrentLevelIncrease) {
      multiApproveRequired = false;
    }
  }
  return { multiApproveRequired, multiApprovalHistory };
};
export const getApprovalLevel = (doc: any, status: any) => {
  let requiredFor = Utility.isEmpty(
    doc?.multiApprovalDetails?.approvalRequiredFor
  )
    ? ''
    : doc?.multiApprovalDetails?.approvalRequiredFor;

  let approvalConditions = Store.getState().automation?.data;
  let currentApproverCondition: any = [];
  let flag: any = false;
  let level: any = '';

  let userSpecificList =
    !Utility.isEmpty(approvalConditions) &&
    approvalConditions.filter((ele: any) => {
      if (doc.documentType === DOC_TYPE.REQUISITION) {
        return ele.fields[0].field_type === doc.documentType;
      } else {
        return (
          ele.fields[0].field_type === doc.documentType &&
          ele.fields[0].approvalFor.split(',').includes(requiredFor)
        );
      }
    });

  if (Utility.isEmpty(userSpecificList)) {
    if (
      status === APPROVAL_STATUS_LIST.PENDING_FOR_APPROVAL ||
      // status === APPROVAL_STATUS_LIST.PENDING ||
      status === APPROVAL_STATUS_LIST.REJECTED
    ) {
      if (Utility.isEmpty(doc?.multiApprovalDetails?.approvalHistory)) {
        level = ` at level 1`;
      } else {
        level = ` at level ` + doc?.multiApprovalDetails?.currentLevel;
      }
    }
  } else {
    currentApproverCondition = userSpecificList[0];
    for (let index = 0; index < userSpecificList.length; index++) {
      flag = Utility.isConditionApproverApply([userSpecificList[index]], doc);
      if (flag) {
        currentApproverCondition = userSpecificList[index];
        break;
      }
    }
  }
  let currentLevelNode =
    currentApproverCondition?.fields?.[0]?.multiApprovalDetails?.filter(
      (ele: any) => ele.level === doc?.multiApprovalDetails?.currentLevel
    );

  if (
    status === APPROVAL_STATUS_LIST.PENDING_FOR_APPROVAL ||
    // status === APPROVAL_STATUS_LIST.PENDING ||
    status === APPROVAL_STATUS_LIST.REJECTED
  ) {
    if (Utility.isEmpty(doc?.multiApprovalDetails?.approvalHistory)) {
      if (Utility.isEmpty(currentLevelNode)) {
        level = ` at level 1`;
      } else {
        level = ` at ` + currentLevelNode[0].levelLabel;
      }
    } else {
      if (Utility.isEmpty(currentLevelNode)) {
        level = ` at level ` + doc?.multiApprovalDetails?.currentLevel;
      } else {
        level = ` at ` + currentLevelNode[0].levelLabel;
      }
    }
  }
  return level;
};
export const showDocAmountUpdatedAlert = (onUpdate: any, onCancel?: any) => {
  showAlert(
    'Document Amount Updated!',
    'Total amount has been update, would you like to update linked payment milestones ?',
    [
      {
        title: 'Cancel',
        className: onCancel
      },
      {
        title: 'Update',
        className: 'bg-button text-white',
        onClick: onUpdate
      }
    ]
  );
};

export const isDocContactInactive = (contact: any) => {
  return contact.status === STATUS_TYPE.INACTIVE;
};

export const lineItemsContainsTDSInfo = (items: any[]) => {
  const isTDSInfoPresent = items.some(
    (item: any) => !Utility.isEmpty(item.tdsInfoIndia)
  );
  return isTDSInfoPresent;
};

const getEmailStatus = (emailSend: boolean = false) => {
  return (
    <DKTooltipWrapper
      content={emailSend ? 'Mail sent' : 'Mail sending'}
      tooltipClassName="bg-deskera-secondary width-auto"
    >
      <DKIcon src={ic_email} className="ic-xs opacity-60 cursor-pointer ml-s" />
    </DKTooltipWrapper>
  );
};

export const getRowIdByModule = (rowData: any, type: string) => {
  switch (type) {
    case DOC_TYPE.BILL:
      return rowData.purchaseInvoiceCode;
    case DOC_TYPE.QUOTE:
      return rowData.quotationCode;
    case DOC_TYPE.INVOICE:
      return rowData.salesInvoiceCode;
    case DOC_TYPE.ORDER:
      return rowData.poCode;
    case DOC_TYPE.SALES_ORDER:
      return rowData.salesOrderCode;
  }
};

export const getEmailStatusForGrid = (rowData: any, type: string) => {
  let moduleIds = Store.getState().commonData.emailTriggeredDocumentIds;
  let ids = moduleIds[type] || [];
  let compareId = getRowIdByModule(rowData, type);
  return rowData?.emailStatus === EMAIL_STATUS.SENT
    ? getEmailStatus(true)
    : ids?.includes(compareId)
    ? getEmailStatus()
    : null;
};

/**
 * Get the product price key to be used depending on document type
 * @param docType
 * @returns salesPrice/purchasePrice as string
 */
export const getProductPriceKeyFromDocType = (docType: DOC_TYPE) => {
  let priceKeyToRead = '';
  const mainModuleType = getMainModuleName(docType);
  if (mainModuleType === MODULE_NAME_FOR_STORAGE_UTILITY.SELL) {
    priceKeyToRead = 'salesPrice';
  } else if (mainModuleType === MODULE_NAME_FOR_STORAGE_UTILITY.BUY) {
    priceKeyToRead = 'purchasePrice';
  }
  return priceKeyToRead;
};

export const updateAvailableQtyBasedOnUOM = (rowData: any, qty: number) => {
  qty = Number(qty);
  const documentUOMSchemaDefinition = rowData.documentUOMSchemaDefinition;
  let availableCount = 0;
  if (typeof qty !== 'undefined' && qty !== null) {
    if (documentUOMSchemaDefinition && qty) {
      availableCount =
        (qty * documentUOMSchemaDefinition.sinkConversionFactor) /
        documentUOMSchemaDefinition.sourceConversionFactor;
      if (availableCount) {
        availableCount = Utility.roundOff(availableCount);
      }
    } else {
      availableCount = typeof qty !== 'undefined' && qty !== null ? qty : 0;
    }
  }
  return Utility.roundOffToTenantDecimalScale(availableCount);
};

export const getLogButton = (data: any) => {
  let res = false;
  if (!checkUserPermission(PERMISSIONS_BY_MODULE.LOGS.TRANSACTION_LOGS)) {
    return res;
  }
  res =
    (data.draftType === DraftTypes.UPDATE ||
      data.draftType === DraftTypes.READONLY) &&
    !data.isReadOnlyDraft &&
    (data.type == 'Quote' ||
      data.type == 'Bill' ||
      data.type == 'Purchase Order' ||
      data.type == 'Sales Order' ||
      data.type == 'Invoice' ||
      data.type == 'Expense Bill' ||
      data.type == 'Requisition');
  return res;
};

export const DOCS_SUPPORTING_FULLSCREEN = [
  DOC_TYPE.INVOICE,
  DOC_TYPE.QUOTE,
  DOC_TYPE.SALES_ORDER,
  DOC_TYPE.ORDER,
  DOC_TYPE.BILL,
  DOC_TYPE.EXPENSE_BILL
];

export const isSGAndPeppolOptInEnabled = (): boolean => {
  const country = Store.getState().authInfo?.currentTenantInfo?.data?.country;
  if (
    country === COUNTRY_CODES.SG &&
    Store.getState().authInfo?.currentTenantInfo?.data?.peppolOptIn === true
  ) {
    return true;
  } else {
    return false;
  }
};

export const addressRenderer = (obj: any, type: any) => {
  let addressLabelArray: any = [];

  let contactName = obj?.rowData?.[type]?.contactName
    ? obj?.rowData?.[type]?.contactName
    : '';

  if (!Utility.isEmpty(obj?.rowData?.[type]?.address1)) {
    addressLabelArray.push(obj?.rowData?.[type]?.address1);
  }

  if (!Utility.isEmpty(obj.rowData?.[type]?.address2)) {
    addressLabelArray.push(obj.rowData?.[type]?.address2);
  }

  if (!Utility.isEmpty(obj.rowData?.[type]?.city)) {
    addressLabelArray.push(obj.rowData?.[type]?.city);
  }

  if (!Utility.isEmpty(obj.rowData?.[type]?.state)) {
    addressLabelArray.push(obj.rowData?.[type]?.state);
  }

  if (!Utility.isEmpty(obj.rowData?.[type]?.country)) {
    addressLabelArray.push(obj.rowData?.[type]?.country);
  }

  if (!Utility.isEmpty(obj.rowData?.[type]?.postalCode)) {
    addressLabelArray.push(obj.rowData?.[type]?.postalCode);
  }

  let addressLabelArray1 = addressLabelArray;

  if (!Utility.isEmpty(contactName)) {
    addressLabelArray1.unshift(contactName);
  }

  var addressLabel = addressLabelArray1.join(', ').substring(0, 40);

  if (addressLabelArray1.join(', ').length > 40) {
    addressLabel += '...';
  }

  return (
    <DKTooltipWrapper
      content={` ${
        contactName ? '<b>' + contactName + '</b>' + '</br></br>' : ''
      }
      ${addressLabelArray.join(', ')}`}
      className="ml-1 position-relative width-auto"
      style={{ whiteSpace: 'normal' }}
      tooltipClassName="bg-deskera-secondary width-auto"
    >
      <DKLabel
        style={{ whiteSpace: 'normal' }}
        text={` ${addressLabel ? addressLabel : ''}`}
      />
    </DKTooltipWrapper>
  );
};

export const isSGAndPeppolOptInDocument = (contactDto: any): boolean => {
  const country = Store.getState().authInfo?.currentTenantInfo?.data?.country;
  if (
    country === COUNTRY_CODES.SG &&
    Store.getState().authInfo?.currentTenantInfo?.data?.peppolOptIn === true &&
    contactDto &&
    Utility.isNotEmpty(contactDto?.peppolId)
  ) {
    return true;
  } else {
    return false;
  }
};

export const updateColumnConfigOnRowClick = (
  columnData: any,
  rowData: any,
  columnConfig: any[],
  allCFs: any[],
  cfUpdatedTimeMap?: any
) => {
  if (
    columnData?.isCustomField &&
    columnData?.type === INPUT_TYPE.DROPDOWN.toLowerCase()
  ) {
    const filteredCF: any = allCFs?.find(
      (field: any) =>
        field.id === columnData.id &&
        field.fieldType.toLowerCase() ===
          CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
    );
    if (!Utility.isEmpty(filteredCF)) {
      const noneAttribute = {
        id: 0,
        code: null,
        status: STATUS_TYPE.ACTIVE,
        value: 'None'
      };
      const childCFs = allCFs.filter(
        (field: any) =>
          field.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase() &&
          field.parent &&
          field.parent.id === filteredCF.id
      );
      let childCF: any = childCFs?.find(
        (cf: any) =>
          typeof rowData?.[cf?.id] !== 'undefined' &&
          rowData?.[cf?.id] !== null &&
          rowData?.[cf?.id] !== ''
      );
      if (!childCF) {
        childCF = childCFs?.[0];
      }
      if (!Utility.isEmpty(cfUpdatedTimeMap)) {
        let cfsInMap: any[] = [];
        childCFs.forEach((cf: any) => {
          if (
            typeof cfUpdatedTimeMap[cf.id] !== 'undefined' &&
            cfUpdatedTimeMap[cf.id] !== null
          ) {
            cfsInMap.push({ cf: cf, timeValue: cfUpdatedTimeMap[cf.id] });
          }
        });
        cfsInMap.sort((cf1: any, cf2: any) => cf2.timeValue - cf1.timeValue);
        if (cfsInMap.length) {
          childCF = cfsInMap?.[0]?.cf;
        }
      }

      const parentCF = filteredCF?.parent;

      const activeFilteredCFAttributes = filteredCF?.attributes?.filter(
        (attr: any) => attr.status.toUpperCase() === STATUS_TYPE.ACTIVE
      );
      activeFilteredCFAttributes.unshift(noneAttribute);
      let parentCFValue: any = null;

      if (!Utility.isEmpty(parentCF)) {
        parentCFValue = Utility.isObject(rowData?.[parentCF.id])
          ? rowData?.[parentCF.id]?.value
          : rowData[parentCF.id];
        if (parentCFValue) {
          const parentAttributeSelected = parentCF?.attributes?.find(
            (attr: any) => attr.value === parentCFValue
          );
          const filteredCurrentCFAttributes =
            activeFilteredCFAttributes?.filter(
              (cfAttr: any) =>
                cfAttr?.parentIdList &&
                cfAttr?.parentIdList?.includes(
                  parentAttributeSelected?.id?.toString()
                )
            ) || [];
          filteredCurrentCFAttributes.unshift(noneAttribute);
          columnData.dropdownConfig.data = filteredCurrentCFAttributes;
        } else {
          columnData.dropdownConfig.data = activeFilteredCFAttributes;
        }
      } else {
        columnData.dropdownConfig.data = activeFilteredCFAttributes;
      }

      if (!Utility.isEmpty(childCF)) {
        const childCFValue = Utility.isObject(rowData?.[childCF.id])
          ? rowData?.[childCF.id]?.value
          : rowData[childCF.id];
        let updatedConfigs: any[] = [...columnConfig];
        const currentConfigIndex = updatedConfigs.findIndex(
          (config: any) => config.id === filteredCF.id
        );
        if (currentConfigIndex !== -1) {
          if (
            typeof childCFValue !== 'undefined' &&
            childCFValue !== null &&
            childCFValue !== ''
          ) {
            const activeChildCFAttributes = childCF.attributes?.filter(
              (attr: any) => attr.status.toUpperCase() === STATUS_TYPE.ACTIVE
            );
            activeChildCFAttributes.unshift(noneAttribute);
            const childCFAttr = activeChildCFAttributes.find(
              (attr: any) => attr.value === childCFValue
            );
            const filteredParentAttributes =
              activeFilteredCFAttributes?.filter(
                (cfAttr: any) =>
                  childCFAttr?.parentIdList &&
                  childCFAttr?.parentIdList?.includes(cfAttr?.id?.toString())
              ) || [];
            filteredParentAttributes.unshift(noneAttribute);
            columnData.dropdownConfig.data = filteredParentAttributes;
          } else {
            if (!parentCFValue) {
              columnData.dropdownConfig.data = activeFilteredCFAttributes;
            }
          }
        }
      }

      if (!parentCF && !childCF) {
        columnData.dropdownConfig.data = activeFilteredCFAttributes;
      }
    }
  }
};

export const getDirectChildCFs = (currentCF: any, allCustomFields: any[]) => {
  let children: any[] = [];
  const childCFs = allCustomFields.filter(
    (cf: any) => cf.parent?.id === currentCF?.id
  );
  if (childCFs?.length) {
    children = [...children, ...childCFs];
    childCFs.forEach((cf: any) => {
      const childrenOfChild = getDirectChildCFs(cf, allCustomFields);
      if (childrenOfChild?.length && children) {
        children = [...children, ...childrenOfChild];
      }
    });
  }
  return children;
};

export const getDirectParentCFs = (currentCF: any, allCustomFields: any[]) => {
  let parents: any[] = [];
  while (
    currentCF?.parent &&
    currentCF?.parent !== null &&
    typeof currentCF?.parent !== 'undefined'
  ) {
    currentCF = currentCF?.parent;
    parents.push(currentCF);
  }
  return parents;
};

// Returns updated RowData based on parent child CFs
export const updateRowDataWithParentCFValues = (
  selectedValue: any,
  rowData: any,
  filteredCF: any,
  allCustomFields: any[]
) => {
  const noneAttribute = {
    id: 0,
    code: null,
    status: STATUS_TYPE.ACTIVE,
    value: 'None'
  };
  let allParentCFsForCurrentCF: any[] = getDirectParentCFs(
    filteredCF,
    allCustomFields
  );
  let allChildCFsForCurrentCF: any[] = getDirectChildCFs(
    filteredCF,
    allCustomFields
  );
  const activeFilteredCFAttributes = filteredCF?.attributes?.filter(
    (attr: any) => attr.status.toUpperCase() === STATUS_TYPE.ACTIVE
  );
  let currentSelectedAttr: any = activeFilteredCFAttributes?.find(
    (attribute: any) =>
      attribute.value ===
      (Utility.isObject(selectedValue) ? selectedValue?.value : selectedValue)
  );

  allParentCFsForCurrentCF.forEach((parentCF: any) => {
    // Update value of all parents
    if (parentCF) {
      const activeParentCFAttributes = parentCF?.attributes?.filter(
        (attr: any) => attr.status.toUpperCase() === STATUS_TYPE.ACTIVE
      );
      const filteredParentAttributes =
        activeParentCFAttributes?.filter(
          (cfAttr: any) =>
            currentSelectedAttr?.parentIdList &&
            currentSelectedAttr?.parentIdList?.includes(cfAttr?.id?.toString())
        ) || [];
      filteredParentAttributes.unshift(noneAttribute);
      const parentValueSelected =
        filteredParentAttributes?.length > 0
          ? filteredParentAttributes?.[1]
          : null;
      const cfToUpdate = {
        id: parentCF.id,
        shortName: parentCF.shortName,
        module: parentCF.module,
        code: parentCF.code,
        label: parentCF.label,
        value: parentValueSelected?.value
      };
      let existingCFs = rowData?.customField ? [...rowData.customField] : [];
      const existingCFIndex = existingCFs.findIndex(
        (cf: any) => cf?.id === cfToUpdate?.id
      );
      if (existingCFIndex === -1) {
        existingCFs = [...existingCFs, cfToUpdate];
      } else {
        existingCFs[existingCFIndex] = cfToUpdate;
      }
      if (rowData) {
        rowData.customField = existingCFs;
      }
      rowData[parentCF.id] = Utility.isObject(selectedValue)
        ? parentValueSelected
        : parentValueSelected?.value;
      currentSelectedAttr = activeParentCFAttributes?.find(
        (attribute: any) => attribute.value === parentValueSelected?.value
      );
    }
  });

  allChildCFsForCurrentCF.forEach((childCF: any) => {
    let currentParent = childCF?.parent;
    if (currentParent) {
      const activeChildCFAttributes = childCF?.attributes?.filter(
        (attr: any) => attr.status.toUpperCase() === STATUS_TYPE.ACTIVE
      );

      const alreadySelectedParentValue = Utility.isObject(
        rowData?.[currentParent?.id]
      )
        ? rowData?.[currentParent?.id]?.value
        : rowData?.[currentParent?.id];

      const filteredParentAttr = currentParent?.attributes?.find(
        (attr: any) => attr.value === alreadySelectedParentValue
      );

      const alreadySelectedChildValue = Utility.isObject(rowData?.[childCF?.id])
        ? rowData?.[childCF?.id]?.value
        : rowData?.[childCF?.id];

      const filteredChildAttributes =
        activeChildCFAttributes?.filter(
          (cfAttr: any) =>
            filteredParentAttr &&
            cfAttr?.parentIdList &&
            cfAttr?.parentIdList?.includes(filteredParentAttr?.id?.toString())
        ) || [];
      filteredChildAttributes.unshift(noneAttribute);

      let updatedChildValue = null;
      if (
        typeof alreadySelectedChildValue !== 'undefined' &&
        alreadySelectedChildValue !== null &&
        alreadySelectedChildValue !== ''
      ) {
        const selectedChildCFValueIndexInFilteredChildAttributes =
          filteredChildAttributes?.findIndex(
            (cfAttr: any) => cfAttr.value === alreadySelectedChildValue
          );
        if (selectedChildCFValueIndexInFilteredChildAttributes !== -1) {
          updatedChildValue = rowData[childCF.id];
        } else {
          // reset child
          updatedChildValue = null;
        }
      } else {
        // reset child
        updatedChildValue = null;
      }

      const cfToUpdate = {
        id: childCF.id,
        shortName: childCF.shortName,
        module: childCF.module,
        code: childCF.code,
        label: childCF.label,
        value: Utility.isObject(updatedChildValue)
          ? updatedChildValue?.value
          : updatedChildValue
      };
      let existingCFs = rowData?.customField ? [...rowData.customField] : [];
      const existingCFIndex = existingCFs.findIndex(
        (cf: any) => cf?.id === cfToUpdate?.id
      );
      if (existingCFIndex === -1) {
        existingCFs = [...existingCFs, cfToUpdate];
      } else {
        existingCFs[existingCFIndex] = cfToUpdate;
      }
      if (rowData) {
        rowData.customField = existingCFs;
      }
      rowData[childCF.id] = updatedChildValue;
    }
  });

  return { rowData };
};

export const checkIfAllProductsActiveInDoc = (doc: any, docType: DOC_TYPE) => {
  let isAllProductActive = false;
  switch (docType) {
    case DOC_TYPE.INVOICE:
      isAllProductActive = doc?.items?.every(
        (item: any) => item?.product?.active
      );
      break;
    default:
      break;
  }
  return isAllProductActive;
};

export const showComponenListSection = (items: any) => {
  const docDetails = items || [];
  if (Utility.isNotEmpty(docDetails)) {
    let lineItems: any[] = [];
    docDetails?.forEach((itemRow: any) => {
      if (
        Utility.isNotEmpty(
          itemRow?.bomComponentGroupDetails?.bomComponentGroups
        )
      ) {
        itemRow = {
          ...itemRow,
          bomComponentGroupDetails: {
            ...itemRow?.bomComponentGroupDetails,
            bomComponentGroups:
              itemRow?.bomComponentGroupDetails?.bomComponentGroups?.map(
                (item: any, index: number) => {
                  return {
                    ...item,
                    expanded: false,
                    finishedGoodName:
                      index === 0
                        ? itemRow?.bomComponentGroupDetails?.productName
                        : ''
                  };
                }
              )
          }
        };
        lineItems = [
          ...lineItems,
          ...itemRow.bomComponentGroupDetails.bomComponentGroups
        ];
      }
    });

    return Utility.isNotEmpty(lineItems);
  } else {
    return false;
  }
};

export const getQtyMultipliedComponentList = (
  componentGroups: any[],
  quantity: number
) => {
  componentGroups = componentGroups?.map((group) => {
    const copyBomComponentGroupItems =
      group?.bomComponentGroupItems?.map((item: any) => {
        item.quantityRequired *= quantity;
        item.salesPrice *= quantity;
        return item;
      }) ?? [];
    group.bomComponentGroupItems = copyBomComponentGroupItems;
    group.bomComponentGroupItemTotalCost *= quantity;
    return group;
  });
  return componentGroups;
};

export const getComponentDetailsForDocumentLineItems = async (
  lineItems: DocumentItem[],
  contact: any,
  documentType: DOC_TYPE
) => {
  if (!COMPONENT_LIST_SUPPORTED_DOCS.includes(documentType)) {
    return lineItems;
  }

  let lineItemsCopy: DocumentItem[] = [...lineItems];

  const contactCode = contact?.code ?? '';

  const payload = lineItems?.map((item: any) => {
    return {
      productQuantity: item?.productQuantity ?? 1,
      productCode: item?.product?.productId,
      contactCode: contactCode
    };
  });

  showLoader('Please wait! Fetching component group details...');
  const bomComponentGroupDetails =
    await ProductService.getComponentGroupInfoWithProductCode(payload);

  try {
    removeLoader();
    if (bomComponentGroupDetails && lineItems) {
      lineItemsCopy =
        lineItemsCopy?.map((lineItem: any) => {
          let groupDetailFoundInResponse: any = bomComponentGroupDetails?.find(
            (groupDetail: any) =>
              groupDetail?.productCode === lineItem?.product?.productId
          );

          let compGroups = [...groupDetailFoundInResponse?.bomComponentGroups];

          if (Utility.isOperationDetailsForFGOnInvoiceSOQuote()) {
            compGroups.push({
              productGroupId: COMPONENTLIST_GROUP_IDS.OPERATION_ID,
              productGroupName:
                Utility.isOperationDetailsForFGOnInvoiceSOQuote(true),
              bomOperationsConfiguration:
                groupDetailFoundInResponse?.bomOperationsConfiguration ?? []
            });
          }

          if (Utility.isAdditionalChargesDetailsForFGOnInvoiceSOQuote()) {
            compGroups.push({
              productGroupId: COMPONENTLIST_GROUP_IDS.ADDITIONAL_CHARGE_ID,
              productGroupName:
                Utility.isAdditionalChargesDetailsForFGOnInvoiceSOQuote(true),
              bomAddCostConfiguration:
                groupDetailFoundInResponse?.bomAddCostConfiguration ?? []
            });
          }

          groupDetailFoundInResponse = {
            ...groupDetailFoundInResponse,
            bomComponentGroups: compGroups
          };

          if (Utility.isNotEmpty(groupDetailFoundInResponse)) {
            lineItem = {
              ...lineItem,
              bomComponentGroupDetails: groupDetailFoundInResponse,
              unmodifiedBomComponentGroupDetails: groupDetailFoundInResponse
            };
          }

          return lineItem;
        }) ?? [];
    }
  } catch (error) {
    removeLoader();
  }
  return lineItemsCopy;
};

export const getContactObjWhenDocIsLoading = (doc: any) => {
  let contactObj = !Utility.isEmpty(doc.contactDto)
    ? doc.contactDto
    : doc.contact;
  if((doc?.documentMode === DOCUMENT_MODE.VIEW || doc?.documentMode === DOCUMENT_MODE.EDIT) && Utility.isNotEmpty(doc?.contact?.name)){
    contactObj = {...contactObj,name: doc?.contact?.name }
  }
  if (doc.contact?.hasOwnProperty('taxExempted')) {
    if (
      typeof doc?.contact?.taxExempted !== 'undefined' &&
      doc?.contact?.taxExempted !== null
    ) {
      contactObj = {
        ...contactObj,
        taxExempted: doc?.contact?.taxExempted
      };
    } else {
      contactObj = {
        ...contactObj,
        taxExempted: doc?.contactDto?.taxExempted
      };
    }
  } else {
    contactObj = {
      ...contactObj,
      taxExempted: doc?.contactDto?.taxExempted
    };
  }
  return contactObj;
};

export const removeUnwantedPayloadKeysForDocument = (payload: any) => {
  const updatedPayload = deepClone(payload);
  let keyToUpdate = '';

  if (updatedPayload.documentType === DOC_TYPE.QUOTE) {
    keyToUpdate = 'quotationItemDtoList';
  } else if (updatedPayload.documentType === DOC_TYPE.SALES_ORDER) {
    keyToUpdate = 'salesOrderItems';
  } else if (updatedPayload.documentType === DOC_TYPE.INVOICE) {
    keyToUpdate = 'salesInvoiceItems';
  }

  if (Utility.isNotEmpty(keyToUpdate)) {
    updatedPayload[keyToUpdate] = payload?.[keyToUpdate]?.map(
      (itemDto: any) => {
        let newItemDto = { ...itemDto };
        delete newItemDto?.unmodifiedBomComponentGroupDetails;

        if (newItemDto?.bomComponentGroupDetails) {
          newItemDto.bomComponentGroupDetails = {
            ...newItemDto.bomComponentGroupDetails,
            bomComponentGroups:
              itemDto.bomComponentGroupDetails?.bomComponentGroups?.filter(
                (componentGroup: any) =>
                  componentGroup?.productGroupId !==
                    COMPONENTLIST_GROUP_IDS.OPERATION_ID &&
                  componentGroup?.productGroupId !==
                    COMPONENTLIST_GROUP_IDS.ADDITIONAL_CHARGE_ID
              ),
            bomOperationsConfiguration:
              Utility.isOperationDetailsForFGOnInvoiceSOQuote()
                ? itemDto.bomComponentGroupDetails?.bomOperationsConfiguration
                : [],
            bomAddCostConfiguration:
              Utility.isAdditionalChargesDetailsForFGOnInvoiceSOQuote()
                ? itemDto.bomComponentGroupDetails?.bomAddCostConfiguration
                : []
          };
        }

        return newItemDto;
      }
    );
  }

  return updatedPayload;
};

export const addOperationsAndAdditionalCostToBomComponentGroups = (
  item: any
) => {
  let compGroups = [...item?.bomComponentGroupDetails?.bomComponentGroups];

  if (Utility.isOperationDetailsForFGOnInvoiceSOQuote()) {
    compGroups.push({
      productGroupId: COMPONENTLIST_GROUP_IDS.OPERATION_ID,
      productGroupName: Utility.isOperationDetailsForFGOnInvoiceSOQuote(true),
      bomOperationsConfiguration:
        item?.bomComponentGroupDetails?.bomOperationsConfiguration ?? []
    });
  }

  if (Utility.isAdditionalChargesDetailsForFGOnInvoiceSOQuote()) {
    compGroups.push({
      productGroupId: COMPONENTLIST_GROUP_IDS.ADDITIONAL_CHARGE_ID,
      productGroupName:
        Utility.isAdditionalChargesDetailsForFGOnInvoiceSOQuote(true),
      bomAddCostConfiguration:
        item?.bomComponentGroupDetails?.bomAddCostConfiguration ?? []
    });
  }

  return compGroups;
};

export const getAdvanceTrackingStatusFromLineItems = (lineItems: any[]) => {
  let updatedItems = lineItems
    ?.filter((item: any) => {
      return (
        item?.product?.advancedTracking === ADVANCE_TRACKING.BATCH ||
        item?.product?.advancedTracking === ADVANCE_TRACKING.SERIAL
      );
    })
    ?.map((item: any) => {
      return item?.product?.advancedTracking;
    });

  if (Utility.isEmpty(updatedItems)) {
    return 'Not Applicable';
  }

  if (
    updatedItems.includes(ADVANCE_TRACKING.BATCH) &&
    updatedItems.includes(ADVANCE_TRACKING.SERIAL)
  ) {
    return LINEITEMS_ADV_TRACK_STATUS.BOTH;
  } else if (updatedItems.includes(ADVANCE_TRACKING.BATCH)) {
    return LINEITEMS_ADV_TRACK_STATUS.BATCH_TRACKED;
  } else if (updatedItems.includes(ADVANCE_TRACKING.SERIAL)) {
    return LINEITEMS_ADV_TRACK_STATUS.SERIAL_TRACKED;
  } else {
    return '-';
  }
};

export const isStockReservedInDocLineItems = (lineItems: any[]) => {
  const isQuantityReserved = lineItems?.some((item) =>
    item.reservedQuantitiesData?.some((product: any) => {
      if (product?.advancedTrackingType === TRACKING_TYPE.NONE) {
        return product?.reservedQuantity > 0;
      } else {
        const hasReservedQty = product?.advancedTrackingMetaDtos?.some(
          (prod: any) =>
            (prod?.reservedQuantity || 0) -
              (prod?.reservedQuantityFulfilled || 0) !==
            0
        );
        return hasReservedQty;
      }
    })
  );

  return isQuantityReserved;
};

export const getPrimaryCurrencyCheckForDocType = (docType: any) => {
  return (
    docType === DOC_TYPE.INVOICE ||
    docType === DOC_TYPE.BILL ||
    docType === DOC_TYPE.EXPENSE_BILL ||
    docType === DOC_TYPE.ORDER ||
    docType === DOC_TYPE.SALES_ORDER ||
    docType === DOC_TYPE.QUOTE
  );
};

export const getPrimaryCurrencyCheck = () => {
  return (
    !Utility.isEmpty(
      Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
        .MULTI_COMPANY
    ) &&
    Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
      .MULTI_COMPANY.parentOrganization !== undefined &&
    Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
      .MULTI_COMPANY.parentOrganization !== null &&
    Store.getState().authInfo?.currentTenantInfo?.data?.additionalSettings
      .MULTI_COMPANY?.primaryCurrencyCode !==
      Store.getState().authInfo?.currentTenantInfo?.data?.currency
  );
};

export const checkIfLineLevelCustomFieldIsValid = (
  updatedLineItem: any,
  productCF: any,
  byPassValidations: boolean = false
) => {
  productCF = (productCF ?? []).map((cField: any) => {
    let mandatory = cField.mandatory;
    if (cField.label === LOCATION_CLASS_ENUM.CLASS && !byPassValidations) {
      mandatory = Utility.isClassMandatory() && Utility.isClassRowType();
    }
    return {
      ...cField,
      mandatory: mandatory
    };
  });

  productCF?.forEach((field: any) => {
    if (
      (updatedLineItem[field.id] === null ||
        updatedLineItem[field.id] === undefined ||
        updatedLineItem[field.id].toString().trim() === '') &&
      field.mandatory === true &&
      !updatedLineItem?.invalidFields?.includes(field.id)
    ) {
      updatedLineItem?.invalidFields?.push(field.id);
    } else if (
      updatedLineItem[field.id] !== null &&
      updatedLineItem[field.id] !== undefined &&
      updatedLineItem[field.id].toString().trim() !== ''
    ) {
      updatedLineItem.invalidFields = updatedLineItem?.invalidFields?.filter(
        (val: any) => val !== field.id
      );
    } else if (
      field.label === LOCATION_CLASS_ENUM.CLASS &&
      field.mandatory !== true
    ) {
      updatedLineItem.invalidFields = updatedLineItem?.invalidFields?.filter(
        (val: any) => val !== field.id
      );
    }
  });

  return updatedLineItem;
};

export const isSalesDocumentShortfallAlertsEnabled = () =>
  !!Store.getState()?.authInfo?.currentTenantInfo?.data?.additionalSettings
    ?.ALERT_ON_SHORTFALL_IN_SALES_MODULES;

export const updateBOMDetailsForProdQtyRenderer = (
  productWithBOMDetails: any[],
  lineItems: any
) => {
  const payload = productWithBOMDetails?.map((detail: any) => {
    const productCode = detail.productId as string;
    const bomMetaCode = detail.selectedBom.code;
    return {
      productCode,
      bomMetaCode,
      manufacturingLeadTimeFlag: true
    };
  });

  return ProductService.fetchAdditionalProductsBOMExplosionDetails(
    payload
  ).then((data: any) => {
    const bomExplosionData = !Utility.isEmpty(data) ? [...data] : [];
    let shortfallDetails: any = {
      ...DocumentConfigUtility.bomProductsTotalLeadTimeMap
    };
    if (bomExplosionData.length) {
      bomExplosionData.forEach((bomData: any) => {
        const manufacturingLeadTimeInMins = bomData?.manufacturingLeadTime || 0;
        const manufacturingLeadTimeInDays =
          manufacturingLeadTimeInMins / (24 * 60);
        const bomComponentsLeadTimes: number[] = bomData
          ?.bomProductConfiguration?.length
          ? bomData?.bomProductConfiguration?.map(
              (bomConfig: any) => bomConfig?.leadTime
            )
          : [];
        const bomComponentsMaxLeadTime = bomComponentsLeadTimes?.length
          ? Math.max(...bomComponentsLeadTimes)
          : 0;
        const totalLeadTimeInDays =
          bomComponentsMaxLeadTime + manufacturingLeadTimeInDays;

        let hasAnyShortFall = false;

        let reqQty =
          lineItems?.find(
            (item: any) => item?.product?.id === bomData?.productId
          )?.productQuantity ?? 1;
        hasAnyShortFall = bomData?.bomProductConfiguration
          ?.filter(
            (config: any) =>
              config?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
          )
          ?.some((config: any) => {
            const availableQty = Utility.isNotEmpty(
              config?.documentUOMSchemaDefinition
            )
              ? Number(config?.availableUomQuantity ?? 0)
              : Number(config?.availableQuantity ?? 0);
            const localReqQty = reqQty * config?.quantityRequired;
            const isShortfall = localReqQty - availableQty > 0;
            return isShortfall;
          });

        shortfallDetails = {
          ...shortfallDetails,
          [bomData.pid]: {
            availableQuantity: bomData?.availableQuantity || 0,
            totalLeadTime: totalLeadTimeInDays,
            hasShortfall: hasAnyShortFall
          }
        };
      });
      DocumentConfigUtility.bomProductsTotalLeadTimeMap = {
        ...shortfallDetails
      };
    }
  });
};

export const getDestinationOfSupplyAccordingToDOCType = (
  documentType: any,
  isDropship: boolean
) => {
  return (
    [
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.PURCHASE_INWARD_QUOTATION,
      DOC_TYPE.JOB_WORK_OUT_ORDER
    ].includes(documentType) || isDropship
  );
};

export const isClearedAllItemAllowed = (booksDocument: any) => {
   
  const invoicedQtyItem = booksDocument?.items?.filter(
    (row: any) => row?.invoicedQty > 0 || row?.fulfilledQuantityInvoiced > 0
  );
  return !(
    booksDocument?.documentType === DOC_TYPE.SALES_ORDER &&
    !Utility.isEmpty(invoicedQtyItem)
  );
};
