import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  selectShowMainDocsInFullScreen,
  setShowMainDocsInFullScreen
} from '../../../Redux/Slices/CommonDataSlice';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import { DKButton, DKDataGrid, DKLabel, INPUT_TYPE } from 'deskera-ui-library';
import { DOC_TYPE, JWO_DISPATCH_STATUS } from '../../../Constants/Constant';
import ic_expand from '../../../Assets/Icons/ic_expand.svg';
import ic_shrink from '../../../Assets/Icons/ic_shrink.svg';
import { useEffect, useState } from 'react';
import Utility, { deepClone } from '../../../Utility/Utility';
import { t } from 'i18next';

export default function DispatchJWOProduct(props: any) {
  const [jwoDetails, setJwoDetails] = useState(props.detail);
  const [gridData, setGridData] = useState<any>([]);
  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    setGridData(getGridData(jwoDetails));
  }, []);
  const rowEdit = (row: any) => {
    if (row.columnKey === 'productQuantity') {
      let originalIndex = jwoDetails.stockTransferItems.findIndex(
        (product: any) => product.productCode === row.rowData['productCode']
      );
      if (originalIndex !== -1) {
        let items = deepClone(jwoDetails.stockTransferItems);
        items[originalIndex].productQuantity = Number(
          row.rowData[row.columnKey]
        );
        const gridIndex = jwoDetails.stockTransferItems.findIndex(
          (product: any) =>
            product.productCode === row.rowData.productCode &&
            product.lineNumber === row.rowData.lineNumber
        );

        const fgQty = Number(items[gridIndex].pendingQuantity);
        const fgQtyAlreadyDispatched = Number(
          items[gridIndex].productQtyForWhichRmDispatched
        );
        const remainingFGRequired = fgQty - fgQtyAlreadyDispatched;
        if (
          remainingFGRequired < row.rowData[row.columnKey] &&
          !Utility.isJWOReceiveDispatchAdditionalQtyEnable()
        ) {
          row.rowData['invalidFields'] = [row.columnKey];
        } else {
          row.rowData['invalidFields'] = [];
        }

        setJwoDetails({
          ...jwoDetails,
          stockTransferItems: items
        });
      }
    }
  };
  const validate = () => {
    let isValid: any = true;
    const newGridDataItems: any = [];
    const allStockTransferItems: any = [];
    let items = deepClone(jwoDetails.stockTransferItems);
    gridData.forEach((row: any) => {
      if (row) {
        const originalIndex = jwoDetails.stockTransferItems.findIndex(
          (product: any) => product.productCode === row.productCode
        );
        if (originalIndex !== -1) {
          const gridIndex = jwoDetails.stockTransferItems.findIndex(
            (product: any) =>
              product.productCode === row.productCode &&
              product.lineNumber === row.lineNumber
          );
          const fgQty = Number(items[gridIndex].pendingQuantity);
          const fgQtyAlreadyDispatched = Number(
            items[gridIndex].productQtyForWhichRmDispatched
          );
          const remainingFGRequired = fgQty - fgQtyAlreadyDispatched;
          if (remainingFGRequired < Number(row.productQuantity)) {
            row['invalidFields'] = ['productQuantity'];
            isValid = false;
          } else {
            row['invalidFields'] = [];
          }
          if (
            newGridDataItems.find(
              (prod: any) =>
                prod.productCode === items[originalIndex].productCode
            )
          ) {
            items[originalIndex].productQuantity += Number(row.productQuantity);
            items[originalIndex].pendingQuantity += Number(row.pendingQuantity);
          } else {
            items[originalIndex].productQuantity = Number(row.productQuantity);
            newGridDataItems.push(items[originalIndex]);
          }

          let dataItem = { ...items[gridIndex] };
          dataItem.productQuantity = Number(row.productQuantity);
          dataItem.pendingQuantity = Number(row.pendingQuantity);
          allStockTransferItems.push(dataItem);
        }
      }
    });
    if (isValid) {
      isValid = {
        ...jwoDetails,
        stockTransferItems: newGridDataItems?.filter(
          (g: any) => g.productQuantity !== 0
        ),
        allStockTransferItems: allStockTransferItems // for maintain jwo FG qty
      };
      setJwoDetails(isValid);
    }
    setGridData([...gridData]);
    return isValid;
  };
  const getGridData = (grid: any) => {
    let newGridData: any = [];
    grid?.stockTransferItems?.forEach((rowData: any) => {
      newGridData.push({
        ...rowData,
        productName: rowData.product.name,
        documentSequenceCode: rowData.product.documentSequenceCode,
        productQuantity: calculateProductQuantity(grid, rowData)
      });
    });
    return newGridData;
  };
  const calculateProductQuantity = (grid: any, rowData: any) => {
    if (grid.dispatchStatus.includes(JWO_DISPATCH_STATUS.PENDING_DISPATCH)) {
      return rowData.productQuantity || 0;
    } else if (
      rowData.productQtyForWhichRmDispatched >= 0 &&
      rowData.productQtyForWhichRmDispatched <= rowData.pendingQuantity
    ) {
      return Utility.roundingOff(
        Number(
          Number(rowData.pendingQuantity) -
            Number(rowData.productQtyForWhichRmDispatched)
        )
      );
    } else {
      return 0;
    }
  };
  const getDataGridColumns = () => {
    let columns: any = [
      {
        name: 'Product Name',
        index: 0,
        options: null,
        required: false,
        editable: true,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'productName',
        id: 'productName',
        datasource: [],
        key: 'productName',
        lineItem: false,
        type: INPUT_TYPE.TEXT,
        width: 200
      },
      {
        id: 'documentSequenceCode',
        key: 'documentSequenceCode',
        name: 'Product Code',
        type: INPUT_TYPE.TEXT,
        width: 140,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'productQtyForWhichRmDispatched',
        key: 'productQtyForWhichRmDispatched',
        name: 'Dispatched Quantity',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        name: 'Product Quantity',
        index: 0,
        textAlign: 'right',
        options: null,
        required: false,
        editable: true,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'productQuantity',
        id: 'productQuantity',
        datasource: [],
        key: 'productQuantity',
        lineItem: false,
        type: INPUT_TYPE.NUMBER,
        width: 170
      }
    ];
    return columns;
  };
  const getShrinkExpandButton = () => {
    return (
      <DKButton
        icon={showMainDocsInFullscreen ? ic_shrink : ic_expand}
        onClick={() => {
          dispatch(setShowMainDocsInFullScreen(!showMainDocsInFullscreen));
        }}
        disabled={false}
        className="border-m mr-r"
      />
    );
  };
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle gap-2">
          <DKLabel
            text={
              `${jwoDetails?.documentSequenceCode} ` +
              (!props?.isDraft
                ? Utility.isEmpty(props.selectedRecord)
                  ? `Add ${
                      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
                        ? t('JOB_WORK_OUT.DISPATCH_GOODS')
                        : t('JOB_WORK_OUT.STOCK_TRANSFER')
                    }`
                  : `Edit ${
                      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
                        ? t('JOB_WORK_OUT.DISPATCH_GOODS')
                        : t('JOB_WORK_OUT.STOCK_TRANSFER')
                    }`
                : `View ${t('JOB_WORK_OUT.STOCK_TRANSFER')}`)
            }
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          {props.allowFullScreen && getShrinkExpandButton()}
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onCancel}
          />
          <DKButton
            className="bg-app text-white"
            title={t('JOB_WORK_OUT.CONTINUE')}
            onClick={() => {
              const jwoUpdated = validate();
              if (props.onSave && jwoUpdated) {
                props.onSave(jwoUpdated);
              }
            }}
          />
        </div>
      </div>
    );
  };
  const getLineItemGrid = () => {
    return (
      <>
        <DKDataGrid
          styles={{
            mainGridHolder: { maxHeight: 320, paddingBottom: 0 },
            shadowHolder: { maxHeight: 300 }
          }}
          needShadow={false}
          needBorder={true}
          needColumnIcons={false}
          needTrailingColumn={true}
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowRowEdit={!Utility.isEmpty(props.selectedRecord) ? false : true}
          columns={getDataGridColumns()}
          rows={gridData}
          onRowUpdate={rowEdit}
          currentPage={1}
          totalPageCount={1}
          title={''}
          allowColumnShift={false}
          allowBulkOperation={false}
        />
      </>
    );
  };
  const isFullScreenMode = !!props.allowFullScreen && showMainDocsInFullscreen;
  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: isFullScreenMode ? '100%' : 750,
            width: isFullScreenMode ? '100%' : '90%',
            maxHeight: isFullScreenMode ? '100%' : '95%',
            height: isFullScreenMode ? '100%' : '95%',
            padding: 0,
            paddingBottom: 30,
            borderRadius: isFullScreenMode ? 0 : 4
          }}
        >
          {getHeader()}
          <div
            className="column parent-width parent-height p-r"
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              scrollbarWidth: 'none'
            }}
          >
            <div
              id="popup-container"
              className="column parent-width parent-height"
              style={{
                pointerEvents: 'auto'
              }}
            >
              <div className="m-v-r parent-width">{getLineItemGrid()}</div>
            </div>
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
