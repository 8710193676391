import {
  DKButton,
  DKIcon,
  DKInput,
  DKLabel,
  DKLine,
  DKRadioButton,
  DKSpinner,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ic_bom_allocate_green from '../../../Assets/Icons/ic_bom_allocate_green.svg';
import ic_bom_allocate_red from '../../../Assets/Icons/ic_bom_allocate_red.svg';
import bom_allocate_grey from '../../../Assets/bom_allocate_grey.svg';
import bom_allocate_red from '../../../Assets/bom_allocate_red.svg';
import bom_built from '../../../Assets/bom_built.svg';
import {
  BOM_ALLOCATION_TYPE,
  BOOKS_DATE_FORMAT,
  BUY_TYPE,
  CUSTOM_NUMBER_INPUT_MODULES,
  DOCUMENT_MODE,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  LABELS,
  MODULES_NAME,
  MODULE_TYPE,
  PRODUCT_TYPE,
  ROUND_PRECISION,
  STOCK_URL,
  TAX_SYSTEM,
  TRACKING_TYPE,
  STOCK_URL_DEV,
  QTY_ROUNDOFF_PRECISION,
  STATUS_TYPE
} from '../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import { CustomField } from '../../../Models/CustomField';
import { DraftTypes } from '../../../Models/Drafts';
import { OrderInitialState } from '../../../Models/Order';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { fetchBatchTrackingProducts } from '../../../Redux/Slices/BatchTrackingSlice';
import {
  selectBatchSerialCustomFields,
  selectPurchaseTax
} from '../../../Redux/Slices/CommonDataSlice';
import {
  createBlankDraft,
  draftTableId,
  selectDraftsColumnConfig
} from '../../../Redux/Slices/DraftsSlice';
import { fetchProducts } from '../../../Redux/Slices/ProductsSlice';
import { fetchSerialTrackingProducts } from '../../../Redux/Slices/SerialTrackingSlice';
import {
  fetchWarehouseWithRRBCombination,
  selectedWarehouseWithRRBCombination
} from '../../../Redux/Slices/WarehouseSlice';
import DateFormatService from '../../../Services/DateFormat';
import ProductService from '../../../Services/Product';
import BatchTrackingAssignment from '../../../SharedComponents/AdvancedTrackingPopup/BatchTrackingAssignment';
import BatchTrackingReceive from '../../../SharedComponents/AdvancedTrackingPopup/BatchTrackingReceive';
import SerialTrackedAssignment from '../../../SharedComponents/AdvancedTrackingPopup/SerialTrackedAssignment';
import SerialTrackedReceive from '../../../SharedComponents/AdvancedTrackingPopup/SerialTrackedReceive';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import CustomNumberFormatInput from '../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import {
  createLineItem,
  customFieldsContainsErrors,
  getTenantTaxSystem
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Stepper from '../../../SharedComponents/Stepper';
import NoneTrackedAssignment from '../../../SharedComponents/WarehouseManagement/NoneTrackedAssignment';
import NoneTrackedReceive from '../../../SharedComponents/WarehouseManagement/NoneTrackedReceive';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  getCapitalized
} from '../../../Utility/Utility';
import { FOCUS_FIELD_KEY } from '../CreateProductView';
import NumberFormatService from '../../../Services/NumberFormat';
import WarehouseManagementHelper, {
  WAREHOUSE_TYPE
} from '../../../SharedComponents/WarehouseManagement/WarehouseManagementHelper';
import { fetchAdvancedTrackingData } from '../../../Redux/Slices/AdvancedTrackingDataSlice';
import ApiConstants from '../../../Constants/ApiConstants';

export default function BuildAssembly(props: any) {
  const { t, i18n } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [customFieldList, setCustomFieldList] = useState<any>([]);
  const [stepsData, setStepsData] = useState<any[]>([]);
  const [buildQuantity, setBuildQuantity] = useState<any>(0);
  const product: any = props.product;
  const [bomProductData, setBomProductData] = useState<any>('');
  const [autoNumberingFormat, setAutoNumberingFormat] = useState<any>({});
  const [warehouses, setWarehouses] = useState<any>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>({});
  const [selectedBOMWarehouse, setSelectedBOMWarehouse] = useState<any>('');
  const [isSaving, setIsSaving] = useState<any>(false);
  const [productWarehouseCodeData, setProductWarehouseCodeData] = useState<any>(
    {}
  );
  const [showBatchSerialPopup, setShowBatchSerialPopup] = useState<any>(false);
  const [defaultAllocationWarehouse, setDefaultAllocationWarehouse] =
    useState<any>('');
  const [trackingType, setTrackingType] = useState<any>(null);
  const [advancedTrackingList, setAdvancedTrackingList] = useState<any[]>([]);
  const [advancedTrackingCompleted, setAdvancedTrackingCompleted] =
    useState<any>(false);
  // const [firstStepClicked, setFirstStepClicked] = useState<any>(false);
  const [isBatchOrSerailProduct, setIsBatchOrSerialProduct] =
    useState<any>(false);
  const [memoDetails, setMemoDetails] = useState('');

  const [allocationType, setAllocationType] = useState(
    BOM_ALLOCATION_TYPE.SAME_WAREHOUSE
  );
  const [showWarehouseManagement, setShowWarehouseManagement] =
    useState<boolean>(false);
  const [showBOMLoader, setShowBOMLoader] = useState(false);
  const [warehouseProduct, setWarehouseProduct] = useState<any>({});
  const [componentList, setComponentList] = useState([]);
  const tenantInfo = useAppSelector(activeTenantInfo);

  const draftsTableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const purchaseTaxes = useAppSelector(selectPurchaseTax);

  let currDate: any = new Date();
  const [buildDate, setBuildDate] = useState(currDate);

  const dispatch = useAppDispatch();
  // const warehousesData: any = useAppSelector(selectWarehouse);
  let warehousesData: any = useAppSelector(selectedWarehouseWithRRBCombination);
  warehousesData = warehousesData?.content || [];
  const [isFocused, setIsFocused] = useState<FOCUS_FIELD_KEY | ''>();
  const [selectedFilters, setSelectedFilters] = useState<any>({
    warehouse: { code: '', name: '' },
    row: { code: '', name: '' },
    rack: { code: '', name: '' },
    bin: { code: '', name: '' }
  });
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);

  useEffect(() => {
    registerSteps();
    setDefaultActiveWarehouse();
    loadWarehousesWithRRB();
    //fetch advance tracking data for Batch/Serial
    if (product?.advancedTracking !== ADVANCE_TRACKING.NONE) {
      loadBatchTrackingData(product.productId);
    }
  }, []);

  useEffect(() => {
    setShowBOMLoader(true);
    if (props?.product?.id) {
      getBomDetails(props?.product?.id);
    }
    setTrackingType(props?.product?.advancedTracking);
  }, [props.product]);

  useEffect(() => {
    let activeWarehouses =
      warehousesData &&
      warehousesData.filter(
        (item: any) => item.active && item.warehouseType === WAREHOUSE_TYPE.NONE
      );
    // Replacing the Warehouses from Warehouse inventory data api response call to main Warehouse list because main list doesn't have RRB information
    if (!Utility.isEmpty(productWarehouseCodeData)) {
      activeWarehouses = activeWarehouses?.map((item: any) => {
        if (!Utility.isEmpty(productWarehouseCodeData[item.code])) {
          return {
            ...productWarehouseCodeData[item.code],
            warehouseType: item.warehouseType,
            active: true,
            warehouseBinInfos: item?.warehouseBinInfos,
            warehouseRackInfos: item?.warehouseRackInfos,
            warehouseRowInfos: item?.warehouseRowInfos
          };
        } else {
          return { ...item };
        }
      });
    }
    if (!Utility.isEmpty(activeWarehouses)) {
      setWarehouses(activeWarehouses);
    }
  }, [warehousesData, productWarehouseCodeData]);

  useEffect(() => {
    updateShortFallQty();
  }, [selectedFilters]);

  const loadBatchTrackingData = async (code: any) => {
    try {
      dispatch(fetchAdvancedTrackingData(code));
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const updateShortFallQty = () => {
    let bomData = deepClone(bomProductData);
    if (!Utility.isEmpty(productWarehouseCodeData)) {
      bomData.bomProductsConfiguration.forEach((element: any, index: any) => {
        let requiredQuantity =
          Number(element?.quantity) * Number(buildQuantity);
        const warehouseInventoryDetails =
          productWarehouseCodeData[selectedBOMWarehouse?.code];
        if (!Utility.isEmpty(warehouseInventoryDetails)) {
          let availableQuantity = 0;
          if (
            WarehouseManagementHelper.isRRBEnabledForWarehouse(
              warehouseInventoryDetails
            )
          ) {
            let selectedBomAvailableQty =
              warehouseInventoryDetails?.rowRackBinProductAvailableQuantityDtos?.find(
                (availeblQtyItem: any) => {
                  return (
                    availeblQtyItem?.productCode === element?.pid &&
                    (Utility.isEmpty(availeblQtyItem?.rowCode) ||
                      availeblQtyItem?.rowCode ===
                        selectedFilters?.row?.code) &&
                    (Utility.isEmpty(availeblQtyItem?.rackCode) ||
                      availeblQtyItem?.rackCode ===
                        selectedFilters?.rack?.code) &&
                    (Utility.isEmpty(availeblQtyItem?.binCode) ||
                      availeblQtyItem?.binCode === selectedFilters?.bin?.code)
                  );
                }
              );
            availableQuantity = selectedBomAvailableQty
              ? selectedBomAvailableQty?.availableQuantity -
                selectedBomAvailableQty?.reservedQuantity
              : 0;
            if (element?.documentUOMSchemaDefinition) {
              availableQuantity = Utility.getUomQuantityWithoutRoundOff(
                availableQuantity,
                element?.documentUOMSchemaDefinition
              );
            }
          } else {
            if (
              !Utility.isEmpty(
                warehouseInventoryDetails.productAvailableQuantity
              )
            ) {
              availableQuantity =
                (warehouseInventoryDetails.productAvailableQuantity[
                  element.pid
                ] ?? 0) -
                (warehouseInventoryDetails.productReservedQuantity[
                  element.pid
                ] ?? 0);
              if (element?.documentUOMSchemaDefinition) {
                availableQuantity = Utility.getUomQuantityWithoutRoundOff(
                  availableQuantity,
                  element?.documentUOMSchemaDefinition
                );
              }
            }
          }
          if (requiredQuantity > availableQuantity) {
            bomData.bomProductsConfiguration[index].shortFallQuantity =
              availableQuantity - requiredQuantity;
          } else {
            bomData.bomProductsConfiguration[index].shortFallQuantity = 0;
          }
        } else {
          bomData.bomProductsConfiguration[index].shortFallQuantity =
            -requiredQuantity;
        }
      });
      setBomProductData(bomData);
    }
  };

  const getBomDetails = (productId: number) => {
    ProductService.getBomDetailsWithQty(productId, 1)
      .then((response: any) => {
        let bomDetailsResponse = { ...response };
        if (bomDetailsResponse.bomProductsConfiguration.length > 0) {
          let makeBomAssemblyDetails: any = [];
          let componentList = bomDetailsResponse.bomProductsConfiguration.map(
            (detail: any) => {
              return detail.pid;
            }
          );
          setComponentList(componentList);

          bomDetailsResponse.bomProductsConfiguration.forEach(
            (product: any) => {
              const qtyToConsider = Utility.isNotEmpty(
                product?.documentUOMSchemaDefinition
              )
                ? product?.uomQuantity
                : product.quantity;

              const availableQtyToConsider = Utility.isNotEmpty(
                product?.documentUOMSchemaDefinition
              )
                ? product?.availableUomQuantity
                : product.availableQuantity;

              // If any product have enabled advance tracking then for same ware house option will be disabled
              if (
                product.advancedTracking === TRACKING_TYPE.BATCH ||
                product.advancedTracking === TRACKING_TYPE.SERIAL
              ) {
                setAllocationType(BOM_ALLOCATION_TYPE.INDIVIDUAL_WAREHOUSE);
                setIsBatchOrSerialProduct(true);
              }

              // modification of response object
              let updateObject: any = {
                itemId: product.itemId,
                pid: product.pid,
                productCode: product.productCode,
                quantity: qtyToConsider,
                itemName: product.itemName,
                uomName: product.uomName,
                uomId: product.uomId,
                costPerUnit: product.costPerUnit,
                advancedTracking: product.advancedTracking,
                availableQuantity: availableQtyToConsider,
                purchasePrice: product.purchasePrice,
                documentUOMSchemaDefinition:
                  product?.documentUOMSchemaDefinition,
                warehouseInventoryData: [
                  {
                    quantity: Utility.roundingOff(
                      Number(buildQuantity) * Number(qtyToConsider),
                      ROUND_PRECISION
                    ),
                    warehouseCode: selectedBOMWarehouse.code
                  }
                ],
                isQuickCommit: false,
                buildQuantityReq: Utility.roundingOff(
                  Number(buildQuantity) * Number(qtyToConsider),
                  ROUND_PRECISION
                ),
                quantityRequired: Utility.roundingOff(
                  Number(buildQuantity) * Number(qtyToConsider),
                  ROUND_PRECISION
                ),
                actualQuantityUsed: Utility.roundingOff(
                  Number(buildQuantity) * Number(qtyToConsider),
                  ROUND_PRECISION
                ),
                productName: product.itemName,
                shortFallQuantity: 0,
                productId: product.itemId,
                totalQuantity: availableQtyToConsider,
                type: product.type
              };

              makeBomAssemblyDetails.push(updateObject);
            }
          );

          bomDetailsResponse.bomProductsConfiguration = [
            ...makeBomAssemblyDetails
          ];

          setBomProductData(deepClone(bomDetailsResponse));
          setShowBOMLoader(false);
        }
      })
      .catch((error: any) => {
        console.error('Error fetching BOM product details ', error);
      });
  };

  useEffect(() => {
    if (componentList && componentList.length > 0) {
      ProductService.fetchWarehouseProductsByID(componentList)
        .then((res: any) => {
          let codeToWarehouseMapping: any = {};
          if (!Utility.isEmpty(res?.warehouses)) {
            res?.warehouses?.forEach((element: any) => {
              codeToWarehouseMapping[element?.code] = element;
            });
          }
          setProductWarehouseCodeData(codeToWarehouseMapping);
        })
        .catch((error) => {
          console.error('Error fetching Product Warehouse Codes ', error);
        });
    }
  }, [componentList]);

  const loadWarehousesWithRRB = async () => {
    try {
      await dispatch(fetchWarehouseWithRRBCombination({}));
      // await dispatch(fetchProductWarehouseCodes());
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  };

  const registerSteps = () => {
    setStepsData([
      {
        title: 'Plan',
        requiredProps: [],
        reqError: '',
        onClick: (data: any) => {},
        getChild: (data: any) => null
      },
      {
        title: 'Allocate',
        requiredProps: [],
        reqError: '',
        getChild: (data: any) => <div>step 2 block</div>,
        onClick: (data: any) => {}
      },
      {
        title: 'FG Details',
        requiredProps: [],
        reqError: '',
        getChild: (data: any) => <div>step 3 block</div>,
        onClick: (data: any) => {}
      }
    ]);
  };

  const saveAssembly = () => {
    //validation
    if (!advancedTrackingCompleted) {
      showAlert('Error', 'Please assign warehouse details');
      return;
    }
    setIsSaving(true);
    ProductService.createBuildAssembly(getPayload())
      .then((res) => {
        setIsSaving(false);
        dispatch(fetchProducts());
        dispatch(fetchWarehouseWithRRBCombination({}));
        props.onSave();
      })
      .catch((error) => {
        showAlert('Error', 'Failed to create build assembly');
        setIsSaving(false);
      });
  };

  const getAvailableQtyAfterBuilt = () => {
    return buildQuantity + bomProductData.totalAvailableQuantity;
  };
  const getTotalCost = () => {
    return Utility.roundOffToTenantDecimalScale(
      buildQuantity * bomProductData.costPerUnit
    );
  };

  const customFieldUpdated = (list: CustomField[]) => {
    let updatedState = customFieldList;

    updatedState = list;
    setCustomFieldList([...updatedState]);
  };

  const allotmentIcon = (data: any) => {
    let icon = bom_allocate_grey;
    if (
      allocationType === BOM_ALLOCATION_TYPE.INDIVIDUAL_WAREHOUSE &&
      data.wareHouseAlloted
    ) {
      icon = bom_built;
    } else if (
      allocationType === BOM_ALLOCATION_TYPE.INDIVIDUAL_WAREHOUSE &&
      Utility.isEmpty(data.wareHouseAlloted) &&
      data?.type !== PRODUCT_TYPE.NON_TRACKED
    ) {
      icon = bom_allocate_red;
    }

    return icon;
  };

  const loadSerialTrackingProducts = async (code: any) => {
    try {
      dispatch(
        fetchSerialTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const loadBatchTrackingProducts = async (code: any) => {
    try {
      let isBatchSerialsCustomFieldsAvailable =
        batchSerialCFfromStore?.content?.filter(
          (ele: any) => ele.status === STATUS_TYPE.ACTIVE
        );
      dispatch(
        Utility.isEmpty(isBatchSerialsCustomFieldsAvailable)
          ? fetchBatchTrackingProducts({
              productCode: code,
              enableQCWarehouse: false
            })
          : fetchBatchTrackingProducts({
              productCode: code,
              enableQCWarehouse: false,
              checkBatchSerialCF: true
            })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const allotmentAction = (data: any, icon: any) => {
    if (icon === bom_allocate_red) {
      setWarehouseProduct(data);
      setShowWarehouseManagement(true);
      switch (data?.advancedTracking) {
        case ADVANCE_TRACKING.SERIAL:
          loadSerialTrackingProducts(data.pid);
          break;
        case ADVANCE_TRACKING.BATCH:
          loadBatchTrackingProducts(data.pid);
          break;
        case ADVANCE_TRACKING.NONE:
          break;
      }
    }
  };

  const assignDefaultValuesForNormal = (
    warehouseCode: string,
    quantity: any
  ) => {
    let productDefaultWarehouse = warehousesData?.find(
      (item: any) => warehouseCode === item.code
    );
    let data = [
      {
        warehouseCode: product?.inventory?.warehouseCode,
        warehouseName: productDefaultWarehouse?.name,
        warehouse: productDefaultWarehouse,
        rowName: product?.inventory?.rowName,
        rowCode: product?.inventory?.rowCode,
        row: {
          name: product?.inventory?.rowName,
          code: product?.inventory?.rowCode
        },
        rackName: product?.inventory?.rackName,
        rackCode: product?.inventory?.rackCode,
        rack: {
          name: product?.inventory?.rackName,
          code: product?.inventory?.rackCode
        },
        binCode: product?.inventory?.binCode,
        binName: product?.inventory?.binName,
        bin: {
          name: product?.inventory?.binName,
          code: product?.inventory?.binCode
        },
        quantity: Number(quantity)
      }
    ];
    setAdvancedTrackingList(data);
    setAdvancedTrackingCompleted(true);
  };

  const updateBuildQuantity = (data: any) => {
    ProductService.getBomDetailsWithQty(props?.product?.id, Number(data))
      .then((response: any) => {
        let bomDetailsResponse = { ...response };
        if (bomDetailsResponse.bomProductsConfiguration.length > 0) {
          let makeBomAssemblyDetails: any = [];
          let componentList = bomDetailsResponse.bomProductsConfiguration.map(
            (detail: any) => {
              return detail.pid;
            }
          );
          setComponentList(componentList);

          bomDetailsResponse.bomProductsConfiguration.forEach(
            (product: any) => {
              const qtyToConsider = Utility.isNotEmpty(
                product?.documentUOMSchemaDefinition
              )
                ? product?.uomQuantity
                : product.quantity;

              const availableQtyToConsider = Utility.isNotEmpty(
                product?.documentUOMSchemaDefinition
              )
                ? product?.availableUomQuantity
                : product.availableQuantity;

              // If any product have enabled advance tracking then for same ware house option will be disabled
              if (
                product.advancedTracking === TRACKING_TYPE.BATCH ||
                product.advancedTracking === TRACKING_TYPE.SERIAL
              ) {
                setAllocationType(BOM_ALLOCATION_TYPE.INDIVIDUAL_WAREHOUSE);
                setIsBatchOrSerialProduct(true);
              }

              // modification of response object
              let updateObject: any = {
                itemId: product.itemId,
                pid: product.pid,
                productCode: product.productCode,
                quantity: qtyToConsider,
                itemName: product.itemName,
                uomName: product.uomName,
                uomId: product.uomId,
                costPerUnit: product.costPerUnit,
                advancedTracking: product.advancedTracking,
                documentUOMSchemaDefinition:
                  product?.documentUOMSchemaDefinition,
                availableQuantity: availableQtyToConsider,
                purchasePrice: product.purchasePrice,
                warehouseInventoryData: [
                  {
                    quantity: Utility.roundingOff(
                      Number(data) * Number(qtyToConsider),
                      ROUND_PRECISION
                    ),
                    warehouseCode: selectedBOMWarehouse.code
                  }
                ],
                isQuickCommit: false,
                buildQuantityReq: Utility.roundingOff(
                  Number(data) * Number(qtyToConsider),
                  ROUND_PRECISION
                ),
                quantityRequired: Utility.roundingOff(
                  Number(data) * Number(qtyToConsider),
                  ROUND_PRECISION
                ),
                actualQuantityUsed: Utility.roundingOff(
                  Number(data) * Number(qtyToConsider),
                  ROUND_PRECISION
                ),
                productName: product.itemName,
                shortFallQuantity: 0,
                productId: product.itemId,
                totalQuantity: availableQtyToConsider,
                type: product.type
              };

              makeBomAssemblyDetails.push(updateObject);
            }
          );

          bomDetailsResponse.bomProductsConfiguration = [
            ...makeBomAssemblyDetails
          ];

          setBomProductData(deepClone(bomDetailsResponse));
          setShowBOMLoader(false);
          let tmp = deepClone(bomDetailsResponse);
          if (tmp && tmp.bomProductsConfiguration) {
            tmp.bomProductsConfiguration.forEach(
              (element: any, index: number) => {
                tmp.bomProductsConfiguration[index].wareHouseAlloted = null;
              }
            );
            setBomProductData(tmp);
          }
        }
      })
      .catch((error: any) => {
        console.error('Error fetching BOM product details ', error);
      });

    setBuildQuantity(Number(data));
    if (
      product.advancedTracking === ADVANCE_TRACKING.NONE &&
      Utility.isEmpty(advancedTrackingList)
    ) {
      assignDefaultValuesForNormal(product?.inventory?.warehouseCode, data);
    } else {
      setAdvancedTrackingCompleted(false);
    }
  };

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      let closeDateFY: Date = Utility.getCloseDateFY();
      const tenantCloseDateFY = tenantInfo.fyClosingPeriodEndDate;
      if (tenantCloseDateFY && closeDateFY.getTime() > newDate.getTime()) {
        showAlert(
          'Invalid Date',
          `Build date should not before financial year close date`
        );
        return;
      }
      callback(newDate);
    } else {
      callback(new Date());
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };

  const firstForm = () => {
    return (
      <div className="parent-width">
        <div className="flex parent-width mt-4">
          <div className="column w-1/2 mr-4">
            <div className="row my-3">
              <div className="column w-1/2">
                <DKLabel text="Finished Good Name" className="fw-m fs-r" />
              </div>
              <div className="column w-1/2 text-align-left">
                {bomProductData.name}
              </div>
            </div>
            <div className="row my-3">
              <div className="column w-1/2">
                <DKLabel text="Finished Good Code" className="fw-m fs-r" />
              </div>
              <div className="column w-1/2">{bomProductData.productCode}</div>
            </div>
            <div className="row my-3">
              <div className="column w-1/2">
                <DKLabel text="Quantity you can build" className="fw-m fs-r" />
              </div>
              <div className="column w-1/2">
                {bomProductData.allowQuantityToBuild}
              </div>
            </div>
            <div className="row my-3">
              <div className="column w-1/2">
                <DKLabel
                  text="Total Available Quantity"
                  className="fw-m fs-r"
                />
              </div>
              <div className="column w-1/2">
                {bomProductData.totalAvailableQuantity}
              </div>
            </div>
            <div className="row my-3">
              <div className="column w-1/2">
                <DKLabel text="Cost Per Unit" className="fw-m fs-r" />
              </div>
              <div className="column w-1/2">{bomProductData.costPerUnit}</div>
            </div>
          </div>
          <div className="column w-1/2 ml-4">
            <div className="row mb-1.5">
              <div className="column parent-width">
                <DKInput
                  title="Build Date"
                  required={false}
                  className="fw-m fs-r"
                  value={buildDate}
                  type={INPUT_TYPE.DATE}
                  onChange={(value: any) => {
                    validateAndUpdateDate(
                      value,
                      DateFormatService.getDateFromStr(
                        tenantInfo.bookBeginningStartDate,
                        BOOKS_DATE_FORMAT['YYYY-MM-DD']
                      ),
                      setBuildDate,
                      `Build cannot be before books beginning date.`
                    );
                  }}
                  dateFormat={convertBooksDateFormatToUILibraryFormat(
                    tenantInfo.dateFormat
                  )}
                />
              </div>
            </div>
            <div className="row m-v-l">
              <div className="column parent-width">
                <DKInput
                  value={
                    isFocused === FOCUS_FIELD_KEY.QUANTITY_TO_BUILD
                      ? buildQuantity
                      : NumberFormatService.getNumber(parseFloat(buildQuantity))
                  }
                  className="fw-m fs-r"
                  title="Quantity to Build"
                  onChange={(text: any) => {}}
                  onFocus={() =>
                    setIsFocused(FOCUS_FIELD_KEY.QUANTITY_TO_BUILD)
                  }
                  onBlur={(e: any) => {
                    setIsFocused('');
                    updateBuildQuantity(
                      Utility.roundingOff(
                        e.target.value,
                        tenantInfo.decimalScale
                      )
                    );
                  }}
                  type={INPUT_TYPE.NUMBER}
                  canValidate={true}
                  errorMessage={'Please enter quantity to build'}
                />
              </div>
            </div>
            <div className="row my-1.5">
              <div className="column parent-width">
                <DKInput
                  className="fw-m fs-r"
                  title="Memo (optional)"
                  type={INPUT_TYPE.LONG_TEXT}
                  value={memoDetails}
                  required={false}
                  onChange={(value: any) => {
                    setMemoDetails(value);
                  }}
                  readOnly={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row parent-width my-3">
          <CustomFieldsHolder
            moduleName={MODULES_NAME.BOM_ASSEMBLY}
            customFieldsList={customFieldList}
            columnConfig={[]}
            columnConfigTableId={''}
            documentMode={DOCUMENT_MODE.NEW}
            onUpdate={(list) => customFieldUpdated(list)}
          />
        </div>
        <div className="mt-2 parent-width">
          <table
            className="table text-left table-auto"
            style={{ width: '100%' }}
          >
            <thead className="my-3">
              <td className="border-b-2 bg-gray1 rounded-tl-md"></td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray">
                Component Name
              </td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray">
                Quantity
              </td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray rounded-tr-md">
                UOM
              </td>
            </thead>
            <tbody>
              {!Utility.isEmpty(
                bomProductData && bomProductData.bomProductsConfiguration
              ) &&
                bomProductData?.bomProductsConfiguration.map(
                  (data: any, index: number) => {
                    return (
                      <tr>
                        <td className="py-2 pl-3">{index + 1}.</td>
                        <td className="py-2">{data.itemName}</td>
                        <td className="py-2">{data.quantity}</td>
                        <td className="py-2">{data.uomName}</td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const secondForm = () => {
    let rows = WarehouseManagementHelper.getUniqueRows(
      selectedBOMWarehouse?.rowRackBinProductAvailableQuantityDtos,
      selectedFilters?.rack?.code,
      selectedFilters?.bin?.code
    );
    let racks = WarehouseManagementHelper.getUniqueRacks(
      selectedBOMWarehouse?.rowRackBinProductAvailableQuantityDtos,
      selectedFilters?.row?.code,
      selectedFilters?.bin?.code
    );
    let bins = WarehouseManagementHelper.getUniqueBins(
      selectedBOMWarehouse?.rowRackBinProductAvailableQuantityDtos,
      selectedFilters?.row?.code,
      selectedFilters?.rack?.code
    );
    return (
      <div className="parent-width">
        <div className="flex flex-row  parent-width">
          <div className="column">
            {!isBatchOrSerailProduct && (
              <div className="column parent-width">
                <div className="column mr-5">
                  <DKRadioButton
                    title={`Pick all components form the same warehouse`}
                    isSelected={
                      allocationType === BOM_ALLOCATION_TYPE.SAME_WAREHOUSE
                    }
                    onClick={() => {
                      setAllocationType(BOM_ALLOCATION_TYPE.SAME_WAREHOUSE);
                    }}
                    className="mr-s"
                    color={'bg-app'}
                  />
                </div>
                {allocationType === BOM_ALLOCATION_TYPE.SAME_WAREHOUSE && (
                  <div className="row gap-1 mt-2 ml-6">
                    <div className="column" style={{ width: 185 }}>
                      <DKInput
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        className={''}
                        value={selectedBOMWarehouse}
                        formatter={(obj: any) => {
                          return obj.name;
                        }}
                        title={'Warehouse'}
                        icon={''}
                        type={INPUT_TYPE.DROPDOWN}
                        required={false}
                        canValidate={false}
                        onChange={(value: any) => {
                          setSelectedBOMWarehouse(value);
                          let copySelectedFilters = {
                            row: {},
                            rack: {},
                            bin: {},
                            warehouse: {
                              code: value.code,
                              name: value.name
                            }
                          };
                          setSelectedFilters(copySelectedFilters);
                        }}
                        dropdownConfig={{
                          className: '',
                          style: {},
                          allowSearch: true,
                          searchableKey: 'name',
                          canEdit: false,
                          canDelete: false,
                          searchApiConfig: {
                            getUrl: (searchValue: string) => {
                              const query: string = `?limit=50&page=0&filterOnRolesFlg=false&search=${searchValue}&allRRBDetails=true&query=active=true`;
                              const finalEndPoint =
                                ApiConstants.URL.BASE +
                                ApiConstants.URL.ACCOUNTS.WAREHOUSES +
                                query;
                              return finalEndPoint;
                            },
                            dataParser: (response: any) => {
                              var whData = (response?.content || []).filter(
                                (warehouse: any) =>
                                  warehouse &&
                                  warehouse.active &&
                                  warehouse.warehouseType ===
                                    WAREHOUSE_TYPE.NONE
                              );
                              if (whData.length) {
                                setWarehouses(whData);
                              }
                              return whData;
                            },
                            debounceTime: 300
                          },
                          data: getActiveWarehouses(),
                          renderer: (index: any, obj: any) => {
                            return <DKLabel text={`${obj.name}`} />;
                          },
                          onSelect: (index: any, value: any) => {}
                        }}
                      />
                    </div>
                    {!Utility.isEmpty(rows) && (
                      <div className="column" style={{ width: 185 }}>
                        <DKInput
                          direction={INPUT_VIEW_DIRECTION.VERTICAL}
                          className={''}
                          value={selectedFilters?.row?.name}
                          formatter={(obj: any) => {
                            return obj;
                          }}
                          title={'Row'}
                          icon={''}
                          type={INPUT_TYPE.DROPDOWN}
                          required={false}
                          canValidate={false}
                          onChange={(value: any) => {
                            let copySelectedFilters = {
                              ...selectedFilters,
                              row: {
                                code: value.rowCode,
                                name: value.rowName
                              }
                            };
                            setSelectedFilters(copySelectedFilters);
                          }}
                          dropdownConfig={{
                            className: '',
                            style: {},
                            allowSearch: false,
                            searchableKey: 'name',
                            canEdit: false,
                            canDelete: false,
                            data: rows ?? [],
                            renderer: (index: any, obj: any) => {
                              return <DKLabel text={`${obj.rowName}`} />;
                            },
                            onSelect: (index: any, value: any) => {}
                          }}
                        />
                      </div>
                    )}
                    {!Utility.isEmpty(racks) && (
                      <div className="column" style={{ width: 185 }}>
                        <DKInput
                          direction={INPUT_VIEW_DIRECTION.VERTICAL}
                          className={''}
                          value={selectedFilters?.rack?.name}
                          formatter={(obj: any) => {
                            return obj;
                          }}
                          title={'Rack'}
                          icon={''}
                          type={INPUT_TYPE.DROPDOWN}
                          required={false}
                          canValidate={false}
                          onChange={(value: any) => {
                            let copySelectedFilters = {
                              ...selectedFilters,
                              rack: {
                                code: value.rackCode,
                                name: value.rackName
                              }
                            };
                            setSelectedFilters(copySelectedFilters);
                          }}
                          dropdownConfig={{
                            className: '',
                            style: {},
                            allowSearch: false,
                            searchableKey: 'name',
                            canEdit: false,
                            canDelete: false,
                            data: racks ?? [],
                            renderer: (index: any, obj: any) => {
                              return <DKLabel text={`${obj.rackName}`} />;
                            },
                            onSelect: (index: any, value: any) => {}
                          }}
                        />
                      </div>
                    )}
                    {!Utility.isEmpty(bins) && (
                      <div className="column" style={{ width: 185 }}>
                        <DKInput
                          direction={INPUT_VIEW_DIRECTION.VERTICAL}
                          className={''}
                          value={selectedFilters?.bin?.name}
                          formatter={(obj: any) => {
                            return obj;
                          }}
                          title={'Bin'}
                          icon={''}
                          type={INPUT_TYPE.DROPDOWN}
                          required={false}
                          canValidate={false}
                          onChange={(value: any) => {
                            let copySelectedFilters = {
                              ...selectedFilters,
                              bin: {
                                code: value.binCode,
                                name: value.binName
                              }
                            };
                            setSelectedFilters(copySelectedFilters);
                          }}
                          dropdownConfig={{
                            className: '',
                            style: {},
                            allowSearch: false,
                            searchableKey: 'name',
                            canEdit: false,
                            canDelete: false,
                            data: bins ?? [],
                            renderer: (index: any, obj: any) => {
                              return <DKLabel text={`${obj.binName}`} />;
                            },
                            onSelect: (index: any, value: any) => {}
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="row">
              <DKRadioButton
                title={`Allocate the component individually`}
                isSelected={
                  allocationType === BOM_ALLOCATION_TYPE.INDIVIDUAL_WAREHOUSE
                }
                onClick={() => {
                  setAllocationType(BOM_ALLOCATION_TYPE.INDIVIDUAL_WAREHOUSE);
                }}
                className="mr-s mt-3"
                color={'bg-app'}
              />
            </div>
          </div>
        </div>
        <div className="flex mt-5">
          <table
            className="table text-left table-auto"
            style={{ width: '100%' }}
          >
            <thead className="my-3">
              <td className="border-b-2 bg-gray1 rounded-tl-md"></td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray">
                Product Name
              </td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray">
                Product Code
              </td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray text-center">
                Qty Required
              </td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray text-center">
                Total Qty
              </td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray text-center">
                Shortfall Qty
              </td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray">UOM</td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray text-center">
                Cost Per Unit
              </td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray text-center">
                Actual Qty Used
              </td>
              <td className="fw-m py-2 border-b-2 bg-gray1 text-gray rounded-tr-md"></td>
            </thead>
            <tbody>
              {!Utility.isEmpty(
                bomProductData && bomProductData.bomProductsConfiguration
              ) &&
                bomProductData?.bomProductsConfiguration.map(
                  (data: any, index: number) => {
                    const isNonTrackedProduct =
                      data?.type === PRODUCT_TYPE.NON_TRACKED;
                    return (
                      <tr>
                        <td className="py-2 pl-3">{index + 1}.</td>
                        <td className="py-2">{data.itemName}</td>
                        <td className="py-2">{data.productCode}</td>
                        <td className="py-2 text-center">
                          {Utility.roundingOff(
                            Number(data.quantity) * Number(buildQuantity),
                            ROUND_PRECISION
                          )}
                        </td>
                        <td className="py-2 text-center">
                          {data.availableQuantity}
                        </td>
                        <td
                          className="py-2 text-center"
                          style={{
                            color: `${
                              data.shortFallQuantity < 0 && !isNonTrackedProduct
                                ? 'red'
                                : 'black'
                            }`
                          }}
                        >
                          {!isNonTrackedProduct
                            ? Math.abs(data.shortFallQuantity)
                            : 0}
                        </td>
                        <td className="py-2">{data.uomName}</td>
                        <td className="py-2 text-center">{data.costPerUnit}</td>
                        <td className="py-2 text-center">
                          {Utility.roundingOff(
                            Number(data.quantity) * Number(buildQuantity),
                            ROUND_PRECISION
                          )}
                        </td>
                        <td className="py-2">
                          <DKIcon
                            disabled={
                              allocationType ===
                                BOM_ALLOCATION_TYPE.INDIVIDUAL_WAREHOUSE ||
                              data?.type === PRODUCT_TYPE.NON_TRACKED
                            }
                            src={allotmentIcon(data)}
                            onClick={() => {
                              allotmentAction(data, allotmentIcon(data));
                            }}
                            className="ic-r cursor-hand ml-r"
                          />
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const selectedFormat = (selected: any) => {
    /**
     * RECEIVE Selected format {id: "", text: ""}
     */
    setAutoNumberingFormat({ ...selected });
  };

  const setDefaultActiveWarehouse = () => {
    let activeWarehouses =
      warehousesData &&
      warehousesData.filter((item: any) => item.active && !item.rejected);
    if (activeWarehouses && activeWarehouses.length > 0) {
      let selectedWarehouse = activeWarehouses.filter(
        (warehouse: any) => warehouse.code === product?.inventory?.warehouseCode
      );
      if (selectedWarehouse[0] !== undefined && selectedWarehouse[0] !== null) {
        setDefaultAllocationWarehouse(selectedWarehouse[0].code);
        setSelectedWarehouse(selectedWarehouse[0]);
      }
    }
  };

  const getSequenceFormatInput = () => {
    return (
      <div className="parent-width position-relative">
        <div className="">
          <CustomNumberFormatInput
            module={CUSTOM_NUMBER_INPUT_MODULES.BUILD_ASSEMBLY}
            selectedFormat={selectedFormat}
            extraClass={'top-10 right-1'}
            isRow={true}
            autoNumberingFormat={autoNumberingFormat}
            hideLabel={true}
          />
        </div>
      </div>
    );
  };

  const getActiveWarehouses = () => {
    let activeWarehouses =
      warehouses &&
      warehouses.filter((item: any) => {
        return item.active && item.warehouseType === WAREHOUSE_TYPE.NONE;
      });
    if (!Utility.isEmpty(activeWarehouses)) {
      return activeWarehouses;
      //   return activeWarehouses.map((item: any) => item.name);
    } else {
      return [];
    }
  };

  const showBatchSerialPopups = () => {
    if (product.advancedTracking === TRACKING_TYPE.BATCH) {
      loadBatchTrackingProducts(product.productId);
    }
    setShowBatchSerialPopup(true);
  };

  // if user reselects the default warehouse we'll auto populate
  // else if selected warehouse is non RRB, auto populate.
  // else reset
  const onWarehouseChange = (value: any) => {
    if (
      product.advancedTracking === ADVANCE_TRACKING.NONE &&
      value.code === product?.inventory?.warehouseCode
    ) {
      assignDefaultValuesForNormal(value.code, buildQuantity);
    } else {
      let data: any = [];
      let advanceTrackingDone = false;
      if (
        product.advancedTracking === ADVANCE_TRACKING.NONE &&
        !WarehouseManagementHelper.isRRBEnabledForWarehouse(value)
      ) {
        data.push({
          warehouseCode: value.code,
          warehouseName: value.name,
          warehouse: value,
          rowName: null,
          rowCode: null,
          row: {
            name: null,
            code: null
          },
          rackName: null,
          rackCode: null,
          rack: {
            name: null,
            code: null
          },
          binCode: null,
          binName: null,
          bin: {
            name: null,
            code: null
          },
          quantity: Number(buildQuantity)
        });
        advanceTrackingDone = true;
      }
      setAdvancedTrackingList(data);
      setAdvancedTrackingCompleted(advanceTrackingDone);
    }
    setSelectedWarehouse(value);
  };

  const thirdForm = () => {
    return (
      <div className="parent-width">
        <div className="flex parent-width mt-4">
          <div className="column parent-width mr-4">
            <div className="row my-2">
              <div className="column parent-width">
                <DKLabel text="Auto Numbering Format" className="fs-r" />
              </div>
              <div className="column parent-width">
                <div className="parent-width">{getSequenceFormatInput()}</div>
              </div>
            </div>
            <div className="row my-2">
              <div className="column parent-width">
                <DKLabel text="Finished Good Name" className="fs-r" />
              </div>
              <div className="column parent-width text-align-left">
                {bomProductData.name}
              </div>
            </div>
            <div className="row my-2">
              <div className="column parent-width">
                <DKLabel text="Finished Good Code" className="fs-r" />
              </div>
              <div className="column parent-width">
                {bomProductData.productCode}
              </div>
            </div>
            <div className="row my-2">
              <div className="column parent-width">
                <DKLabel text="Built Date" className="fs-r" />
              </div>
              <div className="column parent-width">
                <div className="column parent-width">
                  {DateFormatService.getDateStrFromDate(
                    buildDate,
                    tenantInfo.dateFormat
                  )}
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div className="column parent-width">
                <DKLabel text="Built Quantity" className="fs-r" />
              </div>
              <div className="column parent-width">{buildQuantity}</div>
            </div>
            <DKLine className="" />
            <div className="row my-2">
              <div className="column parent-width">
                <DKLabel
                  text="Total Available Qty After Production"
                  className="fs-r"
                />
              </div>
              <div className="column parent-width">
                {getAvailableQtyAfterBuilt()}
              </div>
            </div>
            <div className="row my-2">
              <div className="column parent-width">
                <DKLabel text="Total Cost to Build" className="fs-r" />
              </div>
              <div className="column parent-width">{getTotalCost()}</div>
            </div>
            <div className="row my-2">
              <div className="column parent-width">
                <DKLabel text="Cost Per Unit" className="fs-r" />
              </div>
              <div className="column parent-width">
                {bomProductData.costPerUnit}
              </div>
            </div>
            <DKLine className="" />
            <div className="row mt-4 mb-2">
              Select the warehouse you want to assemble finished goods
            </div>
            <div className="row my-2">
              <div className="column parent-width">
                <DKLabel
                  text={t(
                    `PRODUCTS.DIALOG.PRODUCT.INVENTORY.OPENING_BALANCE.WHAREHOUSE`
                  )}
                  className="fs-r"
                />
              </div>
              <div className="column" style={{ width: '90%' }}>
                <DKInput
                  className={''}
                  value={selectedWarehouse}
                  formatter={(obj: any) => {
                    return obj.name;
                  }}
                  title={''}
                  icon={''}
                  type={INPUT_TYPE.DROPDOWN}
                  required={false}
                  canValidate={false}
                  onChange={(value: any) => {
                    onWarehouseChange(value);
                  }}
                  dropdownConfig={{
                    className: '',
                    style: {},
                    allowSearch: false,
                    searchableKey: 'name',
                    canEdit: false,
                    canDelete: false,
                    searchApiConfig: {
                      getUrl: (searchValue: string) => {
                        const query: string = `?limit=50&page=0&filterOnRolesFlg=false&search=${searchValue}&allRRBDetails=true&query=active=true`;
                        const finalEndPoint =
                          ApiConstants.URL.BASE +
                          ApiConstants.URL.ACCOUNTS.WAREHOUSES +
                          query;
                        return finalEndPoint;
                      },
                      dataParser: (response: any) => {
                        var whData = (response?.content || []).filter(
                          (warehouse: any) =>
                            warehouse &&
                            warehouse.active &&
                            warehouse.warehouseType === WAREHOUSE_TYPE.NONE
                        );
                        if (whData.length) {
                          setWarehouses(whData);
                        }
                        return whData;
                      },
                      debounceTime: 300
                    },
                    data: getActiveWarehouses(),
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.name}`} />;
                    },
                    onSelect: (index: any, value: any) => {}
                  }}
                />
              </div>
              {trackingType && (
                <div className="column ml-r" style={{ width: '10%' }}>
                  <DKIcon
                    src={
                      advancedTrackingCompleted
                        ? ic_bom_allocate_green
                        : ic_bom_allocate_red
                    }
                    title={''}
                    className={`ic-r flex align-items-center cursor-hand`}
                    onClick={() => {
                      showBatchSerialPopups();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="column w-1/2 mr-4 row-reverse">
            <div className="w-1/2 p-s border-m border-radius-s bg-deskera-secondary">
              <div className="row mb-r">
                <span className="fw-m"> Transfer Stock</span>
              </div>
              <div className="row text-align-left">
                Transfer Stock This takes you to the Stock Transfer App, where
                the user can transfer this FG to another warehouse.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getCurrentStepChild = () => {
    switch (currentIndex) {
      case 0:
        return firstForm();
      case 1:
        return secondForm();
      case 2:
        return thirdForm();
      default:
        break;
    }
  };

  //revisit
  const firstFormValidation = () => {
    if (buildQuantity <= 0) {
      showAlert('Unable to Proceed', 'Build Quantity should be more than 0');
      return true;
    }
    if (buildQuantity > bomProductData.allowQuantityToBuild) {
      showAlert(
        'Unable to Proceed',
        'Build Quantity should not exceed the maximum quantity that can be built'
      );
      return true;
    }
    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(customFieldList);
    if (customFieldHasErrors) {
      return true;
    }
    // Custom fields validation ends
    return false;
  };

  const validateSameWarehouseQuantity = () => {
    if (!Utility.isEmpty(productWarehouseCodeData)) {
      let hasError = false;
      let warehouse = productWarehouseCodeData[selectedBOMWarehouse.code];
      if (!Utility.isEmpty(warehouse)) {
        bomProductData.bomProductsConfiguration.forEach((element: any) => {
          if (
            element.shortFallQuantity < 0 &&
            element.type !== PRODUCT_TYPE.NON_TRACKED
          ) {
            hasError = true;
          }
        });
      } else {
        hasError = true;
      }
      if (hasError) {
        showAlert(
          'Error',
          'Some products have insufficient quantity in the selected warehouse'
        );
        return false;
      }
      return true;
    }
  };

  const secondFormValidation = () => {
    if (allocationType === BOM_ALLOCATION_TYPE.INDIVIDUAL_WAREHOUSE) {
      let notChoosenComponent = 0;
      bomProductData.bomProductsConfiguration.forEach(
        (bomProductConfig: any) => {
          if (
            !bomProductConfig.wareHouseAlloted &&
            bomProductConfig?.type !== PRODUCT_TYPE.NON_TRACKED
          ) {
            notChoosenComponent++;
          }
        }
      );

      if (notChoosenComponent > 0) {
        showAlert(
          'Unable to Proceed',
          'Please allocate warehouse to all components'
        );
        return true;
      }
    }
    if (allocationType === BOM_ALLOCATION_TYPE.SAME_WAREHOUSE) {
      if (
        selectedBOMWarehouse === '' ||
        selectedBOMWarehouse === null ||
        selectedBOMWarehouse === undefined
      ) {
        showAlert('Error', 'Please select warehouse');
        return true;
      }
      if (!validateSameWarehouseQuantity()) {
        return true;
      }
      return false;
    }

    return false;
  };

  const isInvalidStep = (expectedStep: number) => {
    if (expectedStep === 0) return false;

    /* Check if component has required data for expected step */
    if (expectedStep === 1) {
      return firstFormValidation();
    } else if (expectedStep === 2) {
      if (firstFormValidation()) {
        setCurrentIndex(0);
        return true;
      } else if (secondFormValidation()) {
        setCurrentIndex(1);
        return true;
      }
    }

    return false;
  };

  const onSkipToStep = (event: any, index: any) => {
    /* By default expected step is to move forward */
    const expectedStep = index ?? currentIndex + 1;
    // if (expectedStep === 1) {
    //   setFirstStepClicked(true);
    // }

    if (isInvalidStep(expectedStep)) {
      i18n.t(`BULK_PAYMENT_POPUP.STEP_VALIDATION_ERROR`);
      // showToast(errorInfo, TOAST_TYPE.FAILURE);
    } else {
      setCurrentIndex(expectedStep);
    }
  };

  const getControllers = () => {
    const isLastStep = currentIndex === stepsData.length - 1;
    return (
      <div className="row parent-width justify-content-end mt-auto">
        {currentIndex !== 0 && (
          <DKButton
            disabled={isSaving}
            title={t(`BULK_PAYMENT_POPUP.BACK`)}
            className="border-m ml-r bg-white"
            onClick={(e: any) => onSkipToStep(e, currentIndex - 1)}
          />
        )}

        {!isLastStep && (
          <DKButton
            disabled={isSaving}
            title={t(`BULK_PAYMENT_POPUP.CONTINUE`)}
            className={`ml-r  ${
              `bg-button text-white`
              // currencySelection ? `bg-gray1` : `bg-button text-white`
            }`}
            onClick={onSkipToStep}
          />
        )}
        {isLastStep && (
          <>
            <DKButton
              disabled={isSaving}
              title={'Transfer Stock'}
              className={`ml-r mr-r ${
                `bg-app text-white`
                // currencySelection ? `bg-gray1` : `bg-button text-white`
              }`}
              onClick={() =>
                window.open(
                  process.env.REACT_APP_ENV_NAME === 'development'
                    ? STOCK_URL_DEV
                    : STOCK_URL
                )
              }
            />
            <div
              className={`row ${isSaving ? 'border-radius-m border-m' : ''}`}
            >
              <DKButton
                disabled={isSaving}
                title={'Save'}
                className={`${
                  isSaving
                    ? 'border-radius-none text-gray'
                    : 'bg-app text-white'
                }`}
                onClick={() => saveAssembly()}
              />
              {isSaving && (
                <DKSpinner
                  iconClassName="ic-s-2"
                  className="column pl-0 pr-s"
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const buildAssemblyHeader = () => {
    return (
      <div
        style={{ zIndex: 4 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky top-0"
      >
        <div className="row width-auto">
          <DKLabel
            text={'Build Assembly - BOM Product'}
            className="fw-m fs-l"
          />
        </div>
        <div className="flex">
          <DKButton
            disabled={isSaving}
            title={t(`BULK_PAYMENT_POPUP.CANCEL`)}
            className="border-m ml-r bg-white"
            onClick={() => {
              props.onClose();
            }}
          />
          {getControllers()}
        </div>
      </div>
    );
  };

  const getBomProductConfig = () => {
    let bomDetail: any = deepClone(bomProductData);
    let makeBomAssemblyDetails: any[] = [];
    bomDetail.bomProductsConfiguration.forEach((product: any) => {
      // If any product have enabled advance tracking then for same ware house option will be disabled
      let quantityReq = Number(buildQuantity) * Number(product.quantity);
      let requiredQuantity = Utility.roundingOff(
        quantityReq,
        QTY_ROUNDOFF_PRECISION
      );
      if (
        product.advancedTracking === TRACKING_TYPE.BATCH ||
        product.advancedTracking === TRACKING_TYPE.SERIAL
      ) {
        setAllocationType(BOM_ALLOCATION_TYPE.INDIVIDUAL_WAREHOUSE);
        setIsBatchOrSerialProduct(true);
      }

      const isNotNonTrackedProduct = product?.type !== PRODUCT_TYPE.NON_TRACKED;
      // modification of response object
      let updateObject: any = {
        itemId: product.itemId,
        pid: product.pid,
        productCode: product.productCode,
        quantity: isNotNonTrackedProduct ? product.quantity : 0,
        itemName: product.itemName,
        uomName: product.uomName,
        uomId: product.uomId,
        costPerUnit: product.costPerUnit,
        advancedTracking: product.advancedTracking,
        availableQuantity: product.availableQuantity,
        purchasePrice: product.purchasePrice,
        warehouseInventoryData: isNotNonTrackedProduct
          ? [
              {
                quantity: requiredQuantity,
                uomQuantity: requiredQuantity,
                advancedTrackingType: product?.advancedTracking,
                warehouseCode: selectedBOMWarehouse.code,
                rowCode: selectedFilters?.row?.code ?? null,
                rowName: selectedFilters?.row?.name ?? null,
                rackCode: selectedFilters?.rack?.code ?? null,
                rackName: selectedFilters?.rack?.name ?? null,
                binCode: selectedFilters?.bin?.code ?? null,
                binName: selectedFilters?.bin?.name ?? null
              }
            ]
          : [],
        isQuickCommit: product.isQuickCommit,
        buildQuantityReq: isNotNonTrackedProduct
          ? Utility.roundingOff(
              Number(buildQuantity) * Number(product.quantity),
              5
            )
          : 0,
        quantityRequired: isNotNonTrackedProduct
          ? Utility.roundingOff(
              Number(buildQuantity) * Number(product.quantity),
              5
            )
          : 0,
        uomQuantityRequired: isNotNonTrackedProduct
          ? Utility.roundingOff(
              Number(buildQuantity) * Number(product.quantity),
              5
            )
          : 0,
        actualQuantityUsed: isNotNonTrackedProduct
          ? Utility.roundingOff(
              Number(buildQuantity) * Number(product.quantity),
              5
            )
          : 0,
        uomActualQuantityUsed: isNotNonTrackedProduct
          ? Utility.roundingOff(
              Number(buildQuantity) * Number(product.quantity),
              5
            )
          : 0,
        productName: product.itemName,
        shortFallQuantity: 0,
        productId: product.itemId,
        totalQuantity: isNotNonTrackedProduct ? product.availableQuantity : 0,
        uomTotalQuantity: isNotNonTrackedProduct
          ? product.availableQuantity
          : 0,
        stockUom: product?.documentUOMSchemaDefinition?.sinkUOM,
        documentUOMSchemaDefinition: product?.documentUOMSchemaDefinition
      };

      if (
        allocationType === BOM_ALLOCATION_TYPE.INDIVIDUAL_WAREHOUSE &&
        product.advancedTracking === TRACKING_TYPE.NONE
      ) {
        if (
          product.warehouseInventoryData &&
          product.warehouseInventoryData.length > 0
        ) {
          updateObject.warehouseInventoryData =
            product.warehouseInventoryData?.map((whInvData: any) => {
              return {
                ...whInvData,
                uomQuantity: Utility.roundingOff(
                  whInvData.quantity,
                  QTY_ROUNDOFF_PRECISION
                ),
                advancedTrackingType: product?.advancedTracking
              };
            });
        } else {
          updateObject.warehouseInventoryData = null;
        }
      }

      if (
        product.advancedTracking &&
        (product.advancedTracking === TRACKING_TYPE.BATCH ||
          product.advancedTracking === TRACKING_TYPE.SERIAL)
      ) {
        updateObject.warehouseInventoryData =
          product.warehouseInventoryData?.map((whInvData: any) => {
            let qtyForFulfilment = Utility.roundingOff(
              whInvData.quantity,
              QTY_ROUNDOFF_PRECISION
            );

            if (product?.documentUOMSchemaDefinition) {
              qtyForFulfilment = Utility.getUomQuantityWithoutRoundOff(
                qtyForFulfilment,
                product?.documentUOMSchemaDefinition
              );
            }
            return {
              ...whInvData,
              uomQuantity: Utility.roundingOff(
                qtyForFulfilment,
                QTY_ROUNDOFF_PRECISION
              ),
              quantity: Utility.roundingOff(
                qtyForFulfilment,
                QTY_ROUNDOFF_PRECISION
              ),
              advancedTrackingType: product?.advancedTracking,
              advancedTrackingData: whInvData?.advancedTrackingData?.map(
                (advTrack: any) => {
                  return {
                    ...advTrack,
                    qtyToFulfilUom: advTrack?.qtyToFulfil,
                    costPerUnit: whInvData.costPerUnit
                  };
                }
              )
            };
          });
      }

      /* For non tracked product need to empty warehouse inventory data */
      if (!isNotNonTrackedProduct) {
        updateObject.warehouseInventoryData = [];
      }

      makeBomAssemblyDetails.push(updateObject);
    });

    bomDetail.bomProductsConfiguration = [...makeBomAssemblyDetails];

    setBomProductData(deepClone(bomDetail));
    return bomDetail.bomProductsConfiguration;
  };

  const getPayload = () => {
    let advancedTrackingData = advancedTrackingList;
    let warehouseInventoryData: any = {};
    if (
      trackingType === TRACKING_TYPE.BATCH ||
      trackingType === TRACKING_TYPE.SERIAL
    ) {
      // advancedTrackingMetaData
      warehouseInventoryData = advancedTrackingData?.map((element: any) => {
        return {
          advancedTrackingData: [
            {
              batchSize: element?.batchSize,
              qtyToFulfil: element?.batchSize,
              serialBatchNumber: element?.serialBatchNumber,
              manufacturingDate: element?.manufacturingDate,
              expiryDate: element?.expiryDate,
              customField: element?.customField
            }
          ],
          quantity: element?.batchSize,
          rowCode: element?.row ? element?.row?.code : null,
          rackCode: element?.rack ? element?.rack?.code : null,
          binCode: element?.bin ? element?.bin?.code : null,
          warehouseCode: element?.warehouseCode,
          warehouseName: element?.warehouseName
        };
      });
    } else {
      warehouseInventoryData = advancedTrackingData?.map((element: any) => {
        return {
          quantity: element?.quantity,
          warehouseCode: element?.warehouseCode,
          rowCode: element?.row ? element?.row?.code : null,
          rackCode: element?.rack ? element?.rack?.code : null,
          binCode: element?.bin ? element?.bin?.code : null,
          advancedTrackingData: []
        };
      });
    }
    let payload: any = {
      bomAssemblyDetails: getBomProductConfig(), //ankit
      productId: product.id,
      pid: product.productId,
      productName: product.name,
      productCode: product.documentSequenceCode,
      sequenceNumber: '',
      totalCostToBuild: getTotalCost(),
      buildQuantityMax: bomProductData.allowQuantityToBuild,
      totalQuantity: product.inventory
        ? product.inventory.availableQuantity
        : product.availableQuantity,
      costPerUnit: bomProductData.costPerUnit, //ankit
      memo: memoDetails, //ankit
      assemblyDate: DateFormatService.getDateStrFromDate(
        buildDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ), //ankit
      buildQuantityReq: buildQuantity, //ankit
      customField: customFieldList,
      advancedTracking: trackingType ? trackingType : 'NONE',
      warehouseInventoryData: warehouseInventoryData,
      sequenceFormat: autoNumberingFormat.id
    };

    return payload;
  };

  const getComponentProductDetails = async () => {
    const productCodes = bomProductData?.bomProductsConfiguration?.map(
      (pData: any) => pData.pid
    );
    const data = await ProductService.getProductsByProductIds(productCodes);
    return data;
  };

  const getTax = (product: any) => {
    let tax: any = null;
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.INDIA_GST && product.taxPreference === false) {
      return null;
    } else if (taxSystem === TAX_SYSTEM.US) {
      return null;
    } else {
      let taxCode: any = product.purchaseTaxCode;
      tax = purchaseTaxes.find((tax) => tax.code === taxCode);
      return tax ? tax : taxCode === '' ? null : taxCode;
    }
  };

  const calculateProductShortFallQty = (
    shortFallQty: number,
    availableQty: number
  ): number => {
    let buildQuantityAmount =
      Number(buildQuantity) > 0 ? Number(buildQuantity) : 1;
    let totalQuantity = Number(shortFallQty) * buildQuantityAmount;
    if (totalQuantity < availableQty) {
      return totalQuantity;
    }
    return totalQuantity - availableQty;
  };

  const isSubBOMProductIncluded = (): any => {
    let isBOM = false;
    isBOM = bomProductData?.bomProductsConfiguration?.some((product: any) => {
      return product.type === PRODUCT_TYPE.BILL_OF_MATERIALS;
    });
    return isBOM;
  };

  const isBackorderRequired = (): any => {
    let isBackorderRequired = false;
    isBackorderRequired = bomProductData?.bomProductsConfiguration?.some(
      (product: any) => {
        return product.quantity > product.availableQuantity;
      }
    );
    return isBackorderRequired;
  };

  const isBackorderRequiredBOM = (): any => {
    let isBackorderRequired = false;
    isBackorderRequired = bomProductData?.bomProductsConfiguration?.some(
      (product: any) => {
        return (
          product.quantity > product.availableQuantity &&
          product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
        );
      }
    );
    return isBackorderRequired;
  };

  const getMaxDate = (itemList: any[]) => {
    if (Utility.isEmpty(itemList)) {
      return DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
    }
    let maxDateProductObject = itemList?.reduce(function (a, b) {
      let firstDate = a?.expectedDeliveryDt || new Date();
      let secondDate = b?.expectedDeliveryDt || new Date();
      return firstDate > secondDate ? a : b;
    });
    // compoare it to new
    if (
      typeof maxDateProductObject?.expectedDeliveryDt !== 'undefined' &&
      maxDateProductObject?.expectedDeliveryDt !== null
    ) {
      return DateFormatService.getDateStrFromDate(
        maxDateProductObject?.expectedDeliveryDt,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
    } else {
      return DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
    }
  };

  const openBackOrderPopup = async () => {
    let orderData = { ...OrderInitialState };
    try {
      let products = (await getComponentProductDetails()) as any[];
      let filteredProduct = [...products];
      let gstType: any = null;
      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        gstType = Utility.getIndiaDefaultTaxType(null, null);
      }
      let lineItems = filteredProduct?.map((product: any, index: number) => {
        const item = createLineItem(
          product,
          gstType,
          index + 1,
          DOC_TYPE.ORDER
        );
        const productItem = {
          ...item,
          product: {
            ...product,
            uomSchema: product.uomSchema,
            multipleUomSchema: product.multipleUomSchema,
            uomSchemaDto: product.uomSchemaDto
          }
        };
        return productItem;
      });
      const updatedItems: any[] = [];
      lineItems.forEach((lineItem: any) => {
        let productInBomConfig = bomProductData?.bomProductsConfiguration.find(
          (bomProduct: any) => bomProduct.pid === lineItem.productCode
        );
        if (
          productInBomConfig.quantity >
          lineItem?.availableQuantity - (lineItem?.reservedQuantity || 0)
        ) {
          let productQuantity = !Utility.isEmpty(productInBomConfig)
            ? calculateProductShortFallQty(
                productInBomConfig.quantity,
                lineItem?.availableQuantity - (lineItem?.reservedQuantity || 0)
              )
            : 1;
          if (
            typeof productQuantity !== 'undefined' &&
            productQuantity !== null &&
            productQuantity > 0
          ) {
            updatedItems.push({
              ...lineItem,
              taxAmount: 0,
              tax: getTax(lineItem.product),
              price: lineItem.product
                ? lineItem.product.purchasePrice
                : lineItem.price,
              unitPrice: lineItem.product
                ? lineItem.product.purchasePrice
                : lineItem.unitPrice,
              uomUnitPrice: lineItem.product
                ? Utility.getUomPrice(
                    lineItem.product.purchasePrice,
                    lineItem.documentUOMSchemaDefinition
                  )
                : lineItem.uomUnitPrice,
              productQuantity: productQuantity,
              expectedDeliveryDt: Utility.addDaysInDate(
                new Date(),
                lineItem?.product?.leadTimeDetails?.[0]?.leadTime || 0
              )
            });
          }
        }
      });

      orderData = {
        ...orderData,
        receiveByDate: getMaxDate(updatedItems),
        currency: tenantInfo?.currency,
        currencyCode: tenantInfo?.currency,
        backOrder: true,
        orderType: BUY_TYPE.INVENTORY,
        purchaseOrderItems: updatedItems,
        items: updatedItems
      };

      let payloadData = {
        title: LABELS.PURCHASE_ORDERS,
        type: LABELS.PURCHASE_ORDERS,
        tableId: draftsTableId,
        columnConfig: draftsColumnConfig,
        populateFormData: orderData,
        isCenterAlign: true,
        isMaximized: true
      };

      // Close BuildAssembly popup when backorder is opened
      if (props.onClose) {
        props.onClose();
      }
      // Close Fulfillment popup when backorder is opened
      if (props.onBackOrderOpen) {
        props.onBackOrderOpen();
      }

      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
    } catch (err) {
      console.error('Error loading component products details: ', err);
    }
  };

  const getQuantityValidation = (data: any, requiredQty: any, product: any) => {
    let totalQuantity = data?.reduce(
      (prev: number, current: any) =>
        prev +
        Number(current.quantity || current.qtyToFulfil || current.batchSize),
      0
    );
    if (
      product?.documentUOMSchemaDefinition &&
      product.advancedTracking !== ADVANCE_TRACKING.NONE
    ) {
      totalQuantity = Utility.getUomQuantityWithoutRoundOff(
        totalQuantity,
        product?.documentUOMSchemaDefinition
      );
    }
    if (Number(totalQuantity) !== Number(requiredQty)) {
      showAlert(
        'Error!',
        'Quantity Allocated should be equal to Required quantity.'
      );
      return true;
    } else {
      return false;
    }
  };

  const getAssignmentPopups = () => {
    return (
      <>
        {showWarehouseManagement &&
          warehouseProduct.advancedTracking !== TRACKING_TYPE.BATCH &&
          warehouseProduct.advancedTracking !== TRACKING_TYPE.SERIAL && (
            <NoneTrackedAssignment
              details={{
                ...warehouseProduct,
                quantityFulfilled: warehouseProduct.quantityRequired,
                pendingQuantity: Utility.getUomWarehouseQuantityWithoutRoundOff(
                  warehouseProduct.quantityRequired,
                  warehouseProduct?.documentUOMSchemaDefinition
                ),
                parentQuantityToFulfill: warehouseProduct.quantityRequired,
                productCode: warehouseProduct?.pid,
                documentSequenceCode: warehouseProduct?.productCode ?? '',
                product: {
                  name:
                    warehouseProduct?.itemName ?? warehouseProduct?.productName,
                  stockUom: warehouseProduct.uomId,
                  availableQuantiy: warehouseProduct?.availableQuantity
                },
                warehouseInventoryData: []
              }}
              module={MODULE_TYPE.SELL}
              buildQuantity={buildQuantity}
              onSave={(data: any) => {
                if (
                  getQuantityValidation(
                    data,
                    warehouseProduct.quantityRequired,
                    warehouseProduct
                  )
                ) {
                  return;
                }
                let copyBomProductData = { ...bomProductData };
                let findBomProductConfigIndex =
                  copyBomProductData.bomProductsConfiguration.findIndex(
                    (bomProduct: any) =>
                      bomProduct?.productCode === warehouseProduct?.productCode
                  );

                if (findBomProductConfigIndex > -1) {
                  let selectedBomProductConfig =
                    copyBomProductData.bomProductsConfiguration[
                      findBomProductConfigIndex
                    ];

                  selectedBomProductConfig.wareHouseAlloted = true;
                  selectedBomProductConfig.warehouseInventoryData = data;
                  // data.map((updated:any) => {
                  //   let copyUpdated = {...updated}
                  //   copyUpdated.delete('row')
                  //   copyUpdated.delete('rack')
                  //   copyUpdated.delete('bin')
                  //   copyUpdated.delete('warehouse')
                  //   copyUpdated.delete('invalidFields')
                  // });

                  if (Utility.isEmpty(data)) {
                    selectedBomProductConfig.isQuickCommit = true;
                  }

                  copyBomProductData.bomProductsConfiguration[
                    findBomProductConfigIndex
                  ] = selectedBomProductConfig;
                  setBomProductData(copyBomProductData);
                }
                setShowWarehouseManagement(false);
              }}
              onCancel={() => {
                setShowWarehouseManagement(false);
              }}
            ></NoneTrackedAssignment>
          )}
        {showWarehouseManagement &&
          warehouseProduct.advancedTracking === TRACKING_TYPE.BATCH && (
            <BatchTrackingAssignment
              itemDetails={{
                ...warehouseProduct,
                requiredQuantity: warehouseProduct.quantityRequired,
                documentSequenceCode: warehouseProduct?.productCode ?? '',
                product: {
                  name: warehouseProduct?.itemName,
                  stockUom: warehouseProduct.uomId,
                  availableQuantiy: warehouseProduct.availableQuantity
                }
              }}
              module={MODULE_TYPE.SELL}
              defaultProductWarehouse={defaultAllocationWarehouse}
              buildQuantity={buildQuantity}
              onBatchSave={(data: any, quantityAllocated: any) => {
                if (
                  getQuantityValidation(
                    data,
                    warehouseProduct.quantityRequired,
                    warehouseProduct
                  )
                ) {
                  return;
                }
                let copyBomProductData = { ...bomProductData };
                let findBomProductConfigIndex =
                  copyBomProductData.bomProductsConfiguration.findIndex(
                    (bomProduct: any) =>
                      bomProduct?.productCode === warehouseProduct?.productCode
                  );
                if (findBomProductConfigIndex > -1) {
                  let selectedBomProductConfig =
                    copyBomProductData.bomProductsConfiguration[
                      findBomProductConfigIndex
                    ];
                  selectedBomProductConfig.wareHouseAlloted = true;
                  selectedBomProductConfig.warehouseInventoryData = data?.map(
                    (dataItem: any) => {
                      return {
                        ...dataItem,
                        quantity: dataItem?.qtyToFulfil,
                        rowcode: dataItem?.row ? dataItem?.row?.code : null,
                        rackcode: dataItem?.rack ? dataItem?.rack?.code : null,
                        bincode: dataItem?.bin ? dataItem?.bin?.code : null,
                        advancedTrackingData: [
                          {
                            qtyToFulfil: dataItem?.qtyToFulfil,
                            serialBatchNumber: dataItem?.serialBatchNumber
                          }
                        ]
                      };
                    }
                  );
                  if (Utility.isEmpty(data)) {
                    selectedBomProductConfig.isQuickCommit = true;
                  }
                  copyBomProductData.bomProductsConfiguration[
                    findBomProductConfigIndex
                  ] = selectedBomProductConfig;
                  setBomProductData(copyBomProductData);
                }
                setShowWarehouseManagement(false);
              }}
              onClose={() => {
                setShowWarehouseManagement(false);
              }}
            ></BatchTrackingAssignment>
          )}
        {showWarehouseManagement &&
          warehouseProduct.advancedTracking === TRACKING_TYPE.SERIAL && (
            <SerialTrackedAssignment
              isMRP={false}
              itemDetails={{
                ...warehouseProduct,
                requiredQuantity: warehouseProduct.quantity,
                productQuantity: warehouseProduct.quantityRequired,
                documentSequenceCode: warehouseProduct?.productCode ?? '',
                product: {
                  name: warehouseProduct?.itemName,
                  stockUom: warehouseProduct.uomId,
                  availableQuantiy: warehouseProduct.availableQuantity
                }
              }}
              module={MODULE_TYPE.SELL}
              defaultProductWarehouse={defaultAllocationWarehouse}
              buildQuantity={buildQuantity}
              onSerialSave={(data: any, quantityAllocated: any) => {
                if (
                  getQuantityValidation(
                    data,
                    warehouseProduct.quantityRequired,
                    warehouseProduct
                  )
                ) {
                  return;
                }
                let copyBomProductData = { ...bomProductData };
                let findBomProductConfigIndex =
                  copyBomProductData.bomProductsConfiguration.findIndex(
                    (bomProduct: any) =>
                      bomProduct?.productCode === warehouseProduct?.productCode
                  );

                if (findBomProductConfigIndex > -1) {
                  let selectedBomProductConfig =
                    copyBomProductData.bomProductsConfiguration[
                      findBomProductConfigIndex
                    ];

                  selectedBomProductConfig.wareHouseAlloted = true;
                  // selectedBomProductConfig.warehouseInventoryData =
                  //   finalPayload;
                  selectedBomProductConfig.warehouseInventoryData = data?.map(
                    (dataItem: any) => {
                      return {
                        ...dataItem,
                        quantity: dataItem?.qtyToFulfil,
                        rowcode: dataItem?.row ? dataItem?.row?.code : null,
                        rackcode: dataItem?.rack ? dataItem?.rack?.code : null,
                        bincode: dataItem?.bin ? dataItem?.bin?.code : null,
                        advancedTrackingData: [
                          {
                            qtyToFulfil: dataItem?.qtyToFulfil,
                            serialBatchNumber: dataItem?.serialBatchNumber
                          }
                        ]
                      };
                    }
                  );

                  if (Utility.isEmpty(data)) {
                    selectedBomProductConfig.isQuickCommit = true;
                  }

                  copyBomProductData.bomProductsConfiguration[
                    findBomProductConfigIndex
                  ] = selectedBomProductConfig;
                  setBomProductData(deepClone(copyBomProductData));
                }
                setShowWarehouseManagement(false);
              }}
              onClose={() => {
                setShowWarehouseManagement(false);
              }}
            />
          )}
      </>
    );
  };

  const getReceiveGoodsPopup = () => {
    return (
      <>
        {showBatchSerialPopup &&
          product.advancedTracking === TRACKING_TYPE.BATCH && (
            <BatchTrackingReceive
              isMrpFlow={false}
              itemDetails={{
                ...product,
                advancedTrackingMetaData: advancedTrackingList,
                requiredQuantity: buildQuantity,
                quantityRequired: buildQuantity,
                product: {
                  name: product?.name,
                  stockUom: product.uomId,
                  availableQuantity:
                    product?.inventory?.availableQuantity ||
                    product?.availableQuantity
                }
              }}
              module={MODULE_TYPE.BUY}
              targetWarehouse={selectedWarehouse?.code ?? ''}
              onBatchSave={(data: any, quantity: any) => {
                if (
                  getQuantityValidation(
                    data,
                    product?.documentUOMSchemaDefinition
                      ? Utility.getUomQuantityWithoutRoundOff(
                          buildQuantity,
                          product?.documentUOMSchemaDefinition
                        )
                      : buildQuantity,
                    product
                  )
                ) {
                  return;
                }
                setAdvancedTrackingList(data);
                setAdvancedTrackingCompleted(true);
                setShowBatchSerialPopup(false);
              }}
              onClose={() => {
                setShowBatchSerialPopup(false);
              }}
            ></BatchTrackingReceive>
          )}
        {showBatchSerialPopup &&
          product.advancedTracking === TRACKING_TYPE.SERIAL && (
            <SerialTrackedReceive
              isMRP={false}
              itemDetails={{
                ...product,
                advancedTrackingMetaData: advancedTrackingList,
                productQuantity: buildQuantity,
                product: {
                  ...product
                }
              }}
              module={MODULE_TYPE.BUY}
              targetWarehouse={selectedWarehouse?.code ?? ''}
              onSerialSave={(
                data: any,
                isQuickCommit: boolean,
                quantity: any
              ) => {
                if (getQuantityValidation(data, buildQuantity, product)) {
                  return;
                }
                setAdvancedTrackingList(data);
                setAdvancedTrackingCompleted(true);
                setShowBatchSerialPopup(false);
              }}
              onClose={() => {
                setShowBatchSerialPopup(false);
              }}
            ></SerialTrackedReceive>
          )}
        {showBatchSerialPopup &&
          product.advancedTracking === TRACKING_TYPE.NONE && (
            <NoneTrackedReceive
              isMRP={false}
              // disableAddRow={isDisableAddRow()}
              details={{
                ...product,
                quantityFulfilled: buildQuantity,
                productCode: product.productId,
                productQuantity: buildQuantity,
                product: {
                  ...product
                },
                warehouseInventoryData: advancedTrackingList
              }}
              defaultProductWarehouse={selectedWarehouse}
              targetWarehouse={selectedWarehouse?.code ?? ''}
              onSave={(data: any, isQuickCommit: boolean, quantity: any) => {
                if (getQuantityValidation(data, buildQuantity, product)) {
                  return;
                }
                setAdvancedTrackingList(data);
                setAdvancedTrackingCompleted(true);
                setShowBatchSerialPopup(false);
              }}
              onCancel={() => {
                setShowBatchSerialPopup(false);
              }}
            ></NoneTrackedReceive>
          )}
      </>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1100,
          minHeight: '80%',
          width: '85%',
          maxHeight: '95%',
          padding: 0
        }}
      >
        <div className="column parent-width overflow-scroll hide-scroll-bar">
          {buildAssemblyHeader()}
          {showBOMLoader && (
            <div
              style={{ height: '650px' }}
              className="flex parent-width parent-height items-center justify-center font-semibold"
            >
              <DKSpinner
                iconClassName="ic-s-2"
                className="ml-m"
                title="loading document..."
              />
            </div>
          )}
          {!showBOMLoader && (
            <div className="flex flex-col mt-3 p-4 mb-10 parent-width">
              <Stepper
                data={stepsData}
                currentIndex={currentIndex}
                className="mb-l"
              />
              <div className="row parent-width p-4 mb-6 justify-content-center">
                {stepsData && stepsData.length > 0 && getCurrentStepChild()}
              </div>
            </div>
          )}
        </div>
        <div className="row position-absolute bottom-0">
          {(bomProductData?.allowQuantityToBuild === 0 ||
            buildQuantity > bomProductData?.allowQuantityToBuild) && (
            <div className="row bg-deskera-secondary border-radius-s gap-1 p-h-s">
              {isBackorderRequired() && (
                <div className="row width-auto ">
                  <DKLabel text="Quantity to build cannot exceed the quantity you can build. Do you want to create" />
                  <DKButton
                    title="Backorder"
                    className="bg-transparent text-black fw-b"
                    style={{
                      paddingLeft: 4,
                      paddingRight: 0
                    }}
                    onClick={() => openBackOrderPopup()}
                  />
                  ?
                </div>
              )}
              {isSubBOMProductIncluded() && isBackorderRequiredBOM() && (
                <div className="row width-auto p-2 pl-0">
                  {' '}
                  {`Please build the assemblies for BOM product`}
                </div>
              )}
            </div>
          )}
        </div>
        {getReceiveGoodsPopup()}
        {getAssignmentPopups()}
      </div>
    </div>
  );
}
