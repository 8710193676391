import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  showLoader,removeLoader,
  INPUT_TYPE,
  TOAST_TYPE,
  showToast,
  showAlert,
  DKCheckMark,
  DKTooltipWrapper
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiConstants from '../../../Constants/ApiConstants';
import {
  APPLY_TO_TYPE,
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  COMPLAINCE_CURRENCY,
  COUNTRY_CODES,
  CURRENCIES,
  CUSTOM_FIELD_TYPE,
  DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  GST_TYPE,
  INPUT_VIEW_DIRECTION,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  RCM_DOCUMENTS,
  ROUNDING_METHOD,
  STATUS_TYPE,
  TAX_SYSTEM
} from '../../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';
import { NotesConfigManager } from '../../../Managers/NotesConfigManager';
import {
  depositModel,
  expenseModel
} from '../../../Models/ExpenseDepositModel';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../Models/Interfaces';
import { DEFAULT_TDS_PAYABLE_ACCOUNT_CODE } from '../../../Models/TDS';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchAccoutnsList,
  fetchDefaultAccounts,
  selectDefaultAccounts,
  selectedAccounts
} from '../../../Redux/Slices/AccountsSlice';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import {
  fetchClassesByDimensionId,
  fetchTaxes,
  selectCurrencyListWithExchangeRate,
  selectCustomFields,
  selectShowMainDocsInFullScreen,
  selectTaxes
} from '../../../Redux/Slices/CommonDataSlice';
import { fetchContacts, selectContacts } from '../../../Redux/Slices/ContactsSlice';
import {
  selectDepositColumnConfig,
  selectDepositColumnConfigTableId
} from '../../../Redux/Slices/DepositSlice';
import {
  selectExpenseColumnConfig,
  selectExpenseColumnConfigTableId
} from '../../../Redux/Slices/ExpenseSlice';
import {
  fetchCategoryDimensions,
  selectDimensions
} from '../../../Redux/Slices/LocationSlice';
import AccountsService, { AccountAPIConfig } from '../../../Services/Accounts';
import AttachmentService from '../../../Services/Attachment';
import AuthService from '../../../Services/Auth';
import BanksService from '../../../Services/Banks';
import ContactService, { ContactAPIConfig } from '../../../Services/Contact';
import DateFormatService from '../../../Services/DateFormat';
import { localizedText } from '../../../Services/Localization/Localization';
import NumberFormatService from '../../../Services/NumberFormat';
import TaxService from '../../../Services/Tax';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import { GSTExchangeRateDialog } from '../../../SharedComponents/DocumentForm/GSTExchangeRateDialog';
import {
  customFieldsContainsErrors,
  getAccountsAndCFForBudgetValidation,
  getPrimaryCurrencyCheck,
  getTaxDetail,
  getTenantTaxSystem,
  isGSTExchangeRateRequired,
  rcmAppliedIndia,
  rcmAppliedIndiaWithCheckRCMApply,
  renderItcIneligibleSection,
  updateColumnConfigOnRowClick,
  updateRowDataWithParentCFValues,
  validateBudgetForAccounts
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import TDSCalculation from '../../../SharedComponents/DocumentForm/TDSCalculation';
import PopupWrapper from '../../../SharedComponents/PopupWrapper';
import { ConfigUtility } from '../../../Utility/ConfigUtility';
import { DocumentConfigUtility } from '../../../Utility/DocumentConfigUtility';
import { NotesConfigUtility } from '../../../Utility/NotesConfigUtility';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  getCapitalized
} from '../../../Utility/Utility';
import AddCoaPopup from '../../Accounting/COA/AddCoaPopup';
import {
  getNewColumn,
  getNewItem
} from '../../Accounting/JournalEntry/JEHelper';
import AddContact from '../../Contacts/AddContact';
import { triggerDownload } from '../../ImportExport/utility/ExportData';
import AddClass from '../../Settings/Classes/AddClass';
import { GranularPermissionsHelper } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import AddTax from '../../Settings/Tax/AddTax';
import { ExpenseDepositHelper } from './ExpenseDepositHelper';
import { isViewportLarge } from '../../../Utility/ViewportSizeUtils';
import AppManager from '../../../Managers/AppManager';
import TaxGroupDistribution from '../../Tax/TaxGroupDistribution';
import CustomFieldSettings from '../../../SharedComponents/CustomFieldsHolder/CustomFieldSettings';
import CustomFieldService from '../../../Services/CustomField';
import ItcOptions from '../../../SharedComponents/DocumentForm/ItcOptions';
import { DEFAULT_ACCOUNT_MODULE } from '../../../Models/Common';
import DefaultAccountsSettingsPopup from '../../Accounting/COA/DefaultAccountsSettingsPopup';
import { Store } from '../../../Redux/Store';
import { COUNTRIES_WITH_CURRENCIES } from '../../PriceBook/CountriesWithCurrencies';
import ReCheckButton from '../../../SharedComponents/ReCheckButton';
import { selectApplySalesPersonFilter } from '../../../Redux/Slices/BookFilterSlice';

const refInitialState: UpdateCallBacksRefType = {
  pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
  storeCallbacksRef: { updateContact: 'click' }
};

export const ExpenseDepositColumnConfig: any = [
  {
    id: 'account',
    key: 'selectedAccount',
    name: 'Category',
    type: INPUT_TYPE.DROPDOWN,
    width: 300,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: DocumentConfigUtility.productFormatter,
    dropdownConfig: {
      title: 'Select Account',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: {
        getUrl: (searchValue: string) => {
          const config: AccountAPIConfig = {
            ...AccountsService.apiConfig,
            Page: 0,
            SearchTerm: searchValue,
            Limit: 10,
            AccountGroupsString: undefined,
            Query: 'status=ACTIVE'
          };
          AccountsService.apiConfig = config;
          return ApiConstants.URL.BASE + AccountsService.getAccountEndPoint();
        },
        dataParser: (response: any) => {
          return response?.content || [];
        },
        debounceTime: 300
      },
      data: [],
      renderer: DocumentConfigUtility.productRenderer,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: {
        title: '+ Add Account',
        className: 'bg-button text-white',
        onClick: () => {}
      }
    }
  },
  {
    id: 'description',
    key: 'description',
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    width: 120,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'amount',
    key: 'paymentAmount',
    name: 'Amount',
    type: INPUT_TYPE.NUMBER,
    width: 120,
    systemField: true,
    required: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'tax',
    key: 'selectedTaxCode',
    name: 'Tax',
    type: INPUT_TYPE.DROPDOWN,
    width: 120,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    formatter: (obj: any) => {
      return obj.value.name;
    },
    dropdownConfig: {
      title: 'Select Tax',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      renderer: DocumentConfigUtility.taxOptionRenderer,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: {
        title: '+ Add Tax',
        className: 'bg-button text-white',
        onClick: () => {}
      }
    }
  },
  {
    id: 'taxAmount',
    key: 'taxAmount',
    name: 'Tax Amount',
    type: INPUT_TYPE.NUMBER,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'lineAmount',
    key: 'totalAmount',
    name: 'Total Amount',
    type: INPUT_TYPE.NUMBER,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  }
];

export default function ExpenseDepositForm(props: any) {
  const { t, i18n } = useTranslation();
  const taxData = useAppSelector(selectTaxes);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>(
    props.formType === DOC_TYPE.DEPOSIT ||
      props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
      ? depositModel
      : props.formType === DOC_TYPE.EXPENSE ||
        props.formType === DOC_TYPE.PREPAYMENT
      ? expenseModel
      : {}
  );
  const tenantInfo = useAppSelector(activeTenantInfo);
  const showMainDocsInFullscreen = useAppSelector(
    selectShowMainDocsInFullScreen
  );
  const [contact, setContact] = useState<any>();
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [referenceDate, setReferenceDate] = useState(new Date());
  const [filteredAccounts, setFilteredAccounts] = useState<any>([]);
  const [canValidate, setCanValidate] = useState(false);
  // const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const [customFieldsData, setCustomFieldsData] = useState<any[]>([]);
  const [unitPriceGstInclusive, setUnitPriceGstInclusive] = useState(false);
  const systemDimensions = useAppSelector(selectDimensions);
  const [showTds, setShowTds] = useState<boolean>(false);
  const [totalTdsAmount, setTotalTdsAmount] = useState<any>(0);
  const isFullScreenMode = !!props.allowFullScreen && showMainDocsInFullscreen;
  const [openAccountCFSettings, setOpenAccountCFSettings] = useState(false);
  const [accountsCFData, setAccountsCFData] = useState<any[]>([]);
  const cfUpdatedTimeMap = useRef<any>({});
  const [showDefaultAccountSettings, setShowDefaultAccountsSettings] =
    useState(false);
  const [doesDefaultRCMAccountExists, setDoesDefaultRCMAccountExists] =
    useState(false);
  const [tempPrimaryExchangeRate, setTempPrimaryExchangeRate] = useState(
    formData?.primaryExchangeRate
  );
  const canApplySalesPersonFilterOnContact = useAppSelector(
    selectApplySalesPersonFilter
  );

  const [firstChange, setFirstChange] = useState(
    !Utility.isEmpty(props.populateData) ? false : true
  );
  type returnFunction = (index: number) => any;
  const paymentMethods = [
    {
      label: `${getCapitalized(localizedText('cheque'))}`,
      value: 'CHEQUE'
    },
    {
      label: 'Bank Transfer',
      value: 'BANK_TRANSFER'
    },
    {
      label: 'Card',
      value: 'CARD'
    },
    {
      label: 'ACH',
      value: 'ACH'
    }
  ];
  const [records, setRecords] = useState<any>([
    {
      paymentAmount: props.transactionObj
        ? props.transactionObj.transactionAmount
        : '',
      invalidFields: props.transactionObj
        ? ['selectedAccount']
        : ['selectedAccount', 'paymentAmount']
    }
  ]);

  const accountsList = useAppSelector(selectedAccounts);
  const taxesList = useAppSelector(selectTaxes);
  const defaultAccounts = useAppSelector(selectDefaultAccounts);
  const currencyExchangeList = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const [accountCurrency, setAccountCurrency] = useState<any>();
  const [attachments, setAttachments] = useState<any>([]);
  const [gstType, setGstType] = useState<any>(GST_TYPE.INTRA);

  const [showAddContactPopup, setShowAddContactPopup] = useState(false);
  const contactsData = useAppSelector(selectContacts);
  const expenseDepositFormRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [selectedAccount, setSelectedAccount] = useState(props.selectedAccount);
  const [columnConfig, setColumnConfig] = useState(ExpenseDepositColumnConfig);
  const accountsData = useAppSelector(selectedAccounts);
  const taxes = useAppSelector(selectTaxes);
  const [showAddAccountPopup, setShowAddAccountPopup] = useState(false);
  const [showTaxPopup, setShowTaxPopup] = useState(false);
  const [showTaxExchangeRatePopup, setShowTaxExchangeRatePopup] =
    useState(false);
  const [taxGroupDetail, setTaxGroupDetail] = useState([]);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [indexToEdit, setIndexToEdit] = useState(-1);

  const isManualRoundOff = useRef<boolean>(false);
  const [roundOffValue, setRoundOffValue] = useState<any>(0);

  useEffect(() => {
    const initialObj = props.populateData
      ? props.populateData
      : props.formType === DOC_TYPE.DEPOSIT ||
        props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
      ? depositModel
      : props.formType === DOC_TYPE.EXPENSE ||
        props.formType === DOC_TYPE.PREPAYMENT
      ? expenseModel
      : {};

      let primaryExchangeRate = initialObj?.primaryExchangeRate;
      if (
        (primaryExchangeRate === null || primaryExchangeRate === undefined) &&
        getPrimaryCurrencyCheck() &&
        !Utility.isEmpty(currencyExchangeList)
      ) {
        const primaryCurrencyCode =
          tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
        primaryExchangeRate = currencyExchangeList.find(
          (data) => data.currencyCode == primaryCurrencyCode
        )?.currencyExchangeRate;
      }
      initialObj.primaryExchangeRate = primaryExchangeRate;
      setTempPrimaryExchangeRate(primaryExchangeRate);

    if (
      (DOCUMENT_MODE.EDIT === props?.documentMode ||
        DOCUMENT_MODE.VIEW === props?.documentMode ||
        DOCUMENT_MODE.COPY === props?.documentMode) &&
      (props.formType === DOC_TYPE.DEPOSIT ||
        props.formType === DOC_TYPE.EXPENSE) &&
      props?.populateData?.roundOffAmountInBaseCurrency
    ) {
      setRoundOffValue(props?.populateData?.roundOffAmountInBaseCurrency);
      initialObj.roundOffValue =
        props?.populateData?.roundOffAmountInBaseCurrency;
    }
    
    setFormData((prev: any) => ({ ...initialObj, attachmentIds: [] }));
  }, [props.populateData]);

  useEffect(() => {
    getAccountsCFData();
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  useEffect(() => {
    const defaultAccountCl = deepClone(defaultAccounts?.content || []);
    const doesRCmAccountEXists = defaultAccountCl.find(
      (defaults) => defaults.module === DEFAULT_ACCOUNT_MODULE.GL_FOR_ITC_BLOCK
    );
    if (
      !Utility.isEmpty(doesRCmAccountEXists?.accountCode) &&
      doesRCmAccountEXists?.accountCode !== 'NA'
    ) {
      setDoesDefaultRCMAccountExists(true);
    } else {
      setDoesDefaultRCMAccountExists(false);
    }
  }, [defaultAccounts]);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  //////////////////////////////////// custom fields - start ///////////////////////////////

  const expenseColumnConfig = useAppSelector(selectExpenseColumnConfig);
  const expenseTableId = useAppSelector(selectExpenseColumnConfigTableId);

  const depositColumnConfig = useAppSelector(selectDepositColumnConfig);
  const depositTableId = useAppSelector(selectDepositColumnConfigTableId);
  const dimensionData = useAppSelector(selectDimensions);
  const [newAttachments, setNewAttachments] = useState<any[]>([]);
  const [isAccountChanged, setAccountChanged] = useState(false);
  const [currentRowITCInfo, setCurrentRowITCInfo] = useState<any>(null);
  const [showITCPopup, setShowITCPopup] = useState(false);

  const getModuleFromDocType = () => {
    let module: any = '';

    switch (props.formType) {
      case DOC_TYPE.PREPAYMENT:
      case MODULES_NAME.EXPENSE:
        return 'EXPENSE';
      case DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT:
      case DOC_TYPE.DEPOSIT:
        return 'DEPOSIT';
      default:
        break;
    }

    return module;
  };

  const getColumnConfigFromDocType = () => {
    let columnConfigInfo: { tableId?: any; columnConfig?: any } = {};

    switch (props.formType) {
      case DOC_TYPE.PREPAYMENT:
      case MODULES_NAME.EXPENSE:
        columnConfigInfo = {
          columnConfig: expenseColumnConfig,
          tableId: expenseTableId
        };
        break;
      case DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT:
      case DOC_TYPE.DEPOSIT:
        columnConfigInfo = {
          columnConfig: depositColumnConfig,
          tableId: depositTableId
        };
        break;
      default:
        break;
    }

    return columnConfigInfo;
  };

  const customFieldUpdated = (cfList: any[]) => {
    const updatedState = { ...formData };
    let cfParsed = cfList.map((item: any) => {
      return {
        code: item.code,
        label: item.label,
        mandatory: item.mandatory,
        module: item.module,
        status: item.status,
        value: item.value,
        id: item.id
      };
    });
    updatedState.customField = cfParsed;
    setFormData({ ...updatedState });
  };

  const getCustomFieldView = () => {
    return (
      <div className="column parent-width mt-2">
        <CustomFieldsHolder
          moduleName={getModuleFromDocType()}
          customFieldsList={
            !Utility.isEmpty(props.populateData?.customField)
              ? [...props.populateData.customField]
              : []
          }
          columnConfig={getColumnConfigFromDocType().columnConfig}
          columnConfigTableId={getColumnConfigFromDocType().tableId}
          onUpdate={(list) => customFieldUpdated(list)}
        />
      </div>
    );
  };

  /////////////////////////////////// custom fields - end ////////////////////////////////
  useEffect(() => {
    let isIndiaTenantAndRcmDoc =
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      RCM_DOCUMENTS.includes(props?.populateData?.formType)
        ? true
        : false;

    if (isIndiaTenantAndRcmDoc) {
      let isRCMApplicable =false; 
      if (!!props?.populateData?.applyRcmCheck) {
        isRCMApplicable = props?.populateData?.applyRcmCheck;
      } else {
        isRCMApplicable = false;
      }

      setFormData((prev: any) => {
        return { ...prev, applyRcmCheck: isRCMApplicable };
      });
    }
  }, [props?.populateData?.applyRcmCheck, props?.populateData?.formType]);

  useEffect(() => {
    var tmpTaxGroupDetail: any = [];
    if (!Utility.isEmpty(records)) {
      records.forEach((element: any) => {
        if (!Utility.isEmpty(element?.selectedTaxCode)) {
          if (Utility.isEmpty(element?.selectedTaxCode.taxGroupDetails)) {
            let taxobj = {
              [element?.selectedTaxCode.name]: element.taxAmount
            };
            tmpTaxGroupDetail.push(taxobj);
          } else {
            let arr = calculateTaxGroupDetails(element);
            tmpTaxGroupDetail.push(...arr['taxgrpDetail']);
          }
        }
      });
      if (
        (props.formType === DOC_TYPE.EXPENSE ||
          props.formType === DOC_TYPE.DEPOSIT) &&
        Store.getState().authInfo.currentTenantInfo.data.additionalSettings
          ?.ROUND_OFF?.autoRoundOff &&
        !isManualRoundOff.current
      ) {
        roundOffCalculation();
      }
    }
    setTaxGroupDetail(tmpTaxGroupDetail);
  }, [records]);

  useEffect(() => {
    if (currentRowITCInfo) {
      setShowITCPopup(true);
    }
  }, [currentRowITCInfo]);

  const calculateTaxGroupDetails = (element: any) => {
    let tmpTaxGroupDetail: any = [];
    let taxPercent: any = element?.selectedTaxCode?.percent || 0;
    let paymentAmount: any = element?.paymentAmount || 0;
    let totalTaxAmtPre: any = 0;
    let totalTaxAmtAfter: any = 0;
    let sumTaxRatesPre: any = 0;
    let preTaxComponents: any[] = [];
    let afterTaxComponents: any[] = [];
    let preTaxComponentsCount: any = 0;
    let adjustTaxAmountInPreTaxLastComponent: boolean = false;
    let adjustTaxAmountInAfterTaxLastComponent: boolean = false;
    let tmpTaxList: any = [];
    if (unitPriceGstInclusive) {
      paymentAmount = Utility.roundingOff(
        (Number(element?.paymentAmount) * 100) /
          Number(100 + Number(element?.selectedTaxCode?.percent)),
        tenantInfo.decimalScale
      );
    }

    preTaxComponents = element?.selectedTaxCode?.taxGroupDetails?.filter(
      (ele: any) => ele.applyTo === APPLY_TO_TYPE.PRE
    );
    afterTaxComponents = element?.selectedTaxCode?.taxGroupDetails?.filter(
      (ele: any) =>
        ele.applyTo === APPLY_TO_TYPE.AFTER ||
        ele.applyTo === APPLY_TO_TYPE.POST
    );

    if (Utility.isEmpty(afterTaxComponents) && unitPriceGstInclusive) {
      adjustTaxAmountInPreTaxLastComponent = true;
    } else if (unitPriceGstInclusive) {
      adjustTaxAmountInAfterTaxLastComponent = true;
    }

    preTaxComponents?.forEach((pretax: any) => {
      sumTaxRatesPre += pretax.percentage;
      let tempTaxAmtPre = 0;
      if (
        adjustTaxAmountInPreTaxLastComponent &&
        preTaxComponentsCount === preTaxComponents.length - 1
      ) {
        const totalTaxAmount = Utility.roundingOff(
          element.paymentAmount - paymentAmount,
          tenantInfo.decimalScale
        );

        tempTaxAmtPre = Utility.roundingOff(
          totalTaxAmount - totalTaxAmtPre,
          tenantInfo.decimalScale
        );
        let taxobj = {
          [pretax.name]: tempTaxAmtPre
        };
        tmpTaxGroupDetail.push(taxobj);
        let taxDetail = getTaxDetail(pretax, paymentAmount);
        taxDetail.taxAmount = tempTaxAmtPre;
        tmpTaxList.push(taxDetail);
      } else {
        let taxAmount = paymentAmount * (pretax.percentage / 100);
        if (tenantInfo?.country === COUNTRY_CODES.UK) {
          let editedTax = element?.taxList?.find(
            (taxListElement: any) => taxListElement?.taxSeqCode === pretax?.code
          );
          if (editedTax) {
            taxAmount = editedTax?.taxAmount ?? 0;
          }
        }
        tempTaxAmtPre = Utility.roundingOff(taxAmount, tenantInfo.decimalScale);
        let taxobj = {
          [pretax.name]: taxAmount
        };
        tmpTaxGroupDetail.push(taxobj);
        let taxDetail = getTaxDetail(pretax, paymentAmount);
        taxDetail.taxAmount = taxAmount;
        if (taxDetail) {
          tmpTaxList.push(taxDetail);
        }
      }
      totalTaxAmtPre = totalTaxAmtPre + tempTaxAmtPre;
      preTaxComponentsCount = preTaxComponentsCount + 1;
    });

    let tempTaxAmtAfter = 0;
    if (adjustTaxAmountInAfterTaxLastComponent) {
      const totalTaxAmount = Utility.roundingOff(
        element.paymentAmount - paymentAmount,
        tenantInfo.decimalScale
      );

      tempTaxAmtAfter = Utility.roundingOff(
        totalTaxAmount - totalTaxAmtPre,
        tenantInfo.decimalScale
      );
      let taxDetail = getTaxDetail(afterTaxComponents[0], tempTaxAmtAfter);
      tmpTaxList.push(taxDetail);
    } else {
      if (afterTaxComponents?.[0]) {
        tempTaxAmtAfter = Utility.roundingOff(
          (paymentAmount * afterTaxComponents?.[0]?.percentage) / 100,
          tenantInfo.decimalScale
        );
        tempTaxAmtAfter = Utility.roundingOff(
          tempTaxAmtAfter * (1 + sumTaxRatesPre / 100),
          tenantInfo.decimalScale
        );
        let taxDetail =
          afterTaxComponents?.[0] &&
          getTaxDetail(afterTaxComponents?.[0], tempTaxAmtAfter);
        if (tenantInfo.country === COUNTRY_CODES.UK) {
          let updatedTaxDetail = element?.taxList?.find(
            (taxListElement: any) =>
              taxListElement?.taxSeqCode === afterTaxComponents?.[0]?.code
          );
          if (updatedTaxDetail) {
            taxDetail = { ...taxDetail, taxAmount: updatedTaxDetail.taxAmount };
          }
        }
        let taxobjAfter = {
          [taxDetail.taxName]: taxDetail?.taxAmount
        };
        totalTaxAmtPre = totalTaxAmtPre + taxDetail?.taxAmount;
        tmpTaxGroupDetail.push(taxobjAfter);
        if (taxDetail) {
          tmpTaxList.push(taxDetail);
        }
      }
    }
    totalTaxAmtAfter = Utility.roundingOff(
      totalTaxAmtAfter + tempTaxAmtAfter,
      tenantInfo.decimalScale
    );

    let returnObj: any = {};
    if (element?.selectedTaxCode?.isTaxGroup) {
      returnObj['taxgrpDetail'] = tmpTaxGroupDetail;
      returnObj['taxAmount'] = Utility.roundingOff(
        totalTaxAmtPre,
        tenantInfo.decimalScale
      );
      returnObj['lineLevelTotal'] = Utility.roundingOff(
        totalTaxAmtPre + paymentAmount,
        tenantInfo.decimalScale
      );
      returnObj['taxList'] = tmpTaxList;
    } else {
      let taxAmount = Utility.roundingOff(paymentAmount * (taxPercent / 100));
      if (getTenantTaxSystem() === TAX_SYSTEM.US && props.formType === DOC_TYPE.DEPOSIT) {
        taxAmount = element.taxAmount || 0
      }

      returnObj['lineLevelTotal'] = unitPriceGstInclusive
        ? element.paymentAmount
        : element.paymentAmount + taxAmount;
      returnObj['taxAmount'] = Utility.roundingOff(
        taxAmount,
        tenantInfo.decimalScale
      );
      let taxDetail =
        element?.selectedTaxCode &&
        getTaxDetail(element?.selectedTaxCode, paymentAmount);
      if (taxDetail) {
        tmpTaxList.push(taxDetail);
      }
      returnObj['taxList'] = tmpTaxList;
    }
    let lineGstType = element?.gstType ? element?.gstType : gstType;
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      lineGstType === GST_TYPE.EXEMPT
    ) {
      returnObj['lineLevelTotal'] = element.paymentAmount;
      returnObj['taxAmount'] = 0;
    }

    return returnObj;
  };

  useEffect(() => {
    updateConfig();
  }, [accountsData, taxes, dimensionData]);

  useEffect(() => {
    const newRecord:any = []
    records.forEach((item: any, index: any) => {
      newRecord.push(updateLineItemList(item, index))
      updateLineItemList(item, index)
    });
    setRecords(newRecord);
  }, [unitPriceGstInclusive, formData?.applyRcmCheck]);

  const getPreferredShippingState = () => {
    let filtered = contact?.shippingAddress?.filter(
      (add: any) => add.preferred
    );
    if (!Utility.isEmpty(filtered)) {
      return filtered[0]?.state;
    }
  };

  const isBankType = () => {
    if (Utility.isEmpty(selectedAccount)) {
      return false;
    } else {
      return selectedAccount?.accountGroup.toLowerCase() === 'bank';
    }
  };

  const isTenantAndCurrencySg = (): boolean => {
    return (
      tenantInfo &&
      tenantInfo.currency === CURRENCIES.SG &&
      tenantInfo.country === COUNTRY_CODES.SG
    );
  };

  const showGstCurrencyTax = () => {
    return isGSTExchangeRateRequired(
      tenantInfo && tenantInfo.country,
      tenantInfo && tenantInfo.currency
    );
  };

  const setFilteredAccountsData = () => {
    let filterAccounts =
      tenantInfo?.additionalSettings?.ACCOUNT?.showCardCashBankAccountsOnly;
    if (filterAccounts) {
      let filtered = accountsList?.content?.filter(
        (acc: any) =>
          (['Cash', 'Bank'].includes(acc.accountGroup) ||
            (acc.accountGroup === 'Current Liabilities' &&
              acc.isCreditCard === true)) &&
          acc.status === 'ACTIVE'
      );
      setFilteredAccounts(filtered);
    } else {
      setFilteredAccounts(accountsList?.content);
    }
  };

  useEffect(() => {
    if (Utility.isEmpty(tenantInfo)) return;
    if (Utility.isEmpty(contact)) return;
    let gstType = ExpenseDepositHelper.getCustomerGstType(
      tenantInfo,
      getPreferredShippingState(),
      contact.customerType,
      contact.gstTreatment
    );
    setGstType(gstType);

    if (!Utility.isEmpty(records)) {
      const lineItems = records.map((item: any) => {
        return {
          ...item,
          gstType: gstType
        };
      });
      setRecords(lineItems);
    }

    if (
      tenantInfo.multicurrencyEnabled ||
      isGSTExchangeRateRequired(getTenantTaxSystem(), tenantInfo.currency)
    ) {
      if (COMPLAINCE_CURRENCY[AuthService.userDetails?.country]) {
        const currencyDetails = currencyExchangeList.find(
          (x: any) =>
            x.currencyCode ===
            COMPLAINCE_CURRENCY[AuthService.userDetails?.country]
        );
        if (!formData.id && currencyDetails) {
          const gstExchangeRate = currencyDetails.currencyExchangeRate;
          setFormData({
            ...formData,
            gstExchangeRate: 1 / gstExchangeRate
          });
        }
      }
    }

    if (
      !isTenantAndCurrencySg() &&
      showGstCurrencyTax() &&
      !Utility.isEmpty(contact)
    ) {
      let currencyCode = contact.currencyCode;
      if (isGSTExchangeRateRequired(getTenantTaxSystem(), currencyCode)) {
        setShowTaxExchangeRatePopup(true);
      }
    }
    if (Utility.isNotEmpty(records)) {
      const updatedRecords: any = [];
      records.forEach((line: any, index: any) => {
        updatedRecords.push(updateLineItemList(line, index));
      });
      setRecords(updatedRecords);
    }
    updateConfig();
    if (
      getTenantTaxSystem() === TAX_SYSTEM.US &&
      props.formType === DOC_TYPE.DEPOSIT
    ) {
      if (firstChange) {
        calculateUSTax(records);
      } else {
        setFirstChange(true);
      }
    }
    setFilteredAccountsData();
    return () => {};
  }, [contact, tenantInfo]);

  useEffect(() => {
    let updatedRecords = [...records].map((item: any) => {
      let edited = { ...item, gstType: gstType };
      edited = calculateTaxBifurcation(edited);
      return edited;
    });
    setRecords(updatedRecords);
    return () => {};
  }, [gstType]);

  useEffect(() => {
    if (selectedAccount) {
      const filterred = accountsList?.content?.filter(
        (item: any) => selectedAccount.currency === item.currencyCode
      );
      if (filterred && filterred?.length > 0) {
        let newState = { ...formData };
        newState.currencyName = filterred[0].currencyName;
        newState.currency = filterred[0].currencyCode;
        setFormData({ ...newState });
      }
    }
  }, [selectedAccount]);

  const getDocumentCurrency = () => {
    return selectedAccount
      ? selectedAccount.currency
      : tenantInfo
      ? tenantInfo?.currency
      : '';
  };

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };

  const handleAndUpdateRCMCheck = (check: boolean) => {
    if (check === true) {
      if (doesDefaultRCMAccountExists === true) {
        setFormData((value: any) => ({
          ...value,
          applyRcmCheck: check
        }));
      } else {
        const defaultAccountUpdateBtn = [
          {
            title: 'Cancel',
            className: 'bg-gray-2 border-m',
            onClick: () => {}
          },
          {
            title: 'Yes, Update',
            className: 'bg-blue text-white ml-r',
            onClick: () => {
              setShowDefaultAccountsSettings(true);
            }
          }
        ];
        showAlert(
          'Alert',
          'Please map default RCM account',
          defaultAccountUpdateBtn
        );
      }
    } else {
      setFormData((value: any) => ({
        ...value,
        applyRcmCheck: check
      }));
    }
  };

  const getClassDimensionData = () => {
    if (dimensionData) {
      const classData = dimensionData?.content?.find(
        (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
      );
      const classFields: any[] = classData?.attributes?.map((attr: any) => {
        return {
          id: classData.id,
          shortName: classData.shortName,
          module: classData.module,
          code: classData.code,
          label: classData.label,
          value: attr.value
        };
      });
      return classFields;
    }
  };

  const getRCMTootltipContent = (data: any) => {
    let contentString: string = '<div>';
    data.map((item: any) => {
      return Object.entries(item).map(([key, value]) => {
        contentString += `<p>${key} - `;
        contentString += `${NumberFormatService.getNumber(
          value ? (value as number) : 0
        )}</p>`;
      });
    });
    contentString += '</div>';
    return contentString;
  };

  const updateConfig = () => {
    let config = columnConfig;
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'selectedAccount':
          let newData = Utility.getAccountsStructured(accountsData?.content);
          ConfigUtility.fillStructuredAccountArray(newData);
          let newStructuredData = ConfigUtility.structuredAccountsArr?.filter(
            (item: any) => item.status === STATUS_TYPE.ACTIVE
          );
          conf.dropdownConfig.data = newStructuredData;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            const Query = 'status=active';
            return ExpenseDepositHelper.getAccountURL(search, Query);
          };
          conf.dropdownConfig.button =
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.COA.CREATE
            )
              ? {
                  title: '+ Add Account',
                  className: 'bg-button text-white',
                  onClick: () => {}
                }
              : null;
          if (conf.dropdownConfig.button) {
            conf.dropdownConfig.button.onClick = () =>
              setShowAddAccountPopup(true); //show add Account
          }

          break;
        case 'paymentAmount':
          conf.formatter = (data: any) =>
            NotesConfigUtility.amountFormatter(
              data.value,
              getDocumentCurrency()
            );
          break;
        case 'selectedTaxCode':
          conf.hidden = getTenantTaxSystem() === TAX_SYSTEM.US;
          let taxList: any = taxes.filter((taxItem: any) => {
            if (
              taxItem.effectiveEndDate !== undefined &&
              taxItem.effectiveEndDate !== null
            ) {
              if (
                DateFormatService.getDateFromStr(
                  formData?.documentDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ) >
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ) &&
                DateFormatService.getDateFromStr(
                  formData?.documentDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ) <
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveEndDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  )
              ) {
                return taxItem;
              }
            } else {
              if (
                DateFormatService.getDateFromStr(
                  formData?.documentDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ) >
                DateFormatService.getDateFromStr(
                  taxItem.effectiveStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              ) {
                return taxItem;
              }
            }
          });
          conf.dropdownConfig.data = taxList?.length
            ? NotesConfigUtility.taxDataParser(taxList, props.formType)
            : [];

          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            NotesConfigManager.getTaxURL(
              search,
              !Utility.isEmpty(formData?.documentDate)
                ? formData?.documentDate
                : DateFormatService.getDateStrFromDate(
                    new Date(),
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  )
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            NotesConfigUtility.taxDataParser(data, props.formType);
          conf.dropdownConfig.data = taxList?.length
            ? ExpenseDepositHelper.taxDataParser(
                { content: taxList },
                props.formType
              )
            : [];
          conf.dropdownConfig.button.onClick = () => setShowTaxPopup(true); //Show Create Tax Popup
          break;
        case 'taxAmount':
          conf.renderer = (data: any) => {
            if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
              let itcIneligibleType: string = data?.rowData?.itcIneligibleType;
              let records = data?.rowData?.taxBifurcation || [];
              if (
                data?.rowData?.isRcmApplied &&
                props.formType === MODULES_NAME.EXPENSE
              ) {
                return (
                  <div
                    style={{ width: '100%' }}
                    className="column m-s z-index-3"
                  >
                    <div className="row  justify-content-between">
                      <div className="column">
                        <DKLabel className="text-align-left" text={'NA'} />
                        <DKLabel
                          className="text-align-left text-gray fw-r fs-s"
                          text={`RCM ${
                            data?.rowData?.selectedTaxCode?.percent
                              ? data?.rowData?.selectedTaxCode?.percent
                              : 0
                          }%`}
                        />
                      </div>
                      {!Utility.isEmpty(records) && (
                        <DKTooltipWrapper
                          content={getRCMTootltipContent(records)}
                          tooltipClassName="bg-deskera-secondary"
                        >
                          <DKIcon
                            src={DKIcons.ic_info}
                            className="ic-xs mr-l ic-r opacity-60 cursor-pointer"
                          />
                        </DKTooltipWrapper>
                      )}
                    </div>
                    {renderItcIneligibleSection(itcIneligibleType)}
                  </div>
                );
              } else {
                return (
                  <div className="column">
                    {records.map((item: any) => {
                      return Object.entries(item).map(([key, value]) => {
                        return (
                          <div className="row gap-2 justify-content-start">
                            <DKLabel className="text-align-left" text={key} />
                            <DKLabel
                              className="text-align-right"
                              text={NumberFormatService.getNumber(
                                value ? (value as number) : 0
                              )}
                            />
                          </div>
                        );
                      });
                    })}
                    {renderItcIneligibleSection(itcIneligibleType)}
                  </div>
                );
              }
            } else {
              return (
                <DKLabel
                  className="parent-width text-align-right"
                  text={`${Utility.getCurrencySymbolFromCode(
                    getDocumentCurrency()
                  )} ${NumberFormatService.getNumber(
                    data?.rowData.taxAmount ? data?.rowData.taxAmount : 0
                  )}`}
                />
              );
            }
          };
          if (
            (tenantInfo?.country === COUNTRY_CODES.UK ||
              tenantInfo?.country === COUNTRY_CODES.US) &&
            (props.formType === DOC_TYPE.DEPOSIT ||
              props.formType === DOC_TYPE.EXPENSE ||
              props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT)
          ) {
            conf.editable = true;
          } else if (tenantInfo?.country === COUNTRY_CODES.US &&
            props.formType === DOC_TYPE.PREPAYMENT) {
            conf.editable = true;
          } else {
            conf.editable = false;
          }
          break;
        case 'totalAmount':
          conf.formatter = (data: any) =>
            NotesConfigUtility.amountFormatter(
              data.value,
              getDocumentCurrency()
            );
          break;
        default:
          const classData = dimensionData?.content?.find(
            (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
          );

          if (classData && classData.id === conf.id) {
            conf.hidden = hideClassColumn();
            conf.dropdownConfig.button = {
              title: '+ Add New',
              className: 'bg-button text-white',
              onClick: () => setShowAddClassPopup(true)
            };
          }

          break;
      }
    });
    setColumnConfig(config.filter((col: any) => !col.hidden));
  };

  const closeCreateView = (data: any = {}) => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP,
        data: data
        // show variantform
      });
    }
  };

  const parseResponseObject = (response: any, transactionType: string) => {
    let docType = '';
    let cdType = '';
    if (transactionType === 'expense') {
      if (
        response.makePaymentItemDtoList &&
        response.makePaymentItemDtoList.length > 0
      ) {
        docType = response.makePaymentItemDtoList[0].documentType;
      }
      cdType = 'CREDIT';
    }
    if (transactionType === 'deposit') {
      if (
        response.receivePaymentItemDtoList &&
        response.receivePaymentItemDtoList.length > 0
      ) {
        docType = response.receivePaymentItemDtoList[0].documentType;
      }
      cdType = 'DEBIT';
    }
    return {
      documentCode: response.code,
      documentType: docType,
      contactName: response.contactName,
      referenceDate: DateFormatService.getDateStrFromDate(
        referenceDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      referenceNumber: response.referenceNumber,
      // transactionAmount: response.amountDue,
      transactionAmount: response.amount,
      accountCode: selectedAccount.code,
      cdType: cdType,
      currency: response.currency
    };
  };

  const setApiCallInProgress = (value: boolean) => {
    if (value) {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
    } else {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.ENABLE_FORM
        });
      }
    }
  };

  const validateAccountsBudget = (doc: any) => {
    const accountsAndCFInfo = getAccountsAndCFForBudgetValidation(
      [...(doc?.makePaymentItemDtoList || [])],
      doc?.customField,
      'documentCode',
      'selectedTaxCode',
      accountCurrency?.currencyExchangeRate
        ? accountCurrency?.currencyExchangeRate
        : 1,
      false
    );
    const allAccountsData = accountsAndCFInfo?.accountsInfo;
    const customField = accountsAndCFInfo?.customField;

    if (allAccountsData.length) {
      validateBudgetForAccounts(
        DateFormatService.getFormattedDateString(
          doc.documentDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD'],
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        allAccountsData,
        customField,
        props.populateData?.jeCode ? props.populateData?.jeCode : null
      ).then(
        (budgetResp: { data: any[]; limitCrossed: boolean }) => {
          if (budgetResp.limitCrossed) {
            let buttons = [
              {
                title: 'Cancel',
                className: 'bg-gray2 border-m ',
                onClick: () => {
                  setApiCallInProgress(false);
                }
              },
              {
                title: 'Proceed',
                className: 'bg-button text-white ml-r',
                onClick: () => {
                  makeFinalAPICall(doc);
                }
              }
            ];
            let message =
              '<ul class="text-align-left" style="list-style-type: disc; margin-left: 5px;">';
            budgetResp.data?.forEach((alertData: any) => {
              message += `<li>${alertData.message}</li>`;
            });
            message +=
              '<div class="text-align-center mt-l">Do you want to continue?</div>';
            message += '</ul>';
            showAlert('Warning!', message, buttons);
          } else {
            makeFinalAPICall(doc);
          }
        },
        (err) => {
          console.error('Error validating accounts budget: ', err);
          setApiCallInProgress(false);
        }
      );
    }
  };

  const getBaseToSelectedCurrencyConversionValue = (): number => {
    return parseFloat((1 / accountCurrency?.currencyExchangeRate).toFixed(6));
  };

  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = [...columnConfig];
    let colConfigsWithOnlyCF = oldColConfigs.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(accountsCFData)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = accountsCFData.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              lineItem[cf.id],
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'ACCOUNT',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };

  const createExpenseDeposit = () => {
    let reqObject = { ...formData };
    if (
      props.formType === DOC_TYPE.PREPAYMENT ||
      props.formType === MODULES_NAME.EXPENSE
    ) {
      reqObject = { ...reqObject, memo: encodeURIComponent(reqObject.memo) };
    }
    reqObject.accountName = selectedAccount.name;
    reqObject.exchangeRate =
      reqObject.exchangeRate || getBaseToSelectedCurrencyConversionValue();
    reqObject.currency = selectedAccount.currency;
    reqObject.unitPriceGstInclusive = unitPriceGstInclusive;
    reqObject.receivePaymentFeeDtoList =
      reqObject?.receivePaymentFeeDtoList?.map((item: any) => ({
        accountCode: item.accountCode,
        amount: item.amount
      }));
    reqObject.customerTypeIndia = contact?.customerType;
    reqObject.vendorTypeIndia = contact?.vendorType;
    reqObject.gstTreatmentIndia = contact?.gstTreatment;
    reqObject.gstIn = contact?.gstin;
    if (
      (props.formType === DOC_TYPE.DEPOSIT ||
      props.formType === DOC_TYPE.EXPENSE) &&
      reqObject?.roundOffValue
    ) {
      reqObject.roundOffAmountInDocumentCurrency = parseFloat(reqObject?.roundOffValue);
      reqObject.roundOffAmountInBaseCurrency = parseFloat(reqObject?.roundOffValue);
      reqObject.amount = reqObject.amount + parseFloat(reqObject?.roundOffValue);
    }
    if (
      props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT ||
      props.formType === DOC_TYPE.DEPOSIT
    ) {
      reqObject.accountCodePayTo = selectedAccount.code;
      reqObject.receivePaymentItemDtoList = records.map((item: any) => {
        let taxObj = calculateTaxGroupDetails(item);

        return {
          ...item,
          customField: getLineItemCFs(item),
          taxList: taxObj['taxList'],
          documentType: props.formType
        };
      });
      setApiCallInProgress(true);
      BanksService.desposit(reqObject)
        .then(
          (res: any) => {
            props.recordCreate &&
              props.recordCreate(parseResponseObject(res, 'deposit'));
            closeCreateView();
            setApiCallInProgress(false);
          },
          (err: any) => {
            setApiCallInProgress(false);
          }
        )
        .catch((err: any) => {
          setApiCallInProgress(false);
        });
    }
    if (
      props.formType === DOC_TYPE.PREPAYMENT ||
      props.formType === DOC_TYPE.EXPENSE
    ) {
      reqObject.accountCodePayFrom = selectedAccount.code;
      reqObject.makePaymentItemDtoList = records.map((item: any) => {
        let taxObj = calculateTaxGroupDetails(item);

        if (
          item?.itcAdjustment !== '' &&
          undefined !== item?.itcAdjustment &&
          null !== item?.itcAdjustment &&
          'NA' !== item?.itcAdjustment
        ) {
          item.itcAdjustment = item.itcAdjustment;
        } else {
          delete item?.itcAdjustment;
        }

        if (props.formType === DOC_TYPE.EXPENSE && item?.isRcmApplied) {
          item.taxAmount = 0;
          item.igstRate = 0;
          item.sgstRate = 0;
          item.cgstRate = 0;
        }
        if (Utility.isNotEmpty(item?.selectedAccount?.glAccountCode)) {
          item.glAccountCode = item?.selectedAccount?.glAccountCode;
        } else {
          delete item.glAccountCode;
        }

        return {
          ...item,
          customField: getLineItemCFs(item),
          taxList: taxObj['taxList'],
          documentType: props.formType
        };
      });

      setApiCallInProgress(true);
      validateAccountsBudget(reqObject);
    }
  };

  const makeFinalAPICall = (payload: any) => {
    BanksService.expense(payload)
      .then(
        (res: any) => {
          props.recordCreate &&
            props.recordCreate(parseResponseObject(res, 'expense'));
          closeCreateView();
          setApiCallInProgress(false);
        },
        (err: any) => {
          setApiCallInProgress(false);
        }
      )
      .catch((err: any) => {
        setApiCallInProgress(false);
      });
  };

  const validate = () => {
    if (
      (props.formType === DOC_TYPE.PREPAYMENT ||
        props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT) &&
      Utility.isEmpty(contact)
    ) {
      return false;
    }
    if (Utility.isEmpty(selectedAccount)) {
      return false;
    }
    if (isBankType()) {
      if (Utility.isEmpty(formData?.paymentType)) {
        return false;
      }
      // if (Utility.isEmpty(formData?.referenceNumber)) {
      //   return false;
      // }
    }
    let docType =
      props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT ||
      props.formType === DOC_TYPE.DEPOSIT
        ? DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
        : DOC_TYPE.EXPENSE;
    if (!Utility.isEmpty(formData?.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        formData?.documentDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Payment date',
          docType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, 'Payment date')) {
        return false;
      }
    }

    if (Utility.isEmpty(records)) {
      setRecords([
        ...records,
        {
          invalidFields: ['selectedAccount', 'paymentAmount']
        }
      ]);
      return false;
    }
    let ifAccountIsEmpty = records.reduce(function (add: any, current: any) {
      return add || Utility.isEmpty(current.documentCode);
    }, false);

    let ifAmountIsEmpty = records.some(function (record: any) {
      return (
        !record.paymentAmount ||
        Number(NumberFormatService.getNumber(record.paymentAmount)) === 0
      );
    }, false);

    if (ifAccountIsEmpty || ifAmountIsEmpty) {
      return false;
    }

    if (
      formData?.receivePaymentFeeDtoList &&
      formData?.receivePaymentFeeDtoList.length > 0
    ) {
      let feeAmounts = formData?.receivePaymentFeeDtoList?.[0]
      if(!isValidTransactionFeeValue(feeAmounts?.amount)){
        return;
      }
      let item = formData?.receivePaymentFeeDtoList[0];
      if (Utility.isEmpty(item.accountCode)) {
        return false;
      }
      if (
        item.amount === undefined ||
        item.amount === null ||
        item.amount === 0
      ) {
        return false;
      } else {
        // apply check for fee amount should not be greater than the total amount
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      formData?.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    return true;
  };

  const isTransactionFeeValueNotEmpty = (value: string | number) =>
    value !== undefined && value !== null;
  const isValidTransactionFeeValue = (value: string | number) => {
    let total = NumberFormatService.getNumber(
      getSubtotal() + getTotalTax() - totalTdsAmount
    );
    return (
      isTransactionFeeValueNotEmpty(value) &&
      Utility.isNumber(value) &&
      parseFloat(String(value)) > 0 &&
      parseFloat(String(total)) - parseFloat(String(value)) > 0
    );
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_EXPENSE_DEPOSITE,
        data: () => {
          setCanValidate(true);
          if (validate()) {
            createExpenseDeposit();
          }
        }
      });
  };

  useEffect(() => {
    let sum = records.reduce(function (add: any, current: any) {
      return (
        parseFloat(add) +
        (current.totalAmount ? parseFloat(current.totalAmount) : 0)
      );
    }, 0);
    setFormData((prevForm: any) => {
      return {
        ...prevForm,
        amount: sum
      };
    });
    let totalTDS = 0;
    records.forEach((item: any, rowIndex: any) => {
      if (!Utility.isEmpty(item) && !Utility.isEmpty(item.tdsInfoIndia)) {
        totalTDS += item.tdsInfoIndia.tdsAmount;
      }
    });
    setTotalTdsAmount(totalTDS);
    if (totalTDS > 0) {
      setShowTds(true);
    }
  }, [records]);

  const setCurrencyObject = () => {
    let currency = selectedAccount
      ? selectedAccount.currency
      : tenantInfo?.currency;
    const filterred = currencyExchangeList.filter(
      (item: any) => currency === item.currencyCode
    );
    if (filterred.length > 0) {
      setAccountCurrency(filterred[0]);
    }
  };

  useEffect(() => {
    setFilteredAccountsData();
  }, [accountsList]);

  useEffect(() => {
    if (accountCurrency) {
      let newState = { ...formData };
      if (!formData?.id || isAccountChanged) {
        newState.exchangeRate = getBaseToSelectedCurrencyConversionValue();
        setFormData({ ...newState });
        setAccountChanged(false);
      }
    }
  }, [accountCurrency]);

  const findAndSetSelectedAccount = () => {
    const filterred =
      accountsList &&
      accountsList.content &&
      props.location &&
      accountsList.content.filter(
        (item: any) => props.location.search.slice(1) === item.code
      );

    if (filterred && filterred.length > 0) {
      setSelectedAccount(filterred[0]);
    }
  };

  const checkIfSelectedAccountIsEmpty = () => {
    if (Utility.isEmpty(selectedAccount)) {
      findAndSetSelectedAccount();
    }
  };

  const getTaxObject = (taxCode: any) => {
    let filtered = taxes.filter((item: any) => taxCode === item.code);
    if (!Utility.isEmpty(filtered)) {
      return filtered[0];
    } else {
      return null;
    }
  };

  const getAccountObject = (accCode: any) => {
    let filtered = accountsList?.content?.filter(
      (item: any) => accCode === item.code
    );
    if (!Utility.isEmpty(filtered)) {
      return filtered[0];
    } else {
      return null;
    }
  };

  const loadSelectedContact = (code: string) => {
    ContactService.fetchContactByContactCode(code)
      .then(
        (res: any) => {
          if (res && res.content && res.content.length > 0) {
            setContact({
              ...res.content[0],
              name:
                props.populateData?.contactName ?? res.content[0]?.name ?? ''
            });
          }
        },
        (err: any) => {}
      )
      .catch((err: any) => {});
  };

  const setDataForEdit = () => {
    let currentRecords: any;
    if (props.populateData) {
      if (props.populateData.documentDate) {
        let paymentDate = DateFormatService.getDateFromStr(
          props.populateData.documentDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        setPaymentDate(paymentDate);
      }
      if (props.populateData.referenceDate) {
        let referenceDate = DateFormatService.getDateFromStr(
          props.populateData.referenceDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        );
        setReferenceDate(referenceDate);
      }

      if (
        props.formType === DOC_TYPE.DEPOSIT ||
        props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
      ) {
        currentRecords = props.populateData.receivePaymentItemDtoList;
        setSelectedAccount(
          getAccountObject(props.populateData.accountCodePayTo)
        );
      } else if (
        props.formType === DOC_TYPE.EXPENSE ||
        props.formType === DOC_TYPE.PREPAYMENT
      ) {
        currentRecords = props.populateData.makePaymentItemDtoList;
        setSelectedAccount(
          getAccountObject(props.populateData.accountCodePayFrom)
        );
      }

      currentRecords = currentRecords?.map((item: any) => {
        // taxCode, documentCode;
        let editedItem = { ...item };
        editedItem.selectedTaxCode = getTaxObject(editedItem.taxCode);
        editedItem.selectedAccount = getAccountObject(editedItem.documentCode);
        editedItem.totalAmount =
          editedItem.taxAmount + editedItem.paymentAmount;

        editedItem = calculateTaxBifurcation(editedItem);
        return editedItem;
      });
      setRecords(currentRecords);
      props.populateData.contactCode &&
        loadSelectedContact(props.populateData.contactCode);
      setUnitPriceGstInclusive(
        props.populateData.unitPriceGstInclusive || false
      );
    }
  };

  useEffect(() => {
    if (!Utility.isEmpty(selectedAccount)) {
      setCurrencyObject();
      updateConfig();
    }
    setCurrencyObject();
  }, [selectedAccount]);

  const addCFinColumnConfig = (oldConfigs: any[]) => {
    let oldColConfigs = [...oldConfigs];

    if (!Utility.isEmpty(accountsCFData)) {
      let accCustomFields = accountsCFData;
      accCustomFields = accCustomFields.filter(
        (ele: any) => ele.label !== LOCATION_CLASS_ENUM.LOCATION
      );
      accCustomFields?.forEach((accCF: any) => {
        const newItem = getNewColumn(accCF);
        const newItemInExistingColConfig = oldColConfigs.find(
          (config: any) => config.code === accCF.code
        );
        if (Utility.isEmpty(newItemInExistingColConfig)) {
          oldColConfigs.push({
            ...newItem,
            hidden:
              accCF.label === LOCATION_CLASS_ENUM.CLASS
                ? hideClassColumn()
                : newItem.hidden
          });
        }
      });
      setCustomFieldsData(accCustomFields);
      oldColConfigs = oldColConfigs.filter((col: any) => !col.hidden);
    }
    return oldColConfigs;
  };

  useEffect(() => {
    if (!Utility.isEmpty(accountsCFData)) {
      setColumnConfig(addCFinColumnConfig(columnConfig));
    }
  }, [accountsCFData, systemDimensions]);

  useEffect(() => {
    const updatedState = [...records];
    let oldColConfigs = [...columnConfig];
    let key: string = 'makePaymentItemDtoList';
    if (
      props.formType === DOC_TYPE.DEPOSIT ||
      props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
    ) {
      key = 'receivePaymentItemDtoList';
    }

    if (props.populateData?.id && !Utility.isEmpty(customFieldsData)) {
      props?.populateData[key]?.forEach((item1: any, index1: any) => {
        item1?.customField?.forEach((item: any, index: any) => {
          let filteredCF: any = customFieldsData?.find(
            (cf: any) => cf.code === item.code
          );
          if (!Utility.isEmpty(filteredCF)) {
            const newItem = getNewItem(item, filteredCF);
            let cfValue;
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              cfValue = DateFormatService.getDateFromStr(
                item.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === item.value
              );
              if (tempCF) {
                cfValue = tempCF ? tempCF : '';
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
            ) {
              cfValue = item ? item : '';
            } else {
              cfValue = item.value ? item.value : '';
            }

            let alreadyPresentConfig = oldColConfigs?.find(
              (col: any) => col.code === item.code
            );

            if (Utility.isEmpty(alreadyPresentConfig)) {
              oldColConfigs.push(newItem);
            }
            updatedState[index1][item.id] = cfValue;
          }
        });
      });

      setColumnConfig([...oldColConfigs]);
      setRecords([...updatedState]);
    }
  }, [customFieldsData]);

  const checkIfUSOrg = () => {
    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      // selectedTaxCode, taxBifurcation;
      //remove selectedTaxCode for all
      let editedColumns = [...columnConfig];
      if (
        props.formType === DOC_TYPE.EXPENSE ||
        props.formType === DOC_TYPE.PREPAYMENT ||
        props.formType === DOC_TYPE.DEPOSIT ||
        props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
      ) {
        editedColumns = editedColumns.filter(
          (col: any) => col.key !== 'selectedTaxCode'
        );
      }

      //remove taxBifurcation for all except Direct Deposit
      if (
        props.formType === DOC_TYPE.EXPENSE ||
        props.formType === DOC_TYPE.PREPAYMENT ||
        props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
      ) {
        editedColumns = editedColumns.filter(
          (col: any) => col.key !== 'taxBifurcation'
        );
      }
      setColumnConfig(addCFinColumnConfig(editedColumns));
    }
  };
  const fetchData = () => {
    if (!taxData?.length) {
      dispatch(fetchTaxes());
    }
    if (
      Utility.isEmpty(accountsData) ||
      Utility.isEmpty(accountsData.content)
    ) {
      dispatch(fetchAccoutnsList());
    }
  };

  useEffect(() => {
    ConfigUtility.structuredAccountsArr = [];
    checkIfUSOrg();
    setDataForEdit();
    checkIfSelectedAccountIsEmpty();
    fetchData();
    setFormData((prevForm: any) => {
      return {
        ...prevForm,
        amount: props.populateData?.amount ? props.populateData?.amount : 0,
        referenceNumber: props.transactionObj
          ? props.transactionObj.chequeNumber
          : prevForm.referenceNumber,
        paymentType: props.populateData?.paymentType
          ? props.populateData.paymentType
          : paymentMethods[0]?.value,
        memo:
          props.formType === DOC_TYPE.PREPAYMENT ||
          props.formType === MODULES_NAME.EXPENSE
            ? props.populateData?.memo
              ? decodeMemo(props.populateData?.memo)
              : ''
            : props.populateData?.memo
      };
    });
    props.transactionObj &&
      BanksService.getRuleDetailForTransactionId(props.transactionObj.id)
        .then(
          (res: any) => {},
          (err: any) => {}
        )
        .catch((err: any) => {});
    registerInteractions();
    fetchAttachments();
    return () => {
      setFormData(null);
    };
  }, []);

  useEffect(() => {
    registerInteractions();
    updateConfig();
  }, [formData]);

  useEffect(() => {
    setFormData((prevState: any) => {
      return {
        ...prevState,
        documentDate: Utility.formatDate(paymentDate, DATE_FORMAT.YYYY_MM_DD),
        referenceDate: Utility.formatDate(
          referenceDate,
          DATE_FORMAT.YYYY_MM_DD
        ),
        contactCode: contact && contact.code,
        contactName: contact && contact.name
      };
    });
  }, [paymentDate, referenceDate, contact]);

  const decodeMemo = (memo: any) => {
    let result = '';
    try {
      result = decodeURIComponent(memo);
    } catch (e) {
      result = memo;
    }
    return result;
  };

  const loadContacts = (term: string) => {
    const config: ContactAPIConfig = {
      ...ContactService.apiConfig,
      Page: 0,
      SearchTerm: term,
      Limit: 20,
      IncludeOpeningAmounts: false,
      IncludeOweAmounts: false
    };
    try {
      ContactService.apiConfig = config;
      const response = ContactService.getContactsByPage().then((data: any) => {
        const filteredData =
          data?.content?.length > 0 &&
          data.content.filter((item: any) => item.status !== 'INACTIVE');
        return filteredData;
      });

      // if contact is empty from parent, then setContact from the response else use from parent component
      if (Utility.isEmpty(contact)) {
        response?.then((res: any) => {
          const contactList: any[] = res;
          const contactDetails = contactList.find((x) => {
            if (contact?.id) {
              return x.id === contact.id;
            } else {
              return null;
            }
          });
          setContact({ ...contactDetails });
        });
      }
      return response;
    } catch (err) {
      console.error('Error loading contacts: ', err);
    }
  };

  const isDplChecked = () => {
    if(!dimensionData || !contact) return false;
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField =  contact?.customField?.filter((cField:any)=>cField?.id==DplCustomFieldId)    
    if(!dplContactCustomField || dplContactCustomField?.length==0) return false;
    return true;
  }

  const getContactView = () => {
    const contactList =
      contactsData?.content?.filter(
        (contact: any) =>
          contact.status.toLowerCase() === STATUS_TYPE.active) || null;
    return (
      <div
        className="flex flex-col items-center"
        style={{ width: isFullScreenMode ? 250 : '100%' }}
      >
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          title={`Contact`}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={contact}
          formatter={(obj: any) => {
            return obj.name;
          }}
          onChange={(contactObj: any) => {
            if (
              Utility.isUSorg() &&
              Utility.isDPLSettingEnabled()
            ) {
              checkForDpl(contactObj);
            }
            setContact(contactObj);
            setFormData((value: any) => ({
              ...value,
              interCompany: !!contactObj?.intercompanyType
            }));
          }}
          required={
            props.formType === DOC_TYPE.PREPAYMENT ||
            props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
          }
          canValidate={canValidate}
          dropdownConfig={{
            title: 'Select Contact',
            allowSearch: true,
            searchableKey: 'name',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            searchApiConfig: {
              getUrl: (searchValue: string) => {
                const config: ContactAPIConfig = {
                  ...ContactService.apiConfig,
                  Page: 0,
                  SearchTerm: searchValue,
                  Limit: 20,
                  IncludeOpeningAmounts: false,
                  IncludeOweAmounts: false,
                  Query: 'status=active',
                  SortDir: 'DESC',
                  Sort: 'documentSequenceCode'
                };
                if (
                  canApplySalesPersonFilterOnContact.canApplyFilter &&
                  !config.Query?.includes('salesperson') &&
                  canApplySalesPersonFilterOnContact?.loggedInUserInfo?.[0]?.id
                ) {
                  let configQuery = config?.Query ? config?.Query + ',' : '';
                  config.Query =
                    configQuery +
                    `salesperson=${canApplySalesPersonFilterOnContact?.loggedInUserInfo?.[0]?.id}`;
                  config.SalesPerson =
                    canApplySalesPersonFilterOnContact?.loggedInUserInfo?.[0]?.id;
                }

                ContactService.apiConfig = config;
                return ContactService.getContactsApiUrl();
              },
              dataParser: (response: any) => {
                return response?.content || [];
              },
              debounceTime: 300
            },
            data: contactList ? contactList : [],
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj.name}`} />;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {},
            button: GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.CONTACTS.CREATE
            )
              ? {
                  title: '+ Add Contact',
                  className: 'bg-button text-white',
                  onClick: () => {
                    setShowAddContactPopup(true);
                  }
                }
              : null
          }}
        />
        {Utility.isUSorg() && Utility.isDPLSettingEnabled() && Utility.isNotEmpty(contact)  && Utility.isNotEmpty(dimensionData) && isDplChecked() && (
          <div className="row">
            <ReCheckButton onClick={()=>{
              const billingAddress:any[]=[];
              const countryCodeMap:any = {}
              COUNTRIES_WITH_CURRENCIES.forEach((country)=>{
                countryCodeMap[country?.country?.toLowerCase()] = country?.countryCode
              })
              if(contact?.billingAddress){
                contact?.billingAddress?.forEach((address:any)=>{
                  if(address?.country){
                    billingAddress.push({...address,countryCode: countryCodeMap?.[address?.country?.toLowerCase()]})
                  }
                })
              }
              const DplCustomFieldId = dimensionData?.content?.filter(
                (customField: any) =>
                  customField?.system &&
                  customField?.shortName === 'denied_parties_custom_field'
              )?.[0]?.id;
              const dplContactCustomField =  contact?.customField?.filter((cField:any)=>cField?.id==DplCustomFieldId)
              if(!dplContactCustomField || dplContactCustomField?.length==0) return;
              const payload = {
                name: contact?.name ?? '',
                billingAddress: billingAddress,
                customField: dplContactCustomField
              };
              showLoader()
              ContactService.recheck(contact?.id ,payload).then((res)=>{
                removeLoader()
                if(res){
                  showToast("The contact you are using is under denied party list",TOAST_TYPE.SUCCESS)
                }else{
                  showToast("The contact you are using is not under denied party list",TOAST_TYPE.SUCCESS)
                }
                dispatch(fetchContacts(''));
              }).catch((err)=>{
                removeLoader()
              })
            }} />
          </div>
        )}
      </div>
    );
  };
  const checkForDpl = (contact: any) => {
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    )?.[0];
    if (!dplContactCustomField) return;
    if (dplContactCustomField?.value === 'Yes') {
      showToast(
        'The contact you are using is under denied party list',
        TOAST_TYPE.SUCCESS
      );
    }
  };
  const getHeaderElement = (
    title: string,
    value: string,
    width = '20%',
    isLeft = false
  ) => {
    return (
      <div className="column parent-width" style={{ width: width }}>
        <DKLabel
          className={`text-app-color ${
            isLeft ? 'text-align-left' : 'text-align-right'
          } parent-width `}
          text={title}
        />
        <DKLabel
          className={`${
            isLeft ? 'text-align-left' : 'text-align-right'
          } parent-width`}
          text={value}
        />
      </div>
    );
  };

  const getHeader = () => {
    return (
      props.transactionObj && (
        <div className="row rounded-md parent-width bg-gray-100 justify-between align-items-start mt-2 p-3">
          {getHeaderElement(
            'Contact',
            props.transactionObj.merchantName,
            '8%',
            true
          )}
          {getHeaderElement(
            'Date',
            DateFormatService.getDateStrFromDate(new Date())
          )}
          {getHeaderElement(
            'Reference',
            props.transactionObj.chequeNumber,
            '23%'
          )}
          {getHeaderElement(
            'Amount',
            props.transactionObj.transactionAmount
              ? `${
                  props.transactionObj.currencyCode
                } ${NumberFormatService.getNumber(
                  props.transactionObj.transactionAmount
                )}`
              : '',
            '23%'
          )}
          {getHeaderElement(
            'Description',
            props.transactionObj.description,
            '26%'
          )}
        </div>
      )
    );
  };

  type callBack = () => any;

  const getPaymentDateField = (title: string, date: Date, onChange: any) => {
    return (
      <DKInput
        className={''}
        title={title}
        canValidate={canValidate}
        type={INPUT_TYPE.DATE}
        value={date}
        onChange={(date: Date) => {
          validateAndUpdateDate(
            date,
            DateFormatService.getDateFromStr(
              tenantInfo.bookBeginningStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ),
            onChange,
            `Payment Date cannot be before books beginning date.`
          );
        }}
        datePickerConfig={{
          isDateRange: false
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={true}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
      />
    );
  };
  const getDateField = (title: string, date: Date, onChange: any) => {
    return (
      <DKInput
        className={''}
        title={title}
        canValidate={canValidate}
        type={INPUT_TYPE.DATE}
        value={date}
        onChange={(date: Date) => {
          onChange(date);
        }}
        datePickerConfig={{
          isDateRange: false
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={true}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
      />
    );
  };

  const getReportingCurrency = () => {
    return (
      <DKInput
        title={'Reporting Currency'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={
          accountCurrency
            ? `${accountCurrency.currencyName} (${accountCurrency.currencyCode})`
            : ''
        }
        readOnly={true}
        required={false}
      />
    );
  };

  const isFeeAmountRequired = () => {
    let isReq =
      formData?.receivePaymentFeeDtoList &&
      formData?.receivePaymentFeeDtoList.length > 0 &&
      formData?.receivePaymentFeeDtoList[0].accountCode
        ? true
        : false;
    return isReq;
  };

  const getFeeAmountField = (hasError = false) => {
    return (
      <DKInput
        title={`This is the transaction fee that is deducted before depositing funds`}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={
          formData?.receivePaymentFeeDtoList &&
          formData?.receivePaymentFeeDtoList.length > 0
            ? formData?.receivePaymentFeeDtoList[0].amount
            : ''
        }
        options={getAccountsNameList(accountsList && accountsList.content)}
        type={INPUT_TYPE.NUMBER}
        onChange={(value: any) => {
          setAccountFee(null, value);
        }}
        validator={(value: number) => isValidTransactionFeeValue(value)}
        errorMessage="Fee amount should be greater than 0 and less than total amount"
        required={isFeeAmountRequired()}
        canValidate={canValidate}
      />
    );
  };

  const getHeaderLabel = (text: any) => {
    return (
      <DKLabel
        className="app-font text-align-center fw-m parent-width"
        text={text}
      />
    );
  };

  const getRecordsHeader = (
    <div className="row mt-2 border-t-2 border-b-2 py-3 border-gray-200 parent-width gap-2">
      {getHeaderLabel('Account')}
      {getHeaderLabel('Description')}
      {getHeaderLabel('Amount')}
      {getHeaderLabel('Tax')}
      {getHeaderLabel('Tax Amount')}
      {getHeaderLabel('Payment Amount')}
    </div>
  );

  const getSelectedAccount = (code: string) => {
    let filterred =
      accountsList &&
      accountsList.content.filter((item: any) => code === item.code);
    if (filterred.length > 0) {
      const first = filterred[0];
      return {
        label: first.name,
        value: first.code
      };
    } else {
      return null;
    }
  };

  const getSelectedTaxAccount = (taxCode: string) => {
    let filterred = taxesList.filter((item: any) => taxCode === item.code);
    if (filterred.length > 0) {
      return filterred[0];
    } else {
      return null;
    }
  };

  const getSelectedTax = (taxCode: string) => {
    const first = getSelectedTaxAccount(taxCode);
    if (first) {
      return {
        label: first.name,
        value: first.code
      };
    } else {
      return null;
    }
  };

  const calculateTaxForRecord = (record: any) => {
    if (
      getTenantTaxSystem() === TAX_SYSTEM.US &&
      props.formType === DOC_TYPE.DEPOSIT
    ) {
      return Math.abs(record.taxAmount);
    }
    let tax = getSelectedTaxAccount(record.taxCode);
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      gstType === GST_TYPE.EXEMPT
    ) {
      return 0;
    }
    if (
      tenantInfo.country === COUNTRY_CODES.UK &&
      (props.formType === DOC_TYPE.DEPOSIT ||
        props.formType === DOC_TYPE.EXPENSE ||
        props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT)
    ) {
      return Math.abs(record.taxAmount);
    }
    return tax
      ? unitPriceGstInclusive
        ? calculateTaxForUnitPriceInclusive(record, tax)
        : Utility.roundingOff(
            Math.abs(record.paymentAmount) * (tax.percent / 100),
            tenantInfo.decimalScale
          )
      : 0;
  };

  const calculateTaxForUnitPriceInclusive = (record: any, tax: any) => {
    if (
      getTenantTaxSystem() === TAX_SYSTEM.SG ||
      tenantInfo.country === TAX_SYSTEM.INDIA_GST
    ) {
      const taxAmount = Utility.roundingOff(
        (Number(record.paymentAmount) * 100) /
          Number(100 + Number(tax.percent)),
        tenantInfo.decimalScale
      );
      return Utility.roundingOff(
        record.paymentAmount - Number(taxAmount),
        tenantInfo.decimalScale
      );
    } else {
      return Utility.roundingOff(
        Math.abs(record.paymentAmount) * (tax.percent / 100),
        tenantInfo.decimalScale
      );
    }
  };

  const calculateSubTotal = () => {
    let subTotal = 0;
    if (unitPriceGstInclusive) {
      if (
        getTenantTaxSystem() === TAX_SYSTEM.SG ||
        (tenantInfo.country === TAX_SYSTEM.INDIA_GST &&
          gstType !== GST_TYPE.EXEMPT)
      ) {
        records?.reduce(function (add: number, current: any) {
          let tax = getSelectedTaxAccount(current.taxCode);
          let amount: any = 0;
          if (tax) {
            let payAmt = Utility.roundingOff(
              (Number(current.paymentAmount) * 100) /
                Number(100 + Number(tax.percent)),
              tenantInfo.decimalScale
            );
            let taxAmount = Utility.roundingOff(payAmt * (tax.percent / 100));
            amount = current.paymentAmount - taxAmount;
          }
          return (subTotal =
            add +
            (current.paymentAmount
              ? tax
                ? amount
                : current.paymentAmount
                ? parseFloat(current.paymentAmount)
                : 0
              : 0));
        }, 0);
      } else {
        subTotal = records?.reduce(function (add: number, current: any) {
          return (
            add +
            (current.paymentAmount ? parseFloat(current.paymentAmount) : 0)
          );
        }, 0);
      }
    } else {
      subTotal = records?.reduce(function (add: number, current: any) {
        return (
          add + (current.paymentAmount ? parseFloat(current.paymentAmount) : 0)
        );
      }, 0);
    }
    return Utility.roundOff(subTotal, tenantInfo.decimalScale);
  };

  const canAddAccount = () => {
    for (let i = 0; i < records.length; i++) {
      if (Utility.isEmpty(records[i].selectedAccount)) {
        return false;
      }
      let paymentAmt = records[i].paymentAmount;
      if (
        paymentAmt === '0' ||
        paymentAmt === '' ||
        paymentAmt === undefined ||
        paymentAmt === null
      ) {
        return false;
      }
    }
    return true;
  };

  const getAddAccountButton = (
    <DKButton
      className={`fw-b ${
        canValidate && Utility.isEmpty(records) ? 'text-red' : 'text-blue'
      }`}
      style={{
        paddingTop: 0
      }}
      title="+ Add Account"
      onClick={() => {
        // if (canAddAccount()) {
        setRecords([
          ...records,
          {
            invalidFields: ['selectedAccount', 'paymentAmount']
          }
        ]);
        // }
      }}
    />
  );

  const taxInclusiveCheckbox = (
    <div className="flex flex-row items-end justify-end w-full">
      <DKCheckMark
        color="bg-button"
        isSelected={unitPriceGstInclusive}
        onClick={() => {
          setUnitPriceGstInclusive(!unitPriceGstInclusive);
        }}
        className="text-black z-index-1"
        title={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.UNIT_PRICE_IS_TAX_INCLUSIVE`)}
      />
    </div>
  );

  const applyRCMCheckbox = () => {
    const isIndiaTenant = getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST;
    if (!isIndiaTenant || props.formType !== DOC_TYPE.EXPENSE) {
      return <></>;
    }
    return (
      <>
        <div
          style={{
            wordBreak: 'break-word'
          }}
          className="row width-auto mb-l justify-start  cursor-pointer relative items-center"
        >
          <DKTooltipWrapper
            content={`
               Click gear icon in COA to
               check/map default RCM account`}
          >
            <DKIcon
              src={DKIcons.ic_info}
              className="ic-r ic-s cursor-hand unselectable"
              style={{ opacity: 1 }}
            />
          </DKTooltipWrapper>
          <DKCheckMark
            color="bg-button"
            isSelected={formData?.applyRcmCheck}
            onClick={() => {
              handleAndUpdateRCMCheck(!formData?.applyRcmCheck);
            }}
            className="text-black z-index-1 ml-s"
            title={'Is RCM Applicable'}
          />
        </div>
      </>
    );
  };

  const getDetailAccountSelectionPopup = () => {
    return (
      <DefaultAccountsSettingsPopup
        onCancel={() => {
          setShowDefaultAccountsSettings(false);
        }}
        onSave={() => {
          setShowDefaultAccountsSettings(false);
          dispatch(fetchDefaultAccounts());
        }}
      />
    );
  };

  const intercompanyTxnCheckbox = (
    <div className="flex flex-row w-full">
      <DKCheckMark
        color="bg-button"
        isSelected={formData?.interCompany}
        onClick={() => {
          setFormData((value: any) => ({
            ...value,
            interCompany: !value.interCompany
          }));
        }}
        className="text-black z-index-1"
        title={`Is Intercompany Transaction`}
        disabled={!!contact}
      />
    </div>
  );

  const getSubtotal = () => {
    let total = calculateSubTotal();
    return total || 0;
  };

  const getTotalTax = () => {
    let total = 0;
    if (unitPriceGstInclusive) {
      total = records
        ?.filter((record: any) => !record?.isRcmApplied)
        .reduce(function (add: number, current: any) {
          let taxObj = calculateTaxGroupDetails(current);
          return add + taxObj['taxAmount'] || 0;
        }, 0);
    } else {
      total = records
        ?.filter((record: any) => !record?.isRcmApplied)
        .reduce(function (add: number, current: any) {
          return add + calculateTaxForRecord(current);
        }, 0);
    }

    if (total) {
      return total;
    } else {
      return 0;
    }
  };

  ////////////////////////////////////////////////////
  ////////// ATTACHMENT FUNCTIONALITIES //////////////
  ////////////////////////////////////////////////////
  const fetchAttachments = () => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[props.formType];
    const entityId = props.populateData?.id || props.populateData?.entityId;
    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments()
      .then((attachmentList: any) => {
        setAttachments(attachmentList);
      })
      .catch((err: any) => {});
  };

  const uploadAttachmentToAWS = (file: File) => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[props.formType];
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: ''
    };
    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );
      return;
    }

    AttachmentService.uploadAttachment(file)
      .then((res) => {
        const attachmentForListing = [...attachments, res];
        const newlyAddedAttachments = [...newAttachments, res];
        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setFormData((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
      })
      .catch((err) => {
        showAlert(
          'Error',
          'Something went wrong while uploading the attachment, please try again.'
        );
      });
  };

  const removeAttachment = (attachmentId: any) => {
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );
        const newlyAddedAttachments = newAttachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );
        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setFormData((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
      })
      .catch(() => {
        showAlert(
          'Error',
          'Something went wrong while removing the attachment, please try again.'
        );
      });
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file)
        );
    });
    input.click();
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showAlert(
          'Error',
          'Something went wrong, while downloading the attachment.'
        );
      });
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap gap-2">
        {attachments.map((attachment: any) => (
          <div
            className="row border-m border-radius-s p-h-r p-v-s mr-r bg-gray0 overflow-hidden"
            key={attachment.attachmentId}
            style={{
              width: '18rem'
            }}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-s cursor-pointer"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="ml-s w-auto truncate cursor-pointer border-none py-2"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              {attachment.attachmentFileName}
            </div>
            {/* <DKButton
              title={attachment.attachmentFileName}
              className="ml-s w-auto truncate cursor-pointer border-none"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            /> */}
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s ml-l cursor-pointer"
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };

  const [showTaxDetails, setshowTaxDetails] = useState(false);

  const getKeyValueListForList = (additionalTax: any) => {
    return (
      <div className="column parent-width">
        {Object.entries(additionalTax).map(([key, val], index: number) => {
          return (
            <div className="row parent-width justify-content-between">
              <DKLabel className="text-align-left" text={key} />
              <DKLabel
                className="text-align-right"
                text={NumberFormatService.getNumber(val ? (val as number) : 0)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const getTaxBreakUp = () => {
    let totalTax = 0;
    let totalCgst = 0;
    let totalSgst = 0;
    let totalIgst = 0;
    let taxGroupInTax: any = [];
    records.forEach((element: any) => {
      totalTax =
        totalTax + (element.taxAmount ? parseFloat(element.taxAmount) : 0);
      totalCgst =
        totalCgst + (element.cgstRate ? parseFloat(element.cgstRate) : 0);
      totalSgst =
        totalSgst + (element.sgstRate ? parseFloat(element.sgstRate) : 0);
      totalIgst =
        totalIgst + (element.igstRate ? parseFloat(element.igstRate) : 0);
      //creating Dictionary to avoid duplicacy of taxes if same additional tax is applied in more than one row.
      if (element?.selectedTaxCode?.additionalTaxIn) {
        let amountAlreadyAdded = taxGroupInTax[element.selectedTaxCode.name]
          ? taxGroupInTax[element.selectedTaxCode.name]
          : 0;
        taxGroupInTax[element.selectedTaxCode.name] =
          amountAlreadyAdded + element.taxAmount;
      }
    });
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      if (gstType === GST_TYPE.INTER) {
        return (
          <div className="column parent-width">
            <div className="row parent-width py-1 justify-content-between">
              <DKLabel className=" text-align-left" text={'IGST'} />
              <DKLabel
                className=" text-align-right"
                text={NumberFormatService.getNumber(
                  totalIgst ? (totalIgst as number) : 0
                )}
              />
            </div>
            {getKeyValueListForList(taxGroupInTax)}
          </div>
        );
      } else if (gstType === GST_TYPE.INTRA) {
        return (
          <div className="column py-1 parent-width justify-content-between app-font">
            <div className="row parent-width justify-content-between ">
              <DKLabel className=" text-align-left" text={'CGST'} />
              <DKLabel
                className=" text-align-right"
                text={NumberFormatService.getNumber(
                  totalCgst ? (totalCgst as number) : 0
                )}
              />
            </div>
            <div className="row parent-width justify-content-between ">
              <DKLabel className="text-align-left" text={'SGST'} />
              <DKLabel
                className="text-align-right"
                text={NumberFormatService.getNumber(
                  totalSgst ? (totalSgst as number) : 0
                )}
              />
            </div>
            {getKeyValueListForList(taxGroupInTax)}
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <div className="column parent-width gap-2">
          {taxGroupDetail.map((item: any) => {
            return (
              <div className="row">
                {Object.entries(item).map(([keyName, value], innerIndex) => {
                  return (
                    <div className="row gap-1 justify-content-between">
                      <DKLabel text={keyName} />
                      <DKLabel
                        className={(value as number) < 0 ? 'text-red' : ''}
                        text={NumberFormatService.getNumber(
                          value ? (value as number) : 0
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const showBaseTaxForSG = () => {
    return (
      getTenantTaxSystem() === TAX_SYSTEM.SG &&
      tenantInfo.currency !== CURRENCIES.SG
    );
  };

  const getBaseCurrency = (): string => {
    return CURRENCIES[tenantInfo.country];
  };

  const getMemoView = () => {
    return (
      <div className="column justify-content-between" style={{ width: '50%' }}>
        <textarea
          rows={4}
          placeholder={'Memo...'}
          className="border rounded border-gray-300 w-full text-black tracking-normal placeholder-gray-600::placeholder focus:outline-none focus:ring-2 focus:ring-blue-500 p-2"
          onChange={(e) => {
            setFormData({ ...formData, memo: e.target.value });
          }}
          value={Utility.isEmpty(formData?.memo) ? '' : formData?.memo}
        />
        <div className="column parent-width mt-2">
          {GranularPermissionsHelper.getAttachFilePermissionForExpenseDeposit(
            props.formType
          ) && (
            <DKButton
              title={
                <>
                  + Attach Files
                  <span className="text-gray fw-r ml-s">(Max 5MB)</span>
                </>
              }
              icon={DKIcons.ic_attachment}
              className={'text-blue fw-m'}
              onClick={triggerAttachmentUpload}
            />
          )}
          <div className="row mt-r">{getAttachments()}</div>
        </div>
      </div>
    );
  };

  const onPrimaryCurrencyChange = (primaryExchangeRate: number) => {
    setFormData((prevState: any) => {
      return {
        ...prevState,
        primaryExchangeRate: primaryExchangeRate
      };
    });
  };

  const getPrimaryCurrencyRateField = () => {
    const PrimaryCurrTotal: any =
      formData.amount * (tempPrimaryExchangeRate || 1);
    const primaryCurrencyCode =
      tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
    return (
      formData.currency !== primaryCurrencyCode &&
      getPrimaryCurrencyCheck() && (
        <div className="row parent-width mb-l justify-content-between align-items-start number-hide-arrows fs-s">
          <div className="parent-width">
            <DKLabel
              text={`Primary Conversion: ${
                formData?.primaryExchangeRate &&
                NumberFormatService.getNumber(PrimaryCurrTotal)
              }(${primaryCurrencyCode}) at`}
            />
            <div
              className={
                'row border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder p-1 mt-s ' +
                'focus:outline-none focus:ring-2 focus:ring-blue-200'
              }
            >
              <div
                className="row currency-select-trigger align-items-center content-start"
                style={{ width: 'fit-content' }}
              >
                <div className="ml-1">{`1 ${tenantInfo.currency}`}</div>
                <div className="currency-dropdown-flag ml-2">
                  <span
                    className={`currency-dropdown-selector-flag flag ${tenantInfo.currency}`}
                  ></span>
                </div>
                <DKIcon
                  className="ml-r"
                  style={{
                    transform: 'rotate(90deg)'
                  }}
                  src={DKIcons.ic_sort}
                />
              </div>
              <div className="row align-items-start content-end ml-s">
                <input
                  onBlur={(e) => {
                    const rate = e.target.value
                      ? +e.target.value
                      : formData.primaryExchangeRate;
                    if (
                      +e.target.value !== 0 &&
                      formData.primaryExchangeRate !==
                        1 / (rate as number)
                    ) {
                      onPrimaryCurrencyChange(rate as number);
                    }
                  }}
                  className={`text-align-right outline-none hover:bg-blue-100 focus:bg-blue-100`}
                  value={tempPrimaryExchangeRate}
                  type={'number'}
                  onChange={(e: any) => {
                    setTempPrimaryExchangeRate(+e.target.value);
                  }}
                  style={{ width: '62%' }}
                  disabled={
                    props.documentMode === DOCUMENT_MODE.VIEW 
                  }
                />
                <div className="ml-1">{`${primaryCurrencyCode}`}</div>
                <div className="currency-dropdown-flag ml-2">
                  <span
                    className={`currency-dropdown-selector-flag flag ${primaryCurrencyCode}`}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const roundOffCalculation = () => {
    let tmpTotal: number = 0;
    let roundOffDiff = 0;
    let tmpTarget: any = {};
    let roundOffValue1: any = {};
    if (
      Store.getState().authInfo.currentTenantInfo.data.additionalSettings
        ?.ROUND_OFF?.autoRoundOff &&
      !isManualRoundOff.current
    ) {
      //Auto round off feature
      if (
        (selectedAccount && selectedAccount.currency) !== tenantInfo.currency
      ) {
        tmpTotal =
          (getSubtotal() + getTotalTax()) * formData?.exchangeRate || 0;
      } else if (selectedAccount) {
        tmpTotal = getSubtotal() + getTotalTax() - totalTdsAmount;
      }
      switch (
        Store.getState().authInfo.currentTenantInfo.data.additionalSettings
          ?.ROUND_OFF?.roundingMethod
      ) {
        case ROUNDING_METHOD.ROUND_OFF:
          roundOffDiff = Utility.roundOff(
            Math.round(tmpTotal) - tmpTotal,
            tenantInfo.decimalScale
          );
          break;
        case ROUNDING_METHOD.ROUND_UP:
          roundOffDiff = Utility.roundOff(
            Math.ceil(tmpTotal) - tmpTotal,
            tenantInfo.decimalScale
          );
          break;
        case ROUNDING_METHOD.ROUND_DOWN:
          roundOffDiff = Utility.roundOff(
            Math.floor(tmpTotal) - tmpTotal,
            tenantInfo.decimalScale
          );
          break;
        default:
          roundOffDiff = Utility.roundOff(
            Math.round(tmpTotal) - tmpTotal,
            tenantInfo.decimalScale
          );
          break;
      }
      roundOffValue1['value'] = roundOffDiff;
      tmpTarget['target'] = roundOffValue1;
      handleRoundingOffChange(tmpTarget, tmpTotal);
    }
    return roundOffValue1;
  };

  const handleRoundingOffChange = (e: any, totalWithoutRounding: number) => {
    const value = e.target.value;
    if (
      value === '' ||
      value === '-' ||
      (+value <= totalWithoutRounding &&
        +value >= Math.sign(-1) * totalWithoutRounding)
    ) {
      setFormData({ ...formData, roundOffValue: value })
      setRoundOffValue(value);
    }
  };

  const manualRoundOffChange = (e: any) => {
    isManualRoundOff.current = true;

    if (
      !Store.getState().authInfo.currentTenantInfo.data.additionalSettings
        ?.ROUND_OFF?.autoRoundOff
    ) {
      let tmpTotal: number = 0;

      if (
        (selectedAccount && selectedAccount.currency) !== tenantInfo.currency
      ) {
        tmpTotal = getSubtotal() + getTotalTax() * formData?.exchangeRate || 0;
      } else if (selectedAccount) {
        tmpTotal = getSubtotal() + getTotalTax() - totalTdsAmount;
      }
      handleRoundingOffChange(e, tmpTotal);
    } else {
      showAlert(
        '',
        'Please Disable Auto Round off from Organization Settings to apply Manual Round value'
      );
    }
    isManualRoundOff.current = false;
  };

  const getSummaryView = () => {
    return (
      <div className="column justify-content-between" style={{ width: '30%' }}>
        {getPrimaryCurrencyRateField()}
        <div className="row parent-width mt-1.5 justify-content-between app-font fw-m fs-m border-b py-1 gap-10">
          <DKLabel className=" text-align-left" text={'SUB-TOTAL'} />
          <DKLabel
            className=" text-align-right"
            text={NumberFormatService.getNumber(getSubtotal())}
          />
        </div>
        {getTenantTaxSystem() !== TAX_SYSTEM.US ||
          (getTenantTaxSystem() === TAX_SYSTEM.US &&
            props.formType === DOC_TYPE.DEPOSIT && (
              <div className="row parent-width justify-content-between app-font fw-m fs-m border-b gap-10">
                <DKButton
                  className="parent-width justify-content-between"
                  style={{ paddingLeft: 0 }}
                  title={'+TAX'}
                  icon={
                    showTaxDetails
                      ? DKIcons.ic_arrow_up2
                      : DKIcons.ic_arrow_down2
                  }
                  isReverse={true}
                  onClick={() => {
                    setshowTaxDetails(!showTaxDetails);
                  }}
                />
                <DKLabel
                  className=" text-align-right"
                  text={NumberFormatService.getNumber(getTotalTax())}
                />
              </div>
            ))}
        {showTaxDetails &&
          getTenantTaxSystem() !== TAX_SYSTEM.US &&
          getTaxBreakUp()}
        {showTds && (
          <div className="row parent-width mt-1.5 justify-content-between app-font fw-m fs-m border-b py-1 gap-10">
            <DKLabel className=" text-align-left" text={'-TDS'} />
            <DKLabel
              className=" text-align-right"
              text={NumberFormatService.getNumber(totalTdsAmount)}
            />
          </div>
        )}
        {(props.formType === DOC_TYPE.DEPOSIT ||
          props.formType === DOC_TYPE.EXPENSE) &&
          selectedAccount && (
            <div
              className="row width-auto mb-m justify-content-between"
              style={{ width: '100%' }}
            >
              <div className="row width-auto">
                <DKLabel
                  text={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.ROUNDING_OFF`)}
                  className={'fw-m'}
                />
              </div>

              <div className="flex flex-col items-end w-24 number-hide-arrows">
                <input
                  value={roundOffValue}
                  type="text"
                  className="border-transparent hover:border-gray-300 focus:border-gray-400 w-10/12 text-right border rounded-sm outline-none cursor-pointer"
                  onChange={manualRoundOffChange}
                  disabled={props.documentMode === DOCUMENT_MODE.VIEW}
                />
              </div>
            </div>
          )}
        {selectedAccount && (
          <div className="row parent-width mt-1.5 justify-content-between app-font fw-m fs-m border-b py-1 gap-10">
            <div className="row justify-content-between">
              <DKLabel
                className=" text-align-left"
                text={`Total (${selectedAccount.currency})`}
              />
              <DKLabel
                className=" text-align-right"
                text={NumberFormatService.getNumber(
                  parseFloat(roundOffValue) +
                    getSubtotal() +
                    getTotalTax() -
                    totalTdsAmount
                )}
              />
            </div>
          </div>
        )}
        {showBaseTaxForSG() && (
          <div className="row  justify-between mt-2">
            <div className="row">
              <DKLabel
                text={`Tax (${getBaseCurrency()})`}
                className={' parent-width'}
              />
            </div>
            <DKLabel
              className={'parent-width text-align-right'}
              text={`${Utility.getCurrencySymbolFromCode(
                COMPLAINCE_CURRENCY[AuthService.userDetails?.country]
              )} ${NumberFormatService.getNumber(
                getTotalTax() / (formData?.gstExchangeRate as number)
              )}`}
            />
          </div>
        )}
        {(props.formType === DOC_TYPE.DEPOSIT ||
          props.formType === DOC_TYPE.EXPENSE) &&
          (selectedAccount && selectedAccount.currency) !==
            tenantInfo.currency && (
            <div
              className="row width-auto mb-m justify-content-between"
              style={{ width: '100%' }}
            >
              <div className="row width-auto">
                <DKLabel
                  text={t(`DOCUMENT.DOCUMENT_SUMMARY_VIEW.ROUNDING_OFF`)}
                  className={'fw-m'}
                />
              </div>

              <div className="flex flex-col items-end w-24 number-hide-arrows">
                <input
                  value={roundOffValue}
                  type="text"
                  className="border-transparent hover:border-gray-300 focus:border-gray-400 w-10/12 text-right border rounded-sm outline-none cursor-pointer"
                  onChange={manualRoundOffChange}
                  disabled={props.documentMode === DOCUMENT_MODE.VIEW}
                />
              </div>
            </div>
          )}
        {(selectedAccount && selectedAccount.currency) !==
          tenantInfo.currency && (
          <div className="row parent-width mt-1.5 justify-content-between app-font fw-m fs-m border-b py-1 gap-10">
            <DKLabel
              className="text-align-left"
              text={`Total (${tenantInfo.currency})`}
            />
            {accountCurrency && (
              <DKLabel
                className=" text-align-right"
                text={`${
                  accountCurrency &&
                  NumberFormatService.getNumber(
                    (parseFloat(roundOffValue) +
                      getSubtotal() +
                      getTotalTax()) *
                      formData?.exchangeRate || 0
                    // getBaseToSelectedCurrencyConversionValue()
                  )
                }`}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const getBottomSection = (
    <div className="row parent-width justify-content-between mt-5 align-items-start">
      {getMemoView()}
      {getSummaryView()}
    </div>
  );

  const getAccountTypeString = () => {
    if (
      props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT ||
      props.formType === DOC_TYPE.DEPOSIT
    ) {
      return 'Deposit To';
    }

    if (
      props.formType === DOC_TYPE.PREPAYMENT ||
      props.formType === DOC_TYPE.EXPENSE
    ) {
      return 'Pay From';
    }
  };

  const setAccountFee = (accountCode: any, value: any) => {
    let feeArray = formData?.receivePaymentFeeDtoList
      ? [...formData?.receivePaymentFeeDtoList]
      : [{}];
    let itemToBeUpdated: any;
    if (feeArray && feeArray.length > 0) {
      itemToBeUpdated = { ...feeArray[0] };
    } else {
      itemToBeUpdated = {
        accountCode: '',
        amount: ''
      };
    }
    if (accountCode || accountCode === '') {
      itemToBeUpdated.accountCode = accountCode;
    }
    if (value || value === '') {
      itemToBeUpdated.amount = value ? (value as number) : '';
    }
    feeArray[0] = itemToBeUpdated;
    setFormData((preState: any) => {
      return {
        ...preState,
        receivePaymentFeeDtoList: feeArray
      };
    });
  };

  const getAddContactPopup = () => {
    const buttonConfig: BtnType[] = [
      {
        title: t(`DOCUMENT.BUTTON.CANCEL`),
        class: 'border-m mr-s',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      },
      {
        title: `Create`,
        class: 'bg-button text-white mr-ss',
        clickAction: POPUP_CLICK_TYPE.CREATE_CONTACT
      }
    ];
    const catchClicks = (data: PopupClickActionType) => {
      switch (data.type) {
        case POPUP_CLICK_TYPE.CLOSE_POPUP:
          showAddContactPopup && setShowAddContactPopup(false);
          break;
        case POPUP_CLICK_TYPE.CREATE_CONTACT:
          expenseDepositFormRef.current?.storeCallbacksRef.copyContact();
          break;
      }
    };

    const parentChildInteraction = (passingData: CallBackPayloadType) => {
      switch (passingData.type) {
        case POPUP_CALLBACKS_TYPE.CREATE_CONTACT:
          expenseDepositFormRef.current.storeCallbacksRef.copyContact =
            passingData.data;
          break;
        case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
          showAddContactPopup && setShowAddContactPopup(false);
          break;
        case POPUP_CALLBACKS_TYPE.CREATE_CONTACT_SUCCESS:
          setShowAddContactPopup(false);
          setDetailedContact(passingData.data.id);
          break;
      }
    };

    return showAddContactPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={'Create Contact'}
        btnList={buttonConfig}
        disableClickOutside={true}
        width={!isDesktop ? '95%' : '40%'}
        minWidth={!isDesktop ? '' : '550px'}
        height={'95%'}
      >
        <AddContact
          contactMode={DOCUMENT_MODE.NEW}
          populateFormData={null}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
        />
      </PopupWrapper>
    ) : null;
  };

  const setDetailedContact = async (id: number) => {
    try {
      const detailedContact = await ContactService.getContactDetailsById(id);
      setContact(detailedContact);
    } catch (err) {
      console.error('Error loading detailed contact: ', err);
    }
  };

  const setSelectAccountValuesinFormData = (account: any) => {
    let currenctAccount = { ...account };
    if (
      props.formType === DOC_TYPE.EXPENSE ||
      props.formType === DOC_TYPE.PREPAYMENT
    ) {
      setFormData({ ...formData, accountCodePayFrom: currenctAccount.code });
    } else if (
      props.formType === DOC_TYPE.DEPOSIT ||
      props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
    ) {
      setFormData({ ...formData, accountCodePayTo: currenctAccount.code });
    }
  };

  const getSelectedAccountIndex = () => {
    let filtered: any;
    filtered = filteredAccounts?.filter(
      (item: any) => item.code === selectedAccount?.code
    );

    if (filtered && filtered.length > 0) {
      return [filteredAccounts?.indexOf(filtered[0])];
    } else {
      return [];
    }
  };

  const getAccountGroupCondition = () => {
    let filterAccounts =
      tenantInfo?.additionalSettings?.ACCOUNT?.showCardCashBankAccountsOnly;
    if (filterAccounts) {
      return true;
    } else {
      return false;
    }
  };

  const getAccountField = () => {
    let newData = Utility.getAccountsStructured(filteredAccounts);
    ConfigUtility.fillStructuredAccountArray(newData);
    let newStructuredData = ConfigUtility.structuredAccountsArr?.filter(
      (item: any) => item.status === STATUS_TYPE.ACTIVE
    );
    return (
      <DKInput
        title={props.formType && getAccountTypeString()}
        required={true}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={selectedAccount}
        formatter={(obj: any) => {
          return obj.name;
        }}
        onChange={(account: any) => {
          setSelectAccountValuesinFormData(account);
          setSelectedAccount(account);
          setAccountChanged(true);
        }}
        readOnly={props.selectedAccount}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          title: 'Select Account',
          allowSearch: true,
          searchableKey: 'name',
          canEdit: false,
          canDelete: false,
          data: getAccountGroupCondition()
            ? newStructuredData?.filter((acc: any) => {
                return (
                  ['Cash', 'Bank'].includes(acc.accountGroup) ||
                  (acc.accountGroup === 'Current Liabilities' &&
                    acc.isCreditCard === true)
                );
              })
            : newStructuredData,
          renderer: (index: number, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          },
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const config: AccountAPIConfig = {
                ...AccountsService.apiConfig,
                Page: 0,
                SearchTerm: searchValue,
                Limit: 10,
                AccountGroupsString: getAccountGroupCondition()
                  ? 'Bank,Cash,Current Liabilities'
                  : '',
                Query: 'status=ACTIVE'
              };
              AccountsService.apiConfig = config;
              return (
                ApiConstants.URL.BASE + AccountsService.getAccountEndPoint()
              );
            },
            dataParser: (response: any) => {
              let filtered = response?.content?.filter(
                (acc: any) =>
                  ['Cash', 'Bank'].includes(acc.accountGroup) ||
                  (acc.accountGroup === 'Current Liabilities' &&
                    acc.isCreditCard === true)
              );
              return getAccountGroupCondition() ? filtered : response?.content;
            },
            debounceTime: 300
          },

          // onSelect: (index: number, value: any) => {},
          button: GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.COA.CREATE
          )
            ? {
                title: 'Add Account',
                className: 'bg-button text-white',
                onClick: () => {
                  setShowAddAccountPopup(true);
                }
              }
            : null
        }}
      />
    );
  };

  const getContactRow = (
    <div className="row row-responsive parent-width justify-content-between gap-5 align-items-start">
      {getContactView()}
      {getAddContactPopup()}
      {getAccountField()}
    </div>
  );

  const getContactRowForFullScreen = (
    <div className="row row-responsive parent-width gap-5 align-items-start">
      {getContactView()}
      {getAddContactPopup()}
      <div
        className="position-relative"
        style={{ width: isFullScreenMode ? 200 : '100%' }}
      >
        {getPaymentDateField(
          'Payment Date',
          paymentDate,
          (date: Date, warningMessage: any) => {
            if (warningMessage !== undefined) {
              showAlert('Invalid Date', warningMessage);
            }
            setPaymentDate(date);
          }
        )}
      </div>
    </div>
  );

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      let docType =
        props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT ||
        props.formType === DOC_TYPE.DEPOSIT
          ? DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
          : DOC_TYPE.EXPENSE;
      if (!Utility.checkClosingDate(newDate, 'Payment date')) {
        callback(new Date(paymentDate));
      }
      if (
        Utility.checkActiveDateRangeValidation(
          newDate,
          tenantInfo,
          'Payment date',
          docType
        )
      ) {
        callback(newDate);
      } else {
        callback(new Date(paymentDate));
      }
      // callback(newDate);
    } else {
      callback(new Date(paymentDate), warningMessage);
    }
  };

  const getPaymentMethodInput = () => {
    return (
      <DKInput
        title={'Payment Method'}
        required={true}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        options={paymentMethods}
        formatter={(obj: any) => {
          return obj.label;
        }}
        value={paymentMethods.find(
          (method: any) => method.value === formData?.paymentType
        )}
        onChange={(paymentType: any) => {
          setFormData({ ...formData, paymentType: paymentType?.value });
        }}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          title: 'Select Payment Method',
          allowSearch: false,
          canEdit: false,
          canDelete: false,
          data: paymentMethods,
          renderer: (index: number, obj: any) => {
            return <DKLabel text={obj.label} />;
          }
          // onSelect: (index: number, value: any) => {},
        }}
      />
    );
  };

  const getReferenceNumberInput = (
    <DKInput
      type={INPUT_TYPE.TEXT}
      title={'Reference Number'}
      required={false}
      canValidate={canValidate}
      direction={INPUT_VIEW_DIRECTION.VERTICAL}
      value={formData?.referenceNumber}
      onChange={(value: any) => {
        setFormData({ ...formData, referenceNumber: value });
        verifyCheckNumber(value);
      }}
    />
  );

  const getAccountRowForFullScreen = (
    <div className="row row-responsive parent-width gap-5 align-items-start">
      <div className="column" style={{ width: 250 }}>
        {getAccountField()}
      </div>
      {isBankType() ? (
        <>
          <div className="column" style={{ width: 200 }}>
            {getPaymentMethodInput()}
          </div>
          <div className="column" style={{ width: 200 }}>
            {getDateField('Reference Date', referenceDate, (date: Date) => {
              setReferenceDate(date);
            })}
          </div>
          <div className="column" style={{ width: 200 }}>
            {getReferenceNumberInput}
          </div>
        </>
      ) : null}
    </div>
  );

  const getPaymentDateRow = (
    <div className="row row-responsive parent-width justify-content-between align-items-start gap-5">
      <div className="position-relative parent-width">
        {getPaymentDateField(
          'Payment Date',
          paymentDate,
          (date: Date, warningMessage: any) => {
            if (warningMessage !== undefined) {
              showAlert('Invalid Date', warningMessage);
            }
            setPaymentDate(date);
          }
        )}
      </div>
      {isBankType() ? (
        getPaymentMethodInput()
      ) : (
        <div className="row"></div> // for alignment purpose
      )}
    </div>
  );

  const getReferenceDateRow = (
    <div className="row row-responsive parent-width justify-content-between gap-5 align-items-start">
      <div className="position-relative parent-width">
        {getDateField('Reference Date', referenceDate, (date: Date) => {
          setReferenceDate(date);
        })}
      </div>
      {getReferenceNumberInput}
    </div>
  );

  const getCurrencyRateInput = (
    <div className="column parent-width">
      <DKLabel text={'Currency Rate'} />
      <div
        className={
          'row border rounded  w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder p-1 mt-s ' +
          'focus:outline-none focus:ring-2 focus:ring-blue-200 bg-gray1'
        }
        style={{ border: '1px solid rgb(235, 235, 235)' }}
      >
        <div className="row currency-select-trigger align-items-center content-start">
          <div className="ml-1">{`1 ${accountCurrency?.currencyCode}`}</div>
          <div className="currency-dropdown-flag ml-2">
            <span
              className={`currency-dropdown-selector-flag flag ${accountCurrency?.currencyCode}`}
            ></span>
          </div>
        </div>
        <DKIcon
          className="ml-r"
          style={{
            transform: 'rotate(90deg)'
          }}
          src={DKIcons.ic_sort}
        />
        <div className="row align-items-start align-items-center content-end ml-s">
          <input
            className={`text-align-right outline-none hover:bg-blue-100 focus:bg-blue-100 bg-gray1`}
            value={formData?.exchangeRate}
            type={'number'}
            pattern={'[0-9]+'}
            onChange={(e: any) => {
              setFormData({ ...formData, exchangeRate: +e.target.value });
            }}
            style={{ width: '62%' }}
          />
          <div className="ml-1">{`${tenantInfo.currency}`}</div>
          <div className="currency-dropdown-flag ml-2">
            <span
              className={`currency-dropdown-selector-flag flag ${tenantInfo.currency}`}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );

  const getReportingCurrencyRow = (
    <div className="row row-responsive parent-width justify-content-between gap-5">
      {getReportingCurrency()}
      {isBankType() && accountCurrency?.currencyCode !== tenantInfo.currency ? (
        getCurrencyRateInput
      ) : (
        <div className="row"></div>
      )}
    </div>
  );

  const getReportingCurrencyRowForFullScreen = (
    <div className="row row-responsive parent-width gap-5">
      <div className="column" style={{ width: 250 }}>
        {getReportingCurrency()}
      </div>
      {isBankType() && accountCurrency?.currencyCode !== tenantInfo.currency ? (
        <div className="column" style={{ width: 420 }}>
          {getCurrencyRateInput}
        </div>
      ) : null}
    </div>
  );

  const verifyCheckNumber = (value: any) => {
    let account = getAccountObject(formData?.accountCodePayFrom);
    let usedChequeNumbers: any = [];
    usedChequeNumbers = selectedAccount?.usedChequeNumbers?.split(',');
    if (usedChequeNumbers?.length > 0 && usedChequeNumbers.includes(value)) {
      showAlert('', 'This Reference Number is already exists');
    }
  };
  const getAccountsNameList = (accounts: any) => {
    if (Utility.isEmpty(accounts)) {
      return [];
    } else {
      let result = accountsList.content.map((item: any) => {
        if (item.status === 'ACTIVE') {
          return item.name;
        }
      });
      return result;
    }
  };

  const getSelectedIndexOf = (value: any, array: any) => {
    let filterred = accountsList.content.filter(
      (item: any) => value === item.code
    );
    if (filterred.length > 0) {
      const first = filterred[0];
      if (Utility.isEmpty(first)) {
        return null;
      } else {
        return first;
      }
    } else {
      return null;
    }
  };

  const isFeeAccountRequired = () => {
    let isReq =
      formData?.receivePaymentFeeDtoList &&
      formData?.receivePaymentFeeDtoList.length > 0 &&
      formData?.receivePaymentFeeDtoList[0].amount
        ? true
        : false;
    return isReq;
  };

  const getFeeAccountInput = () => {
    let newData = Utility.getAccountsStructured(filteredAccounts);
    ConfigUtility.fillStructuredAccountArray(newData);
    let newStructuredData = ConfigUtility.structuredAccountsArr?.filter(
      (item: any) => item.status === STATUS_TYPE.ACTIVE
    );
    return (
      <DKInput
        title={'Fee Account (optional)'}
        required={isFeeAccountRequired()}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={
          formData?.receivePaymentFeeDtoList &&
          formData?.receivePaymentFeeDtoList.length > 0
            ? getSelectedIndexOf(
                formData?.receivePaymentFeeDtoList[0].accountCode,
                accountsList
              )
            : []
        }
        formatter={(obj: any) => {
          return obj.name;
        }}
        onChange={(account: any) => {
          Utility.isEmpty(account)
            ? setAccountFee('', null)
            : setAccountFee(account.code, null);
        }}
        readOnly={props.selectedAccount}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          title: 'Select Account',
          allowSearch: true,
          searchableKey: 'name',
          canEdit: false,
          canDelete: false,
          data: newStructuredData,
          renderer: (index: number, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          },
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const config: AccountAPIConfig = {
                ...AccountsService.apiConfig,
                Page: 0,
                SearchTerm: searchValue,
                Limit: 10,
                AccountGroupsString: '',
                Query: 'status=ACTIVE'
              };
              AccountsService.apiConfig = config;
              return (
                ApiConstants.URL.BASE + AccountsService.getAccountEndPoint()
              );
            },
            dataParser: (response: any) => {
              return Utility.getAccountsData(response?.content);
            },
            debounceTime: 300
          },

          // onSelect: (index: number, value: any) => {},
          button: {
            title: 'Add Account',
            className: 'bg-button text-white',
            onClick: () => {
              setShowAddAccountPopup(true);
            }
          }
        }}
      />
    );
  };

  const getFeeAccountRow = () => {
    return (
      <div className="row row-responsive parent-width justify-content-between align-items-start gap-5">
        {getFeeAccountInput()}
        {getFeeAmountField()}
      </div>
    );
  };

  const getFeeAccountRowForFullScreen = () => {
    return (
      <div className="row row-responsive parent-width align-items-end gap-5">
        <div className="column" style={{ width: 250 }}>
          {getFeeAccountInput()}
        </div>
        <div className="column" style={{ width: 250 }}>
          {getFeeAmountField()}
        </div>
      </div>
    );
  };

  const [currentRowTDSInfo, setCurrentRowTDSInfo] = useState<any>(null);
  const [isTDSDeducted, setIsTDSDeducted] = useState(false);
  const [lastUpdatedIndex, setLastUpdatedIndex] = useState<any>(null);
  const [showTdsCalculation, setShowTdsCalculation] = useState(false);

  const getAccountForAccountCode = (accCode: any) => {
    let filtered = accountsList?.content?.filter(
      (item: any) => item.code === accCode
    );
    if (filtered && filtered.length) {
      return filtered[0];
    } else {
      return null;
    }
  };

  const onTDSDeduct = (tdsData: any) => {
    setShowTds(true);
    let rows = [...records] as any;
    setCurrentRowTDSInfo(null);
    rows[lastUpdatedIndex].tdsInfoIndia = tdsData;
    let updatedState = { ...formData };
    rows = rows;
    let totalTDS = 0;
    rows.forEach((item: any, rowIndex: any) => {
      if (!Utility.isEmpty(item) && !Utility.isEmpty(item.tdsInfoIndia)) {
        totalTDS += item.tdsInfoIndia.tdsAmount;
      }
    });
    setTotalTdsAmount(totalTDS);
    // updatedState.summaryInfo = {
    //   ...updatedState.summaryInfo,
    //   totalTdsAmount: totalTDS,
    //   totalAmount:
    //     updatedState.summaryInfo.subTotal +
    //     updatedState.summaryInfo.taxAmount -
    //     totalTDS
    // };
    setFormData({ ...updatedState });
    setRecords(rows);
  };

  const onDeductTDS = ({ rowIndex, rowData }: any) => {
    let updatedState = { ...formData };
    let rows = [...records];
    const lineAmount = rowData.paymentAmount;
    const payableAccount = DEFAULT_TDS_PAYABLE_ACCOUNT_CODE;
    const assessableAmount = lineAmount;
    let natureOfPayment;
    let selectedAccountObj = rowData?.selectedAccount;

    if (selectedAccountObj) {
      natureOfPayment = selectedAccountObj.natureOfIncomePayment;
    }
    let documentDate = DateFormatService.getDateStrFromDate(
      paymentDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );

    if (!contact && !documentDate && !natureOfPayment) return;

    let tdsInfoData = {
      lineAmount,
      assessableAmount,
      incomePayment: natureOfPayment,
      payableAccount,
      contact: contact,
      documentDate: documentDate,
      isDeductedTds: isTDSDeducted
    };
    if (
      rowData?.tdsInfoIndia &&
      tdsInfoData.lineAmount === rowData.tdsInfoIndia.lineAmount
    ) {
      tdsInfoData = {
        ...rowData.tdsInfoIndia,
        contact: contact,
        isDeductedTds: isTDSDeducted,
        documentDate: documentDate
      };
    }

    rows[rowIndex] = {
      ...rowData,
      isTdsApplicableContact: contact?.tdsInfoIndia?.deductionApplicable,
      isTdsApplicableProduct: rowData?.product?.tdsApplicableIndia,
      isTdsApplicableAccount: false
    };
    setCurrentRowTDSInfo(tdsInfoData);
    setLastUpdatedIndex(rowIndex);
  };

  const onOpenITCOptions = ({ rowIndex, rowData }: any) => {
    let itcValue = rowData.itcIneligibleType;
    setLastUpdatedIndex(rowIndex);
    setCurrentRowITCInfo({ rowIndex: rowIndex, itcValue: itcValue });
  };

  const onITCOptionSelected = (itcData: any) => {
    let rows = [...records];
    setShowITCPopup(false);
    setCurrentRowITCInfo(null);
    rows[itcData.rowIndex].itcIneligibleType = itcData.itcValue;
    setRecords(rows);
  };

  const getRowContextMenu = (account: any) => {
    let contextMenu = [
      {
        title: 'Delete',
        icon: DKIcons.ic_delete,
        className: ' p-0',
        onClick: (data: any) => {
          onDelete(data.rowIndex);
        }
      }
    ];

    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      props.formType === MODULES_NAME.EXPENSE
    ) {
      contextMenu.push({
        title: 'ITC',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenITCOptions(data)
      });
    }

    if (
      props.formType === DOC_TYPE.PREPAYMENT &&
      contact &&
      contact?.tdsInfoIndia?.deductionApplicable &&
      account &&
      account.isTdsApplicable
    ) {
      contextMenu.push(
        {
          title: 'Deduct TDS',
          icon: DKIcons.ic_add,
          className: ' p-0',
          onClick: (data: any) => {
            const updatedState = formData;
            onDeductTDS({
              rowIndex: data.rowIndex,
              rowData: records[data.rowIndex]
            });
            setShowTdsCalculation(true);
          }
        },
        {
          title: 'Arrange Custom Fields',
          icon: DKIcons.ic_settings,
          className: ' p-0',
          onClick: (data: any) => setOpenAccountCFSettings(true)
        }
      );
    }
    return contextMenu;
  };

  const getRowButtons = (account: any) => {
    let contextMenu: any = [
      // {
      //   title: '',
      //   icon: DKIcons.ic_delete,
      //   className: ' p-0',
      //   onClick: (data: any) => {
      //     onDelete(data.rowIndex);
      //   }
      // }
    ];
    if (
      props.formType === DOC_TYPE.PREPAYMENT &&
      contact &&
      contact?.tdsInfoIndia?.deductionApplicable &&
      account &&
      account.isTdsApplicable
    ) {
      return null;
    } else {
      return contextMenu;
    }
  };

  const calculateTaxBifurcation = (record: any) => {
    let edited = { ...record };
    let lineGstType: GST_TYPE =
      props.formType === MODULES_NAME.EXPENSE
        ? edited?.gstType
          ? edited?.gstType
          : gstType
        : gstType;

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      switch (lineGstType) {
        case GST_TYPE.INTER:
          if (record?.selectedTaxCode?.additionalTaxIn) {
            edited.igstRate = 0.0;
            edited.taxBifurcation = [
              { IGST: 0.0 },
              { [record.selectedTaxCode.name]: edited.taxAmount }
            ];
          } else {
            edited.igstRate = edited.taxAmount;
            edited.taxBifurcation = [{ IGST: edited.taxAmount }];
          }
          break;
        case GST_TYPE.INTRA:
          if (record?.selectedTaxCode?.additionalTaxIn) {
            edited.cgstRate = 0.0;
            edited.sgstRate = 0.0;
            edited.taxBifurcation = [
              { CGST: edited.cgstRate },
              { SGST: edited.sgstRate },
              { [record.selectedTaxCode.name]: edited.taxAmount }
            ];
          } else {
            const devideGstVal = edited.taxAmount / 2;
            edited.cgstRate = devideGstVal;
            edited.sgstRate = devideGstVal;
            edited.taxBifurcation = [
              { CGST: devideGstVal },
              { SGST: devideGstVal }
            ];
          }
          break;
        case GST_TYPE.EXEMPT:
          edited.taxBifurcation = [{ EXEMPT: '' }];
          break;
      }
    } else {
      edited.taxBifurcation = [{ '': edited.taxAmount }];
    }
    return edited;
  };

  const calculateUSTax = (lineItems: any) => {
    let reqLineItems = lineItems.map((item: any) => {
      return {
        amount: item.paymentAmount,
        description: '',
        quantity: 1
      };
    });
    let reqObject: any = {
      lines: reqLineItems
    };
    if (
      !Utility.isEmpty(contact) &&
      !Utility.isEmpty(contact.shippingAddress)
    ) {
      let preferredAddress = contact.shippingAddress.filter(
        (item: any) => item.preferred
      );
      if (!Utility.isEmpty(preferredAddress)) {
        reqObject.shipTo = preferredAddress[0];
      }
    }

    TaxService.calculateUsTax(reqObject)
      .then(
        (taxObj: any) => {
          let lines = taxObj.lines;
          let editedRecords = [...records];
          for (let i = 0; i < taxObj.lines.length; i++) {
            if (indexToEdit === -1) {
              editedRecords[i].taxAmount = lines[i].tax;
              editedRecords[i].totalAmount =
                parseFloat(editedRecords[i].paymentAmount) +
                parseFloat(editedRecords[i].taxAmount);
            } else {
              if (indexToEdit === i) {
                editedRecords[i].taxAmount = lines[i].tax;
                editedRecords[i].totalAmount =
                  parseFloat(editedRecords[i].paymentAmount) +
                  parseFloat(editedRecords[i].taxAmount);
              }
            }
          }
          setRecords(editedRecords);
          setIndexToEdit(-1);
        },
        (err: any) => {}
      )
      .catch((err: any) => {});
  };

  const getDefaultSelectedClassCFFor = (existingCF: any) => {
    let accCustomFields = accountsCFData;
    const classCustomField =
      accCustomFields?.find(
        (ele: any) => ele.label === LOCATION_CLASS_ENUM.CLASS
      ) ?? [];
    let findObject = classCustomField?.attributes?.find(
      (item: any) =>
        item.dimensionId === existingCF.id && item.value === existingCF.value
    );
    return findObject ? findObject : existingCF.value;
  };

  const updateLineItemList = (rowData: any, rowIndex: any, columnKey?: any) => {
    let editedRecords = [...records];

    let edited: any;

    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      props.formType === MODULES_NAME.EXPENSE
    ) {
      edited = rowData;
    } else {
      edited = editedRecords[rowIndex];
    }

    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      props.formType === MODULES_NAME.EXPENSE
    ) {
      if (formData?.applyRcmCheck === true) {
        if (
          Utility.isEmpty_v2(editedRecords[rowIndex]?.tax) &&
          Utility.isEmpty_v2(editedRecords[rowIndex]?.taxList)
        ) {
          let zeroGst = taxData.find((tax) => tax?.taxCode === 'GST0');
          editedRecords[rowIndex].selectedTaxCode = zeroGst;
          editedRecords[rowIndex].taxCode = zeroGst?.code;
        } else {
          editedRecords[rowIndex].taxCode =
            editedRecords[rowIndex]?.selectedTaxCode?.code;
        }
      }
    }

    edited.invalidFields = edited.invalidFields
      ? [...edited.invalidFields].filter((field) => field !== columnKey)
      : [];


    let isRcmApplied = false;
    if (
      getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
      !Utility.isEmpty(contact) &&
      props.formType === MODULES_NAME.EXPENSE
    ) {
      edited.itcAdjustment = edited?.selectedAccount?.itcAdjustment;

      isRcmApplied = rcmAppliedIndiaWithCheckRCMApply(
        DOC_TYPE.Direct_S_Expense,
        contact?.gstTreatment,
        edited,
        formData?.applyRcmCheck
      );

      edited.isRcmApplied = isRcmApplied;
    }

    if (columnKey === 'selectedAccount') {
      edited.selectedAccount = rowData.selectedAccount;
      edited.documentCode = rowData.selectedAccount.code;
      edited.accountName = rowData.selectedAccount.name;
      edited.description = rowData.selectedAccount.name;
      let lineCustomFields = rowData[columnKey]?.customField;

      if (lineCustomFields?.length > 0) {
        lineCustomFields?.forEach((item: any) => {
          edited[item.label] = item.value;
        });
      }
      edited.customField = lineCustomFields;

      if (lineCustomFields?.length > 0) {
        // lineCustomFields?.forEach((item: any) => {
        //   edited[item.label] = item.value;
        // });
        // Handle custom fields when account is selected
        const availableCFs: any[] = accountsCFData;
        let cfs: any[] = [];
        availableCFs.forEach((availableCF: any) => {
          let cfToUpdate = {
            id: availableCF.id,
            shortName: availableCF.shortName,
            module: MODULES_NAME.ACCOUNT,
            code: availableCF.code,
            label: availableCF.label,
            value: ''
          };
          let valueOfCF = '';
          const existingCF = rowData[columnKey]?.customField?.find(
            (cf: any) => cf.id === availableCF.id
          );
          if (existingCF) {
            if (
              typeof existingCF.value !== 'undefined' &&
              existingCF.value !== null &&
              existingCF.value !== ''
            ) {
              if (
                availableCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
              ) {
                edited[availableCF.id] = DateFormatService.getDateFromStr(
                  existingCF.value,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                );
              } else if (
                availableCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.USER.toLowerCase()
              ) {
                const cfValue = availableCF?.attributes?.find(
                  (attr: any) => attr.code === existingCF.value
                );
                edited[availableCF.id] = cfValue ? cfValue : '';
              } else if (
                availableCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
              ) {
                const cfValue = availableCF?.attributes?.find(
                  (attr: any) => attr.value === existingCF.value
                );
                edited[availableCF.id] = cfValue ? cfValue : '';
              } else {
                edited[availableCF.id] =
                  getDefaultSelectedClassCFFor(existingCF);
              }
            } else {
              edited[availableCF.id] = '';
            }
            valueOfCF = existingCF.value;
          } else {
            edited[availableCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        });
        edited = {
          ...edited,
          customField: cfs
        };
      }
      if (Utility.isEmpty(edited.selectedAccount)) {
        edited.invalidFields.push('selectedAccount');
      }

      edited.taxCode = edited?.selectedAccount?.taxCode;

      const taxDetails = taxData.find((tax) => tax.code === edited.taxCode);

      edited.selectedTaxCode = taxDetails;
    } else {
      if (
        columnKey !== 'description' &&
        columnKey !== 'paymentAmount' &&
        columnKey !== 'selectedTaxCode' &&
        columnKey !== 'taxBifurcation' &&
        columnKey !== 'totalAmount'
      ) {
        //update column values for custom fields directly here
        // edited[columnKey] = rowData[columnKey];
        const configForColumnKey = columnConfig.find(
          (config: any) => config.key === columnKey
        );
        if (configForColumnKey?.isCustomField) {
          const cfInfo: any = customFieldsData.find(
            (cf: any) => cf.id === columnKey
          );
          if (cfInfo) {
            if (
              cfInfo.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ) {
              edited[columnKey] = new Date(rowData[columnKey]);
            } else if (
              cfInfo.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.USER.toLowerCase()
            ) {
              edited[columnKey] = rowData[columnKey];
            } else if (
              cfInfo.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
            ) {
              edited[columnKey] =
                rowData[columnKey].value === 'None' ? null : rowData[columnKey];

              cfUpdatedTimeMap.current = {
                ...cfUpdatedTimeMap.current,
                [cfInfo.id]: new Date().getTime()
              };
              const availableAccountCFs: any[] = accountsCFData;
              const { rowData: updatedRowData } =
                updateRowDataWithParentCFValues(
                  rowData[columnKey].value === 'None'
                    ? null
                    : rowData[columnKey],
                  { ...edited },
                  cfInfo,
                  availableAccountCFs
                );
              edited = updatedRowData;
            } else {
              edited[columnKey] = rowData[columnKey];
            }
          }
        }
      }
    }
    if (columnKey === 'description') {
      edited.description = rowData.description;
    }

    if (columnKey === 'paymentAmount') {
      edited.paymentAmount = Math.abs(rowData.paymentAmount);
      if (
        edited.paymentAmount === '0' ||
        edited.paymentAmount === '' ||
        edited.paymentAmount === undefined ||
        edited.paymentAmount === null
      ) {
        edited.invalidFields.push('paymentAmount');
      }
    }

    if (columnKey === 'selectedTaxCode') {
      edited.selectedTaxCode = rowData.selectedTaxCode;
      edited.taxCode = rowData.selectedTaxCode
        ? rowData.selectedTaxCode.code
        : '';
      if (
        rowIndex === records.length - 1 &&
        tenantInfo.country === COUNTRY_CODES.UK
      ) {
        setFormData({
          ...formData,
          memo: rowData.selectedTaxCode?.defaultMemoUk ?? ''
        });
      }
    }
    if (columnKey === 'customField') {
      edited.customField = [rowData.customField];
    }
    let calculatedItem = calculateTaxGroupDetails(edited);
    if (columnKey !== 'description') {
      edited.totalAmount = calculatedItem['lineLevelTotal'];
    }
    if (
      (tenantInfo.country === COUNTRY_CODES.UK ||
        tenantInfo.country === COUNTRY_CODES.US) &&
      !rowData?.selectedTaxCode?.isTaxGroup &&
      columnKey === 'taxAmount'
    ) {
      edited.taxAmount = rowData.taxAmount ? rowData.taxAmount : 0;
      if (tenantInfo.country === COUNTRY_CODES.US) {
        edited.totalAmount = edited.totalAmount + parseFloat(edited.taxAmount);
      }
    } else {
      if (columnKey !== 'description') {
        edited.taxAmount = calculatedItem['taxAmount'];
      }
    }
    edited = calculateTaxBifurcation(edited);
    edited['taxList'] = calculatedItem['taxList'];

    if (edited?.isRcmApplied) {
      edited['totalAmount'] = edited['totalAmount'] - edited['taxAmount'];
      edited['cgstRate'] = 0;
      edited['sgstRate'] = 0;
      edited['igstRate'] = 0;
    }

    //calculate US tax for Direct Deposit
    editedRecords[rowIndex] = edited;
    if (columnKey) {
      setRecords(editedRecords);
    }
    if (columnKey === 'paymentAmount') {
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        props.formType === DOC_TYPE.DEPOSIT
      ) {
        calculateUSTax(editedRecords);
      }
    }
    if (!columnKey) {
      return edited;
    }
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    updateLineItemList(rowData, rowIndex, columnKey);
  };

  const onDelete = (rowIndex: any) => {
    let rows = [...records];
    rows.splice(rowIndex, 1);
    let lastIndex = rows.length - 1 ?? 0;
    setFormData({
      ...formData,
      memo: rows[lastIndex]?.selectedTaxCode?.defaultMemoUk ?? ''
    });
    setRecords(rows);
  };

  const getAccountsCFData = async () => {
    try {
      const cfData = await CustomFieldService.getCustomFields({
        status: 'ACTIVE',
        limit: '1000',
        module: 'ACCOUNT'
      });
      let sortedCF = cfData?.content?.length ? cfData?.content : [];
      sortedCF.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setAccountsCFData(sortedCF);
      return sortedCF;
    } catch (err: any) {}
  };

  const updateLineLevelCForder = (updatedCFs: any[]) => {
    let accountCFs: any[] = updatedCFs;
    accountCFs.sort(
      (field1: any, field2: any) =>
        field1.customFieldIndex - field2.customFieldIndex
    );

    // const sortedAccountCFsIds = accountCFs.map((accCF: any) => accCF.id);
    const existingCFConfigs = columnConfig.filter(
      (config: any) => config.isCustomField
    );
    const existingConfigsWithoutCFs = columnConfig.filter(
      (config: any) => !!!config.isCustomField
    );
    let updatedCFConfigs: any[] = [];
    accountCFs.forEach((accCF: any) => {
      const existingCFConfig = existingCFConfigs.find(
        (cf: any) => cf.id === accCF.id
      );
      if (existingCFConfig) {
        updatedCFConfigs.push({
          ...existingCFConfig
        });
      }
    });

    const classData = systemDimensions?.content?.find(
      (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
    );
    updatedCFConfigs = updatedCFConfigs.map((cf: any) => {
      if (cf?.id === classData?.id) {
        cf = {
          ...cf,
          hidden: hideClassColumn()
        };
      }
      return cf;
    });
    setColumnConfig(
      [...existingConfigsWithoutCFs, ...updatedCFConfigs].filter(
        (col: any) => !col.hidden
      )
    );
  };

  const getAccountCustomFieldSettings = () => {
    const accountCFs: any[] = accountsCFData;
    return (
      <CustomFieldSettings
        fields={accountCFs}
        moduleName={MODULES_NAME.ACCOUNT}
        onSave={async () => {
          try {
            const updatedOrderedCFs = await getAccountsCFData();
            setOpenAccountCFSettings(false);
            updateLineLevelCForder(updatedOrderedCFs);
          } catch (err: any) {
            console.error('Error fetching product CFs: ', err);
          }
        }}
        onClose={() => setOpenAccountCFSettings(false)}
      />
    );
  };

  const getAddClassForm = () => (
    <AddClass
      data={null}
      onSuccess={() => {
        dispatch(fetchCategoryDimensions());
        dispatch(fetchClassesByDimensionId());
      }}
      onCancel={() => {
        setShowAddClassPopup(false);
      }}
    />
  );

  const onRowClick = ({ columnData, rowIndex, rowData }: any) => {
    if (
      tenantInfo.country === COUNTRY_CODES.UK &&
      columnData.id === 'taxAmount' &&
      rowData?.selectedTaxCode?.isTaxGroup &&
      (props.formType === DOC_TYPE.DEPOSIT ||
        props.formType === DOC_TYPE.EXPENSE ||
        props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT)
    ) {
      setIndexToEdit(rowIndex);
    }
    if (
      tenantInfo.country === COUNTRY_CODES.US &&
      (columnData.id === 'amount' || columnData.id === 'account') &&
      props.formType === DOC_TYPE.DEPOSIT
    ) {
      setIndexToEdit(rowIndex);
    }
    const availableCFs: any[] = accountsCFData;
    updateColumnConfigOnRowClick(
      columnData,
      rowData,
      columnConfig,
      availableCFs,
      cfUpdatedTimeMap.current
    );
  };

  const getColumnConfigForGrid = () => {
    let config: any = [
      ...columnConfig,
      {
        id: 'action',
        key: 'action',
        name: '',
        type: INPUT_TYPE.BUTTON,
        width: 60,
        options: []
      }
    ];
    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
      if (
        props.formType === DOC_TYPE.EXPENSE ||
        props.formType === DOC_TYPE.PREPAYMENT ||
        props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
      ) {
        config = config.filter((col: any) => col.key !== 'taxAmount');
      }
    }
    return [...config];
  };

  const getDataGrid = () => (
    <div
      className="column align-items-start parent-width"
      style={{ marginTop: isFullScreenMode ? 20 : 0 }}
    >
      <DKDataGrid
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={false}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnShift={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={[...records].map((item: any) => {
          return {
            ...item,
            rowContextMenu: getRowContextMenu(item.selectedAccount),
            rowButtons: getRowButtons(item.selectedAccount)
          };
        })}
        columns={getColumnConfigForGrid()}
        onRowUpdate={onRowUpdate}
        onRowClick={onRowClick}
      />
    </div>
  );

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowTdsCalculation(false);
        break;
      case POPUP_CLICK_TYPE.SUBMIT_TDS_AMOUNT:
        expenseDepositFormRef.current.storeCallbacksRef.submitTDSAmount();
        break;
      case POPUP_CLICK_TYPE.CREATE_TAX:
        expenseDepositFormRef.current?.storeCallbacksRef.createTax();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        showAddAccountPopup && setShowAddAccountPopup(false);
        showTaxPopup && setShowTaxPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUBMIT:
        expenseDepositFormRef.current.storeCallbacksRef.submitTDSAmount =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DEDUCT_TDS_SUCCESS:
        if (passingData?.data?.incomePayment) {
          setIsTDSDeducted(true);
          onTDSDeduct(passingData.data);
          showTdsCalculation && setShowTdsCalculation(false);
        }
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX:
        expenseDepositFormRef.current.storeCallbacksRef.createTax =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_COA:
        expenseDepositFormRef.current.storeCallbacksRef.createCoa =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_COA_SUCCESS:
        setShowAddAccountPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX:
        expenseDepositFormRef.current.storeCallbacksRef.createTax =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_TAX_SUCCESS:
        // if (lastUpdatedIndex != null) {
        // let rows = [...records];
        // rows[lastUpdatedIndex]['tax'] = passingData.data;
        // updateConfig();
        // setRecords(rows);
        // }
        break;
    }
  };

  const getTDSCalculationPopUp = () => {
    return (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={`TDS Calculation`}
        btnList={[
          {
            title: `Cancel`,
            class: 'border-m mr-s',
            clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
          },
          {
            title: `Submit`,
            class: 'bg-app text-white mr-ss',
            clickAction: POPUP_CLICK_TYPE.SUBMIT_TDS_AMOUNT
          }
        ]}
        width="40%"
        height="65%"
        disableClickOutside={true}
      >
        <TDSCalculation
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          tdsData={currentRowTDSInfo}
        />
      </PopupWrapper>
    );
  };

  const getAddCoa = () => {
    return (
      <AddCoaPopup
        populateFormData={null}
        onCancel={() => {
          setShowAddAccountPopup(false);
        }}
      />
    );
  };

  const catchClicksTax = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        showTaxPopup && setShowTaxPopup(false);
        break;
      case POPUP_CLICK_TYPE.CREATE_TAX:
        expenseDepositFormRef.current?.storeCallbacksRef.createTax();
        break;
    }
  };

  const getTaxForm = () => (
    <PopupWrapper
      clickAction={catchClicksTax}
      type={POPUP_TYPE.POPUP}
      title={t(`SETTINGS.TAX.ADD_TAX_RATE`)}
      btnList={[
        {
          title: t(`SETTINGS.TAX.BUTTON.CANCEL`),
          class: 'border-m mr-s',
          clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
        },
        {
          title: t(`SETTINGS.TAX.BUTTON.CREATE`),
          class: 'bg-app text-white mr-ss',
          clickAction: POPUP_CLICK_TYPE.CREATE_TAX
        }
      ]}
      width="45%"
      height="75%"
    >
      <AddTax
        populateFormData={null}
        passingInteraction={(callback: CallBackPayloadType) => {
          parentChildInteraction(callback);
        }}
      />
    </PopupWrapper>
  );
  const getTaxExchangeRatePopup = () => {
    return (
      <GSTExchangeRateDialog
        TaxResidencyCurrency={
          COMPLAINCE_CURRENCY[AuthService.userDetails.country]
        }
        TransferCurrency={
          selectedAccount ? selectedAccount.currency : tenantInfo.currency
        }
        GstExchangeRate={formData?.gstExchangeRate}
        onClose={() => setShowTaxExchangeRatePopup(false)}
        onUpdateTaxRate={(rate: number) => {
          setFormData((prevState: any) => {
            return {
              ...prevState,
              gstExchangeRate: rate
            };
          });
          setShowTaxExchangeRatePopup(false);
        }}
      />
    );
  };

  return (
    <div className="column mt-2 parent-width px-4 py-1 gap-2">
      {props.transactionObj && getHeader()}
      {!isFullScreenMode && (
        <div className="column parent-width gap-5">
          {getContactRow}
          {applyRCMCheckbox()}
          {getPaymentDateRow}
          {isBankType() && getReferenceDateRow}
          {getReportingCurrencyRow}
          {(props.formType === DOC_TYPE.DEPOSIT ||
            props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT) &&
            isBankType() &&
            getFeeAccountRow()}
          {!!tenantInfo.additionalSettings?.MULTI_COMPANY &&
            intercompanyTxnCheckbox}
        </div>
      )}
      {isFullScreenMode && (
        <div className="column parent-width gap-5">
          {getContactRowForFullScreen}
          {getAccountRowForFullScreen}
          {applyRCMCheckbox()}
          {getReportingCurrencyRowForFullScreen}
          {(props.formType === DOC_TYPE.DEPOSIT ||
            props.formType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT) &&
            isBankType() &&
            getFeeAccountRowForFullScreen()}
          {!!tenantInfo.additionalSettings?.MULTI_COMPANY &&
            intercompanyTxnCheckbox}
        </div>
      )}
      {getCustomFieldView()}
      {getDataGrid()}
      <div
        className="row justify-content-between align-items-start"
        style={{ marginTop: -16 }}
      >
        {getAddAccountButton}
        {(tenantInfo.country === TAX_SYSTEM.INDIA_GST ||
          getTenantTaxSystem() === TAX_SYSTEM.SG) &&
          taxInclusiveCheckbox}
      </div>
      {getBottomSection}
      {showTdsCalculation && getTDSCalculationPopUp()}
      {showAddAccountPopup && getAddCoa()}
      {showTaxPopup && getTaxForm()}
      {showTaxExchangeRatePopup && getTaxExchangeRatePopup()}
      {showAddClassPopup && getAddClassForm()}
      {indexToEdit >= 0 && tenantInfo.country === COUNTRY_CODES.UK && (
        <TaxGroupDistribution
          taxList={records?.[indexToEdit]?.taxList}
          onCancel={() => {
            setIndexToEdit(-1);
          }}
          onApply={(taxList: any[]) => {
            let recordsCopy = [...records];
            let itemToEdit = recordsCopy[indexToEdit];
            itemToEdit.taxList = taxList;
            itemToEdit.taxAmount = taxList?.reduce(
              (prev: number, current: any) => prev + Number(current.taxAmount),
              0
            );
            itemToEdit.totalAmount =
              itemToEdit.taxAmount + itemToEdit.paymentAmount;
            setRecords(recordsCopy);
            setIndexToEdit(-1);
          }}
        />
      )}
      {openAccountCFSettings && getAccountCustomFieldSettings()}
      {showITCPopup && getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST && (
        <ItcOptions
          itcData={currentRowITCInfo}
          setITCIneligibleOption={(data: any) => {
            onITCOptionSelected(data);
          }}
          onClose={() => {
            setCurrentRowITCInfo(null);
            setShowITCPopup(false);
          }}
        />
      )}
      {showDefaultAccountSettings && getDetailAccountSelectionPopup()}
    </div>
  );
}
