import { INPUT_TYPE } from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  LABELS,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE
} from '../../../../../Constants/Constant';
import { OrderInitialState } from '../../../../../Models/Order';
import DateFormatService from '../../../../../Services/DateFormat';
import OperationsService from '../../../../../Services/MRP/Operations';
import ProductService from '../../../../../Services/Product';
import WarehouseManagementHelper, {
  WAREHOUSE_TYPE
} from '../../../../../SharedComponents/WarehouseManagement/WarehouseManagementHelper';
import Utility from '../../../../../Utility/Utility';
import { getUpdatedPurchaseOrderObject } from '../../../../PurchaseOrders/PurchaseOrderHelper';
import { WORK_ORDER_PR_PO } from '../../../../Settings/AdvancedSettings/AdvancedSettings';
import {
  BOM_EXPLOSION_COLUMN_KEYS,
  WORK_ORDER_STATUS
} from '../../../Constants/MRPColumnConfigs';
import {
  calculateTotalSubstituteAlloted,
  getOperationCost,
  getOperatorCostForOperation,
  getWorkstationCost,
  isAutoCloseWOEnabled,
  populateInnerBomConfigurationInWoItemsFromBomExplosionConfig,
  populateProductDetailWithSelectedOrDefaultBom
} from '../../WorkOrderHelper';
import { IRawMaterial, IRawMaterialListProps } from './RawMaterialModel';
import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';
import { Store } from '../../../../../Redux/Store';
import { selectWarehouse } from '../../../../../Redux/Slices/WarehouseSlice';
import {
  IWorkOrder,
  getDefaultCustomNumberFormatForWorkOrder
} from '../../../../../Services/MRP/WorkOrder';
import { selectOperators } from '../../../../../Redux/Slices/MRP/OperatorSlice';
import { selectIsWOAdhocEnable } from '../../../../../Redux/Slices/MRP/SettingsSlice';
import { REQUIRED_ITEM_TABLE } from '../../../Constants/TableConstant';
import { fetchCustomNumberFormatForAll } from '../../../../../Redux/Slices/CustomNumberFormat';
import WarehouseService from '../../../../../Services/Warehouse';

export default class RawMaterialHelper {
  static COLUMN = [
    {
      name: 'Item',
      key: 'itemName',
      type: INPUT_TYPE.DROPDOWN,
      width: 145,
      required: true,
      editable: true,
      dropdownConfig: {
        title: '',
        allowSearch: true,
        searchableKey: 'name',
        className: 'shadow-m',
        searchApiConfig: {},
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      name: 'Location',
      key: 'sourceWarehouse',
      type: INPUT_TYPE.TEXT,
      width: 160
    },
    {
      name: 'Available',
      key: 'availableQty',
      type: INPUT_TYPE.NUMBER,
      width: 100
    },
    {
      name: 'Alloted/Required',
      key: 'requiredQty',
      type: INPUT_TYPE.NUMBER,
      width: 150,
      required: false,
      editable: true
    },
    // {
    //   name: 'UOM',
    //   key: 'uom',
    //   type: INPUT_TYPE.DROPDOWN,
    //   width: 90,
    //   required: false,
    //   editable: false
    // },
    {
      name: 'Available(Target WH)',
      key: 'availableQtyInTargetWH',
      type: INPUT_TYPE.NUMBER,
      hidden: true /* Only available when warehouse tagging enabled */,
      width: 165
    },
    {
      name: 'Unit Cost',
      key: 'purchasePrice',
      type: INPUT_TYPE.NUMBER,
      width: 100
    },
    {
      name: 'Total Cost',
      key: 'amount',
      type: INPUT_TYPE.NUMBER,
      width: 100
    },
    {
      name: 'WO Code',
      key: 'workOrderChildDetails',
      type: INPUT_TYPE.TEXT,
      width: 100
    },
    {
      name: '',
      key: 'actions',
      type: INPUT_TYPE.BUTTON,
      width: 170
    }
  ];

  static getRawMaterialColumns() {
    return RawMaterialHelper.COLUMN;
  }

  static isNonServiceProduct = (product: any) => {
    return (
      [PRODUCT_TYPE.BILL_OF_MATERIALS, PRODUCT_TYPE.TRACKED].includes(
        product?.itemName?.type
      ) &&
      [
        ADVANCE_TRACKING.BATCH,
        ADVANCE_TRACKING.SERIAL,
        ADVANCE_TRACKING.NONE
      ].includes(product?.itemName.advancedTracking)
    );
  };

  static getDefaultActiveWarehouse() {
    const warehousesData = selectWarehouse(Store.getState());
    let activeWarehouses =
      warehousesData &&
      warehousesData.content &&
      warehousesData.content.filter(
        (item: any) => item.active && item.warehouseType === WAREHOUSE_TYPE.NONE
      );

    let selectedWarehouse =
      activeWarehouses?.find((warehouse: any) => warehouse.primary === true) ||
      activeWarehouses[0];
    return selectedWarehouse;
  }

  static isNoneTrackedItemAssigned(item: any) {
    const totalQuantity =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: number, b: any) => Number(a) + parseFloat(b['quantity']),
          0
        )) ||
      0;
    let totalIncludingSubstitute =
      totalQuantity + calculateTotalSubstituteAlloted(item);
    return Number(item.actualRequiredQty) === Number(totalIncludingSubstitute);
  }

  static isSerialItemAssigned(item: any) {
    let totalQuantity =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: any, b: any) => (a += parseFloat(b['quantity'])),
          0
        )) ||
      0;
    totalQuantity = Utility.getUomQuantityWithoutRoundOff(
      totalQuantity,
      item?.documentUOMSchemaDefinition
    );
    let totalIncludingSubstitute =
      totalQuantity + calculateTotalSubstituteAlloted(item);

    return Number(totalIncludingSubstitute) === item?.requiredQty;
  }

  static isBatchItemAssigned(item: any) {
    const totalItem =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: number, b: any) => Number(a) + parseFloat(b['quantity']),
          0
        )) ||
      0;

    let totalIncludingSubstitute =
      totalItem + calculateTotalSubstituteAlloted(item);

    return totalIncludingSubstitute === item.requiredQty;
  }

  static callFetchProductAPI(pid: any) {
    return ProductService.getProductsByProductIds([pid])
      .then((res) => {
        return res;
      })
      .catch((err) => {});
  }

  static async createWorkOrderPayload(
    selectedRawMaterial: IRawMaterial,
    product: any,
    taggedWHInventoryDetails: any,
    parentWorkOrderData: IWorkOrder,
    isFromBomExplosion?: boolean
  ) {
    const defaultNumberFormat = getDefaultCustomNumberFormatForWorkOrder();
    const allWarehouses = await WarehouseService.getAllWarehouses();

    const operationIds = product?.bomOperationsConfiguration?.map(
      (ops: any) => ops?.operationId
    );
    const operatorData = selectOperators(Store.getState())?.content;
    let operationDetails: any = [];
    if (!Utility.isEmpty(operationIds)) {
      operationDetails = await OperationsService.getOperationsById(operationIds)
        .then((res: any) => {
          return res;
        })
        .catch((err: any) => {});
    }

    if (product && !product.selectedBom) {
      product = populateProductDetailWithSelectedOrDefaultBom(product);
    }

    let availableQty = selectedRawMaterial?.availableQty ?? 0;
    let requiredQty = selectedRawMaterial?.requiredQty ?? 0;
    let shortfallQty: any = 1;
    if (selectedRawMaterial.shortFallQty) {
      shortfallQty = selectedRawMaterial.shortFallQty;
    } else {
      const isShortFall = Number(availableQty) <= Number(requiredQty);

      if (isShortFall) {
        shortfallQty = Math.abs(Number(availableQty) - Number(requiredQty));
      }

      shortfallQty = shortfallQty > 0 ? shortfallQty : 1;
    }

    let warehouse = RawMaterialHelper.getDefaultActiveWarehouse();
    if (Utility.isRRBTaggingEnabled()) {
      warehouse = parentWorkOrderData?.targetWarehouse;
    }
    if (
      Utility.isBinAllocationMandatory() ||
      Utility.isWarehouseTaggingEnabled()
    ) {
      warehouse = parentWorkOrderData?.targetWarehouse;

      if (
        taggedWHInventoryDetails &&
        !taggedWHInventoryDetails.allItemsAvailableInTaggedWH
      ) {
        shortfallQty = taggedWHInventoryDetails?.shortFallQty ?? shortfallQty;
      }
    } else {
      const whFromDefaultProduct = allWarehouses?.content?.find(
        (filteredWH: any) =>
          filteredWH?.code === product?.inventory?.warehouseCode
      );
      warehouse = whFromDefaultProduct;
    }

    if (Utility.isEmptyObject(warehouse)) {
      warehouse = RawMaterialHelper.getDefaultActiveWarehouse();
    }

    if (Utility.isNotEmpty(selectedRawMaterial?.documentUOMSchemaDefinition)) {
      shortfallQty = Utility.getUomWarehouseQuantity(
        shortfallQty,
        selectedRawMaterial?.documentUOMSchemaDefinition
      );
    }

    const bomMetaCode = product?.selectedBom?.code ?? '';

    const isAdhocEnabled = selectIsWOAdhocEnable(Store.getState());

    let innerBomProductDetails =
      selectedRawMaterial[REQUIRED_ITEM_TABLE.INNER_BOM_PRODUCT_DETAILS];
    if (isFromBomExplosion) {
      const currentRawMaterialInnerBomConfig =
        populateInnerBomConfigurationInWoItemsFromBomExplosionConfig({
          bomExplosionData: selectedRawMaterial
        });

      innerBomProductDetails =
        currentRawMaterialInnerBomConfig?.innerBomProductDetails;
    }
    let attachments: any[] = product?.attachments || [];
    if (!Utility.isEmpty(product?.selectedBom?.attachments)) {
      attachments = [...attachments, ...product?.selectedBom?.attachments];
    }
    let payload = [
      {
        bomMetaCode: isAdhocEnabled ? null : bomMetaCode,
        adhocBomMetaCode: isAdhocEnabled ? bomMetaCode : null,
        attachments: attachments,
        parentWorkOrderCode: parentWorkOrderData?.workOrderCode,
        sequenceFormat: defaultNumberFormat?.id,
        productCode: product?.productId,
        productName: product?.name,
        manufactureQuantity: shortfallQty,
        actualQuantity: shortfallQty,
        plannedYield: 100,
        actualYield: 100,
        sourceWarehouseCode: '',
        inProgressWarehouseCode: '',
        plannedEndDate: new Date(),
        plannedStartDate: new Date(),
        actualStartDate: new Date(),
        actualEndDate: new Date(),
        deliveryDate: new Date(),
        status: WORK_ORDER_STATUS.OPEN,
        workOrderSourceDetails: parentWorkOrderData?.workOrderSourceDetails ?? [
          {
            workOrderSource: 'NONE'
          }
        ],
        operationCostDetails: {
          actualOperatingCost: 0,
          additionalOperatingCost: 0,
          plannedOperatingCost: 0,
          totalOperatingCost: 0
        },
        warehouseInventoryData: [
          {
            advancedTrackingData: [],
            quantity: WarehouseManagementHelper.isRRBEnabledForWarehouse(
              parentWorkOrderData?.targetWarehouse
            )
              ? 0
              : shortfallQty,
            uomQuantity: WarehouseManagementHelper.isRRBEnabledForWarehouse(
              parentWorkOrderData?.targetWarehouse
            )
              ? 0
              : shortfallQty,
            warehouseCode: warehouse?.code,
            warehouseName: warehouse?.name
          }
        ],
        workOrderItems: product?.bomProductsConfiguration?.map((item: any) => {
          const subComponentInnerBomDetails = innerBomProductDetails?.find(
            (innerBomComponent: any) =>
              innerBomComponent.productId === item?.productCode
          );
          const allSubstitutes =
            item?.[REQUIRED_ITEM_TABLE.BOM_PRODUCT_SUBSTITUTE_DETAILS] || [];
          [
            ...(item?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES] || []),
            ...(subComponentInnerBomDetails?.[
              REQUIRED_ITEM_TABLE.BOM_PRODUCT_SUBSTITUTE_DETAILS
            ] || [])
          ].forEach((cachedSubstitute: any) => {
            const existingAvailableSubstitute = allSubstitutes.find(
              (existingSubstitute: any) =>
                existingSubstitute.productId === cachedSubstitute.productId
            );
            if (existingAvailableSubstitute) {
              existingAvailableSubstitute.isProductPicked =
                cachedSubstitute?.isProductPicked ?? false;
            } else {
              allSubstitutes.push(cachedSubstitute);
            }
          });

          const itemQty = Utility.isNotEmpty(item?.documentUOMSchemaDefinition)
            ? item?.uomQuantity
            : item?.quantity;
          const componentPlannedQuantity =
            Number(shortfallQty) * Number(itemQty);
          return {
            itemId: item?.itemId,
            plannedQuantity: componentPlannedQuantity,
            plannedUomQuantity: componentPlannedQuantity,
            producedQuantity: componentPlannedQuantity,
            producedUomQuantity: componentPlannedQuantity,
            productCode: item?.productCode,
            warehouseInventoryData: [],
            productSubstitutesDetails: allSubstitutes
              .map((substitute: any) => ({
                ...substitute,
                productCode: substitute.productCode || substitute.productId,
                productDocSeqCode:
                  substitute.productDocSeqCode ||
                  substitute.documentSequenceCode,
                warehouseInventoryData: substitute.warehouseInventoryData || []
              }))
              .filter((substitute: any) => substitute.isProductPicked),
            produceProductType: item?.produceProductType ?? '',
            costPerUnit: item?.cost ?? 0,
            innerBomProductDetails:
              subComponentInnerBomDetails?.innerBomProductDetails,
            documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
            stockUom: item?.stockUom,
            quantity: item?.quantity,
            uomQuantity: item?.quantity,
            uomSchemaDto: item?.uomSchemaDto
          };
        }),
        workOrderOperations:
          operationDetails?.map((woOps: any) => {
            let selectedOperators: any[] = [];
            let operationOperators: any[] = [];
            let totalOperationCost = getOperationCost(woOps, shortfallQty) ?? 0;
            let totalWorkstationCost =
              getWorkstationCost(woOps, shortfallQty) ?? 0;

            let selectedOperation = operationDetails?.find(
              (operation: any) => woOps.id === operation.id
            );
            let tempOperator: any = '';
            if (
              !Utility.isEmpty(operatorData) &&
              !Utility.isEmpty(selectedOperation?.operators)
            ) {
              selectedOperation.operators.forEach((operator: any) => {
                tempOperator = operatorData.find(
                  (ele: any) => ele.id === operator.operatorId
                );
                if (!Utility.isEmpty(tempOperator)) {
                  selectedOperators.push({
                    ...tempOperator,
                    operationTime: selectedOperation?.operationTime || 0,
                    operationId: selectedOperation?.id
                  });
                  operationOperators.push({
                    ...tempOperator,
                    operationTime: selectedOperation?.operationTime || 0,
                    operationId: selectedOperation?.id
                  });
                }
              });
            }
            let totalOperatorCost =
              getOperatorCostForOperation(
                woOps?.operationTime,
                operationOperators,
                shortfallQty
              ) ?? 0;

            const materialOperationData =
              product?.bomOperationsConfiguration?.find(
                (operation: any) => operation?.operationId === woOps?.id
              );

            return {
              completedQuantity: 0,
              operationCode: woOps?.operationCode,
              description: woOps?.description,
              itemId: woOps?.id,
              operationTime: woOps?.operationTime,
              status: 'PENDING',
              operationId: woOps?.id,
              operationCost: totalOperationCost,
              operatorCost: totalOperatorCost,
              workstationCost: totalWorkstationCost,
              operationDependency: materialOperationData?.operationDependency,
              processType: woOps?.processType,
              qcNeeded: woOps?.qcNeeded ?? false,
              productCode: woOps?.productCode ?? woOps?.product ?? null
            };
          }) ?? [],
        productDocSeqCode: product?.documentSequenceCode,
        isAutoClosed: isAutoCloseWOEnabled() && operationDetails?.length > 0
      }
    ];
    return payload;
  }

  static getMaxDate = (itemList: any) => {
    if (Utility.isEmpty(itemList)) {
      return new Date();
    }
    let maxDateProductObject = itemList?.reduce(function (a: any, b: any) {
      let firstDate = a?.expectedDeliveryDt || new Date();
      let secondDate = b?.expectedDeliveryDt || new Date();
      return firstDate > secondDate ? a : b;
    });
    // compoare it to new
    if (
      typeof maxDateProductObject?.expectedDeliveryDt !== 'undefined' &&
      maxDateProductObject?.expectedDeliveryDt !== null
    ) {
      return DateFormatService.getDateStrFromDate(
        maxDateProductObject?.expectedDeliveryDt,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
    } else {
      return DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
    }
  };

  static createPOPayload(
    selectedRow: any,
    taggedWHInventoryDetails:
      | {
          allItemsAvailableInTaggedWH?: undefined;
          totalAvailableStockInTaggedWH?: undefined;
          totalAvailableStockInAllWH?: undefined;
          nonTaggedWHStockSummary?: undefined;
          totalAlloted?: undefined;
          qytToBeRequested?: undefined;
          shortFallQty?: undefined;
        }
      | {
          allItemsAvailableInTaggedWH: boolean;
          totalAvailableStockInTaggedWH: any;
          totalAvailableStockInAllWH: number;
          nonTaggedWHStockSummary: any[];
          totalAlloted: number;
          qytToBeRequested: number;
          shortFallQty: number;
        }
      | null,
    props: IRawMaterialListProps,
    row: IRawMaterial,
    tableId: any,
    columnConfigDraft: any
  ) {
    let availableQty = selectedRow?.availableQty ?? 0;
    let requiredQty = selectedRow?.requiredQty ?? 0;
    let shortfallQty = Math.abs(Number(availableQty) - Number(requiredQty));
    let uomQuantity = Math.abs(Number(availableQty) - Number(requiredQty));
    if (
      Utility.isBinAllocationMandatory() ||
      Utility.isWarehouseTaggingEnabled()
    ) {
      shortfallQty = taggedWHInventoryDetails?.shortFallQty ?? 0;
    }

    let orderItems = [
      {
        ...OrderInitialState.purchaseOrderItems?.[0],
        product: {
          ...selectedRow?.itemName,
          documentUOMSchemaDefinition: selectedRow?.documentUOMSchemaDefinition
            ? {
                ...selectedRow?.documentUOMSchemaDefinition,
                schemaId: selectedRow?.uomSchema
              }
            : null
        },
        unitPrice: selectedRow?.itemName?.purchasePrice,
        productQuantity: shortfallQty,
        lineNumber: 1,
        productName: selectedRow?.itemName?.name,
        productCode: selectedRow?.itemName?.productId,
        documentUom: selectedRow?.itemName?.stockUom,
        documentUOMSchemaDefinition: selectedRow?.documentUOMSchemaDefinition
          ? {
              ...selectedRow?.documentUOMSchemaDefinition,
              schemaId: selectedRow?.uomSchema
            }
          : null,
        uomQuantity: uomQuantity,
        uomUnitPrice: selectedRow?.itemName?.purchasePrice,
        productDescription: '',
        pendingQuantity: 0,
        discountInPercent: false,
        userSetTaxes: false,
        expectedDeliveryDt: Utility.addDaysInDate(
          props.workOrderData.plannedStartDate,
          row?.itemName?.leadTime || 0
        )
      }
    ];

    let po: any = {
      ...OrderInitialState,
      purchaseOrderItems: orderItems,
      receiveByDate: RawMaterialHelper.getMaxDate(orderItems),
      showCustomAlert: true,
      updateResponseInStore: true,
      isNonShortFallSettingFlow: true,
      linkedDocuments: Utility.isNotEmpty(
        props.workOrderData.documentSequenceCode
      )
        ? [
            {
              documentType: WORK_ORDER_PR_PO.WORK_ORDER,
              documentCode: props.workOrderData.workOrderCode,
              documentSequenceCode: props.workOrderData.documentSequenceCode,
              documentExpiryDate: DateFormatService.getDateStrFromDate(
                props.workOrderData.deliveryDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
              productCodes: [],
              lineItemDetails: {
                1: {
                  productCode: orderItems[0].productCode,
                  itemProductCode: orderItems[0].productCode,
                  isSubstitute: false
                }
              }
            }
          ]
        : [],
      isCreateFromMRP: true
    };
    po = getUpdatedPurchaseOrderObject(po);
    let payloadData: any = {};
    payloadData.title = LABELS.PURCHASE_ORDERS;
    payloadData.type = LABELS.PURCHASE_ORDERS;
    payloadData.populateFormData = po;
    payloadData.isCenterAlign = true;
    payloadData.tableId = tableId;
    payloadData.columnConfig = columnConfigDraft;
    payloadData.hideMinimizer = true;
    return payloadData;
  }

  static getFlatArrayForObjectAndKey = (
    obj: any,
    key: string,
    type: ADVANCE_TRACKING
  ) => {
    let mapped: any[];
    if (type === ADVANCE_TRACKING.BATCH || type === ADVANCE_TRACKING.SERIAL) {
      mapped = obj?.map((item: any) => {
        return item?.[key]?.map((lineItem: any) => {
          return {
            ...lineItem,
            ...item,
            warehouseName: item.warehouseName,
            warehouseCode: item.warehouseCode
          };
        });
      });
    } else {
      mapped = obj?.map((item: any) => {
        return item?.[key];
      });
    }

    let flatArray = mapped?.flat() || [];
    return flatArray;
  };

  static createProductItemForTracking = (
    selectedItem: any,
    type: ADVANCE_TRACKING
  ) => {
    const requiredQuantity =
      Number(
        selectedItem?.requiredQty -
          calculateTotalSubstituteAlloted(selectedItem)
      ) || 0;
    return {
      product: selectedItem?.itemName,
      productCode: selectedItem?.itemName?.pid,
      documentSequenceCode: selectedItem?.itemName?.documentSequenceCode,
      requiredQuantity: requiredQuantity,
      productQuantity: requiredQuantity,
      advancedTracking: selectedItem?.itemName?.advancedTracking,
      advancedTrackingFulfilmentData:
        RawMaterialHelper.getFlatArrayForObjectAndKey(
          selectedItem?.warehouseInventoryData,
          'advancedTrackingData',
          type
        ),
      reservedQuantitiesData: RawMaterialHelper.getFlatArrayForObjectAndKey(
        selectedItem?.reservedQuantitiesData,
        'advancedTrackingMetaDtos',
        type
      ),
      isQuickCommit: false,
      documentUOMSchemaDefinition: selectedItem?.documentUOMSchemaDefinition
    };
  };

  static getFilteredProducts(
    productsArray: any[],
    workOrder: IWorkOrder,
    isRawMaterial: boolean
  ) {
    let products =
      workOrder?.workOrderItems?.filter((item: any) =>
        isRawMaterial
          ? item.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
          : item.produceProductType !== PRODUCE_PRODUCT_TYPE.NONE
      ) ?? [];
    let selectedProductIds = products?.map(
      (current: any) =>
        current?.pid ?? current?.itemName?.pid ?? current?.itemName?.productId
    );
    selectedProductIds.push(workOrder?.productCode);
    // if (product?.id) {
    //   selectedProductIds.push(product.id);
    // }
    let filtered =
      productsArray?.filter(
        (prodItem: any) =>
          prodItem.active &&
          !selectedProductIds?.includes(prodItem.productId) &&
          prodItem?.hasVariants === false
      ) || [];
    return filtered;
  }

  static getFilteredOperations = (
    response: any[],
    selectedOperations: any[]
  ) => {
    let selectedOperation =
      selectedOperations?.reduce((prev: any, current: any) => {
        return [...prev, current?.operationName?.id];
      }, []) || [];
    let filtered =
      response?.filter(
        (operation: any) => !selectedOperation.includes(operation.id)
      ) || [];
    return filtered;
  };
}
